import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    size: window.innerWidth,
}

export const WindowSize = createSlice({
  name: 'Size',
  initialState,
  reducers: {
    WindowResize : (state,action) => {
        state.size = action.payload;

    },
   
  },
})
export const { WindowResize   } = WindowSize.actions

export default WindowSize.reducer