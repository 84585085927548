import React, { useEffect, useRef, useState } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import likes from '../../img/thumbsup.png'
import Insufficient from "../pages/Modalinsuff";
import tags from "../../img/tags.png"
import dislike from '../../img/thumbsdown.png'
import MovingText from 'react-moving-text'
import { useNavigate } from "react-router-dom";
import { FaExclamationCircle, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { PacmanLoader } from "react-spinners";
import { useSelector } from "react-redux";
import   ChatInterface from '../pages/Chatbot'

const Purchase = () => {
    const Navigate = useNavigate()
    useEffect(() => {
        if (localStorage.getItem('Product_Id')) {
            localStorage.removeItem('cartID')
        } else {
            localStorage.removeItem('cartID')
             Navigate(`/Marketplace`, { replace: true });

        }


    }, [])
    
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
    const product_id = localStorage.getItem('Product_Id')
    const [purcahseItem, setpurcahseItem] = useState([])
   
    /*********************** PURCHASE PRODUCT *******/
 const vendor=purcahseItem.vendor_id
const amountdeposit= purcahseItem.amountdeposit
 const[buyProduct,setbuyProduct]=useState(
    {
        product_id:product_id,   
        user_id:userId,
        vendor_id:null,
        amountdeposit:null

       
    }
 )
//  console.log(buyProduct)
 const [crytoMODEL,setcrytoMODEL]=useState({
    product_id:product_id,   
    vendor_id:null,
    amountdeposit:null,
    currency:null
 })
   /*================ Delivery Service ==============*/
  const [deliveryAmount,setdeliveryAmount]=useState({})
  const [delivery_type, setdelivery_type] = useState(null);
  const [shipping, setshipping] = useState();
  function DeliveryInformation(event)
  {
    const{value,name,type}=event.target
   
    setbuyProduct(prev=>({
        ...prev,
        vendor_id:vendor,
        product_id:product_id, 
        user_id:userId,
        delivery_type:delivery_type,
        amountdeposit:amountdeposit,
        shipping:shipping,
        [name]:type === 'checkbox' ? 'checkbox' : value
          }))
          setcrytoMODEL(prev=>({
            ...prev,
            user_id:userId,
            delivery_type:delivery_type,
             shipping:shipping,
            [name]:type === 'checkbox' ? 'checkbox' : value
              }))
  }
//   console.log(crytoMODEL)
function handleCheckboxChange(status,shipped) {
setdelivery_type(status);
setbuyProduct(prev=>({
    ...prev,
    delivery_type:status,
    shipping:shipped
})
)
setcrytoMODEL(prev=>({
    ...prev,
    delivery_type:status,
    shipping:shipped
})
)
setshipping(shipped)
}
useEffect(() => {
  async function DeliveryAmunt() {
      axios.
          get(`api/deliveryFee`)
        .then((response) => {
         
              setdeliveryAmount(response.data)
          })
  }
  DeliveryAmunt()
}, [])
 const[readToBuy,setreadToBuy]=useState(false)
function PurchaseCartItem(e)
{
   e.preventDefault()
    setSuccessMsg()
    setreadToBuy(true)
//     setbuyProduct(prev=>({
//   ...prev,
//   vendor_id:vendor,
//   amountdeposit:amountdeposit,
//   shipping:shipping
//     }))
}
const[insufficientModal,setinsufficientModal]=useState(false)
const CloseModal=()=>setinsufficientModal(false)
const[successMsg,setSuccessMsg]=useState()
const [Errored,setErrored]=useState(false)
const [Timing,setTiming]=useState(false)

/*=============== Close Purchase After purchase ==============*/
useEffect(() => {
    let interval;
    const startTiming = async () => {
      setTiming(true);
    };
  
    async function PurchaseCart (){
      if (readToBuy) {
        axios
          .post(`api/purchase`, buyProduct)
          .then((response) => {
            // console.log(response)


            if (response.data.status == 500) {
              setErrored(true);
              setSuccessMsg(response.data.data);
              setreadToBuy(false);
            } else if (response.data.status == 200) {
              setErrored(false)
              startTiming(); // Start the timing after successful purchase
              setreadToBuy(false);
              setSuccessMsg(response.data.data);
              setTimeout(() => {
                localStorage.removeItem('Product_Id');
                Navigate(`/Marketplace`, { replace: true });
              }, 7000); // Remove the item from localStorage after 1 minute
            } else if (response.data.status == 419) {
              setreadToBuy(false);
              setErrored(true);
              setinsufficientModal(true);
            }
          });
      }
    };
  
    PurchaseCart();
  
    // Clean up the interval on unmount
    return () => clearInterval(interval);
  }, [buyProduct, readToBuy]);
  
    /***************** END  ***************/
/******************* EXCHANGE WITH BTC OR ETH ********/
const [Crypto,setCrypto]=useState({})
const [rateCrypto,setrateCrypto]=useState()
const [exchangeRate,setexchangeRate]=useState()

/************ Loading Dots *****/
const [loading, setLoading] = useState(false);
const [dots, setDots] = useState("");

useEffect(() => {
  let intervalId;
  if (loading) {
    intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots == "...") {
          return ".";
        } else if (prevDots == "..") {
          return "...";
        } else {
          return "..";
        }
      });
    }, 500);
  } else {
    setDots("");
  }

  return () => clearInterval(intervalId);
}, [loading]);

/*****************  END  *********/

const[ReadyToExchange,setReadyToExchange]=useState(false)

function CryptoChange(event)
{
    setReadyToExchange(false)
    setexchangeRate(true)
    setLoading(true)
    setcrytoMODEL(prev => ({
        ...prev,
        vendor_id:vendor,
        amountdeposit:purcahseItem.amountdeposit,
        currency:event.target.value
        
    }))

    setCrypto(prev => ({
        ...prev,
        amount:parseFloat(purcahseItem.amountdeposit) + parseFloat(shipping),
        currency:event.target.value
    }))
}

/**************** COnversion rate from usd to eth or btc or NG*/
  useEffect(() => {
        async function ExchangeRate() {
            if(exchangeRate)
            {
            axios.
                post(`api/ExchangeConversion`,Crypto)
                .then((response) => {
                    if(response.data.status == 200)
                    {
                        setReadyToExchange(false)
                        setLoading(false)
                    setrateCrypto(response.data.data);
            }
            else{
                setReadyToExchange(false)
                setLoading(false)
                setrateCrypto(response.data.data);
            }
        })
        }
    }
        ExchangeRate()
        // return(()=>{
        //     setReadyToExchange(false)
        // })
    }, [Crypto,exchangeRate])
    /****************** END  ***************/
/****************** END *********/

/************** BUY WITH SUFFICIENT CRYPTOCURRENCY *******/

/**** wallets balances */
const{wallets,History,dataNotFound}=useSelector((state)=>state.Balanceslice)
   
/******************* Purchase with BTC OR ETH  OR NGN ***********/
function ExchangeCurrencyPurchase(e)
{
    e.preventDefault()
 setReadyToExchange(true)
}

useEffect(() => {
    let interval;
    const startTiming = async () => {
      setTiming(true);
    };
    async function ExchangeRate() {
        if(ReadyToExchange )
        {
        
          axios.
            post(`api/CurrencyExchange`,crytoMODEL)
            .then((response) => {
                // console.log(response)
                if(response.data.status == 419)
                {
                setReadyToExchange(false)
                setLoading(false)
                setrateCrypto(response.data.data);
                }
        else  if(response.data.status == 201)
        {
            setReadyToExchange(false)
            setLoading(false)
        setrateCrypto(response.data.data);
}
        else{
            setReadyToExchange(false)
            setErrored(false) 
               startTiming(); // Start the timing after successful purchase
         setLoading(false)
            setrateCrypto()
            setSuccessMsg(response.data.data)
            setinsufficientModal(false)
            setTimeout(() => {
                localStorage.removeItem('Product_Id');
                Navigate(`/Marketplace`, { replace: true });
              }, 7000); // Remove the item from localStorage after 1 minute
       }
    })
    }
}
    ExchangeRate()

     // Clean up the interval on unmount
     return () => clearInterval(interval);
}, [crytoMODEL,ReadyToExchange])

/****************** END ****************/
    /*************** GET PRODUCT / VENDOR DETAILS ****/
    useEffect(() => {
        async function PurchaseItem() {
            axios.
                get(`api/product_Details/${product_id}`)
                .then((response) => {
                    setpurcahseItem(response.data);
                })
        }
        PurchaseItem()
    }, [])
    const slides = purcahseItem.slide_image
    const slideImage = slides && slides.map((Element,index) => {
        return (
            <div className="slide-img" key={index}>
                <img src={Element} 
                onClick={() => {document.querySelector('.display-img').src = Element}}
                alt="" />

            </div>
        )
    })
    const disstyled={
        cursor:'not-allowed',
      
      }
      /*========== TOTAL PRICE =============*/
    function TotalAmount()
    {
        
        if (purcahseItem && purcahseItem.product_price) {
            if(shipping)
            {
                const amount = parseFloat(purcahseItem.product_price.replace(/,/g, ''));
      const newAmount = amount + parseFloat(shipping.replace(/,/g, '') || 0);
         return newAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     
            }
            else{
                return purcahseItem.product_price
            }
        
        }
        else{
            return 0
        }
    }
    /*************** END *****/
   
const { size }=useSelector((state)=>state.WindowSize)
const { showChat }=useSelector((state)=>state.Chatslice)

    return (
        <div style={showChat  && size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
     
            <Auth />
            <div className="container-fluid">
                <Sidebar />
                <header>
                    <Header />
                </header>
                <hr />
         
                <div className="flexbox">
                    <div className="flex left">
                  
                        <div className="product">
                            <div className="image">
                                <img className='display-img ' src={purcahseItem.product_imG} alt="" />
                            </div>

                            <div className="slide">
                                {/* slide image */}
                                {/* <a onClick={} */}
                                {slideImage}
                            </div>

                            {/* END */}
                            <div className="tags">
                                <img src={tags} alt="tag" />
                                <div className="tag-items">
                                    {/* <p>games</p>
                            <p>xbox</p> */}
                                    <p>{purcahseItem.product_tag}</p>
                                </div>
                            </div>
                            <div className="condition">
                           
                                <h3> Condition </h3>
                                <p>{purcahseItem.product_condition}</p>
                            </div>
                            <div className="description">
                                <h1>Ad Description</h1>
                                <textarea name="" id="" disabled placeholder={purcahseItem.product_description}></textarea>


                            </div>
                        </div>
                    </div>

                    <div className="flex right">
                        {/* SUCCESS MSG */}
               {successMsg ?
 <MovingText
 type="slideInFromRight"
 duration='350ms'
 delay='0s'
 direction='alternate'
 className={Errored ? "errormsg" : "sucessmsg"}
  iteration='1'
 fillMode='none'>
                          <p>                       
                            {successMsg}
                            </p>
 
                            </MovingText>
                            : ''
               }
                    {/* END */}
                        <div className="head">
                       
                            <h1>{purcahseItem.product_name}</h1>
                            <p className="categories">{purcahseItem.product_category}</p>
                        </div>
                        <div className="price">
                            <p className="amount">{TotalAmount()} USD</p>
                        </div>
                        <div className="toggle-box">
                    <div className="toggle-items">                        <input
      type="checkbox"
      name="deliver"
      checked={delivery_type === '1'}
      onChange={() => handleCheckboxChange('1',0)}
    />
   <p>Seller Delivery</p>
                        <p>P.O.D</p>
                    </div>
                    {/* <div className="toggle-items">
    <input
      type="checkbox"
      name="deliver"
       checked={delivery_type === '0'}
      onChange={() => handleCheckboxChange('0',`${deliveryAmount.fee}`)}
    />
     <p>Squaremart Delivery</p>
                        <p>{deliveryAmount.fee} USD</p>
                    </div> */}
                </div>
                        {/* <div className="btn">
                        
                            <button disabled={readToBuy ? 'disabled': null} style={readToBuy  ? disstyled : null} onClick={PurchaseCartItem}><span>{readToBuy ? 'Please wait...' : 'BUY NOW'}</span></button>
                        </div> */}
                        {/* <div class="dashboard"> */}
                      {! delivery_type ?      <table>
                        <thead>
                        <tr className="head">

<td id="image">
<p className="image"> {purcahseItem.letter} </p>

</td>
<td className="float-right">
<p id="name">
{purcahseItem.vendor_name}</p>
<div className="info">
<p>online</p>
<p>professional</p>
</div>

</td>

</tr>
                        </thead>
                                
                                    
<tbody>
<tr>
                                    <td id="bordered">
                                        Number of successful trade
                                    </td>
                                    <td id="bordered">
                                        {purcahseItem.successful_trade}
                                    </td>
                                </tr>
                                <tr>
                                    <td id="col3">
                                        Account created

                                    </td>
                                    <td>
                                        {purcahseItem.time_join}
                                    </td>
                                </tr>
                                <tr>
                                    <td id="col3">
                                        Feedback summary
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                <span>
                                                    <FaThumbsUp style={{ color: '#5d9c59' }} /> + {purcahseItem.likes}
                                                    {/* <img src={likes} alt=""/> */}


                                                </span>
                                            </li>
                                            <li>
                                                <span>
                                                    {/* <img src={dislike} alt=""/> */}
                                                    <FaThumbsDown style={{ color: '#df2e38' }} /> -{purcahseItem.dislike}

                                                </span>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td id="bordered">
                                        Identity verification
                                    </td>
                                    <td id="bordered">
                                        Verified
                                    </td>
                                </tr>
                                <tr>
                                    <td id="col3">
                                        Average response time
                                    </td>
                                    <td>
                                        6 minutes
                                    </td>
                                </tr>
</tbody>
                              
                            </table>
:
                         
                            <div className="delivery-info">
                    <h1>Delivery Information</h1>
                        <form action="" onSubmit={PurchaseCartItem}>
                    <div className="delivery-form">

                            <div className="form-input">
                                <label htmlFor="">Country</label>
                                <input type="text" 
                                onChange={DeliveryInformation}
                                name="shipping_country"
                                required id=""/>
                            </div>
                            
                            <div className="form-input">
                                <label htmlFor="">Phone Number</label>
                                <input type="tel" required
                                    onChange={DeliveryInformation}
                                     name="phone_No" id=""/>
                            </div>

                            <div className="form-input">
                                <label htmlFor="">Full Address</label>
                                <input type="text"
                                 onChange={DeliveryInformation}
                                  required 
                                  name="shipping_address" id=""/>
                            </div>
                           
                        </div>
                            {/* <input type="submit" value="proceed"/> */}
                            <div className="btn">
                            {/* <button><span>{readToBuy ? 'Please wait...' : 'BUY NOW'}</span></button>   */}
                        <button disabled={readToBuy ? 'disabled': null} style={readToBuy  ? disstyled : null}><span>{readToBuy ? 'Please wait...' : 'BUY NOW'}</span></button>
                    </div>
                        </form>

                   
                </div>
}
                        {/* </div> */}
                    </div>
                </div>
                {/* INSUFFIENCT MODAL rateCrypto*/}
               {insufficientModal ? <Insufficient
               toggle ={CryptoChange}
               condition={rateCrypto}
               btnPurchase={ReadyToExchange}
               CurrencyPurchase={ ExchangeCurrencyPurchase}
               rates = {loading ? `Loading${dots}` : rateCrypto && `${rateCrypto}`}
      amount={TotalAmount()}
               disableModal={CloseModal}/> : ''} 
         
            </div>
            {/* CHAT LIVE SUPPORT */}
            <ChatInterface/>
        </div>
    )
}
export default Purchase 
