import axios from "axios";
import React, { useContext,useEffect, useState } from "react";
import {AiTwotoneSetting,AiOutlineLoading,AiOutlineLogout,AiFillBell} from 'react-icons/ai'
import { useNavigate ,useLocation, Link} from "react-router-dom";
import Internet from "./Offline";
import { ThemeContext } from "./ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../Redux/MoodlightSlice";
import { WindowResize } from "../Redux/WindowSize";
import userimg from "../../img/user-img.png";
import { Setprofile, setpayoutAddress } from "../Redux/ProfilePageslice";
import { FaExclamationCircle } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
const Header=()=>
{
  const{theme}=useSelector((state)=>state.Modelight)
  const dispatch=useDispatch()
  const handleThemeChange = () => {
      const newTheme = theme == "light" ? "dark" : "light";
      dispatch(changeTheme(newTheme));
    };

    const location = useLocation();
    const currentPath = location.pathname; 
    const token =sessionStorage.getItem("authenticated")
    // const[profile,setprofile]=useState([])
    const profile = useSelector((state) => state.ProfilePageslice.profile);
    const userId= `${profile.id}`
   
    const {unreadMsg}=useSelector((state=>state.Inboxslices))
    const [IsFirstRender,setIsFirstRender]=useState(true)
    const [IsFirstRenderprofile,setIsFirstRenderprofile]=useState(true)

    const Navigate=useNavigate()
   /******************** PAYMENT ADDRESS *******/
//    useEffect(() => {
//     const fetchData = async () => {
//         try {
//             // await payoutSetting();
//             await userProfile();
//         } catch (error) {
//             // console.error('Error fetching data:', error);
//         }
//     };

//     // Fetch data initially
//     fetchData();

//     // Fetch data every 50 seconds
//     const intervalId = setInterval(fetchData, 50000); // 50 seconds in milliseconds

//     // Clean up interval to avoid memory leaks
//     return () => clearInterval(intervalId);
// }, [dispatch, Setprofile]);
   
/***************** PAYOUT SETTING ************/
//         useEffect(()=>{
//     async function PayoutSetting(){
//         if(IsFirstRender){
//         axios.get("api/PayoutSetting")
//             .then((response)=>{
//        dispatch(setpayoutAddress(response.data));
//         setIsFirstRender(false);
//     } )
// }
// }     PayoutSetting()
//         },[dispatch,setpayoutAddress])



    /************LOG OUT */
function Logout()
{
  sessionStorage.removeItem("Prodile_name")
    sessionStorage.removeItem("authenticated")
     Navigate('/login',{replace :true})
         
}
    /******* End ******/
    // const[isloaded,setisloaded]=useState(true)

    /***************** PROFILE ********/
   
// const userProfile = async () => {
//   try {
//       const response = await axios.get("api/profile");
//       setIsFirstRenderprofile(false);
//       if (response.data.status === 200) {
//           setisloaded(false);
//       } else {
//           setisloaded(true);
//       }
//       dispatch(Setprofile(response.data.users));
//   } catch (error) {
//       // console.error('Error fetching user profile:', error);
//   }
// };
     sessionStorage.setItem("Prodile_name",`${profile.name}`)
   
     /**************** END  ************/
     
    /******************* WINDOW SIZE *****/
    const{size}=useSelector((state)=>state.WindowSize)
  
    const{Attempt}=useSelector((state)=>state.Createslice)
    useEffect(()=>{
  function windowWidth(){
    dispatch(WindowResize(window.innerWidth))
  }
  window.addEventListener("resize", windowWidth);
  return function () {
    window.removeEventListener("resize", windowWidth);
  };   
},[])
    return (
    <>  <div className={profile.name && !Attempt ? null :"loading-wave"}></div>
         <div className={`header ${theme}`}>
               <Internet/>
        <div className="profile">
          <div className="img-profile">
          <img src={userimg} alt="" />
          <div className="img-text">
            <p>Hi  {size > 960 ? <>{profile.name}</>: <>{profile.mobile_name}</>},</p>
            <small>Welcome Back</small>
          </div>
          </div>
            {/* <div className="pro-picture">
            <p>{profile.letter}</p>   
            <small>Welcome back</small>
            </div>
            <div className="profile-name">
                  {size > 960 ? <p>{profile.name}</p> : <p>{profile.mobile_name}</p>}   
          
            </div> */}
           
        </div>
      <div className="bellout">
        <span><Link to='/inbox' style={{color:'black',cursor:'pointer'}}><AiFillBell/></Link></span>
        <small>{unreadMsg}</small>

      </div>
        
        <div className="logout"> 
         {/* MANY REQUEST */}
          
            <a style={{cursor:'pointer'}} onClick={Logout}>
               <span><HiOutlineLogout/></span> 
        <span>{size > 767 ? 'Logout' : null}  </span> 
            </a>
          </div>
       
    </div> 
    
    </>
    )
}
export default Header
