import React, { useEffect, useState } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import copy from 'clipboard-copy';
import { Link, useNavigate } from "react-router-dom";
import MovingText from 'react-moving-text'
import Payout from "./Payout";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import Notification from "./Notificaton";
import { FaBell, FaLock, FaLongArrowAltUp, FaTimes, FaUser, FaWallet } from "react-icons/fa";
import { SettingNav } from "./SettingNav";
import { useDispatch, useSelector } from "react-redux";
import { setSettingNav } from "../Redux/Createslice";
const Setting=()=>
{
     /*======================== 2FA ENABLED ================*/
    const {SettingNav}=useSelector((state)=>state.Createslice)

     const [Enable2fa,setEnable2fa]=useState()
     const[QRCODE,setQRCODE]=useState()
     function Acivate2FA()
     {
     setEnable2fa(true)
     }
     
     useEffect(()=>{
     async function TwoStepfactor(){
          if(Enable2fa)
      {
          axios.get(`api/setup2FA`)
      .then((response) => {
        setEnable2fa(false)
        // console.log(response.data.QR_Image)
        setQRCODE(response.data)
      })
       }
  }
  TwoStepfactor()
     
    },[Enable2fa])
    const textToCopy = `${QRCODE ? QRCODE.secret : ''}`
    const[copied,setcopied]=useState(false)
     const handleCopy = () => {
    copy(textToCopy);
    setcopied(true)
    //alert('Secret Key copied to clipboard!');
  };
     /*================= END ======================*/
    const [switching,setswitching]=useState(1)
    const [newpin,setnewpin]=useState([])
    const[Readypin,setReadypin]=useState(false)
    const [errorField, setErrorField] = useState()
   const[errorstatus,seterrorstatus]=useState()
   const [fielderror,setfielderror]=useState()
    function switchedSet(id)
    {
      dispatch(setSettingNav(false))
        setswitching(id)
        setfielderror()
        setnewpin()
        setQRCODE()
        setReadypin(false)
        setErrorField()
      seterrorstatus()
    }
    /*========= NEW PASSWORD ================*/
   
    function PasswordChange(event)
    {
      const {value,type,name}=event.target
      setErrorField(false)
      seterrorstatus()
      setfielderror()
      setnewpin((nextpin)=>{
        return {
              ...nextpin,
              [name]:type == 'checkbox' ? 'checkbox' :value
        }
      })
     
    }
     function Handlesubmit(e)
     {
      e.preventDefault()
      setReadypin(true)
      seterrorstatus()
      setfielderror()
      setErrorField(false)
     } 
    //  console.log(errorField)
     useEffect(()=>{
      async function ChangePassword()
      {
        if(Readypin)
        {
          axios.post('api/Newpassword',newpin)
        .then((response)=>{
          if(response.data.status == 415){
          
            setfielderror(response.data.errors)
            setReadypin(false)
            setErrorField()
         
        }
        else  if(response.data.status == 501){
          setReadypin(false)
          setfielderror()
          setErrorField(response.data.errors)
          }
          else{
            setfielderror(response.data.errors)
            setReadypin(false)
            setErrorField()
          seterrorstatus(response.data.status)
          }
        })
        }

      }
      ChangePassword()
     },[Readypin])
    /*============ END =============*/
    function condition(){
        if(switching == 1)
        {
            return (
        <div className="change-pin">
        <h1>Change pin</h1>
        <form  method="post" onSubmit={Handlesubmit}>
          <div className="flex-pin-box">
            <div className="pin-box">
              <label>Current pin</label>
              <input type="text"
                onChange={PasswordChange} name="password" id="" />
            </div>
            <div className="pin-box">
              <label>New pin</label>
              <input type="text" 
              onChange={PasswordChange}
              name="new_password" id="" />
            </div>
          </div>
          <div className="submit">
                <button type="submit"><span>{Readypin ? 'Please wait' : 'Proceed'}</span></button>
                </div>
          {/* <div className="btn">
            <input type="submit" value="Proceed" />
          </div> */}
        </form>
      </div> 
            )
        }
        else if(switching == 2)
        {
          if(QRCODE){
            return (
            <>
                                <p className='factor-text'>Set up your two factor authentication by scanning the barcode below Or copy the Secret Key to Google authenticator setup.
                                  {QRCODE ?   <p  className='factor-copy'>Secret Key : {textToCopy}</p>
                                  :
                                  null 
            }
                                  {copied ?
                                  <div className="submit">
                                   <button><span>Copied</span></button> </div> :
                              <div className="submit">
  <button onClick={handleCopy}><span>Copy Secret Key</span></button> </div>  
            }
                                   </p>
          <div className='qrcode' style={{ textAlign:'center' ,marginTop:'1em'}}>
          {QRCODE && <QRCodeSVG value={QRCODE.QR_Image}  />}
            </div> 
            </>  
            )
           
        }
        else{
        return (
          <>
            <p className='factor-text'>You must set up your Google Authenticator app before continuing, and always scan your QRCODE whenever Enable 2FA. You will be unable to login otherwise</p>
              <div>
              <div className="submit">
              <button type="submit" style={{cursor:'pointer'}} onClick={Acivate2FA}>
              <span>Enable 2FA</span></button>
                </div>    
                       </div></>
      
       )
              
        }
      }
     else if(switching == 3){
       return <Notification/>
      }
      else if(switching == 4){
  return <Payout/>
      }
    }
    //navbar setting
    const dispatch = useDispatch();
    //  setting NAVBAR
    function SettingNavclose()
    {
      dispatch(setSettingNav(false))
    }
    /************LOG OUT */
    const Navigate=useNavigate()
   function Logout()
   {
     sessionStorage.removeItem("Prodile_name")
       sessionStorage.removeItem("authenticated")
        Navigate('/login',{replace :true})
            
   }
       /******* End ******/
    return (
        <>
        <Auth />
        <div className="container-fluid" style={SettingNav ? {position: 'fixed'} :null}>
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            {/* setting navbar */}
            {SettingNav ? <> <div className="coverback"> 
            <div className="head">
    <h1> <Link  onClick={SettingNavclose}><FaTimes/></Link> <span>Settings</span></h1>
</div>
<div className="set-mobile-drop">

    <div className="set-mobile-items">
        <div className="span-icon" onClick={()=>switchedSet(1)}>
            <span><FaUser /></span>
            <p>Change Password</p>
        </div>
        <div className="arrow-right">
            <IoIosArrowForward/>
        </div>
    </div>
    <div className="set-mobile-items">
        <div className="span-icon" onClick={()=>switchedSet(4)}>
            <span><FaWallet/></span>
            <p>Payout Settings</p>
        </div>
        <div className="arrow-right">
        <IoIosArrowForward/>
        </div>
    </div>
    
    <div className="set-mobile-items">
        <div className="span-icon" onClick={()=>switchedSet(3)}>
            <span><FaBell/></span>
            <p>Notifications</p>
        </div>
        <div className="arrow-right">
        <IoIosArrowForward/>
        </div>
    </div>
    <div className="set-mobile-items">
        <div className="span-icon" onClick={()=>switchedSet(2)}>
            <span><FaLock /></span>
            <p>Enable 2FA</p>
        </div>
        <div className="arrow-right">
        <IoIosArrowForward/>
        </div>
    </div>
    <div className="set-mobile-items">
        <div className="span-icon" onClick={Logout}>
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17 2H7C5.3 2 4 3.3 4 5V11H12.6L10.3 8.7C9.9 8.3 9.9 7.7 10.3 7.3C10.7 6.9 11.3 6.9 11.7 7.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7L11.7 16.7C11.3 17.1 10.7 17.1 10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3L12.6 13H4V19C4 20.7 5.3 22 7 22H17C18.7 22 20 20.7 20 19V5C20 3.3 18.7 2 17 2Z" fill="#118AB2"/>
</svg>
            </span>
            <p>Logout</p>
        </div>
        <div className="arrow-right">
        <IoIosArrowForward/>
        </div>
    </div>
    </div>
</div></>: null}
            

             {/* END */}
            {/* <SettingNav/> */}
    
        <div className="settings">
        <div className="flex-box">
          <div className={switching == 1 ? "items active" : 'items'} onClick={()=>switchedSet(1)}>
            <a >Change Password</a>
          </div>
          <div className={switching == 2 ? "items active" : 'items'} onClick={()=>switchedSet(2)}>
            <a >Enable 2FA</a>
          </div>
          <div className={switching == 3 ? "items active" : 'items'} onClick={()=>switchedSet(3)}>
            <a >Receive Notification</a>
          </div>
          <div className={switching == 4 ? "items active" : 'items'} onClick={()=>switchedSet(4)}>
            <a >Payout settings</a>
          </div>
        </div>
        {/* ERROR MSG */}
        {errorField || fielderror ? 
        <MovingText
                        type="slideInFromRight"
                        duration='350ms'
                        delay='0s'
                        direction='alternate'
                        //    timing='ease'
                        iteration='1'
                        fillMode='none'>
                          <div className={errorstatus == 201 ? 'sucessmsg' :"error-field"}>
                      {errorField ? Object.keys(errorField).length > 0 && (
                                <div>
                                    {Object.keys(errorField).map((key) => (
                                        errorField[key].map((error, index) => (
                                            <div className={errorstatus == 201 ? 'succMsg' :"error"} key={`${key}-${index}`}>
                                                {error}
                                            </div>
                                        ))
                                    ))}
                                </div>
                            ) : ''}
  { fielderror ?
                                <div className={errorstatus == 201 ? 'succMsg' :"error"}>
                                    {fielderror}
                                </div> : ''}
                        </div> </MovingText>
                        : null} 
{/* SETTING */}

            {condition()}
      </div>
    </div>
    </>
    )
}
export default Setting