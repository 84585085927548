import React, { useEffect, useState } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Profile_img from '../../img/Group 18@2x.png'
import logosms from '../../img/squreinbox.png'
import { FaInbox } from "react-icons/fa";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MdOutlineForwardToInbox } from "react-icons/md";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setInboxMSg, setdataNotFoundInbox } from "../Redux/Inboxslices";
import Balance from "./Balance";
import { setAttempt } from "../Redux/Createslice";

const Inboxsms=()=>
{
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
    // console.log(userId)
    
    const Navigate = useNavigate();
    /*********** VIEW CLICK MESSage ***********/
    const[dataId,setdataId]= useState(
        { sms_id: null, 
            activities: null }
            );
    const[Ready,setReady]=useState(false)
    
    function SMSId(sms_id,activities)
    {
        dispatch(setAttempt('Loading...'))
        setdataId((previous)=>{
            
                const Data = {
                    ...previous,
                    sms_id: sms_id,
                    activities: activities,
                        };
                return Data;

        } )
        setReady(true)

    }
    //  console.log(dataId.activities)
     const prolink = `/inboxRead/${dataId.sms_id}/${dataId.activities}`;
             
        /*********************** Unread Action  ************/
       
        useEffect(()=>{
            async function Messages(){
                if((Ready) && (dataId.activities))
                {
                axios
                .get(`api/messages_view/${dataId.sms_id}/${dataId.activities}`)
            .then((response) => {
                 Navigate(`${prolink}`, { replace: true });
              
            })
        }  
        }
        Messages()
        },[Ready,dataId])

        /**************************** END *******/
    useEffect(() => {
    const Messagedata = async () => {
        try {
         
            // await fetchmessages();
        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    };

    // Fetch data initially
    Messagedata();

    // Fetch data every 50 seconds
    const intervalId = setInterval(Messagedata, 50000); // 50 seconds in milliseconds

    // Clean up interval to avoid memory leaks
    return () => clearInterval(intervalId);
    
}, [setInboxMSg, setdataNotFoundInbox]);

const fetchmessages = async () => {
    try {
        const response = await axios.get('api/sms_messages');
        
        dispatch(setInboxMSg(response.data));
        dispatch(setdataNotFoundInbox('Inbox Empty'));
    } catch (error) {
        // console.error('Error fetching messages:', error);
    }
};
       /************* INBOX MESSAGES  ***********/
   const{InboxMSg,dataNotFound}=useSelector((state)=>state.Inboxslices)
   const dispatch=useDispatch()
   

    const messages=InboxMSg.map((element,index)=>{
        let msgIconClass = "";  let statusbar = "";
if(((element.subject == 'Delivered') && (element.msg_read_approve == 2)))
{
    msgIconClass=
    <div className="msg-info">
    <p>{element.time}</p>
    <small className="read"></small>
</div>
}
else if(((element.subject == 'Swap') && (element.msg_read_approve == 2) && (element.user_id == userId)))
{
    msgIconClass=
    <div className="msg-info">
                            <p>{element.time}</p>
                            <small className="read"></small>
                        </div>
}
else if(((element.subject == 'Swap') && (element.msg_read) && (element.vendor_id == userId)))
{
    msgIconClass=
    <div className="msg-info">
                            <p>{element.time}</p>
                            <small className="read"></small>
                        </div>
}
else if((element.msg_read) && (element.subject != 'Delivered') && (element.subject != 'Swap')){
    msgIconClass=
    <div className="msg-info">
                            <p>{element.time}</p>
                            <small className="read"></small>
                        </div>
}
else{
    msgIconClass=
    <div className="msg-info">
    <p>{element.time}</p>
    <small className="unread"></small>
</div>
}
   if(element.status === 'Pending')
   {
    statusbar= <big className="pending">{element.subject}</big>
   }
   else if(element.status === 'Success')
   {
    statusbar= <big className="success">{element.subject}</big>
   }
   else {
    statusbar= <big className="cancel">{element.subject}</big>
   }
        return (
            <div className="msg-item" key={index}  onClick={()=>SMSId(element.id,element.activities)}>
            <div className="msg-content">
               {statusbar}
                    <small>{element.message} </small>
            </div>
            { msgIconClass}

        </div>

        )
    })
    /*************** END *******/
    return (
        <>
        <Auth />
{/*         <Balance/> */}
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            {/* INBOX */}
            <div className="inbox">
             
             <div className="avatar">
                 <div className="img">
                  <MdOutlineForwardToInbox/>
                 </div>
                 <div className="profile-name">
                     <h3>NOTIFICATIONS</h3>
                     <p>sms box</p>
                 </div>
            
         </div>
         <div className="notification-msg">
           <div className="nt-msg-header">
            </div>
            <div className="notice-msg">
            {InboxMSg.length > 0  ? messages  : 
         <div className="empty-table">{dataNotFound}</div>}

                   

            </div>
        </div>
         {/* <div className="notifications">
           
         {InboxMSg.length > 0  ? messages  : 
         <div className="empty-table">{dataNotFound}</div>}
         </div> */}
     </div>
            </div>
            </>
    )
}

export default Inboxsms
