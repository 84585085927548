import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logos from '../../img/squaremart-logo.png'
import { setmessage } from "../Redux/Createslice";
import Footer from "./Footer";
import { FaBitcoin, FaQuestion, FaTimes } from "react-icons/fa";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { SiCoinmarketcap } from "react-icons/si";
import Balance from "../Dashboard/Balance";
import ScrollToTop from "./ScrollToTop";
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'

const TwoFactor=()=>
{
    useEffect(()=>{
        if(sessionStorage.getItem("TOTP")){
          setTimeout(() => {
            sessionStorage.removeItem("TOTP")
            Navigate('/login', { replace: true })
          },180000); 
        }
    },[])
    useEffect(() => {
          if (!(sessionStorage.getItem("TOTP"))) {

            Navigate('/login', { replace: true })
        }
    },[])
    const Navigate = useNavigate()
    const [GoogleCode,setGoogleCode]=useState()
    const[Loading,setLoading]=useState(false)
    function HandlerSubmit(e)
    {
        e.preventDefault()
        setLoading(true)
        dispatch(setmessage())
    }
  
function Twoverification(event)
{
    setLoading(false)
    dispatch(setmessage())
    const { name, value, type, checked } = event.target;

    setGoogleCode((prevData) => {
            const updatedData = {
                ...prevData,
                email:sessionStorage.getItem('emailfactor'),
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
}
const dispatch=useDispatch()
    useEffect(()=>{
        async function StepVerification()
        {
            if(Loading)
            {
        axios.post(`api/verificationTwoFactor`,GoogleCode)
        .then((res)=>{
          
            if(localStorage.getItem('cartID')){
                if(res.data.status == 401)
                {
                    setLoading(false)
                    dispatch(setmessage(res.data.data))
                }
                else   if(res.data.success == true){
                sessionStorage.setItem("authenticated", res.data.token);
                Navigate('/Purchase', { replace: true })
                }
             }
            
             else if(res.data.status == 401)
             {
                setLoading(false)
                dispatch(setmessage(res.data.data))
             }
             else if(res.data.success == true){
                sessionStorage.setItem("authenticated", res.data.token);
            Navigate('/Dashboard', { replace: true })
             }
           
        })
        }
    }
    StepVerification()
    },[Loading])
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
    const {message}=useSelector((state)=>state.Createslice) 
     const[showmenu,setshowmenu]=useState(false)
    function Menumobile(){
     setshowmenu((previus) => !previus)
     }
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <DesktopHeader/>
 <MobileHeaders/>
      <div className="container">

       <div className="two-factor" >
        <div className="layout-section">
        <div></div>
            <div className="squaremart">
                <div className="square-log">
                         <div className="sign-in">
                        <h1 style={{ color:'#000000'}}>TWO FACTOR CODE</h1>
                             </div>
                </div>
                <div className="form-control">
                {   message ? 
        <div className= 'error-field'>
       
                              <div className= 'error' >
                                    {message}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                    <form onSubmit={HandlerSubmit} method="post">
                        <label htmlFor="email">OTP CODE</label>
                            <input type="tel" name="google_auth_code"
                            onChange={Twoverification} placeholder="Enter OTP" id="OTP" required/>
                            <br></br>
                          <button className="btn-create" type="submit">{Loading ? 'Loggin...' :'Login'}</button>
                         </form>
                </div>
            </div>
           <div></div>
        </div>
    </div>
    {showmenu ? 
                     <div id="mySidenav" className= "sidenav" >
                        <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
                          <ul>
                          <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
                          <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>
                          <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li className="login">
                            <Link style={link} to='/register'>Register</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
    {/* <Footer/> */}
  {/* <form onSubmit={HandlerSubmit}>
      <input type="tel" name="google_auth_code" onChange={Twoverification} placeholder="OTP"/>
   <button>Submit</button> </form> */}
   </div>
   
            
   </div>
    )
}
export default TwoFactor
