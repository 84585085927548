import React, { useState } from "react";
import { CgDollar } from "react-icons/cg";
import { FaDollarSign,FaLocationDot } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";
const ProductData=(props)=>
{
   
    return (
        <>
        <div className="product">
        <div className="product-image">
            <img src={props.img} alt=""/>
        </div>
        <div className="price-cat">
        <p>{props.category}</p>

           <h4 className="price"><CgDollar/>{props.price}</h4>
        </div>
        <div className="product-name">
         <p>{props.title}</p>
        </div>
        <div className="info">
            <div className="county-info">
           <MdOutlineLocationOn/>
            <h3 className="country">{props.country}</h3>
      
            </div>
           <div className="price-info">
            <p className="time">{props.time}</p>
           </div>
           
            
          
        </div>
        <div className="action">
            <div className="view">
                <button className="open-modal" onClick={props.toggleclick}>VIEW</button>
            </div>
            <div className="buy">
                <button  onClick={props.CartItem}>BUY</button>
            </div>
        </div>
    </div>
   
    </>
    )
}
export default ProductData