import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profile: [],
  wallets:[],
  payoutAddress:{
    btc_address:'',
    ngn_address:'',
    eth_address:'',
    usdt_address:'',
    account_name:'',
    banks:'',
  }
}

export const ProfilePageslice = createSlice({
  name: 'PROFILE',
  initialState,
  reducers: {

    setpayoutAddress : (state,action) => {
      state.payoutAddress = action.payload;

  },
        Setprofile : (state,action) => {
        state.profile = action.payload;

    },
    setWallet: (state,action) => {
    state.wallets = action.payload;

},
  },
})

// Action creators are generated for each case reducer function
export const { Setprofile ,setWallet,setpayoutAddress  } = ProfilePageslice.actions

export default ProfilePageslice.reducer