import React, { useState, useEffect, useRef } from 'react';
import { BsFillChatRightTextFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Toggleshow,addRobotResponse,setUserMessage ,addRobotMessage,addUserMessage} from '../Redux/Chatslice';
import { FaArrowLeft, FaRegTimesCircle, FaTimes } from 'react-icons/fa';
import { IoIosChatbubbles } from 'react-icons/io';

// const phoneNumber = '971555637361'; // Replace with your desired phone number

// const robotResponses = {
//   'hi': 'Hello! Welcome to Squaremart.',
//   'pickNumber': 'Please pick a number from 0 to 10.',
//   '0': 'About Trading in Squaremart kindly talk to any of our Agent Online for the complaint',
//   '1': 'Squaremart is a legit and trustworthy platform.',
//   '2': 'To talk to any of our agents, kindly click below to talk to one of our Agent.',
//   '3': 'How can I find and add to my deposit?',
//   '4': 'International Shipping',
//   // Add more responses for each number option
// };

function ChatInterface() {
  // const [chatLog, setChatLog] = useState([]);
  // const [userMessage, setUserMessage] = useState('');
  const dispatch=useDispatch()
const {showChat,chatLog,userMessage ,phoneNumber,chatthird,chatfirst,robotResponses,repliedrobot}=useSelector((state)=>state.Chatslice)
  useEffect(() => {
    // Start the conversation with the default message
    const robotResponse = getRobotResponse('hi');
    // const newRobotMessage = {
    //   type: 'robot',
    //   message: robotResponse,
    // };
    dispatch(addRobotResponse([robotResponse]))
    // setChatLog([newRobotMessage]);
  }, []);

  /** SCROLLING */
  const chatContainerRef = useRef(0); // Initialize chatContainerRef with null

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer.scrollHeight > chatContainer.clientHeight) {
      chatContainer.scrollTop = chatContainer.scrollHeight - chatContainer.clientHeight;
    }
  }, [chatLog]);


  /** END */
  
  const handleUserMessageChange = (event) => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer.scrollHeight > chatContainer.clientHeight) {
      chatContainer.scrollTop = chatContainer.scrollHeight - chatContainer.clientHeight;
    }
     dispatch(setUserMessage(event.target.value));
  };

  const handleUserMessageSubmit = (event) => {
    event.preventDefault();

    if (userMessage.trim() === '') {
      return;
    }

    // const newUserMessage = {
    //   type: 'user',
    //   message: userMessage.trim(),
    // };

    // const chatLogCopy = [...chatLog, newUserMessage];
    // setChatLog(chatLogCopy);
       dispatch(addUserMessage(userMessage.trim()));
       const robotResponse = getRobotResponse(userMessage.trim());
       dispatch(addRobotMessage(robotResponse));
    // const robotResponse = getRobotResponse(userMessage .message);
    // // const newRobotMessage = {
    //   type: 'robot',
    //   message: robotResponse,
    // };

    // const updatedChatLog = [...chatLogCopy, newRobotMessage];
    // setChatLog(updatedChatLog);
    dispatch(setUserMessage(''));
  };

  const getRobotResponse = (userMessage) => {
    const messageKey = userMessage.toLowerCase();

    if (chatLog.length === 1) {
      return chatfirst;
    } else if (chatLog.length === 3) {
      return chatthird;
    } else if (robotResponses.hasOwnProperty(messageKey)) {
      return robotResponses[messageKey];
    }
   
    return repliedrobot.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const generateWhatsAppLink = () => {
    const agentResponse = chatLog.find((message) => message.type === 'robot' && message.message === robotResponses['2']);

    if (agentResponse) {
      const robotMessages = [];
      const userMessages = [];

      for (let i = 0; i < chatLog.length; i++) {
        const message = chatLog[i];
        if (message.type === 'robot' && robotMessages.length < 3) {
          robotMessages.push(`Robot: ${message.message}`);
        } else if (message.type === 'user' && userMessages.length < 3) {
          userMessages.push(`User: ${message.message}`);
        }
      }

      const chatMessages = [...robotMessages, ...userMessages].join('\n');

      const encodedChatMessages = encodeURIComponent(chatMessages.trim());
      return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedChatMessages}`;
    }

    return null;
  };

  const toggleChat = () => {
   const clickchange = showChat == false ? true : false;
      
    dispatch(Toggleshow(clickchange));
  };
/*************** Scrolling chat */
const [IsVisible,setIsVisible]=useState(false)
useEffect(() => {
  const handleScroll = () => {
    const currentScrollY = window.pageYOffset;
    // console.log(currentScrollY)
    if (currentScrollY > 130) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
}, []);
const { size }=useSelector((state)=>state.WindowSize)
function conditionButton()
{
  if(size > 767)
  {
    if(showChat){
      return null
    }
    else{
      return <IoIosChatbubbles onClick={toggleChat} className="chat-icon" />
    }
     
  }
  else if(IsVisible)
  {
   return <IoIosChatbubbles onClick={toggleChat} className="chat-icon" />
    
  }
  
}
  return (
    <>
    {conditionButton()}
     
      {showChat  ? (
        <>
      <div   className="chat-box">
      <div className="header">
        <h1>LIVE CHAT  
        </h1> <FaArrowLeft className='cancel-icon' onClick={toggleChat}  />
      </div>
      <div className="scrollable" ref={chatContainerRef}>
        <div className="message">
        {chatLog.map((message, index) => (
          <div key={index} className={`message ${message.type}`}>
            {message.message}
          </div>
        ))}
        </div>
        {generateWhatsAppLink() && (
        <a href={generateWhatsAppLink()} target="_blank" rel="noopener noreferrer">
          Talk to Agent
        </a>
      )}
      </div>

      <div className="chat-form-class">
      <form onSubmit={handleUserMessageSubmit}>
          <div className="form-input">
    
          <input
          type="text"
          value={userMessage}
          onChange={handleUserMessageChange}
          placeholder="Type your message..."
        />
          </div>
          <div className="form-input">
         <div className='button'>
        <button>Send</button>
        </div>  
         </div>
        </form>
      </div>
    </div>
           </>
      ) : (
      null   )}
    </>
  );
}

export default ChatInterface;
