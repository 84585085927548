import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatLog: [],
  userMessage: '',
  chatthird: 'What can I do for you today?',
  chatfirst: 'Kindly send your Transaction Invoice Number to continue.',
  showChat: false,
  repliedrobot: `I am sorry, I do not understand. To check any of our FAQs, kindly pick from 0 to 10.
    0 : How many days does it take to complete my trade?
    1 : Tell me more about your services.
    2 : I would like to talk to one of your agents.
    3 : How can I find and add to my deposit?`,
  phoneNumber: '971555637361',
  robotResponses: {
    'hi': 'Hello! Welcome to Squaremart.',
    'pickNumber': 'Please pick a number from 0 to 10.',
    '0': 'About Trading in Squaremart kindly talk to any of our Agent Online for the complaint',
    '1': 'Squaremart is a legit and trustworthy platform.',
    '2': 'To talk to any of our agents, kindly click below to talk to one of our Agent.',
    '3': 'How can I find and add to my deposit?',
    // Add more responses for each number option
  },
};

const ChatText = createSlice({
  name: 'Chat',
  initialState,
  reducers: {
    setChatLog: (state, action) => {
      state.chatLog = action.payload;
    },
    setUserMessage: (state, action) => {
      state.userMessage = action.payload;
    },
    addMessage: (state, action) => {
      state.chatLog.push(action.payload);
    },
    Toggleshow: (state) => {
      state.showChat = !state.showChat;
    },
     addRobotResponse:(state,action)=>{
        const newRobotMessage = {
            type: 'robot',
            message: action.payload,
          };
          state.chatLog.push(newRobotMessage);
 
       },
    addUserMessage: (state, action) => {
      const newUserMessage = {
        type: 'user',
        message: action.payload.trim(),
      };
      state.chatLog.push(newUserMessage);
    },
    addRobotMessage: (state, action) => {
      const newRobotMessage = {
        type: 'robot',
        message: action.payload,
      };
      state.chatLog.push(newRobotMessage);
    },
    clearChatLog: (state) => {
      state.chatLog = [];
    },
  },
});

export const {
  setChatLog,
  setUserMessage,
  addMessage,
  Toggleshow,
  addUserMessage,addRobotResponse,
  addRobotMessage,
  clearChatLog,
} = ChatText.actions;

export default ChatText.reducer;
