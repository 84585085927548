import React, { useRef } from 'react'
import firebase from 'firebase/app';
import { Auth ,confirmPasswordReset,connectAuthEmulator,sendPasswordResetEmail} from 'firebase/auth'
import { auth } from './Firebase'
import { useState } from 'react'
import logos from '../../img/squaremart-logo.png'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { setmessage } from '../Redux/Createslice';
import { FaBitcoin, FaQuestion, FaTimes } from 'react-icons/fa';
import { MdOutlineCardGiftcard } from 'react-icons/md';
import { SiCoinmarketcap } from 'react-icons/si';
import axios from 'axios';
import ScrollToTop from './ScrollToTop';
import Header from '../Header';
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'

const ForgetPassword=()=>
{
    
    const Navigate=useNavigate()
    const emailRef=useRef(null)
    const NewpasswordRef=useRef(null)
    /************ Confirm password *****/
    const urlSearchParams = new URLSearchParams(window.location.search);
    const oobCodeParam = urlSearchParams.get('oobCode');
    const retrievemail = urlSearchParams.get('email');

    function inputdata(event)
    {
        const { name,value}=event.target
        setData((prevData) => ({
            ...prevData,
            [name]:value
           })); 
    }
    const[data,setData]=useState({
        email:retrievemail,
       })
// console.log(data)
    const [oobCode, setOobCode] = useState(oobCodeParam);
    const dispatch=useDispatch()
const [errorfield,seterrorfield]=useState()
const[Valid,setValid]=useState()
    const [isloadingpass,setisloadingpass]=useState()
    const[isloading,setisloading]=useState()
    function ConfirmPassword(e)
    {
      
        setisloadingpass(true)
          e.preventDefault()
        confirmPasswordReset(
            auth,
            oobCode, NewpasswordRef.current.value
        ).then(() => {
            setisloading(true)
         
          })
          .catch((error) => {
            const errorCode=error.code
            setisloadingpass(false)
            setValid()
            if(errorCode === 'auth/invalid-action-code')
            {
                seterrorfield('Link expired')
            }
            else{
                seterrorfield(errorCode)
            }
          
            });
    }
    /*********** update database password */
    useEffect(()=>{
        async function Updatepassword()
        {
            if(isloading)
            {
            axios.post(`api/updatepassword`,data)
            .then((res)=>{
                seterrorfield()
                setisloadingpass(false)
                setisloading(false)
                setValid(res.data.data); 
                Navigate('/login', { replace: true })
            }
            )
        }
        }
        Updatepassword()
    },[isloading])
    /***************** END *************/
    /************** SEND EMAIL FOR PASSWORD RESET */
    const[isloadingemail,setisloadingemail]=useState()
    function ResetPassword(e)
    {
        setisloadingemail(true)
        e.preventDefault()
    sendPasswordResetEmail(
        auth,
        emailRef.current.value
    ).then((auth)=>{
        seterrorfield()
        setisloadingemail(false)
        setValid('Email Sent for password reset!');
    
   }).catch((error)=>{
    //   console.error(error)
      const errorCode=error.code
      setisloadingemail(false)
      setValid()
      seterrorfield(errorCode)
   })
}
/*************** */
const {size}=useSelector((state)=>state.WindowSize)
    const {message}=useSelector((state)=>state.Createslice)
const link = {
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: '20px'
}
const[showmenu,setshowmenu]=useState(false)
function Menumobile(){
 setshowmenu((previus) => !previus)
 }
    return (
        <div style={( showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
      <DesktopHeader/>
        <MobileHeaders/>
        <div className="container">
   

       <div className="two-factor" >
        <div className="layout-section">
        <div></div>
            <div className="squaremart">
                <div className="square-log">
                         <div className="sign-in">
                        <h1 style={{ color:'#000000'}}>Password Reset</h1>
                             </div>
                </div>
                <div className="form-control">
                {   Valid  ? 
        <div className= 'valid-field'>
       
                              <div className= 'valid' >
                                    {Valid}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                {   errorfield  ? 
        <div className= 'error-field'>
       
                              <div className= 'error' >
                                    {errorfield}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                   {!oobCode ? 
       <form onSubmit={ResetPassword}>
        
<label htmlFor='Email'>Email</label>
       <input type='email' ref={emailRef}  placeholder="Enter your Email Address"  name='email' required/>
       <br></br> <button className="btn-create">{isloadingemail ? 'Sending...' :'Submit'}</button> 
       
                          
                        
   </form> 
   :
   <form onSubmit={ConfirmPassword}>
   <label htmlFor='pass'>New Password</label>
   <input type='hidden'  value={retrievemail}/>
   <input type='password' onChange={inputdata} ref={NewpasswordRef} name='password'  placeholder="Enter your New Password"  required/>
   <br></br> 
   <button className="btn-create">
    {isloadingpass ? 'Updating Password..' : 'Change Password'}
    </button>
      
</form>
      } 
                </div>
            </div>
           <div></div>
           { 
           showmenu ? 
          <div id="mySidenav" className= "sidenav" >
          <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
        <ul>
         <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
        <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>
         <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li className="login">
                            <Link style={link} to='/login'>Login</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
        </div>
    </div>
    

        
        </div></div>
    )
}
export default ForgetPassword
