import React, { useEffect, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { SiVerizon } from "react-icons/si";
import MovingText from 'react-moving-text'
import { FaBackward,FaBackwardFast, FaClock, FaCloudversify, FaExclamationCircle, FaFastBackward, FaMarker, FaRegThumbsDown, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import axios from "axios";
import DropReview from "./DropReview";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChatInterface from "../../pages/Chatbot";
import { setlockbutton, setmessage, setmsg } from "../../Redux/Createslice";
import { BiArrowBack } from "react-icons/bi";
import { BsFillSkipBackwardCircleFill } from "react-icons/bs";
import { AiOutlineRollback } from "react-icons/ai";
import Gifttradevalidate from "./Gifttradevalidate";
import Verificationquery from "./VerifyQuery";
import { setSwapdetail, setcardlockbutton, setgiftcardinvoiceNumber, setgiftcardprocessTrigger, setgiftcardprocessing, settradesucces, setusdtmsg } from "../../Redux/Chart";
const TradGiftcard=()=>
{
    const { size }=useSelector((state)=>state.WindowSize)
const { showChat }=useSelector((state)=>state.Chatslice)
 const{giftcard_buy_id,fetchgiftcard}=useSelector((state)=>state.SwapCard)
     const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
    const [isLoading, setisLoading] = useState(false);
    const Navigate = useNavigate()
  
   /************* USDT ID *********/
   useEffect(()=>{
    if(!(fetchgiftcard.giftcard_amount ))
    {
         Navigate("/trade" ,{ replace: true });

    }
})

   const[fetchGiftcard,setfetchGiftcard]=useState({})
 
 useEffect(() => {
        async function SelectUsdt() {

            axios
                .get(`api/fetchGiftcardId/${giftcard_buy_id}`)
                .then((response) => {
                    setfetchGiftcard(response.data);
                      
                })

        }
        SelectUsdt()
    }, [giftcard_buy_id])
     // Define invoice number

     const{giftcardinvoiceNumber,tradesucces,swapDetails,giftcardprocessTrigger,usdtmsg,cardlockbutton,cardprocessing}=useSelector(state=>state.Chart)
    
     /************* DATA *******/
     const[storeDatagiftcard,setstoreDatagiftcard]=useState()
  
    /****************** FINAL TRADE ******/
    
      /******************** Time left to pay **************/
    const [counter, setCounter] = useState(3600); // set counter to 30 minutes (in seconds)

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
  
      return () => {
        clearInterval(intervalId);
      };
    }, []);
    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;
  
    /********************** End *********/
    
    /************* SUBMIT **************/
    const {message}=useSelector((state)=>state.Createslice)
    const dispatch=useDispatch()
/********************* Send Trade to purchase *****/

    function HandlerPurchase(e)
        {
            e.preventDefault()
            dispatch(setmessage())
            setverifyload(true)
            setisLoading(true)

            //Generate invoice 
            const prefix = "SQE";
           const suffix = "MAT";
                 const date = new Date().toISOString().slice(2, 7).replace("-", "");
                 const randomNumber = Math.floor(Math.random() * 10000);
                 const paddedNumber = randomNumber.toString().padStart(4, "0");
            dispatch(setgiftcardinvoiceNumber
                (`${prefix}${date}${paddedNumber}${suffix}`));
           
            setstoreDatagiftcard({
                giftcard_buy_id: giftcard_buy_id,
                giftcard_amount: fetchgiftcard.giftcard_amount,
                giftcardinvoiceNumber: `${prefix}${date}${paddedNumber}${suffix}`,//giftcardinvoiceNumber,
                user_id: userId
            });
        } 

        /************ CheckVefication ********/
const{verified}=useSelector((state)=>state.Tradeslices)
const badge=verified && verified.verify_badge
const Priceproduct=storeDatagiftcard && storeDatagiftcard.giftcard_amount
    const [verifyload,setverifyload]=useState(false)
          const[sucesss,setsucesss]=useState()
       
          /********************** Trade Giftcard **************/
          useEffect(()=>{
            async function BuyGiftcard()
            {
                if(verifyload)
                {
                if((badge == 0))
                {
                    dispatch(setmessage('UnAuthorized kindly Verify your account'))
                    setisLoading(false)
                   setverifyload(false)
                }
                else  if((badge == 1) && (Priceproduct > 500))
                {
                  
                    dispatch(setmessage('UnAuthorized kindly Verify your account'))
                    setisLoading(false)
                    setverifyload(false)
                }
                else  if((badge == 2) && (Priceproduct > 2000)){
                    dispatch(setmessage('UnAuthorized kindly Verify your account'))
                    setisLoading(false)
                   setverifyload(false)
                }
              else  if(isLoading){
            axios.post(`api/SwappingGiftcard`,storeDatagiftcard)
                .then((response)=>{
                    if(response.data.status  ==  200){
                        dispatch(setcardlockbutton(true))
                        setisLoading(false)
                        dispatch(setusdtmsg(true))
                        setverifyload(false)
                        dispatch(setmessage(response.data.data))
                        setsucesss(response.data.status)
                        dispatch(setgiftcardprocessTrigger(true))    
                    }
                    else if(response.data.status  ==  405){
                        dispatch(setmessage( <>
                            {response.data.data} <FaExclamationCircle />
                          </>))
                             setisLoading(false)
                             setverifyload(false)
                    }
                    else   if (response.data.status == 419) {
                        dispatch(setmessage( <>
                            {response.data.data} <FaExclamationCircle />
                          </>))
                        setisLoading(false)
                        setverifyload(false)
                    }
                
                })
            }
        }
        }
            BuyGiftcard()
          },[isLoading,storeDatagiftcard,verifyload])
    /**************** END **********/

    /************* CONNECT BUYER AND SELLER TO CHECK TRADE STATUS *****/
 /*********************  /*********************  polling Request to check trade if trade has been accepted or rejected by the buyer and  seller will send review
     *****/ 
 useEffect(() => {
    let interval;
        if(giftcardprocessTrigger){
           interval = setInterval(() => {
             fetchProducts();
           }, 8000);
         }
     
         return () => clearInterval(interval);
  }, [giftcardprocessTrigger]);
const[openreview,setopenreview]=useState(false)
const connect_data={
    giftcardinvoiceNumber:giftcardinvoiceNumber
}
  const fetchProducts = async () => {
   if(giftcardprocessTrigger)
    {
         const response = await axios.post('api/connect_buy_seller',connect_data);
    if(response.data.escrow_status == 1){
        dispatch(setmessage('Trade Completed'))
        dispatch(settradesucces(true))
         dispatch(setgiftcardprocessTrigger(false))
         //Regenerate invoice
        const prefix = "SQE";
                const suffix = "MAT";
                const date = new Date().toISOString().slice(2, 7).replace("-", "");
                const randomNumber = Math.floor(Math.random() * 10000);
                const paddedNumber = randomNumber.toString().padStart(4, "0");
                setgiftcardinvoiceNumber(`${prefix}${date}${paddedNumber}${suffix}`);
       /********** review details */
       dispatch(setSwapdetail({
            giftcard_image:response.data.giftcard_image,
            review_id:response.data.review_id,
            likes: '',
            dislike: ''
                    }))
                    /*************end */
                setstoreDatagiftcard({
                    review_id:response.data.review_id,
                    giftcard_buy_id: giftcard_buy_id,
                    giftcard_amount: fetchgiftcard.giftcard_amount,
                    giftcardinvoiceNumber: giftcardinvoiceNumber,
                    user_id: userId
                });
                dispatch(setgiftcardprocessing(true))
    }
     else if(response.data.escrow_status == 2){
        dispatch(settradesucces(false))
     dispatch(setgiftcardprocessTrigger(false))
    //  setopenreview(true)
     dispatch(setmessage('Trade Rejected'))

          //Regenerate invoice
          const prefix = "SQE";
        const suffix = "MAT";
        const date = new Date().toISOString().slice(2, 7).replace("-", "");
        const randomNumber = Math.floor(Math.random() * 10000);
        const paddedNumber = randomNumber.toString().padStart(4, "0");
        setgiftcardinvoiceNumber(`${prefix}${date}${paddedNumber}${suffix}`);
        dispatch(setgiftcardprocessing(true))
      /********** review details */
        dispatch(setSwapdetail({
            giftcard_image:response.data.giftcard_image,
            review_id:response.data.review_id,
            likes: '',
            dislike: ''
                    }))
                    /************ end */
        setstoreDatagiftcard({
            review_id:response.data.review_id,
            giftcard_buy_id: giftcard_buy_id,
            giftcard_amount: fetchgiftcard.giftcard_amount,
            giftcardinvoiceNumber: giftcardinvoiceNumber,
            user_id: userId
        });
    }
    
    }
   
  };
 /*********** SendReview *****/
 const [styledislike, setstyledislike] = useState({
    color: 'red',
    cursor: 'pointer',
});
const [styledlike, setstyledlike] = useState({
    color: 'green',
    cursor: 'pointer',
});

const[isloadreview,setisloadreview]=useState(false)
function Handlerthumpsup() {
    setisloadreview(true)
    setstyledislike({
        color: 'red',
        cursor: 'pointer',
    })
    setstyledlike(prevStyle => ({
        ...prevStyle,
        color: 'black' // change color to black on each click
    }));
    dispatch(setSwapdetail({
        ...swapDetails,
        likes: 'likes',
        dislike: '',
    }));
}

function Handlerthumpsdown() {
    setisloadreview(true)
    setstyledlike({
        color: 'green',
        cursor: 'pointer',
    })
    setstyledislike(prevStyle => ({
        ...prevStyle,
        color: 'black' // change color to black on each click
    }));
    
    dispatch(setSwapdetail({
        ...swapDetails,
        likes: '',
        dislike: 'dislike',
    }));
}
/***************** GIFTCARD REVIEW ******/
function BuyerReview()
{
    dispatch(setgiftcardprocessing(false))
    setopenreview(true)
}
useEffect(() => {
    async function DropReview() {
        if (isloadreview) {
            axios
                .post(`api/dropgiftcardReview`, swapDetails)
                .then((response) => {
                    dispatch(setSwapdetail())
                    dispatch(setmessage())
                    dispatch(setmsg())
                    dispatch(settradesucces(false))
                    dispatch(setcardlockbutton(false))
                    dispatch(setgiftcardprocessing(false))
                    dispatch(setgiftcardprocessTrigger(false))
                        setopenreview(false)
                        setisloadreview(false)
                        //  Navigate("/trade", { replace: true });


                })

        }
    }
    DropReview()
}, [storeDatagiftcard, isloadreview])
const disstyled={
    cursor:'not-allowed',
    pointerevents: 'none',
    opacity: '0.6'
  
  }
    /******************* CONDITION ********/
  function Conditions()
  {
      if(cardprocessing){
        return (
        <div className="terms">
        {/* <h1>Confirm  Gift card to sell USDT to buyer</h1> */}
        <div className="conditions">
            <div className="head">
                {/* <h2> USDT/GIFT CARD SYSTEM EXCHANGE</h2> */}
            </div>
          
           
            <form >
            <div className="seller-terms">
                    <div className="termsof">
                        <div className="conditions">
                            <div id="imageContainer">
                                <img src={swapDetails.giftcard_image} width='200' alt=""
                                 id="image-preview" />
                            </div>

                          
                        </div>
                        <div className="actions">
                                   {/* <button type="button"  className="reject" id="reject"></button> */}
                                
                                   <button type="button" style={{ backgroundImage:'none' }} onClick={BuyerReview} className="approved">Send Review</button>
                                 
                               
                               </div>
                       
                       
                       
                    </div>
               
            </div>
          
       
    </form>
      </div> 
    </div>
        )
    }
    else if(openreview)
    {

        return (
            <DropReview
            styledlike={styledlike}
            styledislike={styledislike}
            Handlerthumpsup=  {() => Handlerthumpsup()}
            Handlerthumpsdown= {() => Handlerthumpsdown()}
            />
        )
    }
    else{
  return (
    <div className="terms">
        <div className="head-back">
    <h1> 
    <Link to='/BuyGiftcard'> <IoIosArrowBack /></Link> <span>
        Read terms before continue to buy {fetchGiftcard.selling_giftcard}</span></h1>
    
    </div> <div className="conditions">
        <div className="head">
        <h2> Transaction ID: {giftcardinvoiceNumber}</h2> 
            {/* <h2> USDT/GIFT CARD SYSTEM EXCHANGE</h2> */}
        </div>
        <p>
            
            Once you agree to the seller’s offer terms for this transaction you can proceed with the 
            trade any breach in offer the terms would a penalty to you and the trade would be cancelled. 
        </p>
   
        <div className="time">
           
        <FaClock />
                <p>Time left to pay : <span id="counter"> 
{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}

                    
                </span></p>

        </div>
        <div className="about-seller">
    
            <div className="seller">
                <h2>About Seller</h2>
                <div className="seller-items">
                    <div className="items-grid">
                        <div className="grid-box green">
                            <ul>
                                <li>+{fetchGiftcard.likes}</li>
                                <li><FaThumbsUp/></li>
                            </ul>
                              <p>Positive Feedback</p>

                        </div>
                         <div className="grid-box red">
                            <ul>
                                <li>-{fetchGiftcard.dislike}</li>
                                <li><FaThumbsDown/></li>
                            </ul>
                              <p>Negative Feedback</p>

                        </div>
                           
                    </div>
                    <div className="items">
                        <h3>Verification</h3>
                        <div className="btn">verified</div>
                        

                    </div>
                    <div className="items">
                        <h3>Number of successful trade</h3>
                        <small>{fetchGiftcard.successful_trade}</small>
                    </div>
                    <div className="items">
                           <h3>Average trade per day</h3>
                        <small>3</small>
                    </div>
                </div>
            </div>
        </div>


        <div className="seller-terms">
                <div className="termsof">
                    <div className="conditions">
                        <h4>seller terms</h4>
                        <ul>
                     <div dangerouslySetInnerHTML={{ __html: fetchGiftcard.terms }}
                               />
                         
                        </ul>
                    </div>
                    <form onSubmit={HandlerPurchase}>
                    <div className="cont">
                
    <button className="card-button" disabled={cardlockbutton || isLoading ? 'disabled': null} style={cardlockbutton ||isLoading ? disstyled : { cursor:'pointer' }}><span>
      {isLoading ? 'Loading...' : `Buy ${fetchGiftcard.selling_giftcard ?? 'Gift Card'}`}  </span></button>
                    </div>
                    </form>
                  
                </div>
           
        </div>
    </div>
   
</div>
  )
    }
}
    return (
        <div style={showChat  && size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
     
        <Auth/>
        <Gifttradevalidate/>
        <Verificationquery/>
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            { message ?
             
             <MovingText
  type="slideInFromRight"
  duration='350ms'
  className={sucesss == 200 || tradesucces ? "sucessmsg": "errormsg"}
  delay='0s'
  direction='alternate'
  //    timing='ease'
  iteration='1'
  fillMode='none'> 
   <p>{message}</p></MovingText> 


 : null }
          { Conditions() }
          
            </div>
            <ChatInterface/>
            </div>
    )
}
export default TradGiftcard