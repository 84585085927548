import React from "react";

const Modal=(props)=>
{
    function Conditions()
    {
        if(props.image && props.value)
        {
return ( 
    <>
  {props.phone == 'Success' || props.phone == 'Re-Fund' ? null : 
    <div className="purchase-head-items" onClick={()=>props.accept()}><p>{props.verify ? 'Approving..' : 'Approve'}</p></div>} 
 
         </>
)
        }
        else  if(props.image)
        {
return ( 
    <>
    <div className="purchase-head-items" onClick={()=>props.accept(1)}><p>{props.verify && props.action === 1 ? 'Approving..' : 'Approve'}</p></div>
         <div className="purchase-head-items" onClick={()=>props.accept(2)}><p>{props.verify && props.action === 2 ? 'Reject..' : 'Reject' }</p></div>
   </>
)
        }
    else if(props.value)
    {
return null
    }
        else if(props.verify)
        {
            return ( 
                <> <div className="purchase-head-items" onClick={()=>props.accept()}><p>Approving...</p></div></>
            )
        }
        else 
        {
            return ( 
                <> <div className="purchase-head-items" onClick={()=>props.accept()}><p>Approve</p></div></>
            )
        }
    }
    return (
        // <!-- Modal -->
<div id="myModal" className="modal">
<div className="modal-content-purchase">
  <span className="close" onClick={()=>props.Modal_cancel()}>×</span>
  
  <div className="product-purchase-details">
    <div className="purchase-head">
        {/* <div className="purchase-head-items">{props.topic}</div> */}
       {Conditions()}
    </div>
    <div className="purchase-body">
        <div className="table-purchase">
            <table>
                <thead>
                <tr>
                     <th>{props.user_name}</th>
                    <td>{props.name}</td>  

            </tr>
            </thead>
            <tbody>
            {props.document ?     <tr>
            <th>{props.doc_name}</th>
                
                    <td>{props.document}</td> 
              
            </tr>: null
                    }
            <tr>
                <th>{props.number}</th>
                <td>{props.phone} </td>
              
            </tr>
            <tr>
                <th>{props.wallet}</th>
                <td>{props.address} </td>
              
            </tr>
            </tbody>
            </table>
        </div>
        {props.image   ?  
        <div className="purchase-product-image">
        <img src={props.image} height='20' alt=""/>
        </div>
        : null }
    </div>
  </div>
</div></div>

    )
}
export default Modal
