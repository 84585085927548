import React, { useEffect, useState } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate, useParams } from "react-router-dom";
import logosms from '../../img/squreinbox.png'
import card from '../../img/giftcard.png'
import axios from "axios";
import {useDispatch, useSelector } from "react-redux";
import { setAttempt } from "../Redux/Createslice";
import { FaArrowLeft } from "react-icons/fa";

const Readinbox=()=>{
   const {sms_id, activities} = useParams();
    const Navigate = useNavigate();
    const dispatch=useDispatch()
    const[msgData,setmsgData]=useState([])
    useEffect(()=>{
      async function ReadMsg()
      {
        if((sms_id) && (activities))
        {
          axios
          .get(`api/messages_view/${sms_id}/${activities}`)
      .then((response) => {
        if(response.data.id)
        {
          
          setmsgData(response.data)
          dispatch(setAttempt())
        }
        else{
          Navigate('/inbox', { replace: true });
        }
        
      })
        }

      }
      ReadMsg()
  
    },[sms_id,activities])
    const[Loading,setLoading]=useState()
    const[readyloading,setreadyloading]=useState()
    const[product_id,setproduct_id]=useState()
    const[action,setaction]=useState()
    const[status,setstatus]=useState()
    function Approvedproduct(id)
    {
      setLoading(true)
      setproduct_id(id)
    }

    function ApprovedBuyerproduct(id,status,action)
    {
      setreadyloading(true)
      setstatus(status)
setaction(action)
 setproduct_id(id)
    }
    function loadbox(){
  if(Loading){
    return 'Please wait...'
  }else{
    return 'APPROVE ORDER'
  }
    }
    const styled={
      cursor:'not-allowed',
    
    }
/********************* Approved ordered by Seller *******/

useEffect(()=>{
  async function Approved()
  {
    if((Loading))
    {
      axios
      .get(`api/SellerApprovedOrder/${product_id}`)
  .then((response) => {
    if(response.data == 200)
    {
      setLoading(false)
      Navigate('/inbox', { replace: true });
    }
  })
    }

  }
  Approved()

},[Loading])

/*================== Approved ordered by Buyer ==============*/
useEffect(()=>{
  async function ApproverBuyer()
  {
    if((readyloading))
    {
      axios
      .get(`api/approvedOrder/${product_id}/${status}/${action}`)
  .then((response) => {
    if(response.data == 200)
    {
      setreadyloading(false)
      Navigate('/inbox', { replace: true });
    }
    else{
      setreadyloading(false)
    // Navigate('/inbox', { replace: true });
        dispatch(setAttempt(response.data.data))
    }
  
    
  })
    }

  }
  ApproverBuyer()

},[readyloading])
const profile = useSelector((state) => state.ProfilePageslice.profile);

const userId= `${profile.id}`
function loadboxareject()
{
  if((msgData.msg_read_approve == 2)  && (msgData.subject == 'Order Cancelled'))
  {
    return 'Rejected'
  }
  else if(readyloading && action == 2)
  {
    return 'Please wait'
  }
  else{
    return 'Reject'
  }
  
}
function loadboxaccept()
{
  if((msgData.msg_read_approve == 2)  && (msgData.subject != 'Order Cancelled'))
  {
    return 'Approved'
  }
  else if(readyloading && action == 1 )
  {
    return 'Please wait'
  }
  else{
    return 'Approve'
  }
}
function messageCheck(){
  if(msgData.msg_read && msgData.subject === 'Order Cancelled')
  {
    return <p>The order has been canceled by the buyer, and a refund has been issued to your account.</p>
  }
  else if(msgData.msg_read)
  {
return <p>Order approved successfully. Funds will be deposited after the buyer confirms delivery. If you don't receive funds within two days post-delivery, contact us with proof of shipment. This ensures both parties' protection. </p> 
    
  }else
  {
    return  <p>Sellers: Kindly approve the receipt only after delivering the product to the buyer. Ensure the buyer confirms receipt from their end as well after you've confirmed yours. This ensures a smooth transaction. Thank you.</p>
   
  }
}
function condition()
{
  if((msgData.product_image))
  {
  if(((msgData.msg_read_approve == 1) || (msgData.msg_read_approve == 2)) && (msgData.user_id == userId)){
    return (
      <> <p>Once you receive the order, confirm receipt. If you haven't received it, reject the receipt notification and reach out to us to claim your funds, as we need thorough proof that you didn't receive the order. This is to safeguard both the buyer's and seller's funds, ensuring terms are met.</p>
      <div className="product-image">
     <img src={msgData.product_image} alt=""/>
    </div>
      <div className="actions"> 
      <button disabled={msgData.msg_read_approve == 2 || readyloading  ? 'disabled': null} style={msgData.msg_read_approve == 2 || readyloading ? styled : null} id="reject" onClick={()=>ApprovedBuyerproduct(`${msgData.id}`,'Purchase Decline',2)} className="reject">{ loadboxareject()} </button>
      <button disabled={msgData.msg_read_approve == 2 || readyloading  ? 'disabled': null} style={msgData.msg_read_approve == 2 || readyloading ? styled : null} className='approved'onClick={()=>ApprovedBuyerproduct(`${msgData.id}`,'Success',1)}  id='approved'>{ loadboxaccept()}   </button>  </div>
      </>   
    )
  
}
  else{
    if(msgData.delivery_type == 1){
  return (
    <>
    <p>{msgData.message}</p>
     <div className="product-image">
    <img src={msgData.product_image} alt=""/>
   </div>
   <div className="btn">
    {messageCheck()}
     {/* {msgData.msg_read ? <p>Order approved successfully. Funds will be deposited after the buyer confirms delivery. If you don't receive funds within two days post-delivery, contact us with proof of shipment. This ensures both parties' protection. </p> :
     <p>Sellers: Kindly approve the receipt only after delivering the product to the buyer. Ensure the buyer confirms receipt from their end as well after you've confirmed yours. This ensures a smooth transaction. Thank you.</p>
    } */}
    <button disabled={msgData.msg_read || Loading  ? 'disabled': null} style={msgData.msg_read || Loading ? styled : null} onClick={()=>Approvedproduct(`${msgData.id}`)}>{msgData.msg_read ? 'APPROVED' : loadbox()}</button>
   </div>
   {msgData.msg_read ? null :
   <div className="buyer-details">
     <h3>Buyer's Information</h3>
        <p className="name">Name:- {msgData.buyer_name}</p>
        <p className="phone">Phone No:- {msgData.address_phone }</p>
        <p className="address">Address:- {msgData.shipping_address } { msgData.shipping_country }</p>
   
      </div> } </>
  )
    }
    else{
      return <>
      <p>{msgData.message}</p>
      <div className="product-image">
    <img src={msgData.product_image} alt=""/>
   </div>
      <p>NOTE: Buyer is using Squaremart Dispatch and Payment will be initiated by the Admin Once delivery, Kindly drop the products to any of our Nearest Office .</p>
  
      </> 
    }
}
}
else {
  return  <>
  <p>{msgData.message}</p>
  {msgData.product_images && (msgData.subject == 'Trade Completed') ?  <div className="product-image">
    <img src={msgData.product_images} alt=""/>
   </div> : null }
  
  </>
}
}
 function subjuctcondition()
 {
  if( (msgData.subject =='Trade Completed') || (msgData.subject =='Trade Cancelled') || (msgData.subject =='Trading still on Pending'))
  {
    return (
          <> <div className="profile-name">
                     <h3>From</h3>
                     <p>admin@squaremat.com</p>
                      <p>Subject: {msgData.subject}</p>
          </div> <div className="type-date">
                  
                     <button>{msgData.subject}</button>   <p>{msgData.date}</p>
                 </div>
                 </>
    )
  }
  else if(((msgData.msg_read_approve == 1) || (msgData.msg_read_approve == 2)) && (msgData.user_id == userId))
  {
      return (
  <><div className="profile-name">
                     <h3>From</h3>
                     <p>admin@squaremat.com</p> 
                      <p>Subject: {msgData.subject == 'Order Cancelled' ? msgData.subject : 'Delivered' }</p>
                      </div>
        <div className="type-date">
                  
                     <button>Delivered</button>   <p>{msgData.date}</p>
                 </div>
                 </>
      )
  }
  else  {
    return (
          <> <div className="profile-name">
                     <h3>From</h3>
                     <p>admin@squaremat.com</p>
                     <p>Subject: {msgData.subject}</p>
                     </div>
        <div className="type-date">
                  
                     <button>{msgData.subject}</button>   <p>{msgData.date}</p>
                 </div>
                 </>
    )
  }
 }
/**************** END ***************/
    return (
        <>
        <Auth />
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            <div className="inbox-view">
             
             <div className="avatar">
                 <div className="img">
                  {/* <FaArrowLeft style={{ fontSize:'20px' }}/>  */}
                     <img src={logosms} alt=""/>
                 </div>
              {subjuctcondition()}
            
         </div>
         <div className="notifications">
        <div className="content">
   
 <h3>Invoice No:- {msgData.ref}</h3>
 {/* <p>{msgData.message}</p> */}
 
{condition()}

</div>
         </div>
            </div>
            </div>
            </>
    )
}
export default Readinbox
