import React from "react";
import { CgDollar } from "react-icons/cg";
const GiftcardSection =(props)=>
{
    return (
        <tbody>
                   <tr> 
        {/* <td>{props.offers}</td> */}
    <td>{props.accept_payment}</td>
    <td>{props.selling_giftcard}/{props.amount ? <CgDollar/> :''}{props.amount}</td>
    {/* <td>{props.amount ? <CgDollar/> :''}{props.amount}</td> */}
    {/* <td> <b>{props.rate ? props.rate + '%' : ''}</b></td> */}
    <td><button style={{  cursor: 'pointer'}} onClick={()=>props.CryptoGiftcard(props.id)}>Buy({props.rate ? props.rate + '%' : ''})</button> </td>
</tr>
</tbody>
    )
}
export default GiftcardSection