import React from "react";
import { FaTimes } from "react-icons/fa";

const insufficient = (props) => {
    function CurrencyPurchase(e)
    {
        e.preventDefault()
        // console.log('testing')
    }
    return (
        <div className="container">

            <div className="modalinsuffi">
                <div className="modal-content">
                    <span className="close">
                        <FaTimes onClick={props.disableModal}/></span>
                    <div className="content">
                        <form action="">

                            <h1>Insufficient USDT  Amount</h1>
                            <div className="formclass">
                            <br />            
                                  <label htmlFor="">Amount</label>
                                  <div className="input-field">
                <div className="icon">$</div>
                    <input type="text"  readOnly name="" id="" value={props.amount} />
                                </div>   
                                <br />   
                         <label htmlFor="">Pay with any other cryptocurrency</label><br />
                                               <select name="" defaultValue="Select" id="" onChange={props.toggle} >
                                                <option value="Select" disabled>Select Cryptocurrency</option>
                                               <option value="BTC">BTC</option>
                                               <option value="ETH">ETH</option>
{/*                                                <option value="NGN">NGN</option> */}
                                            
                                               </select>
                                               <p> {props.rates}
                                               </p>

                              


                            </div>
                            <div className="btn">
                               {props.condition == 'Error Try again' || props.condition == null ?
                                  <button disabled='disabled' style={{ cursor:'not-allowed' }} type="submit">Purchase</button>  :
                                  <button type="submit"  style={props.btnPurchase ? { cursor:'not-allowed' } : null}
                                  disabled={props.btnPurchase ? 'disabled': null}  onClick={props.CurrencyPurchase}>{props.btnPurchase ? 'Please wait...' : 'Purchase'}</button>
                               } 
                            
                            </div>
                        </form>


                    </div>

                </div>
            </div>
        </div>
    )
}
export default insufficient
