import React, { useEffect, useRef, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import { FaClock, FaExclamation, FaExclamationCircle, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import sender from '../../../img/send.png'
import MovingText from 'react-moving-text'
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import DropReview from "./DropReview";
import { useDispatch, useSelector } from "react-redux";
import ChatInterface from "../../pages/Chatbot";
import { setlockbutton, setmessage, setmsg } from "../../Redux/Createslice";
import { AiOutlineRollback } from "react-icons/ai";
import Tradepagevalidat from "./Tradepagevalidate";
import Verificationquery from "./VerifyQuery";
import { setInvoiceNumber, setgiftcardmsg, setprocessTrigger, setprocessing, setswapdetailreview, settradesucces } from "../../Redux/Chart";

const Finaltrade = () => {
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
    const { showChat }=useSelector((state)=>state.Chatslice)
    const{processTrigger,processing,invoiceNumber,tradesucces,giftcardmsg,swapdetailreview}=useSelector(state=>state.Chart)
 
    const { size }=useSelector((state)=>state.WindowSize)

     const [isLoading, setisLoading] = useState(false);
   
    const Navigate = useNavigate()
    const{fetchUsdt}=useSelector((state)=>state.SwapCard) 
     useEffect(() => { 
        if (!(fetchUsdt.usdt_buy_id) || (!(fetchUsdt.Usdtamount))) {
             Navigate("/trade", { replace: true });

        }
    })
    const [storeDataUsdt, setstoreDataUsdt] = useState()

   
    /************* DATA *******/
    useEffect(() => {
        setstoreDataUsdt({
            usdt_buy_id: fetchUsdt.usdt_buy_id,
            Usdtamount: fetchUsdt.Usdtamount,
            invoiceNumber: invoiceNumber,
            giftcard_image: '',
            user_id: userId
        });
    }, []);
    
    /************** END ***********/

    /************** FILEUPLOAD *********/
    const [selectedFiles, setSelectedFiles] = useState();
    const fileInputRef = useRef(null);
      const dispatch=useDispatch()
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = (event) => {
        dispatch(setmessage())
        const newFiles = event.target.files[0];
        /****** INVOICE *****/
        const prefix = "SQE";
        const suffix = "MAT";
        const date = new Date().toISOString().slice(2, 7).replace("-", "");
        const randomNumber = Math.floor(Math.random() * 10000);
        const paddedNumber = randomNumber.toString().padStart(4, "0");
         dispatch(setInvoiceNumber
            (`${prefix}${date}${paddedNumber}${suffix}`));
  /******* END ****/
        setSelectedFiles(newFiles);
        setstoreDataUsdt((prevValues) => ({
            ...prevValues,
            invoiceNumber: `${prefix}${date}${paddedNumber}${suffix}`,//invoiceNumber,
            giftcard_image: newFiles,
        }));

    };

    function handleRemoveImage() {
        dispatch(setmessage())
        setverifyload(false)
        setisLoadingtrade(false)
        setstyledlike({
            color: 'green',
            cursor: 'pointer',
        })
        setstyledislike({
            color: 'red',
            cursor: 'pointer',
        })
        setSelectedFiles();
        setstoreDataUsdt((prevValues) => ({
            ...prevValues,
            giftcard_image: '',
        }));
    }

    /*********** SendReview *****/
    const [styledislike, setstyledislike] = useState({
        color: 'red',
        cursor: 'pointer',
    });
    const [styledlike, setstyledlike] = useState({
        color: 'green',
        cursor: 'pointer',
    });

 const[isloadreview,setisloadreview]=useState(false)
    function Handlerthumpsup() {
        setisloadreview(true)
        setstyledislike({
            color: 'red',
            cursor: 'pointer',
        })
        setstyledlike(prevStyle => ({
            ...prevStyle,
            color: 'black' // change color to black on each click
        }));
        dispatch(setswapdetailreview({
            ...swapdetailreview,
            likes: 'likes',
            dislike: '',
        }));
       
     }
    
    function Handlerthumpsdown() {
        setisloadreview(true)
        setstyledlike({
            color: 'green',
            cursor: 'pointer',
        })
        setstyledislike(prevStyle => ({
            ...prevStyle,
            color: 'black' // change color to black on each click
        }));
        dispatch(setswapdetailreview({
            ...swapdetailreview,
            likes: '',
            dislike: 'dislike',
        }));
     
    }
    /************* Ready to drop review *********/
  
    useEffect(() => {
        async function DropReview() {
            if (isloadreview) {
                axios
                    .post(`api/dropReview`, swapdetailreview)
                    .then((response) => {
                        dispatch(setswapdetailreview())
                        dispatch(settradesucces(false))
                        dispatch(setlockbutton(false))
                        dispatch(setmessage())
                        dispatch(setmsg())
                            setisloadreview(false)
                            dispatch(setprocessTrigger())
                            dispatch(setprocessing(false))
                            //  Navigate("/trade", { replace: true });
//Regenerate invoice

const prefix = "SQE";
const suffix = "MAT";
const date = new Date().toISOString().slice(2, 7).replace("-", "");
const randomNumber = Math.floor(Math.random() * 10000);
const paddedNumber = randomNumber.toString().padStart(4, "0");
dispatch(setInvoiceNumber(`${prefix}${date}${paddedNumber}${suffix}`));


                    })

            }
        }
        DropReview()
    }, [storeDataUsdt, isloadreview])
    /******************* END **************/

    /***************** SUBMIT TRADE DATA *********/
    function HandlerTradeData(e) {
        e.preventDefault()
        dispatch(setmessage())
        setverifyload(true)
        setisLoadingtrade(true)
    }
   
    const {message,lockbutton}=useSelector((state)=>state.Createslice)

    const[sucesss,setsucesss]=useState()
    const [isLoadingtrade, setisLoadingtrade] = useState(false)
 
    /************ CheckVefication ********/
const{verified}=useSelector((state)=>state.Tradeslices)
const badge=verified && verified.verify_badge
const Priceproduct=storeDataUsdt && storeDataUsdt.Usdtamount
const [verifyload,setverifyload]=useState(false)


/******************* Trade USDT *************/   
useEffect(() => {
        async function SwapGiftCard() {
            if(verifyload)
            {
            if((badge == 0))
            {
                dispatch(setmessage('UnAuthorized kindly Verify your account'))
                setisLoadingtrade(false)
               setverifyload(false)
            }
            else  if((badge == 1) && (Priceproduct > 500))
            {
              
                dispatch(setmessage('UnAuthorized kindly Verify your account'))
                   setisLoadingtrade(false)
                setverifyload(false)
            }
            else  if((badge == 2) && (Priceproduct > 2000)){
                dispatch(setmessage('UnAuthorized kindly Verify your account'))
               setisLoadingtrade(false)
               setverifyload(false)
            }
           else if (isLoadingtrade) {
                axios
                    .post(`api/Swapping`, storeDataUsdt,{
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        if (response.data.status === 419) {
                            dispatch(setmessage( <>
                                {response.data.errors.giftcard_image} <FaExclamationCircle />
                              </>))
                            setisLoadingtrade(false)
                            setverifyload(false)
                        }
                        else if (response.data.status === 405) {
                            dispatch(setmessage( <>
                            {response.data.data} <FaExclamationCircle />
                          </>))

                            // setmessage({response.data.data} <FaExclamationCircle/> );
                            setisLoadingtrade(false)
                            setverifyload(false)
                        }
                        else if (response.data.status === 201) {
                            dispatch(setmessage( <>
                                {response.data.errors} <FaExclamationCircle />
                              </>))
                            setisLoadingtrade(false)
                            setverifyload(false)
                        }
                        else {
                      dispatch(setmessage(response.data.data));
                           setsucesss(response.data.status)
                              setisLoadingtrade(false)
                              setverifyload(false)
                              dispatch(setprocessTrigger(true))
                              setSelectedFiles();
                              dispatch(setgiftcardmsg(true))
                               dispatch(setlockbutton(true))
                            setstoreDataUsdt((previous) =>{
                               return {
                                ...previous,
                                     giftcard_image: '',
                             
                          } 
                         })
                          
                        }


                    })

            }
        }
        }
        SwapGiftCard()
    }, [storeDataUsdt, isLoadingtrade,verifyload])
   
    /****************** END *************/
    /*********** CONNECT BUYER AND SELLER TO CHECK TRADE STATUS ******/
    /*********************  polling Request to check trade if trade has been accepted or rejected by the buyer and  seller will send review
     *****/ 
    useEffect(() => {
        let interval;
        if(processTrigger){
           interval = setInterval(() => {
             fetchProducts();
           }, 8000);
         }
     
         return () => clearInterval(interval);
      }, [processTrigger]);

const connect_data={
    invoiceNumber:invoiceNumber
}
      const fetchProducts = async () => {
        if(processTrigger)
        {
             const response = await axios.post('api/connecting_buy_seller',connect_data);

             if(response.data.escrow_status == 1){
            dispatch(setmessage('Swap Accepted'))
            dispatch(setprocessTrigger(false))
            dispatch(settradesucces(true))
             //Regenerate invoice
            const prefix = "SQE";
                    const suffix = "MAT";
                    const date = new Date().toISOString().slice(2, 7).replace("-", "");
                    const randomNumber = Math.floor(Math.random() * 10000);
                    const paddedNumber = randomNumber.toString().padStart(4, "0");
                   dispatch(setInvoiceNumber(`${prefix}${date}${paddedNumber}${suffix}`));
                /********** review details */
     
                   dispatch(setswapdetailreview({
                    review_id:response.data.review_id,
                  likes: '',
                  dislike: ''
                          }))
                          /***********end */
                           setstoreDataUsdt({
                                usdt_buy_id: fetchUsdt.usdt_buy_id,
                                Usdtamount: fetchUsdt.Usdtamount,
                                invoiceNumber: `${prefix}${date}${paddedNumber}${suffix}`,
                                giftcard_image: '',
                                user_id: userId
                            })
            dispatch(setprocessing(true))
        }
         else if(response.data.escrow_status == 2){
            dispatch(setprocessTrigger(false))
         dispatch(setprocessing(true))
         dispatch(settradesucces(false))
         dispatch(setmessage('Swap Rejected'))
              //Regenerate invoice
              const prefix = "SQE";
            const suffix = "MAT";
            const date = new Date().toISOString().slice(2, 7).replace("-", "");
            const randomNumber = Math.floor(Math.random() * 10000);
            const paddedNumber = randomNumber.toString().padStart(4, "0");
            dispatch(setInvoiceNumber(`${prefix}${date}${paddedNumber}${suffix}`));
           /********** review details */
     
            dispatch(setswapdetailreview({
                review_id:response.data.review_id,
              likes: '',
              dislike: ''
                      }))
                      /****************end */
            setstoreDataUsdt({
                usdt_buy_id: fetchUsdt.usdt_buy_id,
                Usdtamount: fetchUsdt.Usdtamount,
                invoiceNumber: `${prefix}${date}${paddedNumber}${suffix}`,
                giftcard_image: '',
                user_id: userId
            })
        }
        
        }
       
      };
    /******************** Time left to pay **************/
    const [counter, setCounter] = useState(3600); // set counter to 30 minutes (in seconds)

    useEffect(() => {
        const intervalId = setInterval(() => {
          setCounter(prevCounter => {
            if (prevCounter > 0) {
              return prevCounter - 1;
            } else {
              clearInterval(intervalId); // Stop the interval when counter reaches 0
              return 0;
            }
          });
        }, 1000);
      
        return () => {
          clearInterval(intervalId);
        };
      }, []);
    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;
  
    /********************** End *********/
    const disstyled={
        cursor:'not-allowed',
    pointerevents: 'none',
    opacity: '0.6'
      
      }
    /******************* END  *************/
    return (
        <div style={showChat  && size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
     
            <Auth />
            <Tradepagevalidat/>
<Verificationquery/>
            <div className="container-fluid">
                <Sidebar />
                <header>
                    <Header />
                   
                </header>
                <hr />
             { message  ?
             
                         <MovingText
              type="slideInFromRight"
              duration='350ms'
              className={sucesss == 200 || tradesucces ? "sucessmsg": "errormsg"}
              delay='0s'
              direction='alternate'
              //    timing='ease'
              iteration='1'
              fillMode='none'> 
               <p>{message} </p></MovingText> 
           

             : null }

                <div className="terms">
              
                     { processing ? 

                       // Drop Review
                    
                      <div className="conditions">
                      {/* <form> */}
                          <div className="final-seller-terms">
                              <div className="termsof">
                                  <div className="conditions">
              
                                      <div id="imageContainer">
                                          <div className="image-preview" id="image-preview">
              
                                          </div>
                                      </div>
                                      {/* DROP REVIEW */}
              
                                      <div id="review">
                                          {/* <h2>Swap Accepted</h2> */}
                                          <div className="review"  >
                                              <p>Drop a review</p>
                                              <FaThumbsUp onClick={Handlerthumpsup} style={styledlike} />
                                              <FaThumbsDown onClick={Handlerthumpsdown} style={styledislike} />
                                          </div>
                                      </div>
              
                                  </div>
              
              
              
                              </div>
              
                          </div>
              
              
                      {/* </form> */}
              
                  </div>
                 
                        :
                   
                     <>
                     <div className="head-back">
             <h1>  <Link to='/Systemexchange'> <IoIosArrowBack /></Link> <span>Read terms before proceeding Uploading {fetchUsdt.accept_giftcard} to seller</span></h1>    
 

 </div> 
             
                 <div className="conditions">
                     <div className="head">
                         
                         <h2> Transaction ID: {invoiceNumber ? invoiceNumber : 'SQEMAT7638129'}</h2>
                     </div>
                     <p>
                         USDT for this trade has been locked in escrow. Please make sure you meet seller’s term before uploading {fetchUsdt.accept_giftcard} worth {fetchUsdt.Usdtamount}USD for this transaction
                     </p>

                     <div className="time">

                       
                         <FaClock />
                         <p>Time left to pay : <span id="counter"> 
  {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
 
                             
                         </span></p>



                     </div>


                     <form onSubmit={HandlerTradeData}>
                         <div className="final-seller-terms">
                             <div className="termsof">
                                 <div className="conditions">
                                     <div id="imageContainer">
                                         <div className="image-preview" id="image-preview">

                                             {selectedFiles ? (
                                                 <>
                                                     <img
                                                         src={URL.createObjectURL(selectedFiles)}
                                                         alt=""
                                                         style={{ width: "100%" }}
                                                     />
                                                     {/* <button onClick={() => handleRemoveImage()}>Remove</button> */}

                                                 </>
                                             ) : (
                                                 ""
                                             )}

                                         </div>
                                     </div>
                                   
                                 </div>
                                 <input type="file"
                                     name="giftcard_image"
                                     ref={fileInputRef}

                                     accept="image/*"
                                     onChange={handleFileSelect}
                                     style={{ display: "none" }}
                                 />
                                 <div className="actions">
                                     <button type="button" className="cancel" id="cancel" onClick={() => handleRemoveImage()} style={{ cursor: "pointer" }}>Cancel</button>
                                     <div className="btn-action">
                                         <button type="button" className="upload" id="upload" onClick={handleImageClick}>Upload Gift Card to Seller</button>
                                         {/* <button type="button" class="send" id="send" disabled="" style={{cursor: "not-allowed"}}>
                                     <img src={sender} alt=""/>
                                 </button> */}
                                     </div>
                                 </div>
                                 <input type="file"
                                     name="" id="image-input"
                                     style={{ display: 'none' }} />

                             </div>

                         </div>
                         <div className="cont">
                             <button   disabled={lockbutton || isLoadingtrade ? 'disabled': null}   style={lockbutton || isLoadingtrade ? disstyled : null}><span>{isLoadingtrade ? "Please wait..." : 'Continue'}</span></button>
                         </div>

                     </form>

                 </div>
                 </>
                    //  <DropReview
                    //  styledlike={styledlike}
                    //  styledislike={styledislike}
                    //  Handlerthumpsup=  {() => Handlerthumpsup()}
                    //  Handlerthumpsdown= {() => Handlerthumpsdown()}
                    //  />
                        // <div className="conditions">
                        //     {/* <form> */}
                        //         <div className="final-seller-terms">
                        //             <div className="termsof">
                        //                 <div className="conditions">

                        //                     <div id="imageContainer">
                        //                         <div className="image-preview" id="image-preview">

                        //                         </div>
                        //                     </div>
                        //                     {/* DROP REVIEW */}

                        //                     <div id="review">
                        //                         {/* <h2>Swap Accepted</h2> */}
                        //                         <div className="review"  >
                        //                             <p>Drop a review</p>
                        //                             <FaThumbsUp onClick={() => Handlerthumpsup()} style={styledlike} />
                        //                             <FaThumbsDown onClick={() => Handlerthumpsdown()} style={styledislike} />
                        //                         </div>
                        //                     </div>

                        //                 </div>



                        //             </div>

                        //         </div>


                        //     {/* </form> */}

                        // </div>
                               //  END
                    }
                </div>

            </div>
            {/* CHAT LIVE SUPPORT */}
            <ChatInterface/>
        </div>
    )
}
export default Finaltrade