import React from "react";
import { CgDollar } from "react-icons/cg";
import { useSelector } from "react-redux";

const Featured =(props)=>
{
    const { size }=useSelector((state)=>state.WindowSize)
    return (
      <div className="featured-item"> <div className='top'>
      <img src={props.image} alt=""/>
    </div> 
     <div className="pricetag">
          <p>{props.category}</p>   
          {/* <CgDollar/>{props.price} */}
          <span>{props.title} </span>
          <span style={{  fontWeight: '300',lineHeight: '1.5em' }}>
           {props.description} </span>
      </div>
      {/* <h3 className="product-name">{props.title}</h3> */}
      <div className="datetag">
          <p> <CgDollar/>{props.price} </p>
       {size > 767 ?  <span> <button onClick={props.CartItem}>Purchase </button></span> : null}  
      </div>
      {size < 767 ?
      <button onClick={props.CartItem}>Purchase </button> : null}
       
  </div>
    )
}
export default Featured