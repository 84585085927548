import axios from "axios";
import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import ClearSessionStorageAfterInactivity from "./ClearSessionStorageAfterInactivity";

const AdminAuth=()=>{
    const authorization=sessionStorage.getItem('admin_authenticated')
      const Navigate=useNavigate()
       const[authloading,setauthloading]=useState(true)
       const [searchTerm, setSearchTerm] = useState('');
       /******* 2FA */
       useEffect(() => {
        sessionStorage.removeItem('Admin_TOTP')
        
    })
    /******* END */
      useEffect(()=>{
      async function auth(){
          if(authorization){
            try{
          axios.
        get(`api/admin/validate_jwt`) 
    .then((res)=>{
          
               if(res.data.status == 419){
              
                sessionStorage.clear('admin_authenticated')
                Navigate('/Admin/login',{replace :true})
            
            }
            else {
              setauthloading(false)
            }
           
     })
        }
        catch (error) {
         // console.log('Error:', error.message);
          setauthloading(false);
        }
      }
        else
        if(!authorization){
             Navigate('/Admin/login',{replace :true})
            
        }    
        else{
          setauthloading(false)
        }
      };
      auth()
      },[authorization])

      const admin_token = sessionStorage.getItem('admin_authenticated');
      axios.defaults.headers.common['Authorization'] = `Bearer ${admin_token}`;
    
    
     //dependacy array is use to controll rendering process, 
    return(
<>
<ClearSessionStorageAfterInactivity/>
</>
    )
}
export default AdminAuth
