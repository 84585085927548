import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import connect from '../../img/sign-coin-logo.png'
import logos from '../../img/squaremart-logo.png'
import googlelogo from '../../img/Google - png 0.png'
import Footer from "./Footer";
import { FaBitcoin, FaQuestion, FaRegEye, FaRegEyeSlash, FaTimes } from "react-icons/fa";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { SiCoinmarketcap } from "react-icons/si";
import axios from "axios";
import { setcountries, setloginbutton, setmessage } from "../Redux/Createslice";
import Google from "./Google";
import loadimg from '../../img/bx_loader-circle.png'
import { auth } from "./Firebase";
import firebase from 'firebase/app'
import {createUserWithEmailAndPassword, sendSignInLinkToEmail} from 'firebase/auth'
import { useRef } from "react";
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'

const Register=()=>
{
    
    /******************* Login with Google ******/
    function Googled() {

        window.location.href = 'api/authorized/google'
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    const Navigate = useNavigate()
    const accesstoken = sessionStorage.getItem("authenticated");
  
    useEffect(() => {
        async function auth() {
        
            if (token) {
                sessionStorage.setItem("authenticated", token);
                Navigate('/Dashboard', { replace: true })
                
            }
            else if (accesstoken) {
                 Navigate('/Dashboard', { replace: true })
             }

        }
        auth()
    })
    /********************  Register ************/
    const [ regData,setregData]=useState([])
    const [Readyload,setReadyload]=useState(false)
    const {message}=useSelector((state)=>state.Createslice)
const [errorfield,seterrorfield]=useState()
const dispatch=useDispatch()
    function RegisterData(event)
    {
        
    dispatch(setloginbutton(false))
        setReadyload(false)
        dispatch(setmessage())
       const{name,value,type}=event.target
     
       if (name == 'phone' && (!(/^\d+$/.test(value)))) {
    dispatch(setmessage('Invalid phone number format!'))
    seterrorfield(419)
    setregData((previous)=>(
         {
        ...previous,
        phone:null
        }
    ))
   
  }
  else{
       setregData((previous)=>{
        return {
        ...previous,
        [name]:type === 'checkbox' ? 'checkbox' : value
     }
     })
    }
}

const{loginbutton,countries}=useSelector((state)=>state.Createslice)

// console.log(countries)
const country_code= countries.map((sourceElement,index) => {
    return (
      
            <option key={index} value={sourceElement.callingCode}>
                {sourceElement.callingCode}
            </option>


    );
});
    useEffect(()=>{
        async function Register()
        {
            if(Readyload)
            {
    axios.post(`api/registered`,regData)
    .then((res)=>{
        dispatch(setmessage(res.data.data))
        seterrorfield(res.data.status)
        setReadyload(false)
        if(res.data.success === true)
        {
            // sessionStorage.setItem("authenticated", res.data.token)
              dispatch(setmessage('Verification Link Sent to Your mail'))
            dispatch(setloginbutton(false))
              seterrorfield(401)
        }
    })
            }

        }
        Register()
    },[Readyload])
    const emailRef=useRef(null)
    const passwordRef=useRef(null)
   function Handlerregister(e)
   {
    e.preventDefault()
    dispatch(setmessage())
    dispatch(setloginbutton(true))
    /******** Firebase Auth *****/
    createUserWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
    ).then((auth)=>{
         setReadyload(true)
    }).catch((error)=>{
        const errorCode=error.code
        if( errorCode === 'auth/email-already-in-use'){
            dispatch(setmessage(('Email Already Exits !')))
            seterrorfield(201)
    dispatch(setloginbutton(false))
        }
        else if( errorCode === 'auth/weak-password')
        {
            dispatch(setmessage(('Password too weak')))
            seterrorfield(501)
    dispatch(setloginbutton(false))
                
        }
        else{
            dispatch(setloginbutton(false))
            setReadyload(false)
            
        }
        
    })
   }
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
      
     const[showmenu,setshowmenu]=useState(false)
    function Menumobile(){
     setshowmenu((previus) => !previus)
     }
     const border={
       border: '1px solid red'
     }
     /********************** PASSWORD SHOW ***/
     const [showPassword, setShowPassword] = useState(false);

     const togglePasswordVisibility = () => {
       setShowPassword(!showPassword);
     };

     const inputType = showPassword ? 'text' : 'password';
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <DesktopHeader/>
 <MobileHeaders/>
<div className="container">

       <div className="signup-layout">
        <div className="layout-field">
            <div className="squaremart">
                <div className="square-head">
                        {/* <img src={logos} alt="mart logo"/> */}
                    <div className="sign-up">
                        <h1 style={{ color:'#000000'}}>Register</h1>
                        <Google/>
                        {/* <button onClick={Googled}><img src={googlelogo}
                        alt="Continue with google"/></button> */}
                    </div>
                </div>
                <div className="form-fill">
                {(errorfield === 401 && message) ? <p style={{padding:'10px',backgroundColor:'rgb(61, 160, 61)',color:'white'}}>{message}</p> : null }
                       
                    <form action="" onSubmit={Handlerregister} method="post">
                    <label htmlFor="name">Full Name</label>
                        <input type="text" onChange={RegisterData} 
                        required name="name" placeholder="Enter your Name" id="name"/>
                        
                        <label htmlFor="email">Email Address</label>
                        <input type="email" ref={emailRef} onChange={RegisterData} style={(errorfield === 201 && message) ? border : {color: 'whore'}}
                        required name="email"
                        placeholder="Enter your Email Address" id="email"/>
                         {(errorfield === 201 && message) ? <p>{message}</p> : null }
                       
                        <label htmlFor="phone">Phone Number</label>
                        <span>    <select defaultValue='' onChange={RegisterData}
                         name="code" className='phone' required>
                        <option disabled="disabled" value=''>+234</option>
                      {country_code}
                        </select>
                         <input type="tel"   style={(errorfield === 419 && message) ? border : {color: 'whore'}}
                         onChange={RegisterData} required name="phone" 
                         placeholder="Enter your Phone number" id="phone"/></span>
                        {(errorfield === 419 && message) ? <p>{message}</p> : null }
                       
                        <label htmlFor="password">Password</label>
                        <span className="spann">   
                        <input type={inputType} placeholder="Enter your Preffered Password" 
                        style={(errorfield === 501 && message) ? border : 
                        {color: 'whore'}} ref={passwordRef} 
                        onChange={RegisterData} required name="password"
                         id="password"/>
                         {showPassword ? (
        <FaRegEyeSlash
          className="eye-icon"
          onClick={togglePasswordVisibility}
        />
      ) : (
        <FaRegEye
          className="eye-icon"
          onClick={togglePasswordVisibility}
        />
      )} </span> 
                        {(errorfield === 501 && message) ? <p>{message}</p> : null }
                      
                        <div>
                        <input type="checkbox" required name="accept" id="accept"/>
                        <span>I have read and agree with <Link to="/TermsCondition">Terms of service</Link> and <Link to="/privacy">privacy Policy</Link></span>
                        </div>
                        <button  className={(regData.phone === null) || loginbutton ? 'disabledButton' :"btn-create"} type="submit">{Readyload || loginbutton ? <img src={loadimg} className="spin-icon" /> :'Create an account'}</button>
                        <span className="existed">Already have an account? <Link to="/login">Sign in</Link></span>
                    </form>
                </div>
            </div>
            <div className="square-frame">
                <img src={connect} alt="Connect with us"/>
            </div>
        </div>
    </div>
    {showmenu ? 
                     <div id="mySidenav" className= "sidenav" >
                        <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
                          <ul>
                          <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
                          <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>
                          <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li class="login">
                            <Link style={link} to='/login'>Login</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
    {/* <Footer/> */}
       </div>
       </div>
    )
}
export default Register
