import { initializeApp } from 'firebase/app'
import {getAuth,GoogleAuthProvider} from 'firebase/auth'
import { useGoogleData } from './Googledata';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYUPTh5mhcBo56KLZIiCZzSibMJnodZZU",
  authDomain: "squaremart-ebb15.firebaseapp.com",
  projectId: "squaremart-ebb15",
  storageBucket: "squaremart-ebb15.appspot.com",
  messagingSenderId: "93990245454",
  appId: "1:93990245454:web:22ec31de0fc350a18e1f1f"
};

//Account console used Quozeem.salam@gmail.com squaremart project

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth =getAuth(app)
const provider = new GoogleAuthProvider()
// export const signInWithGoogle = ()=>
// {

//   signInWithPopup(auth,provider)
//   .then((result)=>{
//     const GoogleData={
//         'email':result.user.email,
//         'name':result.user.displayName,
//         'google_id':result.user.uid
      
//     }
//     console.log(GoogleData)
//   }).catch((error)=>{
//     console.log(error)
//   })
// }
