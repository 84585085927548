import React, { useEffect } from "react";
import Auth from "./Auth";
import Header from "./Header";
import Sidebar from "./Sidebar";
import fileupload from '../../img/choose-file.png'
import { useRef } from "react";
import { useState } from "react";
import axios from "axios";
import sephora from '../../img/sephora.png'
import IconHome from '../../img/home.png'
import { FaAmazon, FaCcVisa, FaSteam, FaGooglePlay, FaApple } from "react-icons/fa";
import { useSelector } from "react-redux";

const Giftcard = (props) => {
  const {allgiftcard}=useSelector((state)=>state.SwapCard)

  const Giftcard=allgiftcard.map((element,index)=>{
  return ( 
     <div className="card" key={index} onClick={() => props.GiftcardSelect(element.cards)}>
  <div className="icon">
    <img src={element.images} width='22' alt=""/>
  </div>
  <div className="card-name">
    {element.cards}
  </div>
</div>
  )
})
  return (
    <div className="giftcards">
      <div className="head">
        <h2>Gift Cards</h2>
      </div>
      <p>
      Trade with any of the available giftcard on our platform
      </p>
      <div className="body">
        {Giftcard.length > 0 ? Giftcard : <p style={{fontWeight:'bold', textAlign:'center' }}>TABLE IS CURRENTLY EMPTY</p>}
       
      </div>
    </div>
  );
};

export default Giftcard