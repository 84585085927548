import React, { useEffect, useRef, useState } from 'react'
import jwt_decode from 'jwt-decode'
import axios from "axios";
import Footer from './pages/Footer';
import { Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { BsFillChatRightTextFill, BsFillMenuButtonFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import {AiOutlineMenu, AiOutlineWallet} from 'react-icons/ai'
import {FaBitcoin, FaDraftingCompass, FaHome, FaQuestionCircle, FaRegTimesCircle, FaTimes} from 'react-icons/fa'
import { MdContactPage, MdOutlineCardGiftcard } from 'react-icons/md';
import { SiCoinmarketcap } from 'react-icons/si';
import logo from '../img/squrelogo.png'
import searh from '../img/Search.svg'
  import { Toggleshow} from './Redux/Chatslice';
import { setAttempt, setCategory, setallProduct,setcomplete,setcountries,setdataNotFound } from './Redux/Createslice';
import { setCategoryLoad, setcompletecounttrade, setcountryCount } from './Redux/Balanceslice';


const Header=()=>
{
    const{allProduct,category_name,dataNotFound,Categories}=useSelector((state=>state.Createslice))
const { size }=useSelector((state)=>state.WindowSize)
const{countryCount,CategoryLoad,completecounttrade}=useSelector((state=>state.Balanceslice))

const { showChat }=useSelector((state)=>state.Chatslice)
const dispatch=useDispatch()
const Navigate = useNavigate()
/************************ COMPLETE TRADE    **********/
useEffect(() => {
  if(completecounttrade === 0){
  // Send request to check page load count
  axios.get('/api/completeTrade')
    .then(response => {
      dispatch(setcomplete(response.data));
    dispatch(setcompletecounttrade(countryCount + 1));
    })
    .catch(error => {
      dispatch(setAttempt());
         })
  };
}, []); // Empty dependency array to run only once on component mount

useEffect(() => {
  if (completecounttrade !== 0) {
    // Delay subsequent requests by 1 minute
    const delay = setTimeout(() => {
      // Send request after delay
      axios.get('/api/completeTrade')
        .then(response => {
          dispatch(setcomplete(response.data));
          dispatch(setcompletecounttrade(completecounttrade + 1));
        })
        .catch(error => {
          dispatch(setAttempt());
        });
    }, 60000); // 1 minute in milliseconds

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(delay);
  }
}, [completecounttrade]);
/************* END ****/
/************************ Location Country   **********/
useEffect(() => {
    if(countryCount === 0){
    // Send request to check page load count
    axios.get('/api/location_Country')
      .then(response => {
        dispatch(setcountries(response.data));
      dispatch(setcountryCount(countryCount + 1));
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (countryCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/location_Country')
          .then(response => {
            dispatch(setcountries(response.data));
            dispatch(setcountryCount(countryCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [countryCount]);
/************* END ****/
/************************ category    **********/
useEffect(() => {
    if(CategoryLoad === 0){
    // Send request to check page load count
    axios.get('/api/Category')
      .then(response => {
        dispatch(setCategory(response.data));
         dispatch(setCategoryLoad(CategoryLoad + 1));
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (CategoryLoad !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/Category')
          .then(response => {
            dispatch(setCategory(response.data));
         dispatch(setCategoryLoad(CategoryLoad + 1));
    
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [countryCount]);

/*************** END  *****/
  
/*============== COMPLETE TRADE  // Latest Activities ================*/
const [completedTrade, setCompletedTrade] = useState(false);

useEffect(() => {
    async function AllProductCATEGORY() {
        if (category_name) {
            axios.get(`api/allProductsCategory/${category_name}`)
                .then((response) => {
                    dispatch(setallProduct(response.data));
                    dispatch(setdataNotFound('Product Table is Currently Empty'));
                });
        }
    }

    AllProductCATEGORY();

    const intervalId = setInterval(AllProductCATEGORY, 50000); // 50 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
}, [category_name]);
/**  END */
/**************** Menu Mobile */
const[showmenu,setshowmenu]=useState(false)
function Menumobile(){
 setshowmenu((previus) => !previus)
 }
 /** END  */
  /* HELP SUPPORT */
  const toggleChat = () => {
           
    dispatch(Toggleshow());
  };
 
    return (
        <header>
        <div className='head-nav'>
   <div className="header-top">
        <div className="header-top-left">
            <div className="logo">
                <Link to='/'><img src={logo} 
                alt="logo"
                /></Link>
            </div>
             <div className="menu-container">
             <AiOutlineMenu onClick={Menumobile} style={{ fontSize:'25px',fontWeight:'bold',color:'black' }}/>
     
            </div>

        </div>
        <div className="header-top-middle">
            <img src={searh} alt="search-btn"/>
            <input type="search" name="search" placeholder="Search for anything"/>
        </div>
        <div className="header-top-right">
        {!sessionStorage.getItem("Prodile_name") ||  sessionStorage.getItem("Prodile_name") == 'undefined' ?
                            <> <Link to='/login' className="log-in">Log-in</Link>
                            <Link to='/register'  className="sign-up">Sign-up</Link>
                       </>
                       :
                   <p style={{textDecoration: 'none',cursor:'pointer', fontSize:'20px'}} 
                   onClick={()=> Navigate(`/login` ,{ replace: true })} className='profile-login'>{ sessionStorage.getItem("Prodile_name")}</p> 
    }
            </div>
   </div>
   <nav className="header-bottom" id="navbar">
        <ul className="desktop">
            <li><Link to='/'>Home</Link></li>
            <li><Link to='/AboutUs'>About US</Link></li>
            <li><Link to="/Marketplace">Marketplace</Link></li>
            <li><Link to="/Dashboard">Wallet</Link></li>
            <li><Link to="/ContactUs">Contact US</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
        </ul>
         
   </nav>
   {/* <!-- MOBILE HEADER --> */}
   {showmenu  ? 
   <div className="header-mobile">
    <span><FaTimes onClick={Menumobile}/></span>
         <ul className="top">
            <li><Link to='/'>
           <FaHome/>   
            Home</Link></li>
            <li><Link to='/AboutUs'>
               <FaDraftingCompass/>About US</Link></li>
               <li><Link to='/Marketplace'>
               <SiCoinmarketcap/>Marketplace</Link></li> 
              
            <li><Link to='/Dashboard'><AiOutlineWallet/>Wallet</Link></li>
            <li><Link to="/ContactUs"><MdContactPage/>Contact</Link></li>
            {/* onClick={toggleChat} */}
            <li><Link  to="/faq"><FaQuestionCircle/>FAQ</Link></li>
        </ul>
        <ul className="bottom">
            <li className="log-in"><Link to="/login">Log-in</Link></li>
            <li className="sign-up"><Link to="/register">Sign-up</Link></li>
            </ul>
        </div>
        :
        null }

   {/* <!-- MOBILE HEADER ENDS --> */}
   
   </div> </header>
    )
}

export default Header