import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Tradepagevalidat=()=>
{
    const{usdt_buy_id}=useSelector((state)=>state.SwapCard)
  
const Navigate=useNavigate()
    useEffect(()=>{
        if( (!(usdt_buy_id)))
        {
             Navigate("/trade" ,{ replace: true });
    
        }
    })
    return (
        <>
        </>
    )
}
export default Tradepagevalidat