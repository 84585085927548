import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  unreadMsg:'',
  InboxMSg:[],
  dataNotFound:''
}

export const Inboxslices = createSlice({
  name: 'Inbox',
  initialState,
  reducers: {
   
   
    setunreadMsg: (state, action) => {
      state.unreadMsg = action.payload
    },
    setInboxMSg: (state, action) => {
      state.InboxMSg = action.payload
    },
    setdataNotFoundInbox: (state, action) => {
      state.dataNotFound = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setunreadMsg,setInboxMSg,setdataNotFoundInbox } = Inboxslices.actions

export default Inboxslices.reducer