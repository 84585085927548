import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,Switch,
  Route,

  useParams,
} from "react-router-dom";
import Login from './component/pages/login';
import Home from './component/index'
import Verification from './component/Dashboard/Verification';
import Dashboard from './component/Dashboard/Wallets';
import Marketplace from './component/pages/Marketplace'
import AboutUs from './component/pages/AboutUs'
import ContactUs from './component/pages/Contactus'
import TermsCondition from './component/pages/TermsCondition'
import Faq from './component/pages/faq'
import Addproduct from './component/Dashboard/Addproduct';
import Trade from './component/Dashboard/Trade'
import Net from './component/Dashboard/Net';
import BuyGiftcard from './component/Dashboard/Trade/BuyGiftcard';
import BuyUsdt from './component/Dashboard/Trade/BuyUsdt';
import Purchase from './component/Dashboard/Purchase';
import Systemexchange from './component/Dashboard/Trade/Systemexchange'; 
import CreateUsdtad from './component/Dashboard/Trade/CreateUsdtad';
import CreateGiftcardad from './component/Dashboard/Trade/CreateGiftcardad';
import Finaltrade from './component/Dashboard/Trade/Finaltrade';
import Acceptcard from './component/Dashboard/Trade/Acceptgiftcard';
import ChatInterface from './component/pages/Chatbot';
import Inboxsms from './component/Dashboard/Inboxsms';
import TradGiftcard from './component/Dashboard/Trade/TradeGifcard';
import Readinbox from './component/Dashboard/Readinbox'
import TwoFactor from './component/pages/TwoFactor';
import Setting from './component/Dashboard/Setting';
import Showpage from './component/Redux/Showpage';
import LiveSupport from './component/pages/LiveSupport';
import NotFound from './component/pages/NotFound';
import Paystack from './component/pages/Paystack';
import Withdrawal from './component/Dashboard/Withdrawal';
import Register from './component/pages/Register';
import Google from './component/pages/Google';
import ForgetPassword from './component/pages/ForgetPassword';
import CompleteRegistration from './component/Dashboard/CompleteRegistration';
import ScrollToTop from './component/pages/ScrollToTop';
import Internet from './component/Dashboard/Offline';
import Privacy from './component/pages/Privacy';
import Webhook from './component/pages/Webhook';
import Squareweb from './component/Squareweb'

/***************** ADMIN *****************/
import AdminWithdrawal from './component/AdminController/Withdraw';
import AdminDashboard from './component/AdminController';
import AdminDeposits from './component/AdminController/Deposits';
import AdminDocuments from './component/AdminController/Documents';
import AddAdmin from './component/AdminController/AddAdmin ';
import Adminexchange from './component/AdminController/AdminExchange';
import Transactionfee from './component/AdminController/Transactionfee';
import Adminlogin from './component/AdminController/Login';
import Security from './component/AdminController/Security';
import AdminTwoFactor from './component/AdminController/TwoFactor';
import Inventory from './component/AdminController/Inventory';
import AddCategory from './component/AdminController/AddCategory';
import AddCard from './component/AdminController/AddCard';
import PrivateRoute from './component/Dashboard/ProtectedRoute';
import ProtectTwoFactor from './component/Dashboard/ProtectTwoFactor';
import AdminPrivateRoute from './component/AdminController/AdminPrivateRoute';
import AdminProctedRoute from './component/AdminController/AdminProctedRoute';

function App() {
  return (
   <>
 <Router>
 <ScrollToTop/>
 <Internet/>
   <Routes>
   
      <Route exact element={ <Home/>} path="/" />
   
             <Route element={ <Marketplace/>} path="/Marketplace" />
             <Route element={ <AboutUs/>} path="/AboutUs" />
             <Route element={ <ContactUs/>} path="/Contactus" />
             <Route element={ <Faq/>} path="/faq" />
             <Route element={ <Login/>} path="/login" />
             <Route element={ <ChatInterface/>} path="/ChatInterface" />
             <Route element={<ProtectTwoFactor>
              <TwoFactor/>
             </ProtectTwoFactor> } path="/TwoFactor" />
             <Route element={ <Net/>} path="/Net" />
             <Route path="*" element={<NotFound/>} />
             <Route path="/register" element={<Register/>} />
             <Route path="/TermsCondition" element={<TermsCondition/>}  />
            <Route path="/Privacy" element={<Privacy/>}  />
            <Route path="/ForgetPassword" element={<ForgetPassword/>}  />
       
           {/* Dashboard  */}
             <Route 
              path="/dashboard"
              element={<PrivateRoute>
              <Dashboard/>
              </PrivateRoute>}
             />
           <Route path="/verification" 
             element={<PrivateRoute><Verification/>
             </PrivateRoute> }  />
             <Route path="/Addproduct"  
             element={ <PrivateRoute>
              <Addproduct/>
              </PrivateRoute>} />
             <Route element={<PrivateRoute>
              <Trade/>
             </PrivateRoute> } path="/trade" />
            <Route element={ <PrivateRoute>
              <Purchase/>
              </PrivateRoute>} path="/Purchase" />
             <Route element={<PrivateRoute>
              <BuyUsdt/>
             </PrivateRoute>} path="/BuyUsdt" />
             <Route element={ <PrivateRoute>
              <Systemexchange/>
              </PrivateRoute>} path="/Systemexchange"/>
             <Route element={ <PrivateRoute>
              <BuyGiftcard/>
              </PrivateRoute>} path="/BuyGiftcard" />
             <Route element={ <PrivateRoute>
              <CreateUsdtad/>
              </PrivateRoute>} path="/CreateUsdtad" />
             <Route element={<PrivateRoute>
              <CreateGiftcardad/>
             </PrivateRoute> } path="/CreateGiftcardad" />
             <Route element={ <PrivateRoute>
              <Finaltrade/>
              </PrivateRoute>} path="/Finaltrade" />
             <Route element={ <PrivateRoute>
              <Acceptcard/>
              </PrivateRoute>} path="/InitiatePayment" />

        <Route element={<PrivateRoute>
          <TradGiftcard/>
        </PrivateRoute>} path="/TradGiftcard" />
             <Route element={ <PrivateRoute>
              <Inboxsms/>
              </PrivateRoute>} path="/inbox" />
              <Route element={ <PrivateRoute>
              <Readinbox/>
              </PrivateRoute>} path="/inboxRead/:sms_id/:activities" />
             <Route element={ <PrivateRoute>
              <Setting/>
              </PrivateRoute>} path="/setting" />
             <Route element={ <PrivateRoute>
              <Paystack/>
              </PrivateRoute>} path="/Paystack" />
           <Route path="/withdrawal" element={<PrivateRoute>
              <Withdrawal/>
              </PrivateRoute>} />
             <Route path="/CompleteRegistration" element={<PrivateRoute>
              <CompleteRegistration/>
              </PrivateRoute>}  />
            
         
         {/* Admin Dashboard */}
         <Route path="/Admin/login" element={<Adminlogin/>}  />
         <Route path="/Admin/TwoFactor" 
         element={<AdminProctedRoute>
          <AdminTwoFactor/>
          </AdminProctedRoute>}  />
      
         <Route path="/Admin/Dashboard"
          element={ <AdminPrivateRoute>
            <AdminDashboard/>
            </AdminPrivateRoute>}  />
         <Route path="/Admin/Withdrawal" 
         element={<AdminPrivateRoute>
          <AdminWithdrawal/>
          </AdminPrivateRoute>}  />
         <Route path="/Admin/Deposits" 
         element={<AdminPrivateRoute>
          <AdminDeposits/>
          </AdminPrivateRoute>}  />
         <Route path="/Admin/Documents"
          element={<AdminPrivateRoute>
            <AdminDocuments/>
            </AdminPrivateRoute>}  />
         <Route path="/Add/Admin" 
         element={<AdminPrivateRoute>
          <AddAdmin/>
          </AdminPrivateRoute>}  />
         <Route path="/Admin/Exchange" 
         element={<AdminPrivateRoute>
          <Adminexchange/>
          </AdminPrivateRoute>}  />
         <Route path="/Transactionfee" 
         element={<AdminPrivateRoute>
          <Transactionfee/>
          </AdminPrivateRoute>}  />
       <Route path="/Admin/Security"
          element={<AdminPrivateRoute>
            <Security/>
            </AdminPrivateRoute>}  />
         <Route path="/Admin/Inventory"
          element={<AdminPrivateRoute>
            <Inventory/>
            </AdminPrivateRoute>}  />
         <Route path="/Admin/AddCategory" 
         element={<AdminPrivateRoute>
          <AddCategory/>
          </AdminPrivateRoute>}  />
         <Route path="/Admin/AddCard" 
         element={<AdminPrivateRoute>
          <AddCard/>
          </AdminPrivateRoute>}  />
       
            </Routes>
        </Router>
  

   </>
  );
}

export default App;
