import axios from "axios";
import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import CardValidate from "./Trade/CardValidate";
import  Verificationquery  from "./Trade/VerifyQuery";
import ClearSessionStorageAfterInactivity from "./ClearSessionStorageAfterInactivity";
const Auth=()=>{
    const authorization=sessionStorage.getItem('authenticated')
      const Navigate=useNavigate()
       const[authloading,setAuthLoading]=useState(true)

       /******* 2FA */
       useEffect(() => {
        sessionStorage.removeItem('TOTP')
   })
    /******* END */
    useEffect(() => {
      const intervalId = setInterval(async () => {
          if (authorization && authloading) {
              try {
                  const res = await axios.get(`api/validate_jwt`);
                  if (res.data.status === 419) {
                      sessionStorage.clear('authorization');
                      Navigate('/login', { replace: true });
                  } else {
                      setAuthLoading(false);
                  }
              } catch (error) {
                  // console.log('Error:', error.message);
                  setAuthLoading(false);
              }
          } else if (!authorization) {
              Navigate('/login', { replace: true });
          } else {
              setAuthLoading(false);
          }
      }, 50000); // 50 seconds
  
      // Clear interval on component unmount
      return () => clearInterval(intervalId);
  }, [authorization, Navigate, authloading]);
  
    //   useEffect(()=>{
    //   async function auth(){
    //       if(authorization && authloading){
    //         try{
    //       axios.
    //     get(`api/validate_jwt`) 
    // .then((res)=>{
          
    //            if(res.data.status == 419){
              
    //             sessionStorage.clear('authorization')
    //             Navigate('/login',{replace :true})
            
    //         }
    //         else {
    //           setauthloading(false)
    //         }
           
    //  })
    //     }
    //     catch (error) {
    //      // console.log('Error:', error.message);
    //       setauthloading(false);
    //     }
    //   }
    //     else
    //     if(!authorization){
    //          Navigate('/login',{replace :true})
            
    //     }    
    //     else{
    //       setauthloading(false)
    //     }
    //   };
    //   auth()
    //   },[authorization,Navigate,authloading])

      const token = sessionStorage.getItem('authenticated');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
    
     //dependacy array is use to controll rendering process, 
    return(
<>
<ClearSessionStorageAfterInactivity/>
<CardValidate/>
</>
    )
}
export default Auth
