import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import logo from '../../img/squareswap.png'
import { createBrowserHistory } from 'history';

const Net = () => {
    const Navigate =useNavigate()
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const history = createBrowserHistory();
  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  const previousURL=localStorage.getItem('previousURL')
//   console.log(previousURL)
  useEffect(() => {
        if((isOnline))
        {
             Navigate(`${previousURL}`,{replace :true})
          }
      })
          
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }
    return (
        <body>
            <div className="container">

                {/* <div className="pagination">
                    <div className="paginate">
                        <button disabled={currentPage === 1} onClick={handlePreviousClick}>
                            Previous
                        </button>
                        <button disabled={currentPage === totalPages} onClick={handleNextClick}>
                            Next
                        </button>
                    </div>
                </div> */}
                <header>
                    <div className="headers">

                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="" /></Link>
                            {/* <h1>squareswap</h1>  */}
                        </div>
                        <div className="login">
                            <button><Link style={link} to='/login'>login</Link></button>
                        </div>
                    </div>

                    <nav>
                        <ul>

                            <li><Link to="/trade">Buy with Crypto</Link></li>
                            <li><Link to="/trade">Gift cards</Link></li>
                            <li><Link to="/Marketplace">MarketPlace</Link></li>
                            <li><Link to="/dashboard">Wallet</Link></li>
                            <li><Link to="">Help</Link></li>


                        </ul>
                    </nav>

                </header>
             <p>
             You're currently Offline
                </p>  
            </div>
        </body>
    )
}
export default Net