import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    chatLog: [],
    userMessage:'',
    chatthird:'What can I do for you today?',
    chatfirst:'Kindly send your Transaction Invoice Number to continue.',
    showChat:false,
    repliedrobot:`I am sorry, I do not understand. To check any of our FAQs, kindly pick from 0 to 6.
    0 : How do i fund my account?
    1 : Do i need to verify my account before using the app ?
    2 : I would like to talk to one of your agents.
    3 : How long do i have to wait for trade to complete?
    4 : How does escrow protect my fund during trade ? 
    5 : Can i buy cryptocurrency on this app ?
    6 : How do i start selling on squaremart ?  `,
    phoneNumber :'971555637361',
    robotResponses : {
      'hi': 'Hello! Welcome to Squaremart.',
      'pickNumber': 'Please pick a number from 0 to 6.',
     '0': 'To fund your account, simply create an account with us. Once your account is verified, you can seamlessly fund it through your dashboard.',
     '1': 'Yes, account verification is necessary before using the app. Once your account is verified, you can fully access and utilize all the features of the app.',
     '2': 'To talk to any of our agents, kindly click below to talk to one of our Agent.',
     '3': 'The duration for a trade to complete can vary depending on factors such as market conditions, liquidity, and the type of assets being traded. Generally, trades are processed relatively quickly, but it`s advisable to check the specific terms and conditions of the trade.',
     '4': 'Squaremart protects your funds during a trade by acting as a neutral third party that holds the funds until the transaction conditions are met. In a trade, once both parties agree to the terms, the funds are deposited into the escrow account. The escrow service ensures that neither party can access the funds until all the terms of the trade are fulfilled according to the agreed-upon conditions. This helps prevent fraud, disputes, and ensures a secure transaction process for both parties involved in the trade. Once the conditions are met, the funds held in escrow are released accordingly.',
     '5': 'Certainly, Squaremart provides trading and swapping features, ensuring seamless and smooth activities for our users.',
     '6': 'To start selling on Squaremart, simply create an account and follow the platform`s guidelines for setting up your seller profile. Once your profile is complete and verified, you can list your products for sale and begin engaging with potential buyers through the platform`s selling interface.',
   
      // Add more responses for each number option
    },
}

export const Chatslice = createSlice({
  name: 'Size',
  initialState,
  reducers: {
    setChatLog: (state, action) => {
      state.chatLog = action.payload;
    },
    setUserMessage: (state, action) => {
      state.userMessage = action.payload;
    },
    addMessage: (state, action) => {
      state.chatLog.push(action.payload);
    },
      Toggleshow:(state)=>
      {
        state.showChat = !state.showChat;
      },
    setUserMessagedd : (state,action) => {
        state.userMessage = action.payload;

    },
    addRobotResponse:(state,action)=>{
        const newRobotMessage = {
            type: 'robot',
            message: action.payload,
          };
          if (state.chatLog.length === 0) {
            // If chatLog is empty, add the first robot response
            state.chatLog.push(newRobotMessage);
          } else {
            // If chatLog already has messages, check if the first message is the first robot response
            const firstMessage = state.chatLog[0];
            if (firstMessage.type === 'robot' && firstMessage.message === state.chatfirst) {
              // If the first message is already the first robot response, duplicate it
              state.chatLog.push(newRobotMessage);
            } else {
              // If the first message is not the first robot response, replace it with the first robot response
              state.chatLog[0] = newRobotMessage;
            }
          }
          //  state.chatLog=([newRobotMessage]);  
       },
    addUserMessage : (state,action) => {
        const newUserMessage = {
            type: 'user',
            message: action.payload.trim(),
          };
          state.chatLog.push(newUserMessage)
          // state.chatLog=[...state.chatLog, newUserMessage]
  },
    addRobotMessage:(state,action)=>{
        const newRobotMessage = {
            type: 'robot',
            message: action.payload,
          };
          state.chatLog.push(newRobotMessage);        
    }
   
  },
})
export const {addRobotResponse,addMessage, Toggleshow,addRobotMessage ,resetChatLog,setUserMessage,addUserMessage,setChatLog } = Chatslice.actions

export default Chatslice.reducer
