import react, { useEffect, useState } from 'react'
import Headerbar from './Headerbar'
import Sidebar from './Sidebar/Sidebar'
import { Footer } from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { seterrorCode, setmessage } from '../Redux/Admin/AdminStore'

const Transactionfee=()=>
{
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
   
   const dispatch=useDispatch()
   /************** Transaction Limit *******/
   const[LIMITdata,setLIMITdata]=useState([])
   function Transactionlimit(event)
   {
    
    setLoadinglimit(false)
    const {name,value,type}=event.target
    setLIMITdata((previous) => {
        return {
            ...previous,
            [name]: type === 'checkbox' ? 'checkbox' : value
        };
    });
    if (name == 'limits' &&
   ( !/^\d+(\.\d+)?$/.test(value))) {
    setLIMITdata((previous) => {
        return {
            ...previous,
            limits: ''
        };
    });
    
    } 
    
    
   }
 const [Loadinglimit,setLoadinglimit]=useState(false)
 function Handlersubmitlimit(e)
 {
    e.preventDefault()
    setLoadinglimit(true)
 }
 useEffect(()=>{
    async function Transactionlimit()
    {
        if(Loadinglimit){
            axios.post('api/createTransactionLimits',LIMITdata)
            .then((res)=>{
                setLoadinglimit(false)
                dispatch(seterrorCode(res.data.status))      
           dispatch(setmessage(res.data.data))      
              })
        }
    }
    Transactionlimit()
 },[Loadinglimit])
 /************* End */
   /*********** Transaction fee setting *****/
   const[feesdata,setfeesdata]=useState([])
 function feesetting(event)
 {
    
    setLoadingFee(false)
  const {name,value,type}=event.target
  setfeesdata((previous) => {
    return {
        ...previous,
        [name]:type == 'checkbox' ? 'checkbox' : value
    }
  })
  if (name == 'rates' &&
  ( !/^\d+(\.\d+)?$/.test(value))) {
    setfeesdata((previous) => {
       return {
           ...previous,
           rates: ''
       };
   });
   
   } 

 }
 
 const [Loadingfee,setLoadingFee]=useState(false)
 function HandlersubmitFee(e)
 {
    e.preventDefault()
    setLoadingFee(true)
 }
 useEffect(()=>{
    async function Transactionfee()
    {
        if(Loadingfee){
            axios.post('api/createTransactionfee',feesdata)
            .then((res)=>{
                setLoadingFee(false)
                dispatch(seterrorCode(res.data.status))      
           dispatch(setmessage(res.data.data))      
              })
        }
    }
    Transactionfee()
 },[Loadingfee])
   /****************** END *****/
    /************** NAIRA RATE *******/
    const[nairarate,setnairarate]=useState([])
    function Naira_rate(event)
    {
     
        setLoadingrate(false)
     const {name,value,type}=event.target
     setnairarate((previous) => {
         return {
             ...previous,
             [name]: type === 'checkbox' ? 'checkbox' : value
         };
     });
     if (name == 'limits' &&
    ( !/^\d+(\.\d+)?$/.test(value))) {
        setnairarate((previous) => {
         return {
             ...previous,
             rates: ''
         };
     });
     
     } 
     
     
    }
  const [Loadingrate,setLoadingrate]=useState(false)
  function Handlersubmitrate(e)
  {
     e.preventDefault()
     setLoadingrate(true)
  }
  useEffect(()=>{
     async function NAIRA()
     {
         if(Loadingrate){
             axios.post('api/naira/rates',nairarate)
             .then((res)=>{
                setLoadingrate(false)
                 dispatch(seterrorCode(res.data.status))      
            dispatch(setmessage(res.data.data))      
               })
         }
     }
     NAIRA()
  },[Loadingrate])
  /************* End */
   return (
<>
  
<div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>
<div className="admin-wrapper-col-8">
        <div className="admin-topic">Fees setting</div>
        <div className="admin-form-group-add">
            <div className="admin-head-type">
                <h4>Transactions Setup</h4>
                <h4>Fees Setting</h4>
               
                </div>
                <div className="form-container">
                    <div className="form-row">
                        <div className="admin-form-col-6">
                            <form onSubmit={Handlersubmitlimit} method="post">
                                <div className="form-group">
                                  
                                    <div className="form-input">
                                        <label htmlFor="">Transaction Type</label>
                                        <select name="Limitype" onChange={Transactionlimit} required defaultValue=''>
                       <option value="" disabled>Select Option</option>
                                       <option value="DAILY">Daily</option>
                                            <option value="WEEKLY">Weekly</option>
                                        </select>

                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="">limit (USD)</label>
                      <input type="text" placeholder='234' value={LIMITdata.limits || ''} onChange={Transactionlimit} required name="limits" id=""/>
                                    </div>
                                    {Loadinglimit ?  
                                <button disabled='disabled' type="submit" className="submit">
                                Saving...
                                
                                </button>   
                                :
                                <button type="submit" className="submit">
                                        save
                                        
                                        </button> 
                                }
                                </div>
                            </form>

                        </div>
                        <div className="admin-form-col-6">
                            <h4>Fees setting</h4>
                        <form  onSubmit={HandlersubmitFee} method="post">
                                <div className="form-group">
                                    
                                    <div className="form-input">
                                        <label htmlFor="">Transaction Type</label>
                                        <select name="feeType" id="" required onChange={feesetting} defaultValue="">
    <option value="" disabled>Select Option</option>
    <option value="USDT">USDT</option>
    <option value="USD">USD</option>
</select>


                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="">Fees%</label>
                                        <input placeholder='234' type="text" required name='rates' value={feesdata.rates || ''} onChange={feesetting}  id=""/>

                                    </div>
                                    {Loadingfee ?  
                                <button disabled='disabled' type="submit" className="submit">
                                Saving...
                                
                                </button>   
                                :
                                <button type="submit" className="submit">
                                        save
                                        
                                        </button> 
                                }
                                  
                                </div>
                            </form>
                        </div>

                         {/* NAIRA RATE SET UP */}
                        <div className="admin-form-col-6">
                            <form onSubmit={Handlersubmitrate} method="post">
                                <div className="form-group">
                                  
                                    <div className="form-input">
                                        <label htmlFor="" style={{ fontWeight:'bold' }}>
                                            Naira Rate</label>
                                        

                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="">Rate(NGN)</label>
                      <input type="text" placeholder='234' value={nairarate.rates || ''} onChange={Naira_rate} required name="rates" id=""/>
                                    </div>
                                    {Loadingrate ?  
                                <button disabled='disabled' type="submit" className="submit">
                                Saving...
                                
                                </button>   
                                :
                                <button type="submit" className="submit">
                                        save
                                        
                                        </button> 
                                }
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
               
               </div>
               
       


         
        
        </div>
   
<Footer/>


</div></>
    )
}
export default Transactionfee