import react, { useRef, useState } from 'react'
import Headerbar from './Headerbar'
import Sidebar from './Sidebar/Sidebar'
import { Footer } from './Footer'
import { useSelector } from 'react-redux'
import { CgDollar } from 'react-icons/cg'
import Modal from './InfoModal'
import { BiDollar } from 'react-icons/bi'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import * as XLSX from 'xlsx';


const Deposits=()=>
{
    const{dataNotAccount,DepositData}=useSelector((state)=>state.AdminStore)
 
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu,NavMobileMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
  /*************DETALS POP  ******/
   const [openmodal,setopenmodal]=useState(false)
  const [detailsData,setdetailsData]=useState([])
  const[verify ,setverify]=useState(false) 
  function Buttonload()
  {
    setverify(true)
  }
  function Withdrawdetails(id,ref,type,amountdeposit

    )
  {
    setdetailsData((prev)=>
    {
      return {
        ...prev,
        ref:ref,
        type:type,
       id:id,
       amount:amountdeposit
      }
    })
    setopenmodal(true)
    setverify(false) 
  }
  function Modal_cancel()
  {
    setopenmodal(false)
  }
 
   /*============= Deposit ==========*/
   const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return dateObject.toLocaleDateString(undefined, options);
  };
 /******************* SEARCH  Query ************/

 const [searchQuery, setSearchQuery] = useState("");
 const [filteredHistory, setFilteredHistory] = useState([]);
 
 const handleSearchChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);

  const filtered = DepositData.filter((transaction) => {
    const lowerCaseQuery = query.toLowerCase();

    const ref = transaction.ref ? transaction.ref.toLowerCase() : "";
    const amountdeposit = transaction.amountdeposit
      ? transaction.amountdeposit.toLowerCase()
      : "";
    const type = transaction.type ? transaction.type.toLowerCase() : "";
    const status = transaction.status ? transaction.status.toLowerCase() : "";

    // Check if the query matches any part of the date string
    const dateString = formatDate(transaction.created_at).toLowerCase();
    const isDateMatch = dateString.includes(lowerCaseQuery);

    return (
      ref.includes(lowerCaseQuery) ||
      amountdeposit.includes(lowerCaseQuery) ||
      type.includes(lowerCaseQuery) ||
      isDateMatch ||
      status.includes(lowerCaseQuery)
    );
  });

  setFilteredHistory(filtered);
};


   
 /****************** END *****/
 
//   Pagination
const [currentPage, setCurrentPage] = useState(1);
const [productsPerPage,setproductsPerPage] = useState(10);

// Calculate the index of the first and last product to show on the current page
const indexOfLastProduct = currentPage * productsPerPage;
const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

// Extract the subset of product data to show on the current page

   let serialNumberdeposit= 1;
const currentProductsDeposit = DepositData.slice(indexOfFirstProduct, indexOfLastProduct);

// Generate the product components for the current page
const displayedHistory = searchQuery ? filteredHistory : currentProductsDeposit;

 const DepositTable = displayedHistory.map((sourceElement,index) => {
      return (
        <tbody key={index}> 
        <tr>
    <td>{serialNumberdeposit++}</td>
     <td>{sourceElement.ref}</td>
    <td>{sourceElement.type ?? 'USDT'}</td>
    <td>{sourceElement.amountdeposit}</td>
    <td>{sourceElement.fees}</td>
    <td> {formatDate(sourceElement.created_at && sourceElement.created_at)}</td>
    <td><button className={sourceElement.status == 'Success' ? 'admin-success' :"admin-error"}>{sourceElement.status == 'Payment Unsuccessful' ? 'Failed' : sourceElement.status }</button></td>
    <td><button className="admin-det" onClick={()=>Withdrawdetails(sourceElement.id,sourceElement.ref,sourceElement.type,sourceElement.amountdeposit)}>Detail</button></td>
                           
    </tr>
    </tbody> 
    
    );
});
/*********** Next Previous button *************/
// Calculate the total number of pages based on the total number of products
const totalPages = Math.ceil(DepositData.length / productsPerPage);

// Handle the click event for the "next" button
const handleNextClick = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

// Handle the click event for the "previous" button
const handlePreviousClick = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};
const handleRowChange = (event) => {
    const newValue = parseInt(event.target.value);
    setproductsPerPage(newValue);
  };

  /************* END **********/
    // Create a ref for the table component to print
    const tableRef = useRef(null);
// Function to export the table data to Excel
const exportToExcel = async () => {
  const table = tableRef.current.querySelector('table');

  const ws = XLSX.utils.table_to_sheet(table);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Use XLSX.write to generate a buffer
  const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Create a download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Deposits.xlsx';

  // Trigger the download
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
};

  
    // Function to print the table
    const printTable = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const newWin = window.open('', '_blank');
        newWin.document.open();
        newWin.document.write(`
          <html>
            <head>
              <title>Squaremart Users Deposit List</title>
            </head>
            <body>
              ${table.outerHTML}
            </body>
          </html>
        `);
        newWin.document.close();
        newWin.print();
        newWin.close();
      }
    };
    
    return (
     <>
     
   <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>
<div className="admin-wrapper-col-8">
        <div className="admin-topic">Transactions</div>
        <div className="admin-form-group-add">
            <h4>Deposits</h4>
            <div className="admin-col">
             <div className="actions">
                    <ul>
                        {/* <li><button>copy</button></li> */}
                        <li><button onClick={exportToExcel}>Excel</button></li>
                        {/* <li><button>Pdf</button></li> */}
                        <li><button onClick={printTable}>Print</button></li>
                    </ul>
                    </div>
              
                <div className="admin-search">
                    <label>Search:</label>
                    <input type="text"  value={searchQuery}
      onChange={handleSearchChange}
       placeholder="Search for a value..."/>
                </div>
            </div>

            <div className="admin-deposit">
                <div className="admin-out"> 
                    
                        {/* <h4>withdrawal</h4> */}
                    <div className="table-responsive" id="printable-area" ref={tableRef}>
                    {DepositTable.length > 0  ? 
                             <table id="tableToConvert" className="admin-out">
                          <thead>
                          <tr>
                                <th>S/N</th>
                                <th>Invoice</th>
                                <th id="payment">Payment Method</th>
                                <th>Amount (USD)</th>
                                <th>Fee (USD)</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead> 
                           {DepositTable}
                        </table>
               : 
               <h3>{dataNotAccount}</h3> }
                        </div>
                        
    </div>
    </div>     <div className="admin-down">
                    <div className="rowsperpage">
                        <label htmlFor="rowsPerPage">Rows per Page:</label>
                        <select id="rowsPerPage" onChange={handleRowChange} value={productsPerPage}>
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

</div>
                    <div className="admin-btn">
                    <button className={currentPage === 1 ? 'disabledButton' :''} id="prevBtn" onClick={handlePreviousClick}>
                            Previous
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :''} id="nextBtn" onClick={handleNextClick}>
                            Next
                        </button>            
                    {/* <button className="admin-prev" id="prevBtn">prev</button>
                    <button className="admin-next" id="nextBtn">next</button> */}
                </div>
                </div>  </div>    </div>
<Footer/>
</div>{openmodal  ? <Modal
         doc_name='Type:'
         user_name='Amount:'
         number='ref:'
value='true'
         name={<span><BiDollar />{detailsData.amount}</span>}
document={detailsData.type}
phone={detailsData.ref}
        Modal_cancel={Modal_cancel}/> : null}</>   
    )
}
export default Deposits