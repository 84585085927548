import React from 'react'
import logo from '../../../img/squaremart-logo.png'
import { FaMoneyBillAlt, FaRegUser, FaSms, FaTimes } from 'react-icons/fa'
import { RiArrowDropDownLine, RiExchangeDollarFill } from 'react-icons/ri'
import { HiOutlineDocumentText, HiOutlineMail, HiOutlineReceiptRefund } from 'react-icons/hi'
import { MdCategory, MdInventory, MdReport } from 'react-icons/md'
import { AiFillGift, AiFillSetting } from 'react-icons/ai'
import { GiReceiveMoney } from 'react-icons/gi'
import { BiSupport, BiTransfer } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { setNavMobileMenu } from '../../Redux/Admin/Toggle'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { setDepositData, setExchangeTrade, setInventory, setTotalAmount, setWithdrawalData, setcountry_chart, setdataNotAccount, seterrorCode, setmessage } from '../../Redux/Admin/AdminStore'
import axios from 'axios'
import AdminAuth from '../Auth'
import { SiSpringsecurity } from 'react-icons/si'

const Sidebar=()=>
{
    const Navigate=useNavigate()
   const dispatch=useDispatch()
    const [dropDown,setdropDown]=useState(false)
    const [dropDownsettng,setdropDownsettng]=useState(false)
    const {NavMenu,NavMobileMenu}=useSelector((state)=>state.ToggleNav)
   /*********** Page Location *****/
    const location = useLocation();

  useEffect(() => {
    dispatch(seterrorCode())
    dispatch(setmessage())
    dispatch(setNavMobileMenu(false));
  }, [dispatch, location]);

//   console.log(location.pathname)
  /********** END ******/
    function NavMenuMobileToggle()
    {
      dispatch(setNavMobileMenu(false))
    }
    function Dashboardpage()
    {
        Navigate("/Admin/Dashboard", { replace: true });

    }
    function dropdown()
   {
    setdropDown(previos => !previos)
   }
   function dropdownsettng()
   {
    setdropDownsettng(previos => !previos)
   }
   const {size}=useSelector((state)=>state.WindowSize)
  
   /******* TOTAL AMOUNT *****/
   useEffect(()=>{
    async function TotalAmount()
    {
      axios.get('api/TotalAmount')
      .then((res)=>{
        dispatch(setTotalAmount(res.data))
      
      })
    }
    TotalAmount()
  },[])
/********* END *****/
/******* location_chart *****/
useEffect(()=>{
    async function Location_chart()
    {
      axios.get('api/location_chart')
      .then((res)=>{
        dispatch(setcountry_chart(res.data))
      
      })
    }
    Location_chart()
  },[])
/********* END *****/
 /******* WITHDRAWAL *****/
 useEffect(()=>{
    async function WithdrawalData()
    {
      axios.get('api/WithdrawalData')
      .then((res)=>{
        dispatch(setdataNotAccount('Table is Currently Empty'))
    dispatch(setWithdrawalData(res.data))
      
      })
    }
    WithdrawalData()
  },[])
/********* END *****/

/*******  Exchange *****/
useEffect(()=>{
    async function WithdrawalData()
    {
      axios.get('api/AllTradeExchange')
      .then((res)=>{
        dispatch(setdataNotAccount('Table is Currently Empty'))
    dispatch(setExchangeTrade(res.data))
      
      })
      .catch(error => {
        dispatch(seterrorCode(error.response.status))
        dispatch(setmessage(error.response.data.message))
     });
    }
    WithdrawalData()
  },[])
/********* END *****/

/*******  Inventory *****/
useEffect(()=>{
    async function Inventory()
    {
      axios.get('api/inventory')
      .then((res)=>{
        dispatch(setdataNotAccount('Table is Currently Empty'))
        dispatch(setInventory(res.data))
      // console.log(res.data)
      })
    }
    Inventory()
  },[])
/********* END *****/

/******* DEPOSITS *****/
useEffect(()=>{
    async function DepositData()
    {
      axios.get('api/DepositData')
      .then((res)=>{
        dispatch(setdataNotAccount('Table is Currently Empty'))
    dispatch(setDepositData(res.data))
      
      })
    }
    DepositData()
  },[])
/********* END *****/
    function Conditions() {
        if (NavMenu) {
        return (
             <div className="collapsible" style={{ width:'8%' }}>
        <nav>
            <div className="admin-side">
             </div>
            <div className="admin">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
                          <path d="M29.3573 19.0902H19.2719L25.9886 25.8069C26.2453 26.0636 26.6678 26.0844 26.9317 25.8358C28.5765 24.2861 29.708 22.1971 30.0399 19.8488C30.0969 19.4468 29.7632 19.0902 29.3573 19.0902ZM28.6845 16.336C28.3343 11.2587 24.277 7.20137 19.1996 6.85115C18.812 6.82437 18.4856 7.15121 18.4856 7.53969V17.0501H27.9964C28.3849 17.0501 28.7113 16.7236 28.6845 16.336ZM16.4455 19.0902V9.00475C16.4455 8.59885 16.0889 8.26521 15.6872 8.32216C10.6222 9.0379 6.75064 13.4628 6.93085 18.7659C7.11616 24.2122 11.8055 28.6783 17.2543 28.6099C19.3964 28.5831 21.3758 27.8929 23.0032 26.7385C23.339 26.5005 23.3611 26.0062 23.0699 25.715L16.4455 19.0902Z" fill="black"/>
                      </svg> 
                 </div>
            <ul id="list">
               
                <li className="submenu" id="submenu">
                   <FaMoneyBillAlt  style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items"></span>
                  
                       </li>
              
                <li>
                    
                 <FaRegUser style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                 <span id="list-items"></span>
                 
                </li>
                <li>
                    
                    <MdInventory style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items"></span>
                   
               </li>
                <li className="">
                
                    <HiOutlineDocumentText style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items"></span>
                </li>
               
                <li>
                    
                     <MdReport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                     <span id="list-items"></span>
                    
                </li>
                <li>
                    
                   <HiOutlineMail style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items"></span>
                </li>
                <li>
                
                   <FaSms style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items"></span>
                </li>
            
                 </ul>
                
                 <div id="bottom">
                 <li>
                    
                   <AiFillSetting style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items"></span>
                </li>
                <li>
                
                   <BiSupport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items"></span>
                </li>
                </div>
                
           
           
        </nav>
        </div>  )
    }
    else if((size < 767) && (NavMobileMenu)){
        return  (
            <div className="collapsible" style={{ width:'100%' }}>
         <nav>
            <div className="admin-side">
            <img src={logo} width='200' onClick={Dashboardpage} alt=""/>
                <p onClick={NavMenuMobileToggle}>
                <FaTimes style={{ color:'#E7F6F2'}} className='mobile-icon'/></p>
            </div>
            <div className="admin" onClick={Dashboardpage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
                          <path d="M29.3573 19.0902H19.2719L25.9886 25.8069C26.2453 26.0636 26.6678 26.0844 26.9317 25.8358C28.5765 24.2861 29.708 22.1971 30.0399 19.8488C30.0969 19.4468 29.7632 19.0902 29.3573 19.0902ZM28.6845 16.336C28.3343 11.2587 24.277 7.20137 19.1996 6.85115C18.812 6.82437 18.4856 7.15121 18.4856 7.53969V17.0501H27.9964C28.3849 17.0501 28.7113 16.7236 28.6845 16.336ZM16.4455 19.0902V9.00475C16.4455 8.59885 16.0889 8.26521 15.6872 8.32216C10.6222 9.0379 6.75064 13.4628 6.93085 18.7659C7.11616 24.2122 11.8055 28.6783 17.2543 28.6099C19.3964 28.5831 21.3758 27.8929 23.0032 26.7385C23.339 26.5005 23.3611 26.0062 23.0699 25.715L16.4455 19.0902Z" fill="black"/>
                      </svg>  
                          <span id="list-items">Admin Dashboard</span>
                
            </div>
            <ul id="list">
               
                <li className="submenu" id="submenu">
                    <a href="#" onClick={dropdown}>
                        <FaMoneyBillAlt  style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Finance</span>
                    <RiArrowDropDownLine  className='mobile-icon' style={dropDown ? {fontSize:'30px',color:'#E7F6F2',transform: 'rotate(-0deg)' }:{fontSize:'30px',color:'#E7F6F2', transform: 'rotate(-90deg)' }}
                />
                
                </a>
        
                {dropDown ?  <ul className="dropdown-items" id="dropdown-items">
                        <li>
                            <Link to="/Admin/Deposits">
                        <HiOutlineReceiptRefund  style={{ color:'#E7F6F2'}}/>
                   
                            Deposit </Link></li>
                        <li>
                        <Link to="/Admin/Withdrawal">
                        <GiReceiveMoney  style={{ color:'#E7F6F2'}}/>
                   Withdraw</Link></li>
                        <li> <Link to="/Admin/Exchange">
                        <RiExchangeDollarFill  style={{ color:'#E7F6F2'}} />
                          Exchange</Link></li>
                    </ul> : null}
                </li>
                
              
                <li>
                <Link to="/Add/Admin">
                 <FaRegUser style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                 <span id="list-items">Add Admin</span></Link>
                </li>
                <li className="">
                <Link to="/Admin/Inventory">
                    <MdInventory style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Inventory</span></Link>
                </li>
                <li className="">
                <Link to="/Admin/Documents">
                    <HiOutlineDocumentText style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Documents</span></Link>
                </li>
               
                <li>
                <Link to="/Reports">
                     <MdReport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                     <span id="list-items">Report</span></Link>
                </li>
                <li>
                <Link to="/AdminMail">
                   <HiOutlineMail style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">Email</span></Link>
                </li>
                <li>
                <Link to="/AdminSMS">
                   <FaSms style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">SMS</span></Link>
                </li>
            
                 </ul>
                
                 <div id="bottom">
                 <li className="submenu" id="submenu">
                    <a href="#" onClick={dropdownsettng}>
                        <AiFillSetting  style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Setting</span>
                    <RiArrowDropDownLine  className='mobile-icon' style={dropDownsettng ? {fontSize:'30px',color:'#E7F6F2',transform: 'rotate(-0deg)' }:{fontSize:'30px',color:'#E7F6F2', transform: 'rotate(-90deg)' }}
                />
                
                </a>
        
                {dropDownsettng ?  <ul className="dropdown-items" id="dropdown-items">
                        <li>
                            <Link to="/Transactionfee">
                        <BiTransfer  style={{ color:'#E7F6F2'}}/>
                   
                            Transaction Fee </Link></li>
                            <li>
                            <Link to="/Admin/Security">
                        <SiSpringsecurity  style={{ color:'#E7F6F2'}}/>
                   
                            2FA </Link></li>
                            <li>
                            <Link to="/Admin/AddCategory">
                        <MdCategory  style={{ color:'#E7F6F2'}}/>
                   
                            Add Category </Link></li>
                            <li>
                            <Link to="/Admin/AddCard">
                        <AiFillGift  style={{ color:'#E7F6F2'}}/>
                   
                        Add Gift Card </Link></li>
                        
                    </ul> : null}
                </li>
                <li>
                <Link to="/AdminSupport">
                   <BiSupport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">Support</span></Link>
                </li>
                </div>
                
           
           
        </nav>
        </div>  
        )
    }
    else if(size > 767 ){
        return  (
            <div className="collapsible" style={{ width:'20%' }}>
        <nav>
            <div className="admin-side" onClick={Dashboardpage}>
            <img src={logo} width='200' alt=""/>
                {/* <p onclick="closenav()"><i class="fa-solid fa-times"></i></p> */}
            </div>
            <div className="admin" onClick={Dashboardpage}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="35" viewBox="0 0 36 35" fill="none">
                          <path d="M29.3573 19.0902H19.2719L25.9886 25.8069C26.2453 26.0636 26.6678 26.0844 26.9317 25.8358C28.5765 24.2861 29.708 22.1971 30.0399 19.8488C30.0969 19.4468 29.7632 19.0902 29.3573 19.0902ZM28.6845 16.336C28.3343 11.2587 24.277 7.20137 19.1996 6.85115C18.812 6.82437 18.4856 7.15121 18.4856 7.53969V17.0501H27.9964C28.3849 17.0501 28.7113 16.7236 28.6845 16.336ZM16.4455 19.0902V9.00475C16.4455 8.59885 16.0889 8.26521 15.6872 8.32216C10.6222 9.0379 6.75064 13.4628 6.93085 18.7659C7.11616 24.2122 11.8055 28.6783 17.2543 28.6099C19.3964 28.5831 21.3758 27.8929 23.0032 26.7385C23.339 26.5005 23.3611 26.0062 23.0699 25.715L16.4455 19.0902Z" fill="black"/>
                      </svg>  
                          <span id="list-items">Admin Dashboard</span>
                
            </div>
            <ul id="list">
               
                <li className="submenu" id="submenu">
                    <a href="#" onClick={dropdown}>
                        <FaMoneyBillAlt  style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Finance</span>
                    <RiArrowDropDownLine  className='mobile-icon' style={dropDown ? {fontSize:'30px',color:'#E7F6F2',transform: 'rotate(-0deg)' }:{fontSize:'30px',color:'#E7F6F2', transform: 'rotate(-90deg)' }}
                />
                
                </a>
        
                {dropDown ?  <ul className="dropdown-items" id="dropdown-items">
                        <li>
                            <Link to="/Admin/Deposits">
                        <HiOutlineReceiptRefund  style={{ color:'#E7F6F2'}}/>
                   
                            Deposit </Link></li>
                        <li>
                        <Link to="/Admin/Withdrawal">
                        <GiReceiveMoney  style={{ color:'#E7F6F2'}}/>
                   Withdraw</Link></li>
                        <li> <Link to="/Admin/Exchange">
                        <RiExchangeDollarFill  style={{ color:'#E7F6F2'}} />
                          Exchange</Link></li>
                    </ul> : null}
                </li>
              
                <li>
                <Link to="/Add/Admin">
                 <FaRegUser style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                 <span id="list-items">Add Admin</span></Link>
                </li>
                <li className="">
                <Link to="/Admin/Inventory">
                    <MdInventory style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Inventory</span></Link>
                </li>
                <li className="">
                <Link to="/Admin/Documents">
                    <HiOutlineDocumentText style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Documents</span></Link>
                </li>
              
               
                <li>
                <Link to="/Reports">
                     <MdReport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                     <span id="list-items">Report</span></Link>
                </li>
                <li>
                <Link to="/AdminMail">
                   <HiOutlineMail style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">Email</span></Link>
                </li>
                <li>
                <Link to="/AdminSMS">
                   <FaSms style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">SMS</span></Link>
                </li>
            
                 </ul>
                
                 <div id="bottom">
                
                <li className="submenu" id="submenu">
                    <a href="#" onClick={dropdownsettng}>
                        <AiFillSetting  style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                    <span id="list-items">Setting</span>
                    <RiArrowDropDownLine  className='mobile-icon' style={dropDownsettng ? {fontSize:'30px',color:'#E7F6F2',transform: 'rotate(-0deg)' }:{fontSize:'30px',color:'#E7F6F2', transform: 'rotate(-90deg)' }}
                />
                
                </a>
        
                {dropDownsettng ?  <ul className="dropdown-items" id="dropdown-items">
                        <li>
                            <Link to="/Transactionfee">
                        <BiTransfer  style={{ color:'#E7F6F2'}}/>
                   
                            Transaction Fee </Link></li>
                            <li>
                            <Link to="/Admin/Security">
                        <SiSpringsecurity  style={{ color:'#E7F6F2'}}/>
                   
                            2FA </Link></li>
                            <li>
                            <Link to="/Admin/AddCategory">
                        <MdCategory  style={{ color:'#E7F6F2'}}/>
                   
                            Add Category </Link></li>
                            <li>
                            <Link to="/Admin/AddCard">
                        <AiFillGift  style={{ color:'#E7F6F2'}}/>
                   
                        Add Gift Card </Link></li>
                    </ul> : null}
                </li>
                <li>
                <Link to="/AdminSupport">
                   <BiSupport style={{ color:'#E7F6F2'}} className='mobile-icon'/>
                   <span id="list-items">Support</span></Link>
                </li>
                </div>
                
           
           
        </nav>
        </div>  
        )
    }
   }
    return (
        <div>
            <AdminAuth/>
        {Conditions()}
    </div>
        
    )
}
 export default Sidebar