import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authorization: sessionStorage.getItem("authenticated"),
}

export const AuthSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
   
    Authenticate: (state,action) => {
      state.authorization = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { Authenticate } = AuthSlice.actions

export default AuthSlice.reducer