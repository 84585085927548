import React, {  useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Featured from './Featured';    
import CookiePopup from './pages/Cookies';
 import ChatInterface from './pages/Chatbot';
 import { useDispatch, useSelector } from 'react-redux';
import Carousel2 from '../img/MART--squarehome_carousel1.jpg'
import Carousel1 from '../img/ESCROW--square--carousel2.png'
import Carousel3 from '../img/GIFTCARD--square--carousel3.png'
  import { Toggleshow} from './Redux/Chatslice';
import { setcategory_name } from './Redux/Createslice';
import Dataquery from './pages/Dataquery';
import Header from './Header'
import Footer from './Footer'
import svg1 from '../img/3dicons.svg'
import svg3 from "../img/3dicons (1).svg"
import svg2 from '../img/ChevronRight.svg'
import svg4 from '../img/3dicons (2).svg'
import svg5 from "../img/3dicons (3).svg"
import svg6 from "../img/3dicons (4).svg"
import svg7 from "../img/CheckCircleFill (1).svg"
import svg8 from "../img/CheckCircleFill (2).svg"
import svg9 from "../img/CheckCircleFill.svg"
import Slide1 from '../img/Slide1.png'
import Slide2 from '../img/Slide2.png'
import Slide3 from '../img/Slide3.png'
import svg15 from '../img/clock.png'
import svg16 from '../img/svg16.png'
import svg17 from '../img/svg17.png'
import webImage from '../img/webImage.png'
import Squareweb from './Squareweb'

const Index=()=>
{
    const{allProduct,dataNotFound,Categories,completetrade}=useSelector((state=>state.Createslice))
    const Navigate = useNavigate()
const { size }=useSelector((state)=>state.WindowSize)
const { showChat }=useSelector((state)=>state.Chatslice)
const dispatch=useDispatch()
 /**************** Menu Mobile */
 const[showmenu,setshowmenu]=useState(false)
 
  /** END  */
    /* HELP SUPPORT */
    const toggleChat = () => {
           
        dispatch(Toggleshow());
      };

   
/******************* Categories ****/
function HandleCategory(category){
    
    dispatch(setcategory_name(category))
    Navigate(`/Marketplace` ,{ replace: true });
}
 const catergy=Categories.map((Element,index)=>{
    return (
        <div className="box"  key={index}>
        <span></span>

            <h3 onClick={()=>HandleCategory(Element.category)}>{Element.category}</h3>
            {/* <p>Shop</p> */}
         
            {/* <button type='button' onClick={()=>HandleCategory(Element.category)}> See More</button> */}
            <img src={Element.image} alt=""/>
        </div>
    )
 })
    /**************** END ***********/
    /********************** ALL PRODUCT DETAILS *****/
    const style={
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '30px',
        color: '#000000',
     
}
    /*****************  FEATURED */
    //   Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(8);

    // Calculate the index of the first and last product to show on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    // Extract the subset of product data to show on the current page
    const currentProducts =  allProduct.slice(indexOfFirstProduct, indexOfLastProduct);

    // Generate the product components for the current page
    const [product_id, setproduct_id] = useState()
   
    function CartItem(id) {
        setproduct_id(id)
          localStorage.setItem('Product_Id', id)
         const CartIdProduct= encodeURIComponent(id)
    Navigate(`/login?purchase=${CartIdProduct}` ,{ replace: true });
    }
    const products = currentProducts.map((sourceElement) => {
        return (
          
            <Featured
            CartItem={()=>CartItem(sourceElement.id)}
                key={sourceElement.id}
                description={sourceElement.description}
                category={sourceElement.product_category}
                image={sourceElement.product_image}
                title={sourceElement.product_name}
                country={sourceElement.product_state}
                price={sourceElement.product_price}
                time={sourceElement.time}
            />
            );
        });

    /**************** END */
     /*============== COMPLETE TRADE  // Latest Activities ================*/

     //   Pagination
     const [currentPageComplete, setcurrentPageComplete] = useState(1);
     const [productsPerPagecomplete] = useState(10);
 
     // Calculate the index of the first and last product to show on the current page
     const indexOfLastProductcomplete = currentPageComplete * productsPerPagecomplete;
     const indexOfFirstProductcomplete = indexOfLastProductcomplete - productsPerPagecomplete;
 
     // Extract the subset of product data to show on the current page
     const currentCompletetrade =  completetrade.slice(indexOfFirstProductcomplete, indexOfLastProductcomplete);
 
    const tradeSuccess = currentCompletetrade.map((Element,index) => {
        return (
            <tbody key={index}>
            <tr>
            <td>{Element.activities == 'Swap' ? 'Swap completed' : 'Purchase completed'}</td>
                    <td className="date"><p>{Element.time}</p> </td>
                    <td><button className="paid">Completed</button></td>
            </tr>
          </tbody>
        );
      });
      
    /*********************  END  ******/
   /*************** SLIDER IMAGE ****/
  
   const imagesdesktop = [
    { src: Carousel1, alt: 'Image 1', link: 'Marketplace'},
    { src: Carousel2, alt: 'Image 2', link: 'Trade'},
    { src: Carousel3, alt: 'Image 3', link: 'Trade'},
  ];
  const imagesMobile = [
    { src: Slide1, alt: 'Image 1', link: 'Marketplace'},
    { src: Slide2, alt: 'Image 2', link: 'Trade'},
    { src: Slide3, alt: 'Image 3', link: 'Trade'},
  ];
  const images =  imagesMobile 
const [currentSlide, setCurrentSlide] = useState(0);

const goToSlide = (index) => {
setCurrentSlide(index);
};

useEffect(() => {
    const interval = setInterval(goToNextSlide, 5000); // 5000 milliseconds = 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

const goToPrevSlide = () => {
if (currentSlide === 0) {
  setCurrentSlide(images.length - 1);
} else {
  setCurrentSlide(currentSlide - 1);
}
};

const goToNextSlide = () => {
if (currentSlide === images.length - 1) {
  setCurrentSlide(0);
} else {
  setCurrentSlide(currentSlide + 1);
}
};
/**********END **************/
    return (
        <div style={(showChat || showmenu ) && size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
         <Squareweb/>
            <Dataquery/>
         <Header/>
        <Header/>
        <main>
     <section id="one">
     <div className="option" id="myDiv">
        <div className="option-item">
            <img src={svg1} alt=""/>
            <div className="words">
                <p><Link to="/trade" style={{  color: 'rgba(0, 0, 0, 0.5)',
 textDecoration: 'none', }}>
                    Buy with Cypto</Link></p>
                 <img src={svg2} alt=""/>
            </div>
            
        </div>
        <div className="option-item">
            <img src={svg3} alt=""/>
            <div className="words">
                <p><Link to="/trade" style={{  color: 'rgba(0, 0, 0, 0.5)',
 textDecoration: 'none', }}>Gifts Card</Link></p>
                <img src={svg2} alt=""/>
            </div>
        </div>
        <div className="option-item">
            <img src={svg4} alt=""/>
            <div className="words">
                <p><Link to="/Marketplace" style={{  color: 'rgba(0, 0, 0, 0.5)',
 textDecoration: 'none', }}>
    Marketplace</Link></p>
                <img src={svg2} alt=""/>
            </div>
        </div>
        <div className="option-item">
            <img src={svg5} alt=""/>
            <div className="words">
                <p><Link to="/Dashboard" style={{  color: 'rgba(0, 0, 0, 0.5)',
 textDecoration: 'none', }}>Wallet</Link></p>
                <img src={svg2} alt=""/>
            </div>
        </div>
        <div className="option-item">
            <img src={svg6} alt=""/>
            <div className="words">
                <p><Link to="" onClick={toggleChat} style={{  color: 'rgba(0, 0, 0, 0.5)',
 textDecoration: 'none', }}>Help</Link></p>
                <img src={svg2} alt=""/>
            </div>
        </div>
         </div> 
   <div className="container-card">
         <div className="cards">
            <div className="card first">
                <div className="card-content">
                    <h2>Smart and Secure Purchase</h2>
                    <p>Escrow service provide secure and seamless  digital assets exchanges & purchase.</p>
                    <button><Link style={{ color:'white',textDecoration:'none' }} to='/Trade'>Trade</Link></button>
                </div>
              <div className="image-slider">
{images.map((image, index) => (
<div key={index}>
<Link to={image.link} style={{ cursor: 'pointer' }}>
  <img
    src={image.src}
    alt={image.alt}
    className={index === currentSlide ? 'active-slide' : 'inactive-slide'}
  />
</Link>
</div>
))}
<div className="slide-dots">
{images.map((image, index) => (
<span
  key={index}
  className={index === currentSlide ? 'dot active-dot' : 'dot'}
  onClick={() => goToSlide(index)}
></span>
))}
</div>
</div>
                
              
            </div>
           
            {/* <!-- <div className="card">Card 2</div> */}
            {/* <div className="card">Card 3</div> --> */}
     </div>
    </div>
</section>

        <section className="row-two" data-aos="fade-left">
            <div className="col">
                <div className="top">
                  <div className='background'>
                  <img src={svg15} alt=""/>
                  </div>
                    
                    {/* <span></span> */}
                </div>
                
                <h2>Fast & Reliable.</h2>
                {/* <p>Our trading platform offers lightning-fast order execution, maximum uptime, and robust security measures for traders. With real-time data, dedicated support, and a user-friendly interface, we provide a reliable and efficient trading experience that ensures success in the dynamic world of online trading.</p> */}
            </div>
            <div className="col">
                <div className="top" >
                <div className='background'>
                <img src={svg16} alt=""/>
                    {/* <span></span> */}
                </div> </div>
                <h2>Online Wallet.</h2>
                {/* <p>Our online wallet offers secure storage and convenient access for your digital assets. With advanced encryption and user-friendly features, it's the perfect solution to manage and protect your cryptocurrency holdings. </p> */}
            </div>
            <div className="col">
                <div className="top">
                <div className='background'>
                <img src={svg17} className='img-load'  style={size > 767 ? {  padding: '25px 20px 10px 5px'} :{  padding: '20px 20px 10px 15px'}} alt=""/>
                    {/* <span></span> */}
                </div> </div>
                <h2>Discounted Gift Cards</h2>
                {/* <p>Our platform provides discounted gift cards for popular retailers, allowing you to save money on your purchases. Shop smart and enjoy significant savings on a wide range of products and services with our discounted gift cards.</p> */}
            </div>

        </section>

        <section className="shop"  id="backimg" data-aos="fade-right">
            <h2>Our Services</h2>
            <div className="services">
                <div><p>SHIPPING</p></div>
                <div><p>WALLET</p></div>
                <div><p>GIFTCARD</p></div>
                <div><p>SHOP</p></div>
            </div>

        </section>

        <section className="shop" >
            <h2>Shop Categories</h2>
            <div className="box-container">
                   <div className="boxes">
               
               {catergy ? catergy : null}
                
            </div>
            </div>
            </section>
            <section className="featured" data-aos="zoom-in-up">
            <h2>Featured ( Marketplace )</h2>
            <div className={allProduct.length > 0 ? "featured-container" :
                         "table-empty"}
            >
            {allProduct.length > 0 ? products : 
                       
                       <div style={style}>{dataNotFound}
                </div>
                      
                    }
            </div>
        </section>

{/* LATEST ACTIVITY */}
        <section className="latest-table"  data-aos="zoom-out">
          
            {completetrade.length > 0
              ? <>  <h2>Latest Activities</h2>
               <h4>Transaction</h4>
              </>  
          : null}  <div>
            {completetrade.length > 0 ?
            <table>
                <thead>
                <tr>
                    <th>All</th>
                </tr>
                </thead>
                
                <thead>
                <tr className="head-row">
                    <td>Recevied</td>
                    <td>Date</td>
                    <td>Status</td>
                </tr>
                </thead>
                {tradeSuccess}
            </table> : null
}
            </div>
        </section>
            {/* <!-- <div className="seemore">
                see more &rarr;
            </div> --> */}
           </main>
           <CookiePopup/>
           <Footer/>
            {/* CHAT live support */}
          <ChatInterface/>
</div>

    )
}
export default Index
