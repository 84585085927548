import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    linechart:[],
     graphchart:'',
    purchasegraphchart:'',
}

export const Chart = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    
    setlinechart:(state, action) => {
      state.linechart = action.payload
    },
    setgraphchart:(state, action) => {
      state.graphchart = action.payload
    },
    setpurchasegraphchart:(state, action) => {
      state.purchasegraphchart = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { setlinechart,setpurchasegraphchart,setgraphchart } = Chart.actions

export default Chart.reducer