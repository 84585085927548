import React from "react";
import { useState } from "react";
import Cardssidrbar from "./Cardssidebar";
import Giftcard from "./Giftcard";
import { useNavigate } from "react-router-dom";
import UsdtTable from "./UsdtTable";
import { useEffect } from "react";
import axios from "axios";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";

const CryptoUsdt=(props)=>
{
   
    
    return (
        <div className="table-responsive">
          <h2>Offers</h2>
          <h1></h1>
          <span></span>


        <table>
          <thead> 
              <tr>
                {/* <th>Offers</th> */}
                <th>Trade</th>
                <th>Get</th>
                {/* <th>Amount</th> */}
                {/* <th>Rate at </th> */}
                <th>Action</th>
            
            </tr>
            </thead>
         
         {/* table Row */}
          {props.tableRow}
        
        </table>
      
        {props.allusdt.length > 0 ? (
         
  <div className="pagination">
    <div className="paginate">
      
      <button className={props.currentPage === 1 ? 'disabledButton' : null} onClick={props.handlePreviousClick}>
      <FaAngleDoubleLeft/>
      </button>
      <button className={props.currentPage === props.totalPages ? 'disabledButton' : null} onClick={props.handleNextClick}>
      <FaAngleDoubleRight/>

      </button>
    </div>
  </div>
  
) : <div className="empty-table">{props.dataNotFound}</div>
}
        
    </div>
    )
}
export default CryptoUsdt