import React from "react";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { SiTether } from "react-icons/si";
import { FaBitcoin, FaDollarSign, FaEthereum, FaFunnelDollar, FaMarker, FaSearch, FaTimes, FaWallet } from "react-icons/fa";
import { IoIosArrowBack, IoMdCheckmark } from "react-icons/io";
import { AiOutlineCopy } from "react-icons/ai";
    
const Address=({ handleCopy, switchButton ,Handlerclose})=>
{
    const{walletAddress}=useSelector((state)=>state.Balanceslice)
   
function currencyIcon()
{
  if(walletAddress.currency == 'BTC')
  {
    return <FaBitcoin style={{  fontSize:'25px',color:'#FB8C00' }}/>
  }
  else if(walletAddress.currency == 'ETH')
  {
    return <FaEthereum style={{  fontSize:'25px', color:'#BDD4F1', }}/>
  }
  else if(walletAddress.currency == 'USDT')
  {
  return  <SiTether style={{  fontSize:'25px' ,color:'green' }}/>
  }
}

return (
    <div className="container-address">
        <header>
		<div onClick={Handlerclose}>
		
<IoIosArrowBack style={{ height:"30px" , width:"30px"}}/>
		</div>
        
            <h1>Deposit {walletAddress.currency}</h1>
        </header>
        <section className="ethereum-section">
            <div className="eth-logo">
                {currencyIcon()}       </div>
            <div className="scan-text">
                <p>Scan the QR code to receive the address</p>
            </div>
            <div className="qr-code" style={{textAlign:'center'}}>
                {walletAddress && <QRCodeSVG value={walletAddress.address}  />}
            </div>
            <div className="other-option">
                <div className="arrow-left"></div>
                <div className="or">or</div>
                <div className="arrow-right"></div>
            </div>
            <div className="eth-label">
		      <p>Network {walletAddress.network} </p>
                <p>Your {walletAddress.currency} Address</p>
		  
            </div>
            <div className="eth-address">
                <div className="wallet-address">{walletAddress.address}</div>

                <div className="copy-wallet" onClick={() => handleCopy(walletAddress.address)}>
                  
                  {switchButton ?  <IoMdCheckmark  style={{color:'green', height:"30px" , width:"30px"}}/> : 
                   <AiOutlineCopy  style={{ height:"30px" , width:"30px"}}/>}
                 
                 
              </div>
                   
                   
                </div>
		{/* ERC20 NETWORK  */}
		{/* {walletAddress.address_erc ? <><br/>
      <div className="eth-label">
		     <p>Network {walletAddress.network_erc} </p>
                <p>Your {walletAddress.currency} Address</p>
		  
            </div>
            <div className="eth-address">
                <div className="wallet-address">{walletAddress.address_erc}</div>

                <div className="copy-wallet" onClick={() => handleCopy(walletAddress.address)}>
                  
                  { switchButton  ?  <IoMdCheckmark  style={{color:'green', height:"30px" , width:"30px"}}/> : 
                   <AiOutlineCopy  style={{ height:"30px" , width:"30px"}}/>}
                 
                 
              </div>
                   
                </div>
                </>
     : null
		} */}
            
        </section>
        <section className="deposit">
            <div className="minimum-deposit">
                <b>Minimum Deposit:</b>
                <p>{walletAddress.min_deposit} { walletAddress.currency}</p>
            </div>
            <div className="minimum-deposit">
                <b>Deposit Arrival:</b>
                <p>0 Confirmations</p>
            </div>
        </section>
        {/* <!-- <section className="share-address">
            <button type="submit" className="btn-share">Share Address</button>
        </section> --> */}
    </div>
)
}
export default Address
