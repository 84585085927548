import React, { useState } from "react";
import { FaWallet, FaUserLock, FaArrowCircleLeft, FaInbox, FaArrowLeft, FaArrowAltCircleRight, FaRegArrowAltCircleLeft, FaArrowAltCircleLeft } from "react-icons/fa";
import { IoMdWallet, IoWallet } from 'react-icons/io'
import { AiTwotoneSetting, AiOutlineVerified, AiOutlineLoading, AiOutlineLogout, AiFillHome } from 'react-icons/ai'
import { Link,useNavigate, useLocation } from "react-router-dom";
import { HiHome, HiHomeModern, HiOutlineLogout } from 'react-icons/hi'
import { BsCurrencyExchange } from 'react-icons/bs'
import { RiWalletFill } from "react-icons/ri";
import { FcHome } from "react-icons/fc";
import { MdDashboard } from "react-icons/md";
import '../../css/font.css'
import logo from '../../img/squrelogo.png'
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setunreadMsg ,setInboxMSg, setdataNotFoundInbox } from "../Redux/Inboxslices";
import MovingText from "react-moving-text";
import { setAttempt, setSettingNav, setcountries, setlockbutton, setmessage, setmsg } from "../Redux/Createslice";
import Database from "./Databasefetch";
import Balance from "./Balance";
import { useRef } from "react";
import { setInvoiceNumber, setSwapdetail, setcardlockbutton, setgiftcardprocessTrigger, setgiftcardprocessing, setprocessTrigger, setprocessing, setswapdetailreview, settradesucces } from "../Redux/Chart";
import { SiCoinmarketcap } from "react-icons/si";
import { setWallet, setupdateDataCount } from "../Redux/Balanceslice";
import { TimeOutComponent } from "./Timeout";

const Sidebar = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
 
  const contentRef = useRef(null);

  // Function to scroll the content to the bottom
  const scrollToBottom = () => {
    contentRef.current.scrollTop = contentRef.current.scrollHeight;
  };

  // Scroll to the bottom on component mount and whenever the content changes
  useEffect(() => {
    scrollToBottom();
  }, []);
/*================ UNREAD INBOX ===========*/
// const [unreadMsg,setunreadMsg]=useState()
const {unreadMsg}=useSelector((state=>state.Inboxslices))
const [IsFirstRender,setIsFirstRender]=useState(true)
const [IsFirstRenderlocation,setIsFirstRenderlocation]=useState(true)
 
/************ Read Msg *******/
  const dispatch = useDispatch();
//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             await fetchUnreadMessage();
//             await addCountry();
//         } catch (error) {
//             // console.error('Error fetching data:', error);
//         }
//     };

//     // Fetch data initially
//     fetchData();

//     // Fetch data every 50 seconds
//     const intervalId = setInterval(fetchData, 50000); // 50 seconds in milliseconds

//     // Clean up interval to avoid memory leaks
//     return () => clearInterval(intervalId);
// }, []);

//   const fetchwallet = async () => {
//     try {
//         const response = await axios.get('api/Wallets_Balance');
//         dispatch(setWallet(response.data.data));
//     } catch (error) {
//         // console.error('Error fetching wallet data:', error);
//     }
// };

//  const Inboxmessages = async () => {
//     try {
//         const response = await axios.get('api/sms_messages');
//         // setisLoading(false);
//         dispatch(setInboxMSg(response.data));
//         dispatch(setdataNotFoundInbox('Inbox Empty'));
//     } catch (error) {
//         // console.error('Error fetching messages:', error);
//     }
// };
  
// const fetchUnreadMessage = async () => {
//     try {
//         const response = await axios.get("api/Unread_MSG");
//         dispatch(setAttempt());
//         setIsFirstRender(false);
//         dispatch(setunreadMsg(response.data));
//     } catch (error) {
//       // dispatch(setAttempt(error.response.data.message))
//         dispatch(setAttempt(error.response?.data?.message));
//     }
// };

// const addCountry = async () => {
//     try {
//         const response = await axios.get("api/location_Country");
//         setIsFirstRenderlocation(false);
//         dispatch(setcountries(response.data));
//     } catch (error) {
//         // console.error('Error fetching countries:', error);
//     }
// };

//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             await fetchUnreadMessage();
//             await AddCountry();
//         } catch (error) {
//             // console.error('Error fetching data:', error);
//         }
//     };

//     // Fetch data initially
//     fetchData();

//     // Fetch data every 50 seconds
//     const intervalId = setInterval(fetchData, 50000); // 50 seconds in milliseconds

//     // Clean up interval to avoid memory leaks
//     return () => clearInterval(intervalId);
// }, [dispatch, setAttempt, setunreadMsg, setcountries]);

// const fetchUnreadMessage = async () => {
//   axios
//         .get("api/Unread_MSG")
//         .then((response) => {
//           dispatch(setAttempt())
//           setIsFirstRender(false);
//           dispatch(setunreadMsg(response.data));
//         })
//         .catch(error => {
//              dispatch(setAttempt(error.response.data.message))
//           });
//     }
// /*=================== END=================*/
// /************** ALL COUNTY  ****/
// const AddCountry = async () => {
//   axios
//       axios.get(`api/location_Country`)
//           .then((response) => {
//             setIsFirstRenderlocation(false)
//               dispatch(setcountries(response.data))
//           }
//           )
//   }
/*************** END  *****/
const Navigate=useNavigate()
   
  const handleClick = (page) => {
    setActivePage(page);
  };
  const navItemClass = (page) => {
    
    const backgroundColors = {
      "/Dashboard": "#cfe5ec",
      "/verification": "#f8d7da",
      "/inbox": "#fff3cd",
      "/setting": "#d1ecf1",
    };
    return activePage === page ? "nav active" : "nav";
  }

  const navItemStyle = (page) => {
    const backgroundColors = {
      "/Dashboard": "#cfe5ec",
      "/verification": "#e3f9e8",
      "/inbox": "#f3dde3",
      "/setting": "#fff7dd",
    };
    if (page === "/trade" && !Object.keys(backgroundColors).includes(activePage)) {
      return { backgroundColor: "#d4edda" ,  fontWeight: "900", fontFamily:"League Spartan"};
    }
    
    return activePage === page ? { backgroundColor: backgroundColors[page] } : {};
  };

  const styled={
    color: 'blue',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily:"League Spartan"
  };
  const styled2={
    color: '#56B547',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily:"League Spartan"
  };
  const styled3={
    color: '#91aab9',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily:"League Spartan"
  };
  const styled4={
    color: 'rgb(218, 95, 116)',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily:"League Spartan"
  };
  const styled5={
    color: 'orange',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily:"League Spartan"
  };
const fontstyle={
  fontSize:'20px'
}
const iconstyle={
  color:'#000000',
  fontSize:'20px'
}
/*************** Messages Timing ****/
const {Attempt,message,msg,lockbutton}=useSelector((state)=>state.Createslice)
const{cardlockbutton}=useSelector(state=>state.Chart)
   
useEffect(()=>{
if(message || msg || Attempt){
  setTimeout(() => {
    dispatch(setAttempt())
    dispatch(setmessage())
    dispatch(setmsg())
  },30000); 
  if(lockbutton){
    
    setTimeout(() => {
      dispatch(setswapdetailreview())
       dispatch(settradesucces(false))
       dispatch(setlockbutton())
      // dispatch(setmessage())
       // dispatch(setmsg())
      dispatch(setprocessing())
      dispatch(setprocessTrigger())
    },3 * 60 * 1000); // delay for 3 mins
  }
   if(cardlockbutton)
  {
    setTimeout(() => {
  //     dispatch(setmessage())
  //     dispatch(setmsg())
    dispatch(setcardlockbutton(false))
    dispatch(setSwapdetail())
    dispatch(settradesucces(false))
    dispatch(setgiftcardprocessing(false))
    dispatch(setgiftcardprocessTrigger(false))
  },3 * 60 * 1000); // delay for 3 mins
  }

} 
},[message,msg])
   /************LOG OUT */
   function Logout()
   {
     sessionStorage.removeItem("Prodile_name")
       sessionStorage.removeItem("authenticated")
        Navigate('/login',{replace :true})
            
   }
       /******* End ******/

      //  setting NAVBAR
      function SettingNavOpen()
      {
        dispatch(setSettingNav(true))
      }
  return (
    <> 
    {/* MANY REQUEST ERROR */}
    <div>
  
  {  Attempt ?
               
               <MovingText
    type="slideInFromRight"
    duration='350ms'
    className="errormsg-many-req"
    delay='0s'
    direction='alternate'
    iteration='1'
    fillMode='none'> 
     <p style={{ fontSize:'15px' }}>{Attempt}</p></MovingText> 
  
  
   : null }
   </div>
    {/* END */}
     <Database/>
     {TimeOutComponent()}
     <Balance/>
     <div className="sideimg">
     <img src={logo} alt="" />

     </div>
    <div className="sidebar">
      <span><Link to='/' style={{color:'#1064b1',fontSize:'20px',cursor:'pointer'}}><FaArrowCircleLeft/></Link></span>
      <div className={navItemClass("/Dashboard")} style={navItemStyle("/Dashboard")} >
        <Link to="/Dashboard" style={activePage === "/Dashboard" ? styled : {color:'#1064b1'}}>
          <MdDashboard style={activePage === "/Dashboard" ? fontstyle : {}}/>
          <p>Home</p>
        </Link>
      </div>
      <div className={navItemClass("/verification")} style={navItemStyle("/verification")} >
        <Link to="/verification" style={activePage === "/verification" ? styled2 : {color:'#1064b1'}}>
          <AiOutlineVerified style={activePage === "/verification" ? fontstyle : {}}/>
          <p>KYC</p>
        </Link>
      </div>
      <div className={navItemClass("/trade")} style={navItemStyle("/trade")} >
        <Link to="/trade" style={activePage === "/trade" ? styled3 : {color:'#1064b1'}}>
          <BsCurrencyExchange style={activePage === "/trade" ? fontstyle : {}}/>
          <p>Trade</p>
        </Link>
      </div>
      <div className={navItemClass("/inbox")} style={navItemStyle("/inbox")} >
        <Link to="/inbox" style={activePage === "/inbox" ? styled4 : {color:'#1064b1'}}>
    {/* {unreadMsg ?
     <small id="notification">{unreadMsg}</small>
     : null
    } */}
          
          <FaInbox style={activePage === "/inbox" ? fontstyle : {}}/>
          <p>Inbox</p>
        </Link>
      </div>
      {/* <div className={navItemClass("/setting")} style={navItemStyle("/setting")}>
        <Link to="/setting" style={activePage === "/setting" ? styled5 : {}}>
          <AiTwotoneSetting style={activePage === "/setting" ? fontstyle : {}}/>
          <p>Setting</p>
        </Link>
      </div> */}
      <div className="bottom-nav">
        <div className={navItemClass("/setting")} style={navItemStyle("/setting")}>
          <Link to="/setting" style={activePage === "/setting" ? styled5 : {color:'#1064b1'}}>
          <AiTwotoneSetting style={activePage === "/setting" ? fontstyle : {}}/>
          <p>Settings</p>
          </Link>
        </div>
        </div>
        <div className="bottom-nav-log">
        <div className="navItemClass">
          <Link to="/" style={{color:'#1064b1',cursor:'pointer'}} onClick={Logout}>
          <HiOutlineLogout style={activePage === "/setting" ? fontstyle : {}}/>
          <p style={{ fontWeight:'bold' }}>LogOUT</p>
          </Link>
        </div>
      </div>
      {/* <div className='nav'>
        <Link to="/">
          <FaArrowAltCircleLeft style={{  cursor: 'pointer',
    fontWeight: '900',
    fontFamily:"clash display",fontSize:'20px' }}/>
         
        </Link>
      </div> */}
    </div>
    <div className="footer-mobile" ref={contentRef}
      style={{
        overflowY: 'auto',
        maxHeight: '100vh',
          }}>
    <div className="mobile">
    <div className="nav">
    <div className={navItemClass("/Dashboard")} style={navItemStyle("/Dashboard")}  >
        <Link to="/Dashboard" style={activePage === "/Dashboard" ? styled : {}}>
          <AiFillHome className='icon' style={activePage === "/Dashboard" ? fontstyle : iconstyle}/>
          <p>Home</p>
        </Link>
      </div>
     </div>
     <div className="nav">
     <div className={navItemClass("/verification")} style={navItemStyle("/verification")} >
        <Link to="/verification" style={activePage === "/verification" ? styled2 : {}}>
          <AiOutlineVerified style={activePage === "/verification" ? fontstyle : iconstyle}/>
          <p>KYC</p>
        </Link>
      </div>
        
     </div><div className="nav">
     <div className={navItemClass("/trade")} style={navItemStyle("/trade")} >
        <Link to="/trade" style={activePage === "/trade" ? styled3 : {}}>
          <BsCurrencyExchange style={activePage === "/trade" ? fontstyle : iconstyle}/>
          <p>Trade</p>
        </Link>
      </div>
        
     </div><div className="nav">
     <div className={navItemClass("/inbox")} style={navItemStyle("/inbox")} >
        <Link to="/inbox" style={activePage === "/inbox" ? styled4 : {}}>
    {unreadMsg ?
     <small id="notification">{unreadMsg}</small>
     : null
    }
          
          <FaInbox style={activePage === "/inbox" ? fontstyle : iconstyle}/>
          <p>Inbox</p>
        </Link>
      </div>
         
        
     </div>
     <div className="nav">
     <div className={navItemClass("/Marketplace")} style={navItemStyle("/Marketplace")}>
        <Link to="/Marketplace" style={activePage === "/Marketplace" ? styled5 : {}}>
          <SiCoinmarketcap style={activePage === "/Marketplace" ? fontstyle : iconstyle}/>
          <p>Marketplace</p>
        </Link>
      </div>
     
     </div>
    <div className="nav">
     <div className={navItemClass("/setting")} style={navItemStyle("/setting")}>
        <Link to="/setting" onClick={SettingNavOpen} style={activePage === "/setting" ? styled5 : {}}>
          <AiTwotoneSetting style={activePage === "/setting" ? fontstyle : iconstyle}/>
          <p>Settings</p>
        </Link>
      </div>
     
     </div>
    </div>

</div>
</>
  )
}

export default Sidebar
