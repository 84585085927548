import axios from "axios";
import React, { useEffect, useState } from "react";
import { FcLock } from "react-icons/fc";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setFetchCount, setWallet, setunreadMsg } from "../Redux/Balanceslice";
import { setInboxMSg, setdataNotFoundInbox } from "../Redux/Inboxslices";
import { setverified } from "../Redux/Tradeslices";
import { BalanceTimeOutComponent, TimeOutComponent } from "./Timeout";

const Balance=()=>
{
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
      // const[wallets,setWallet]=useState([])
    const { fetchCount} = useSelector((state) => state.Balanceslice);
    const walletDispatch = useDispatch();
    const dispatch = useDispatch();
     const[isLoading,setisLoading]=useState(true)
   
    /*****************  WALLET *****************/
    
//  useEffect(() => {
//     const fetchData = async () => {
//       try {
//         await wallet();
//         // await messages();
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
//  // Check if fetchCount is initially zero, fetch data without delay
//  if (fetchCount === 0) {
//     fetchData();
//   }
//   else if (fetchCount === 1) {
//     // Set up the timeout for subsequent fetches after 1 minute
//     const intervalId = setInterval(() => {
//         fetchData();
//       }, 60000); // 1 minute in milliseconds
  
//       return () => clearInterval(intervalId);
//   }
// }, [fetchCount, dispatch]);
//   const wallet = async () => {
//     try {
//       const response = await axios.get('api/Wallets_Balance');
//       walletDispatch(setWallet(response.data.data));
//        dispatch(setFetchCount(1));
     
//     } catch (error) {
//       console.error('Error fetching wallet data:', error);
//     }
//   };

//   const messages = async () => {
//     try {
//       const response = await axios.get('api/sms_messages');
//       dispatch(setInboxMSg(response.data));
//       dispatch(setFetchCount(1));
//         dispatch(setdataNotFoundInbox('Inbox Empty'));
//     } catch (error) {
//       console.error('Error fetching messages:', error);
//     }
//   };
 
//   const[fetchedWallet,setfetchedWallet]=useState(true)
  
//  useEffect(() => {
//     const WalletData = async () => {
//         try {
//          await wallet();
//             await messages();
//         } catch (error) {
//             // console.error('Error fetching data:', error);
//         }
//     };

//   // Fetch data every 50 seconds using setTimeout
//   const intervalId = setTimeout(() => {
//     WalletData();
//     // Call setTimeout again after 50 seconds
//     setTimeout(() => {
//         clearTimeout(intervalId);
//         intervalId = setTimeout(WalletData, 50000);
//     }, 50000);
// }, 50000);

// // Clean up timeout to avoid memory leaks
// return () => clearTimeout(intervalId);
    
// }, [setWallet, setInboxMSg]);

// const wallet = async () => {
//     try {
//         const response = await axios.get('api/Wallets_Balance');
//         walletDispatch(setWallet(response.data.data));
//     } catch (error) {
//         // console.error('Error fetching wallet data:', error);
//     }
// };

// const messages = async () => {
//     try {
//         const response = await axios.get('api/sms_messages');
//         setisLoading(false);
//         dispatch(setInboxMSg(response.data));
//         dispatch(setdataNotFoundInbox('Inbox Empty'));
//     } catch (error) {
//         // console.error('Error fetching messages:', error);
//     }
// };
//   /***************** END ******/
/*****************  WALLET *****************/
// useEffect(()=>{
//     async function wallet(){
//         if(fetchedWallet){
//         axios.get(`api/Wallets_Balance`)
//     .then((response) => {
//         walletDispatch(setWallet(response.data.data));
//         setfetchedWallet(false)
       
//     })
//     }
// }
//     wallet()
// },[userId,fetchedWallet])
/**************************** END ******************/
    const {size}=useSelector((state)=>state.WindowSize)
    return (
       <></>
    )
}
export default Balance
