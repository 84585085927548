import React, { useEffect } from "react";
import Auth from "./Auth";
import Header from "./Header";
import Sidebar from "./Sidebar";
import fileupload from '../../img/CloudUpload.png'
import shopping from '../../img/shopping.png'
import success from '../../img/success.png'
import { useRef } from "react";
import { useState } from "react";
import { FaBinoculars, FaShoppingBasket } from "react-icons/fa";
import MovingText from 'react-moving-text'
import axios from "axios";
import {
    Fade, Slide, Bounce, AttentionSeeker,
    Flip, Hinge, JackInTheBox, Reveal, Roll, Rotate, Zoom
} from 'react-awesome-reveal'
import { useSelector } from "react-redux";
import  Verificationquery  from "./Trade/VerifyQuery";

const Addproduct = () => {
    const profile = useSelector((state) => state.ProfilePageslice.profile);
    const countries = useSelector((state) => state.Createslice.countries);

    const userId= `${profile.id}`
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [isLoading, setisLoading] = useState(false);
    const {category} = useSelector((state) => state.Balanceslice);
  
    // console.log(userId)
    
    /************** ALL COUNTRIES ****/
    // const [countries, setcountries] = useState([])
    
    const country = countries.map((sourceElement,index) => {
        return (
          
                <option key={index} value={sourceElement.countryName}>
                    {sourceElement.countryName}
                </option>


        );
    });
    /********* END **********/
    const [InvaliMsg, setInvaliMsg] = useState()
    /***************** Product Condition *********/
    function handleConditionChange(condition) {
        setErrorField({})
        setInvaliMsg()
        seterroStyle(false)
        setStoreData(previousData => ({
            ...previousData,
            product_condition: condition
        })
        )
    }

    /***************    END  *******/

    
    /************Next  *****/
    // const [productdetailsthird, setproductdetailsthird] = useState(1)
    const  [productdetails, setproductdetails] = useState(1)
    const [productdetailsfirst, setproductdetailsfirst] = useState(true)
    const [productdetailsecond, setproductdetailsecond] = useState(false)
    const [productdetailsthird, setproductdetailsthird] = useState(false)
    // const NextButton = () => setproductdetails(2);

    // const NextButtonSecond = () => setproductdetails(3);
    /*********** Previous *********/
    const SubtractFirst = () => setproductdetails(1)
    // const SubtractSecond = () => setproductdetails(2)
    // const SubtractThird = () => setproductdetails(3)
const[fielderror ,setfielderror]=useState()
    /**********End **********/

    /***************** PRODUCT DATA SUBMIT **************/
  
    const [storeData, setStoreData] = useState({
        product_image: [],
        product_condition: ''
    })
    function InputDataValue(event) {
        const { name, value, type, checked } = event.target;
        setErrorField({})
        setInvaliMsg()
        setisLoading(false)
        setverifyload(false)
        setfielderror(false)
        seterroStyle(false)
        if (name == 'product_price' && (!(/^\d+$/.test(value))  || value <= 0 )) {
             setStoreData((prevData) => {
                const updatedData = {
                    ...prevData,
                    product_price:'',
                         };
                return updatedData;
            }); 

        }
        else {
            setStoreData((prevData) => {
            const updatedData = {
                ...prevData,
                vendor_id: userId,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
    }

    }
 
const [verifyload,setverifyload]=useState(false)
    const [FormValues, setFormValues] = useState([])
    const HanlerSubmitProductData = (e) => {
        e.preventDefault();
        setverifyload(true)
        setFormValues(storeData)
        setisLoading(true)

    }

    const [errorField, setErrorField] = useState({})
    const [erroStyle, seterroStyle] = useState()
    /***************** Validate Next Button ******/
    // console.log(storeData)
    function SubtractSecond(){
    if (
        storeData &&
        storeData.product_name &&
        storeData.product_name.length > 0 &&
        storeData.product_category != '' &&
         storeData.product_category &&
        selectedFiles &&
        selectedFiles.length > 0
      ) {
        setproductdetails(2);
        setfielderror(false)
      }
    else{
        // setfielderror('Field is Required*')
    }            
    }
    function NextButton(){
        if (
            storeData &&
        storeData.product_name &&
        storeData.product_name.length > 0 &&
        storeData.product_category != '' &&
         storeData.product_category &&
        selectedFiles &&
        selectedFiles.length > 0
          ) {
            setproductdetails(2);
            setproductdetailsecond(true);
            setfielderror(false)
          }
        else{
            setfielderror('Field is Required*')
                         
        }
    }
    function NextButtonSecond()
    {
        if (
            storeData &&
        storeData.product_price &&
        storeData.product_price.length > 0 &&
        storeData.product_condition != '' &&
         storeData.product_description &&
         storeData.product_description.length > 0  &&
         storeData.product_tag &&
         storeData.product_tag.length > 0
          ) 
            {
                setproductdetails(3)
                setproductdetailsthird(true)
                setfielderror(false)
            }
      
        else{
            setfielderror('Field is Required*')
                         
        }
    }
    function SubtractThird(){
        if (
            storeData &&
        storeData.product_price &&
        storeData.product_price.length > 0 &&
        storeData.product_condition != '' &&
         storeData.product_description &&
         storeData.product_description.length > 0  &&
         storeData.product_tag &&
         storeData.product_tag.length > 0
          ) 
            {
                setproductdetails(3)
                setfielderror(false)
            }
      
        else{
            // setfielderror('Field is Required*')
                         
        }
    }
/************ CheckVefication ********/
const{verified}=useSelector((state)=>state.Tradeslices)
  const badge=verified && verified.verify_badge
  const Priceproduct=FormValues && FormValues.product_price

    useEffect(() => {
        async function Addproduct() {
            if(verifyload)
            {
            if((badge == 0))
            {
                setfielderror('UnAuthorized kindly Verify your account')
               setisLoading(false)
               setverifyload(false)
            }
            else  if((badge == 1) && (Priceproduct > 500))
            {
              
               setfielderror('UnAuthorized kindly Verify your account')
                setisLoading(false)
                setverifyload(false)
            }
            else  if((badge == 2) && (Priceproduct > 2000)){
               setfielderror('UnAuthorized kindly Verify your account')
               setisLoading(false)
               setverifyload(false)
            }
        /************ upload Product Data ******/
           else if (isLoading) {
                 axios.post(`api/addProducts`,
                    FormValues,{
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        if (response.data.status == 200) {
                            setproductdetails(4)
                            setisLoading(false)
                             setverifyload(false)
                            seterroStyle(false)
                            setproductdetailsthird(false)
                            setproductdetailsecond(false)
                            setErrorField({})
                            setInvaliMsg()
                            setSelectedFiles([])
                            setStoreData({
                                product_image: [],
                                vendor_id: userId
                            })

                        } else if (response.data.status == 419) {
                            setErrorField(response.data.errors)
                            setisLoading(false)
                            setverifyload(false)
                            seterroStyle(true)
                            setInvaliMsg()
                        }
                        else if (response.data.status == 202) {
                            setInvaliMsg(response.data.data)
                            setErrorField({})
                            seterroStyle(true)
                            setisLoading(false)
                            setverifyload(false)
                        }
                        else if (response.data.status == 201) {
                            setInvaliMsg(response.data.data)
                            setErrorField({})
                            seterroStyle(true)
                            setisLoading(false)
                            setverifyload(false)
                        }
                        else if (response.data.status == 501) {
                            setInvaliMsg(response.data.data)
                            setErrorField({})
                            seterroStyle(true)
                            setisLoading(false)
                            setverifyload(false)
                        }
                    })
                    
            }
        }
        }
        Addproduct()

    }, [FormValues, isLoading])
    // console.log(FormValues)
    /*************** END **********/
    /************* Category ***************/
  
    const categores = category.map((sourceElement) => {
        return (
            

                <option key={sourceElement.id} value={sourceElement.category}>
                    {sourceElement.category}
                </option>
            

        );
    });
    /**************** End ****** */
    /************** FILEUPLOAD *********/

    const handleImageClick = () => {
        fileInputRef.current.click();
    };
      
    const handleFileSelect = (event) => {
        const newFiles = Array.from(event.target.files);
        if (selectedFiles.length + newFiles.length > 3) {
            alert('You can only select up to three images.');
        } else  if (selectedFiles.length + newFiles.length < 3) {
            alert('You can only select up to three images.');
        }
        else {
            setSelectedFiles([...selectedFiles, ...newFiles]);
            setStoreData((prevValues) => ({
                ...prevValues,
                product_image: [...prevValues.product_image, ...newFiles],
            }));
        }
    };

    const handleRemoveImage = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
        setStoreData((prevData) => {
            const product_image = [...prevData.product_image];
            product_image.splice(index, 1);
            return { ...prevData, product_image };
        });
    };
    // console.log(selectedFiles)
    /********************END FILEUPLOAD ************/
    /********************NEXT BUTTON *********/
    const shiftcolor = {
        backgroundColor: '#9BACA9',
        borderRadius: ' 42px',
        color: '#000000',
        textAlign: "center",
        cursor: 'pointer'
    }
    const conditionLink = {
        backgroundColor: '#000000',
        color: '#ffffff',
        cursor: 'pointer',
        textAlign: "center",
        borderRadius: "10px",
        padding: "0.5em 0em",
    }
    const currentDate = new Date().toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    return (
        <>
            <Auth />
<Verificationquery/>
            <div className="container-fluid">
                <Sidebar />
                <header>
                    <Header />
               
                </header>
                <hr />
                <div className="navigator">

                    <ul>
                      
                            <li> <a style={productdetails == 1 || productdetails == 2 || productdetails == 3? {color: "rgba(14, 118, 43, 1)",cursor: 'pointer'} : { cursor: 'pointer' }}  onClick={productdetailsfirst  ? SubtractFirst : null}> <span style={productdetails == 1 || productdetails == 2 || productdetails == 3  ? {background: "rgba(14, 118, 43, 1)"} : {background:"#9BACA9", cursor: 'pointer' }}>1</span> Ad Information</a></li>
                              <li> <a style={productdetails == 2 || productdetails == 3 ? {color: "rgba(14, 118, 43, 1)",cursor: 'pointer'}  : { cursor: 'pointer' }}  onClick={productdetailsecond  ? SubtractSecond : null}  ><span style={productdetails == 2 || productdetails == 3 ? {background: "rgba(14, 118, 43, 1)"} : {background:"#9BACA9", cursor: 'pointer' }}>2</span> Ad Details</a></li>
                             <li> <a style={productdetails == 3  ? {color: "rgba(14, 118, 43, 1)",cursor: 'pointer'}  : { cursor: 'pointer' }} onClick={productdetailsthird  ? SubtractThird : null}  ><span style={productdetails == 3  ? {background: "rgba(14, 118, 43, 1)"} : { background:"#9BACA9",cursor: 'pointer' }}>3</span> Seller's Info</a></li>
                           
                    </ul>
                    {/* ERROR MSG */}
{/* {fielderror}<p>{fielderror}</p> */}
                    {erroStyle || fielderror ? <MovingText
                        type="slideInFromRight"
                        duration='350ms'
                        delay='0s'
                        direction='alternate'
                        //    timing='ease'
                        iteration='1'
                        fillMode='none'><div className="error-field">

                            {InvaliMsg || fielderror ?
                                <div className="error">
                                    {InvaliMsg ?? fielderror}
                                </div> : ''}

                            {Object.keys(errorField).length > 0 && (
                                <div>
                                    {Object.keys(errorField).map((key) => (
                                        errorField[key].map((error, index) => (
                                            <div className="error" key={`${key}-${index}`}>
                                                {error}
                                            </div>
                                        ))
                                    ))}
                                </div>
                            )}

                        </div> </MovingText>
                        : ''}
                    {/* END */}
                    {/* AD INFO */}


                        {productdetails == 1 ?

                            <div className="form-name">
                                <div className="input">
                                    <div className="form-input">
                                        <label htmlFor="">Title</label><br />
                                        <input type="text"
                                            name="product_name"
                                            defaultValue={storeData.product_name}
                                            onChange={InputDataValue} />
                                    </div>
                                    <div className="form-input">
                                        <label htmlFor="">Category</label><br />
                                        <select onChange={InputDataValue}
                                            style={{ textTransform: 'uppercase' }} name="product_category" id="">

                                            <option value=''>Select Category</option>
                                            {categores}
                                           
                                            
                                        </select>
                                    </div>
                                </div>
                                <div className="intro-text">
                                    <big>Upload your Attachment</big><br />
                                    <small>Drag and drop your files here</small>
                                </div>
                                <div className="files">
                                    <input type="file"
                                        name="product_image"
                                        ref={fileInputRef}

                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        style={{ display: "none" }}
                                        multiple />

                                    <div className="form-input">
                                        <div className="" id="file-link" onClick={handleImageClick}>
                                            <img src={fileupload} alt="" />
                                            <br /><small>jpg png nef</small>
                                            <p>Click here</p>

                                        </div>


                                    </div>
                                  
                                    <p className="alert">N:B: Minimum of three pictures is required </p>

                                </div>
                                <div className="btn">
                                    <input type="submit" style={{ cursor:'pointer' }} onClick={NextButton} value="PROCEED" />
                                </div>
                                <div className="product-image">
                                        <div className="display" id="ismageContainer">
                                            {selectedFiles.length > 0 ? (
                                                selectedFiles.map((file, index) => (
                                                    
                                                    <div key={file.name} className="image-container">
                                                        <div className="img-info">
                                                        <img src={URL.createObjectURL(file)} alt="" />
                                                        <div className="text-info">
                                                            <h5>{file.name}</h5>
                                                            <small> {currentDate}</small> <small>{(file.size / (1024 * 1024)).toFixed(2) > 1 ? (file.size / (1024 * 1024)).toFixed(2) + ' mb':(file.size / (1024 * 1024)).toFixed(2)+' kb'}</small>
                                                        </div>

                                                        </div>
                                                        <button onClick={() => handleRemoveImage(index)}><FaShoppingBasket/></button>
                                                    </div>
                                                ))
                                            ) : (
                                                ''
                                            )}   </div>
                                    </div>
                                

                            </div> : ''}
                        {/* END */}

                        {/* Ads Details */}

                        {productdetails == 2 ? <div className="ad-details">
                            <div className="details">
                                <div className="form-className">

                                    <div className="head-info">
                                        
                                        <div className="input-form">
                                            <label htmlFor="">Tag</label><br />
                                            <input onChange={InputDataValue}
                                                defaultValue={storeData.product_tag}

                                                type="text" name="product_tag" id="" />
                                        </div>
                                        <div className="input-form float-right">
                                            <p>Click on Product Condition*</p>
                                            <div className="border-line">
                                                <ul>
                                                 
                                                        {storeData.product_condition == "NEW"
                                                            ? <a style={conditionLink}
                                                                onClick={() => handleConditionChange("NEW")}>NEW</a>
                                                            :
                                                            <a style={{ cursor: 'pointer',padding:"0.5em 0em",textAlign:"center" }}
                                                                onClick={() => handleConditionChange("NEW")}>NEW</a>
                                                        }
                                                    
                                                {storeData.product_condition == "USED"
                                                        ? <a style={conditionLink}
                                                            onClick={() => handleConditionChange("USED")}>USED</a>
                                                        :
                                                        <a style={{ cursor: 'pointer',padding:"0.5em 0em",textAlign:"center" }}
                                                            onClick={() => handleConditionChange("USED")}>USED</a>}
                                                
                                                </ul>
                                            </div>

                                        </div>
                                        {/* <div className="input-form">
                                            <label htmlFor="">Delievry</label><br />
                                            <select name="" id="">
                                            <option value="">Select a how soon you can deliver</option>
                                           </select>
                                        </div> */}
                                        {/* <div className="input-form">
                                            <label htmlFor="">Warranty</label><br />
                                           <select name="" id="">
                                            <option value="">Select one</option>
                                           </select>
                                        </div> */}
                                    </div>
                                    <div className="product-tags">
                                    <div className="input-form amount-field">
                                            {/* <label htmlFor="">Price*</label><br /> */}
                                            <div className="border-line">
                                                <input type="tel"
                                                    defaultValue={storeData.product_price}
                                                    onChange={InputDataValue}
                                                    name="product_price" id="" placeholder="enter amount" />
                                               <p>USD</p>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <textarea placeholder="Product Description" onChange={InputDataValue}
                                                defaultValue={storeData.product_description}

                                                name='product_description' id="" cols="0" rows="13"></textarea>
                                            {/* <p>Additional for new seller</p> */}
                                        </div>
                                    </div>
                                    {/* <div className="faq">
                                        <h3>How Fast can you Deliver your service?</h3>
                                        <div className="time">
                                            <p>24hrs</p>
                                            <p>24hrs</p>
                                            <p>24hrs</p>
                                            <p>24hrs</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="warranty">
                                        <h3>Warranty?</h3>
                                        <div className="time">
                                            <p>No</p>
                                            <p>2 weeks</p>
                                            <p>1 month</p>

                                        </div>
                                    </div> */}
                                    <br />



                                </div>
                            </div>
                            <div className="btn">
                                <input type="submit" style={{ cursor :'pointer'}} onClick={NextButtonSecond} value="PROCEED" />
                            </div>
                        </div>
                            : ''
                        }
                        {/* END  */}
                        {/* Seller Info */}
                        <form onSubmit={HanlerSubmitProductData}

>
                        {productdetails == 3 ?
                            <div style={{ margin: '2em 0em 2em 0em' }} className="sellerinfor">

                                <div className="details">
                                    {/* <div className="form-input country">
                                        <label for="">Country</label><br />
                                        <input onChange={InputDataValue} type="text" name="product_country" id="" />

                                    </div> */}
                                    <div className="form-input">
                                        <label htmlFor="" id="left" >Country</label><br />
                                        <select onChange={InputDataValue} name="product_country" id="">
                                            <option>Select Country</option>
                                            {countries.length > 0 ? (
                                                country
                                            ) : (
                                                <option value=''>Not Available</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-input state">
                                        <label htmlFor="">State</label><br />
                                        <input onChange={InputDataValue}
                                            defaultValue={storeData.product_state}

                                            type="text" name="product_state" id="product_state" />

                                    </div>

                                </div>
                                <div className="form-input number" >
                                    <label htmlFor="">Mobile No</label><br />
                                    <input onChange={InputDataValue}
                                        defaultValue={storeData.Mobile}

                                        type="tel" name="Mobile" id="" />

                                </div>
                                <p className="note">N:B  This would contain some important information and policies from squaremart and it could contain the possible return policies that might be involved that the seller needs to know</p>
                                <div className="btn">
                                    {isLoading ? <input type="submit" value="Loading ..." />
                                        :
                                        <input type="submit" style={{ cursor:'pointer' }} value="POST AD" />}
                                </div>

                            </div>
                            : ''}
                        {/* END */}
                    </form>

                </div>
                {/* SUCCESSFUL */}
                {productdetails == 4 ?
                    <section>
                        <div className="success">
                            <div className="shopping">
                                <img src={shopping} alt="" />
                            </div>
                            <div className="successbtn">
                                <img src={success} alt="" />
                                <p className="alert">
                                    AD Successfully added
                                </p>

                            </div>
                        </div>
                    </section>

                    : ''}
                {/* END */}
            </div>


        </>
    )
}
export default Addproduct