import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CardValidate = () => {
  const Navigate = useNavigate();
  const profile = useSelector((state) => state.ProfilePageslice.profile);

  const userId= `${profile.id}`
   const [isloadingRequest, setisloadingRequest] = useState(true);
// set rendering time
  useEffect(() => {
    const timer = setTimeout(() => {
      setisloadingRequest(false);
    }, 40000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval;

   // if (isloadingRequest) {
      interval = setInterval(() => {
        fetchProducts();
      }, 30000);
   /// }

    return () => clearInterval(interval);
  });

  const fetchProducts = async () => {
    axios
      .get(`api/accept_rejectCard`)
      .then((response) => {
        if (response.data !== 201) {
          setisloadingRequest(true);
           Navigate("/InitiatePayment", { replace: true });
        }
      });
  };

  return <></>;
};

export default CardValidate;
