import React, { useState, useEffect } from "react";

const CookiePopup = () => {
  const [cookiesEnabled, setCookiesEnabled] = useState(false);
  const [cookiePreference, setCookiePreference] = useState(
    localStorage.getItem("cookiePreference") || "no"
  );

  useEffect(() => {
    setCookiesEnabled(navigator.cookieEnabled);
  }, []);

  const handleAccept = () => {
    setCookiePreference("yes");
    localStorage.setItem("cookiePreference", "yes");
    setCookiesEnabled(true);
  };

  const handleReject = () => {
    setCookiePreference("reject");
    localStorage.setItem("cookiePreference", "reject");
  };

  return (
    
    <div>
      {cookiesEnabled && cookiePreference === "no" && (
        <div className='cookies'> 
          <p>
            This site uses cookies to store your preferences and improve your
            experience. Do you want to enable cookies?
          </p>
          <div className="btn">
          <button className='accept' onClick={handleAccept}>Accept</button>
          <button className='rejec' onClick={handleReject}>Reject</button>
          </div>
        
        </div>
 )} 
    </div>

  );
};

export default CookiePopup;
