import React from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { decodeBase64 } from "bcryptjs";
import { FaLessThan } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineArrowLeft, AiOutlineRollback } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setfetchUsdt, setfetchgiftcards } from "../../Redux/SwapCard";
import Tradepagevalidat from "./Tradepagevalidate";
import ScrollToTop from "../../pages/ScrollToTop";
// import crypto from 'crypto-browserify';

const BuyUsdt=()=>
{
    const{usdt_buy_id,fetchUsdt}=useSelector((state)=>state.SwapCard)
  
     const Navigate = useNavigate()
  const dispatch=useDispatch()
   /************* USDT ID *********/
  
useEffect(()=>{
    if(!(usdt_buy_id))
    {
         Navigate("/trade" ,{ replace: true });

    }
})

  
 useEffect(() => {
        async function SelectUsdt() {

            axios
                .get(`api/fetchUsdtId/${usdt_buy_id}`)
                .then((response) => {
                    dispatch(setfetchUsdt( {
                                ...fetchUsdt,
                                Usdtamount:response.data.amount,
                                accept_giftcard:response.data.accept_giftcard,
                                 
                        }));
                    
                      
                })

        }
        SelectUsdt()
    }, [usdt_buy_id])
    
   /************** END ***********/
   /******************* AMOUNT OF USDT TO BUY  ******/

function Changeamount(event)
{
    const { name, value, type, checked } = event.target;
   
    if (name == 'Usdtamount' && (!(/^\d+$/.test(value))  || value <= 0 )) {
        dispatch(setfetchUsdt(
            { ...fetchUsdt, 
                Usdtamount: null }));
      }
      else{
    dispatch(setfetchUsdt( {
            ...fetchUsdt,
            [name]: type === "checkbox" ? checked : value,
        }));
}   
}
   /****************** END *********/
    function Systemexchange(e)
    {
        e.preventDefault()
        // localStorage.setItem('Purchase_usdt', JSON.stringify(fetchUsdt)) 
         Navigate(`/Systemexchange` ,{ replace: true });
    }
 return (
    <>
    <Auth/>
    <Tradepagevalidat/>
    <div className="container-fluid">
        <Sidebar />
        <header>  
            <Header />
        </header>
        <hr />
        <div className="buy-usdt">
                <div className="head-back">
                <h1> <Link to='/trade'> <IoIosArrowBack/> </Link> <span>select how you want to buy USDT</span></h1>

    
    </div>
                <div className="form-class">
                   <div className="head">
                    <h2>You are buying
                    USDT</h2>
                   </div>
                    <form action="" onSubmit={Systemexchange}>
                        <div className="form-name">
                        <div className="amount">

                            <label htmlFor="">Amount</label><br/>
                            <div className="form-input">
                            <input type="tel" onChange={Changeamount} placeholder="100"
                            name="Usdtamount" value={fetchUsdt.Usdtamount || ''} 
                            />
                            <select className="" id="">
                                <option value="USD">USD</option>
                                {/* <option value="USD">USDT</option> */}
                            </select>
                        </div>
                        </div>
                        <div className="form-input swap">
                            <label htmlFor="">Swap with</label><br/>
                            <input type="text" style={{cursor:'not-allowed'}} 
                            readOnly  placeholder="USDT" value={fetchUsdt.accept_giftcard || ''}/>
                            
                        </div>
                    </div>
                        <div className="btn">
                
                <button style={fetchUsdt.Usdtamount   ? 
                        {  cursor: 'pointer' } :
                        {  cursor: 'not-allowed' }
                } 
                disabled={fetchUsdt.Usdtamount ? 
                   '':
                    'disabled'
            }
                 type="submit" ><span> Continue</span></button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
</>
 )
}
export default BuyUsdt