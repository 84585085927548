import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
//import { BsTwitterX } from 'react-icons/bs';

const Footer=()=>
{
    const currentDate = new Date().toLocaleDateString('en-US', {
       year: 'numeric',
      });
    return (
          <section className="footer">
        <footer className="footer">
            <div className="features">
                <h2>Features</h2>
                <ul>
                    <li><Link to="/Marketplace">Marketplace</Link></li>
                    <li><Link to="/Trade">Gift card swap</Link></li>
                    <li><Link to="/Dashboard">Wallet swap</Link></li>
                </ul>
            </div>
            <div className="company">
                <h2>Company</h2>
                <ul>
                    <li><Link to="/AboutUs">About</Link></li>
                    <li><Link to="/TermsCondition">Terms and Condition</Link></li>
                    <li><Link to='/Privacy'>Privacy policy</Link></li>
                </ul>
            </div>
            <div className="resources">
                <h2>Resources</h2>
                <ul>
                    {/* <!-- <li><Link href="blog.html">Blogs</Link></li>
                    <li><Link to="guide.html">Guide<Link></li>
                    <li><Link to="career.html">Careers<Link></li> --> */}
                    <li><Link to="/faq">FAQ</Link></li>
                </ul>
            </div>
            <div className="support">
                <h2>Support</h2>
                <ul>
                    {/* <!-- <li><Link to="help-center.html">Help Center<Link></li>
                    <li><Link to="help-center.html">Help Center<Link></li> --> */}
                    <li><Link to="/">Help Center</Link></li>
                </ul>
            </div>
        </footer>
        <div className="follow-up">
            <div className="social-media">
                   <Link to=""> <FaLinkedinIn className='icon' /></Link>
                <Link to=""> <FaFacebookF className='icon' /></Link>
                 <Link to=""><FaInstagram className='icon' /></Link>
{/*         <Link to=""><BsTwitterX/></Link>
                       <Link to=""><img src="img/Clubhouse_App_Logo.png" alt="Instagram Social Media Page"></Link>
                <Link to=""><img src="img/Vector (1).png" alt="Instagram Social Media Page"></Link> */}
            </div>
            <div className="copyright">
                <span>Copyright © {currentDate} Squareswap Inc.  All rights reserved.</span>
            </div>
        </div>
    </section>
//   OLD FOOTER      <footer className="footer-animate"  data-aos="zoom-in">
//     <div className="group">
//         <div>
//             <h3>Features</h3>
//             <Link to="/Marketplace">Marketplace</Link>
//             <Link to="/Trade">Gifts and swap</Link>
//             <Link to="/Dashboard">Wallet swap</Link>
//         </div>
//         <div>
//             <h3>Company</h3>
          
//             <Link to='/AboutUs'>About</Link>
//             <a href="">Terms and condition</a>
//             <Link to='/Privacy'>Privacy policy</Link>
//         </div>
//         {/* <div>
//             <h3>Resources</h3>
//             <a href="">Blog</a>
//             <a href="">Guide</a>
//             <a href="">careers</a>
//             <a href="">FAQ</a>
//         </div> */}
//         <div>
//             <h3>Socials</h3>
//             <a href="">Instagram</a>
//             <a href="">Facebook</a>
//             <a href="">Twitter</a>
//             {/* <a href="">*******</a> */}
//         </div>
//         <div>
//             <h3>Support</h3>
//             <a href="">Help center</a>
//             {/* <a href="">Help center</a>
//             <a href="">Help center</a> */}
//         </div>
//     </div>
//     <p><span>Copyright © {currentDate} Squareswap Inc.  All rights reserved.</span></p>
// </footer>

    )
}
export default Footer
