import React, { useEffect, useState } from "react";
import logo from '../../img/squareswap.png'
import dropdown from '../../img/lightdrop.png'
import category from '../../img/category.png'
import ProductData from './ProductData'
import axios from "axios";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import Category from "./Category";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import MovingText from "react-moving-text";
import { CgDollar } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { FaAngleDown, FaCartArrowDown, FaArrowDown, FaTimes, FaAngleRight, FaHome ,FaCoins, FaBitcoin, FaQuestion} from 'react-icons/fa'
import locationIcon from "../../img/location.png"
import { useDispatch, useSelector } from "react-redux";
import {AiOutlineHome, AiOutlineMenu,AiOutlineWallet} from 'react-icons/ai'
import { MdOutlineCardGiftcard} from 'react-icons/md'
import { SiCoinmarketcap} from 'react-icons/si'
import { BiSolidHelpCircle} from 'react-icons/bi'
import logos from '../../img/squaremart-logo.png'
import { setCategory, setallProduct,setcategory_name,setdataNotFound } from "../Redux/Createslice";
import Dataquery from "./Dataquery";
import ScrollToTop from "./ScrollToTop";
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header';
import Footer from "../Footer";
const Marketplace = () =>
 {

    const { showChat }=useSelector((state)=>state.Chatslice)

    const{allProduct,category_name,dataNotFound,Categories}=useSelector((state=>state.Createslice))
   
    const Navigate = useNavigate()
    
    const [ShowModal, setShowModal] = useState(false)
    const [product_id, setproduct_id] = useState()
    const dispatch=useDispatch()
    function CartItem(id) {
        setproduct_id(id)
          localStorage.setItem('Product_Id', id)
         const CartIdProduct= encodeURIComponent(id)
         Navigate(`/login?purchase=${CartIdProduct}` ,{ replace: true });
    }
       const [viewproduct, setviewproduct] = useState([])
    // function ModalHandler(id) {
    //     setproduct_id(() => id);
    //     setShowModal(true)
    // }
  /** VIEW PRODUCT SELCTED */
  
    function ModalHandler(id,product_name,product_image,product_price,product_country,tag,description) {
           
            setviewproduct({
                    id:id,
                    title:product_name, 
                    product_img:product_image,
                    location:product_country,
                    tag:tag,
                    description:description,
                    price:product_price,
                }
            )
            setShowModal(true)

        }
    const Handlerclose = () => setShowModal(false);
    /** VIEW PRODUCT SELCTED */
    // useEffect(() => {
    //     async function SelectProduct() {
    //         if(product_id){
    //         axios
    //             .get(`api/fetchIdProduct/${product_id}`)
    //             .then((response) => {
                   
    //                 setviewproduct(response.data);
    //             })
    //     }
    // }
    //     SelectProduct()
    // }, [product_id])
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }


    /**  END */
    /** VIEW PRODUCT Category */
    useEffect(() => {
        async function SelectCATEGORY() {
            axios
                .get(`api/Category`)
                .then((response) => {
                    dispatch(setCategory(response.data));
                  
                })
        }
        SelectCATEGORY()
    }, [])



    /**  END */
    const Categry_mobile = Categories.map((Element,index) => {
return <button key={index} style={{ textTransform: 'capitalize'}} onClick={()=>HandleCategory(Element.category)}> {Element.category}
   
</button>
    })
    const Categry = Categories.map((Element) => {
        return (
            <Category
                key={Element.id}
                image={Element.image}
                name={Element.category}
                HandlerCategory={()=>HandleCategory(Element.category)}
            />
        )
    })
    function HandleCategory(category){
        dispatch(setcategory_name(category))
    }
         /**GET ALL  PRODUCTS CATEGORY  */
     useEffect(() => {
        async function AllProductCATEGORY() {
            if(category_name){
            axios
                .get(`api/allProductsCategory/${category_name}`)
                .then((response) => {
                          dispatch(setallProduct(response.data));
                    dispatch(setdataNotFound('Product Table is Currently Empty'))
                  
                    const yOffset = 70; // You can adjust this value to determine how much to scroll down
                    window.scrollBy({ top: yOffset, behavior: 'smooth' });
                  
                })
        }
    }
        AllProductCATEGORY()
    }, [category_name])
    /**  END */
    /******************* SEARCH  Query ************/

const [searchQuery, setSearchQuery] = useState('');
const [SearchQueryId, setSearchQueryId] = useState('');
const [filteredHistory, setFilteredHistory] = useState([]);
const [filterAndSortProducts, setfilterAndSortProducts] = useState()
const [filterAndtype, setfilterAndtype] = useState()


const handleSortChange = (action,event) => {
  const query = event.target.value;
  setfilterAndtype(action)
  setfilterAndSortProducts(query)
};

/*********** SORT_ARRANGE *****/
useEffect(()=>{
    async function SORT_ARRANGE()
    {
      if(filterAndSortProducts){
axios.get(`api/sort_product/${filterAndSortProducts}/${filterAndtype}`)
.then(res=>{
 
   dispatch(setallProduct(res.data))
     const yOffset = 500; // You can adjust this value to determine how much to scroll down
    window.scrollBy({ top: yOffset, behavior: 'smooth' });
  
})
      }
     
    }
    SORT_ARRANGE()
   
  },[filterAndSortProducts,filterAndtype])

/*********** END */
const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    const filtered = allProduct.filter((product) => {
      // Customize the filtering logic based on your requirements
      const lowerCaseQuery = query.toLowerCase();
      const product_category = product.product_category ? product.product_category.toLowerCase() : "";
      const product_name = product.product_name
        ? product.product_name.toLowerCase()
        : "";
      return (
   product_category.includes(lowerCaseQuery) ||
        product_name.includes(lowerCaseQuery) 
      );
     
    });
  
    setFilteredHistory(filtered);
  
  };
  function handlersearchbutton(e)
  {
    e.preventDefault()
    const yOffset = 500; // You can adjust this value to determine how much to scroll down
    window.scrollBy({ top: yOffset, behavior: 'smooth' });
  }
  const handleSearchChangeID = (event) => {
    const query = event.target.value;
    setSearchQueryId(query);
  
    const filtered = allProduct.filter((product) => {
      // Customize the filtering logic based on your requirements
      const lowerCaseQuery = query.toLowerCase();
      const product_id = String(product.id).toLowerCase();
  
      return product_id.includes(lowerCaseQuery);
    });
  
    setFilteredHistory(filtered);
  };
/****************** END *****/
      /**FETCH PRODUCTS  */
      const cachedData = JSON.parse(localStorage.getItem('cached'))
    
    /**  END */
    //   Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(6);

    // Calculate the index of the first and last product to show on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    // Extract the subset of product data to show on the current page
    const currentProducts = allProduct.slice(indexOfFirstProduct, indexOfLastProduct);

    // Generate the product components for the current page
    const displayedProducts = searchQuery || SearchQueryId ? filteredHistory : currentProducts;

   
    const products = displayedProducts.map((sourceElement) => {
        return (
            <ProductData
            key={sourceElement.id}
            category={sourceElement.product_category}
            iconLocation={locationIcon}
                CartItem={() => CartItem(sourceElement.id)}
                img={sourceElement.product_image}
                title={sourceElement.product_name}
                country={sourceElement.product_country}
                price={sourceElement.product_price}
                time={sourceElement.time}
                toggleclick={() => ModalHandler(sourceElement.id,sourceElement.product_name
                    ,sourceElement.product_image,sourceElement.product_price,sourceElement.product_country,sourceElement.tag,sourceElement.description)}
            />
        );
    });

    // Calculate the total number of pages based on the total number of products
    const totalPages = Math.ceil(allProduct.length / productsPerPage);

    // Handle the click event for the "next" button
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle the click event for the "previous" button
    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const [arrowdirection, setarrowdirection] = useState(false)
    function Arrow() {

        setarrowdirection((previous) => !previous)
    }
    const btn = {
        fontSize: '25px',
        border: '2px solid white',
        backgroundColor: 'white', borderColor: 'white'
    }
    const {size}=useSelector((state)=>state.WindowSize)
     /**************** Menu Mobile */
     const[showmenu,setshowmenu]=useState(false)
     function Menumobile(){
      setshowmenu((previus) => !previus)
      }
      /** END  */
       /* HELP SUPPORT */
      
    return (
    
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
          <Dataquery/>
       <DesktopHeader/>
        <MobileHeaders/>
             <div className="container">

                <div className="pagination">
                    <div className="paginate">
                        <button className={currentPage === 1 ? 'disabledButton' :''} onClick={handlePreviousClick}>
                            <FaAngleDoubleLeft/>
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :''} onClick={handleNextClick}>
                            <FaAngleDoubleRight/>
                        </button>
                    </div>
                </div>
              
                <div className="row">
{/* <div className=""></div> */}
                    <div className= "col-lg-4" >
                    <div className="mobile-sidebar">
                        {sessionStorage.getItem('authenticated') ?
                      <Link to='/Addproduct'>
                      <button id="addprodcu">+ Add Product</button>
                      </Link>  
                      : null
 }
                        <form onSubmit={handlersearchbutton} className="form-search">
                            <input type="search"
                             value={searchQuery}
                                 onChange={handleSearchChange}
                                  name="" id="search" />
                            <button type="button" className="button">search</button>
                        </form>
                        <div className="itemcat">
                            {Categry_mobile}
                        {/* <button  href="#droppcategory"> */}
                      

                            {/* <button>Home & Office</button>
                            <button>Phones & Tablets</button>
                            <button>Electronics</button>
                            <button>Supermarket</button>
                            <button>Fashion</button>
                            <button>Sporting Goods</button>
                            <button> <span>-</span> other categories</button> */}
                            
                        </div>

                    </div>
                        <div className="sidebar">
                            <div className="dropdown">
                                <div className="icon-before">
                                    <img src={category} alt="" />
                                </div>
                                <p>Categories</p>
                                <div className="icon-after">
                                    <button style={btn} onClick={Arrow}>
                                        {arrowdirection ? <img style={{ transform: 'rotate(-360deg)' }} src={dropdown} alt='' /> : <img src={dropdown} alt='' />}

                                    </button>
                                </div>

                            </div>

                            {
                                arrowdirection
                                    ?
                                    <div className="dropdown-items" id="dropdown-items">
                                        <p className="head">All Categories</p>
                                        <div className="droplinks">
                                            {/* Category Loop */}
                                            <a style={{ textDecoration:'none'}} href="#droppcategory">
                                                      {Categry}
</a>

                                        </div>
                                    </div>
                                    : ''
                            }
                            <div className="search">
                                <input type="search"  
                                 value={searchQuery}
                                 onChange={handleSearchChange}
                                    placeholder="Search items here" />
                                <input type="search" name="" id=""
                                 value={SearchQueryId}
                                 onChange={handleSearchChangeID}
                                    placeholder="Search Ad ID" />
                            </div>

                            <div className="sorting">
                                <div className="date">
                                <select type="search" 
                                 onChange={(event)=>handleSortChange('Date',event)} name="" id="">
                                    <option>Sort by Date</option>
                                    <option value="desc">Recent</option>
                                    <option value="asc">Older</option>
                                    </select>
                                  

                                </div>
                                <div className="price">
                                <select type="search"
                                 onChange={(event)=>handleSortChange('Price',event)} 
                                 name="" id="">
                                    <option>Sort by Price</option>
                                    <option value="asc">Min Price</option>
                                    <option value="desc">Max Price</option>
                                    </select>
                                    {/* <div className="title">
                                        <h3>Sort by</h3>
                                        <h3>Price</h3>
                                    </div>
                                    <div className="sort">
                                        <a href="">Min Price</a>
                                        <a href="">Max Price</a>
                                    </div> */}

                                </div>
                            </div>


                        </div>

                    </div>
                    {/* ALL PRODUCTS LOOP */}
                    {/* <div className="pagination-mobile" id='droppcategory'>
                    <div className="paginate">
                        <button className={currentPage === 1 ? 'disabledButton' :''} onClick={handlePreviousClick}>
                            <FaAngleDoubleLeft/>
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :'' } onClick={handleNextClick}>
                        <FaAngleDoubleRight/>

                        </button>
                    </div>
                </div> */}
                    <div className="col-lg-8">
                 
                     {displayedProducts.length > 0 ? products : 
                       
                            <div className="table-empty">{dataNotFound}
                           </div>
                           
                            }  
                    </div>
                    <div className="pagination-mobile" id='droppcategory'>
                    <div className="paginate">
                        <button className={currentPage === 1 ? 'disabledButton' :''} onClick={handlePreviousClick}>
                            <FaAngleDoubleLeft/>
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :'' } onClick={handleNextClick}>
                        <FaAngleDoubleRight/>

                        </button>
                    </div>
                </div>




                </div>
                {showmenu ? 
                     <div id="mySidenav" className= "sidenav" >
                        <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
                          <ul>
                          <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
                          <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li className="login">
                            <Link style={link} to='/login'>LOGIN</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
              
                {/* MODAL SECTION */}
                {ShowModal ? <Modal
                    key={viewproduct.id}
                    title={viewproduct.title}
                    description={viewproduct.description}
                    tag={viewproduct.tag}
                    location={viewproduct.location}
                    price={viewproduct.price}
                 CartItem={() => CartItem(viewproduct.id)}
                    product_img={viewproduct.product_img}
                    ModalClose={() => Handlerclose()} /> : ''}

            </div>
            <Footer/>
        </div>
        
       
    )
}

export default Marketplace
