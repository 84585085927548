import React from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useEffect } from "react";
import { useState,useRef } from "react";
import { FaClock, FaExclamationCircle } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UploadSellCard from "./UploadSellCard";
import MovingText from 'react-moving-text'
import { useDispatch, useSelector } from "react-redux";
import ChatInterface from "../../pages/Chatbot";
import { setmessage } from "../../Redux/Createslice";

const Acceptcard=()=>
{
  const profile = useSelector((state) => state.ProfilePageslice.profile);

  const userId= `${profile.id}`
  
    /************ Check if There's a swapping request for the User **********/
    
      const[swapDetails,setswapDetails]=useState({})
     const[Noresponse,setNoresponse]=useState('')
      const [isloadingRequest,setisloadingRequest]=useState(true)
      const Navigate = useNavigate()
       /*********************  polling Request to check trade from buyer and seller
     *****/ 
    useEffect(() => {
      Accept_Reject();
  
      // Start polling for new products every 5 seconds
      let interval = setInterval(Accept_Reject, 5000);
  
      // Clean up the interval on unmount
      return () => clearInterval(interval);
    }, [isloadingRequest]);

    const Accept_Reject = async () => {
      if(isloadingRequest){
           const response = await axios .get(`api/accept_rejectCard`);
  
                if (response.data != 201) {
                  setisloadingRequest(false);
                  setswapDetails(response.data);
                 setNoresponse('')
                }
                 else if (response.data == 201) {
                  // setisloadingRequest(false);
                  setNoresponse('Kindly wait to start Trading or come back later')
                }
            
          }
          else
          {
          
             setNoresponse('Kindly wait to start Trading or come back later')
           
          }
        
        }
      
      
    /**************** END ******************/

     /******************** Time left to pay **************/
     const [counter, setCounter] = useState(3600); // set counter to 30 minutes (in seconds)

     useEffect(() => {
       const intervalId = setInterval(() => {
         setCounter(prevCounter => prevCounter - 1);
       }, 1000);
   
       return () => {
         clearInterval(intervalId);
       };
     }, []);
     const minutes = Math.floor(counter / 60);
     const seconds = counter % 60;
   
     /********************** End *********/
     /***************** Request Action  *******/
     const[target,settargeted]=useState()
const[isloadingaction,setisloadingaction]=useState()
     const[requestData,setrequestData]=useState({
        'swap_id':'',
        'action':'',
        'userId':''

     })
   function RequestAcceptReject(id,action)
{
  settargeted(action)
      setisloadingaction(true)
 setrequestData((prevValues)=>({
    ...prevValues,
    swap_id:id,
    action:action,
    userId:userId
 })
 )
}

useEffect(() => {
    async function Accept_Reject() {
     
    if(isloadingaction){ 
   
          axios.post(`api/accept_rejectgranted`,requestData)
          .then((response) => {
         
            if(response.data.status == 200)
         {
          
            setisloadingaction(false)
             Navigate("/trade", { replace: true });

            }
         
              
        })
        }
    }
        Accept_Reject()
           
     }, [requestData,isloadingaction])
     /********************* END ************/
 /************** FILEUPLOAD *********/
 const[storeDataUsdt,setstoreDataUsdt]=useState()
 const dispatch=useDispatch()
 const {message}=useSelector((state)=>state.Createslice)
 const[sucesss,setsucesss]=useState()
 const [selectedFiles, setSelectedFiles] = useState();
 const fileInputRef = useRef(null);

 const handleImageClick = () => {
     fileInputRef.current.click();
 };

 const handleFileSelect = (event) => {
     dispatch(setmessage())
     const newFiles = event.target.files[0];
// console.log(newFiles)
     setSelectedFiles(newFiles);
     setstoreDataUsdt((prevValues) => ({
         ...prevValues,
         giftcard_image: newFiles,
     }));

 };
 function handleRemoveImage() {
  dispatch(setmessage())
    
     setSelectedFiles();
     setstoreDataUsdt((prevValues) => ({
         ...prevValues,
         giftcard_image: '',
     }));
 }
/******************* SUBMIT Uploading SELLING CARD *******/
const[targetLoad,settarget]=useState()
const [uploading,setuploading]=useState(false)
function RequestAcceptRejectGiftcard(id,action)
{
  dispatch(setmessage())
  settarget(action)

     setuploading(true)
     setstoreDataUsdt((prev)=>({
         ...prev,
         userId:userId,
         action:action,
         swap_id:id
     }))
}

useEffect(()=>
{
 async function UploadSellcard()
 {
     if(uploading){
         axios
         .post(`api/uploadgiftcard/`,storeDataUsdt,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })
         .then((response) => {
           if (response.data.status == 405) {
            dispatch(setmessage( <>
            {response.data.data} <FaExclamationCircle />
          </>))
            // setmessage({response.data.data} <FaExclamationCircle/> );
            setuploading(false)
        }
        else   if (response.data.status == 419) {
          dispatch(setmessage( <>
              {response.data.errors.giftcard_image} <FaExclamationCircle />
            </>))
         setuploading(false)
         
      }
      else {
        dispatch(setmessage(response.data.data));
       setsucesss(response.data.status)
       setuploading(false)
        Navigate("/trade", { replace: true });

      }
        
         })
     }
 }
 UploadSellcard()
},[uploading])

//  console.log(storeDataUsdt)
const disstyled={
  cursor:'not-allowed',

}
    /**************** CONDITION ********/
    function Condition() {
      if ( swapDetails.grants == 1){
       
        return (
        <div className="terms">
               <h1>Confirm  Gift card to sell USDT to buyer</h1>
               <div className="conditions">
                   <div className="head">
                       <h2> USDT/GIFT CARD SYSTEM EXCHANGE</h2>
                   </div>
                   <p>
                   {swapDetails.selling} for this trade has been locked in escrow. Please make sure {swapDetails.accept} worth {swapDetails.amount}USD meet the terms before Accepting the transaction
                   </p>
                   <div className="time">
                   <FaClock />  
                    <p>Time left to pay: <span>
    {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
   </span></p>
                         
                           
                   
                   </div>
                  
                   <form >
                   <div className="final-seller-terms">
                           <div className="termsof">
                               <div className="conditions">
                                   <div id="imageContainer">
                                       <img src={swapDetails.card} width='200' alt=""
                                        id="image-preview" />
                                   </div>

                                 
                               </div>
                                            <div className="actions">
                                   <button disabled={isloadingaction ? 'disabled': null}  style={isloadingaction ? disstyled : null} type="button" onClick={()=>RequestAcceptReject(swapDetails.id,2)} className="reject" id="reject">
                                   {target == 2 && isloadingaction ? "Please wait..." :'Reject'}</button>
                                
                                   <button disabled={isloadingaction ? 'disabled': null} style={isloadingaction ? disstyled : null} type="button" onClick={()=>RequestAcceptReject(swapDetails.id,1)} className="approved" id="approved">
                                   {target == 1 && isloadingaction ? "Please wait..." :'Approve'}
                                   </button>
                                 
                               
                               </div>
                              
                              
                           </div>
                      
                   </div>
                 
              
           </form>
             </div> 
           </div>
           )
      }
      
      else if ( swapDetails.grants == 2){
      
      return ( <>
       <UploadSellCard
       action={targetLoad}
      RequestAccept={()=>RequestAcceptRejectGiftcard(swapDetails.id,1)}
       RequestReject={()=>RequestAcceptRejectGiftcard(swapDetails.id,2)}
           seconds ={seconds}
           minute  ={minutes}
              invoiceNumber={swapDetails.invoiceNumber}
              amount={swapDetails.amount}
              selling={swapDetails.selling}
    //  HandlerTradeData={HandlerTradeData}
     selectedFiles={selectedFiles}
     fileInputRef={fileInputRef}
     handleFileSelect={handleFileSelect}
     handleRemoveImage={handleRemoveImage}
     handleImageClick={handleImageClick}
     uploading={uploading}
     accept ={swapDetails.accept}
     /> 
     </>
      )
    }
    else{
      return (
<div className="norep"><marquee scrollamount="10" behavior="alternate">{Noresponse}</marquee>
        </div>
      )
      }
  }
     /******************* END  ****************/
     const { size }=useSelector((state)=>state.WindowSize)
     const { showChat }=useSelector((state)=>state.Chatslice)
     
     return (
      <div style={showChat  && size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
     
        <Auth />
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            { message ?
             
             <MovingText
  type="slideInFromRight"
  duration='350ms'
  className={sucesss == 200 ? "sucessmsg": "errormsg"}
  delay='0s'
  direction='alternate'
  //    timing='ease'
  iteration='1'
  fillMode='none'> 
   <p>{message}</p></MovingText> 


 : null }
     {Condition()}
     
    
            </div>
            <ChatInterface/>
</div> 
   )
}
export default Acceptcard