 import  React, { useState, useEffect, useRef } from 'react' 
import logo from '../img/squaremart-logo.png'
import { Link,useNavigate} from 'react-router-dom'

const Headers=()=>
  {
   
const Navigate = useNavigate()
    return (
   <section className="header-web">
        <div className="squaremart-logo">
           <Link to='/'> <img src={logo} alt="Squaremart Web Logo"/></Link> 
        </div>
        <div className="other-page">
            <ul>
                 <li><Link to='/AboutUs'>About US</Link></li>
            <li><Link to="/Marketplace">Marketplace</Link></li>
            <li><Link to="/Dashboard">Wallet</Link></li>
            <li><Link to="/ContactUs">Contact US</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
               
            </ul>
        </div>
        <div className="signing-page">
            <div className="sign-in">
               {!sessionStorage.getItem("Prodile_name") ||  sessionStorage.getItem("Prodile_name") == 'undefined' ?
                            <> 
                <Link className="login" to="/login">Log In</Link>
                <Link className="sign-up" to="/register">Sign Up</Link>
                            </>     :
                   <p style={{cursor:'pointer'}} 
                   onClick={()=> Navigate(`/Dashboard` ,{ replace: true })} >{ sessionStorage.getItem("Prodile_name")}</p> 
    }
            </div>
        </div>
    </section>
   )
  }
export default Headers
