import React from "react";
import { CgDollar } from "react-icons/cg";

const UsdtTable=(props)=>
{
    return (
        <tbody>
              <tr> 
        {/* <td>{props.offers}</td> */}
    <td>{props.accept}</td>
    <td>{props.selling_usdt}/{props.amount ? <CgDollar/> :''}{props.amount}</td>
    {/* <td>{props.amount ? <CgDollar/> :''}{props.amount}</td> */}
    {/* <td> <b>{props.rate ? props.rate + '%' : ''}</b></td> */}
    <td><button style={{  cursor: 'pointer'}} onClick={()=>props.CryptoUsdt(props.id)}>Buy({props.rate ? props.rate + '%' : ''})</button> </td>
</tr>
</tbody>
      
        
    )
}
export default UsdtTable