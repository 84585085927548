import React from "react";
import { useSelector } from "react-redux";
import Headerbar from "./Headerbar";
import Sidebar from "./Sidebar/Sidebar";
import axios from "axios";
import copy from 'clipboard-copy';
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useEffect } from "react";

const Security=()=>
{
    const {size}=useSelector((state)=>state.WindowSize)
    const {NavMenu,NavMobileMenu}=useSelector((state)=>state.ToggleNav)
     function Contrast()
     {
    if(NavMenu)
    {
      return {  gap: '4em' }
    }
    else if(size < 767)
    {
     return {  gap: '0em' }
    }
    else {
      return {  gap: '12em' }
    }
     }
      /*======================== 2FA ENABLED ================*/
      const [readyEnable2fa,setreadyEnable2fa]=useState()
      const [Enable2fa,setEnable2fa]=useState()
      const[QRCODE,setQRCODE]=useState()
      function Acivate2FA(e)
      {
        e.preventDefault()
      setEnable2fa(true)
      }
 
      useEffect(()=>{
      async function TwoStepfactor(){
           if(Enable2fa)
       {
           axios.get(`api/admin/setup2FA`)
       .then((response) => {
         // console.log(response.data.QR_Image)
         setQRCODE(response.data)
         setreadyEnable2fa(true)
         setEnable2fa(false)
       })
        }
   }
   TwoStepfactor()
      
     },[Enable2fa])
     const textToCopy = `${QRCODE ? QRCODE.secret : ''}`
     const[copied,setcopied]=useState(false)
      const handleCopy = () => {
     copy(textToCopy);
     setcopied(true)
     //alert('Secret Key copied to clipboard!');
   };
      /*================= END ======================*/
      function conditions()
      {
        if(readyEnable2fa){
            return (
                <>
                <div className="admin-form-col-6">
                <form>
                    <div className="form-group">
                      
                        <div className="form-input">
                            <label htmlFor="">Set up your two factor authentication by scanning the barcode below Or copy the Secret Key to Google authenticator setup.</label>
                            </div>
                        <div className="form-input">
                            <label htmlFor="">
                            { QRCODE ?   <p  className='factor-copy'>Secret Key : {textToCopy}</p>
                      :
                      null 
}

                            </label>
</div>
{copied ?         
                    <button type="submit" className="submit">
                            Copied
                            
                            </button> :
                             <button  onClick={handleCopy} type="submit" className="submit">
                             Copy Secret Key
                             
                             </button>
}

                    
                    </div>
                </form>

            </div>    
              <div className="admin-form-col-6">
              <form>
                                <div className="form-group">
                                    
                                    <div className="form-input">
                                        <label htmlFor="">
                                        {QRCODE && <QRCodeSVG value={QRCODE.QR_Image}  />}
                                        </label>   </div>
              </div>
              </form>
       
        
              </div>
              </>
            )
        }
        else{
            return (
                <div className="admin-form-col-6">
                <form>
                    <div className="form-group">
                      
                        <div className="form-input">
                            <label htmlFor="">You must set up your Google Authenticator app before continuing, and always scan your QRCODE whenever Enable 2FA. You will be unable to login otherwise.</label>
                            </div>
                        <div className="form-input">
                          
</div>      
                    <button type="submit"  onClick={Acivate2FA} className="submit">
                  {Enable2fa ? 'Activating..' :  'Enable 2FA'}
                            
                            </button> 
                    
                    </div>
                </form>

            </div>    
            )
        }
      }
    return (
        <>
         <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>
<div className="admin-wrapper-col-8">
        <div className="admin-topic">TWO FACTOR</div>
        <div className="admin-form-group-add">
            <h4>Security Setting</h4>
          
            <div className="form-container">
                    <div className="form-row">
                    
               { conditions()}
                        
    </div>
    </div>      </div>    </div>
</div>
        </>
    )
}
export default Security