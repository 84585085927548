import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-google-charts';
import { useDispatch, useSelector } from 'react-redux';
import { setchartData, setgraphchart, setlinechart, setpurchasegraphchart } from '../Redux/Admin/Chart';
import { Link } from 'react-router-dom';
import { BsBellFill, BsCurrencyDollar } from 'react-icons/bs';
import { RiBankCard2Fill, RiLogoutBoxRLine, RiRefundFill } from 'react-icons/ri';
import { FiDollarSign } from 'react-icons/fi';
// import Sidebar from './Sidebar';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header';
import Headerbar from './Headerbar';
import { Footer } from './Footer';
import { CgDollar } from 'react-icons/cg';
import { AiFillMoneyCollect, AiFillPropertySafety } from 'react-icons/ai';
import { MdOutlineSell, MdSell } from 'react-icons/md';
import { BiTransfer } from 'react-icons/bi';
import { FaInternetExplorer, FaUsers } from 'react-icons/fa';
import { SiProducthunt } from 'react-icons/si';

const Dashboard = () => {
  const{WithdrawalData,country_chart,dataNotAccount,DepositData,TotalAmount}=useSelector((state)=>state.AdminStore)
  const{linechart,graphchart,purchasegraphchart}=useSelector((state)=>state.AdminChart)
  const dispatch=useDispatch()
  /******************WITHDRAWAL DATA *******/
 //   Pagination
 const [currentPage, setCurrentPage] = useState(1);
 const [productsPerPage] = useState(6);

 // Calculate the index of the first and last product to show on the current page
 const indexOfLastProduct = currentPage * productsPerPage;
 const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

 // Extract the subset of product data to show on the current page
 const currentProducts = WithdrawalData.slice(indexOfFirstProduct, indexOfLastProduct);
 let serialNumber = 1;
 // Generate the Withdrawal components for the current page
 const WithdrawalTable = currentProducts.map((sourceElement,index) => {
  return (
    <tbody key={index}> 
    <tr>
      <td>{serialNumber++}</td>
    <td>{sourceElement.type}</td>
    <td>{sourceElement.amount}</td>
    
    <td><button className={sourceElement.status == 'Success' ? 'admin-success' :"admin-error"}>{sourceElement.status}</button></td>
    
    </tr>
    </tbody> 
    
    );
});



  /************* END **********/
/*============= Deposit ==========*/
let serialNumberdeposit= 1;
  const currentProductsDeposit = DepositData.slice(indexOfFirstProduct, indexOfLastProduct);

 // Generate the Withdrawal components for the current page
 const DepositTable = currentProductsDeposit.map((sourceElement,index) => {
  return (
    <tbody key={index}> 
          <tr>
             <td>{serialNumberdeposit++}</td>
    <td>{sourceElement.type ?? 'USDT'}</td>
    <td>{sourceElement.amountdeposit }</td>
    
    <td><button className={sourceElement.status == 'Success' ? 'admin-success' :"admin-error"}>{sourceElement.status == 'Payment Unsuccessful' ? 'Failed' : sourceElement.status }</button></td>
    
    </tr>
    </tbody> 
    
    );
});



  /************* END **********/
    /****************** Chart Monthly Purchase  ****/
    useEffect(()=>{
        async function Linechart()
        {
          axios.get('api/pie_chart_monthly_purchase')
          .then((res)=>{
           
            dispatch(setpurchasegraphchart(res.data))
          
          })
        }
        Linechart()
      },[])
  /****************** Chart Monthly Deposit  ****/
  useEffect(()=>{
    async function Linechart()
    {
      axios.get('api/pie_chart_monthly')
      .then((res)=>{
       
        dispatch(setlinechart(res.data))
      
      })
    }
    Linechart()
  },[])
  /************ Line chart for GIFTCARD **********/
  useEffect(()=>{
    async function Linechart()
    {
      axios.get('api/pie_chart_monthly_card')
      .then((res)=>{
        dispatch(setgraphchart(res.data))
      
      })
    }
    Linechart()
  },[])
  /************ END **********/
/*************** Location chart ****/
const country_class = country_chart.map((sourceElement,index) => {
  return (
    <div className="country-lists" key={index}>
    <label htmlFor="">{sourceElement.countryName }</label>
    <div className="progress-container">  
      <div id="progressbar" style={{ width:sourceElement.number > 100 ? '100%' : sourceElement.number+'%'}}></div>
    </div>
        </div>

);
});

/*************  END *********/
  const options = {
    backgroundColor: 'transparent',
    chartArea: { width: '100%', height: '1000%' },
    title: "Market Monthly Income(USD)",
    is3D: true,
  };
  const optionsdeposit = {
    backgroundColor: 'transparent',
   title: "Monthly Deposit Income(USD)",
    is3D: true,
  };
  const optiongiftcard = {
    backgroundColor: 'transparent',
   title: "GiftCard Monthly Income(USD)",
    is3D: true,
  };
 
  const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
  return (
   <>
  
   <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>

<div className="admin-data-widget-wrapper">
  <div className="data-widget-grid-box">
    <div className="dash-widget-row">
      <div className="dash-widget bg-pink">
        <h1><AiFillMoneyCollect />Withdrawals</h1>
        <p><FiDollarSign/>{TotalAmount.TotalWithdrawal}</p>
      </div>
      <div className="dash-widget bg-light-yellow">
      <h1><RiRefundFill/>Deposits</h1>
      <p><FiDollarSign/>{TotalAmount.Totaldeposit}</p>
     </div>
      <div className="dash-widget bg-primary">
      <h1><MdSell/>Total Purchase</h1>
      <p><FiDollarSign/>{TotalAmount.TotalPurchasedAmount}</p>
  </div>
      <div className="dash-widget bg-light-red" style={{ color:'black' }}>
      <h1><RiBankCard2Fill/>Total Transaction</h1>
      <p><FiDollarSign/>{TotalAmount.TotalTransaction}</p>
      </div>
      <div className="dash-widget bg-blue">
      <h1><FaInternetExplorer/>Website Visits</h1>
      <p>{TotalAmount.websiteVisit}</p>
   </div>
      <div className="dash-widget bg-orange">
      <h1><FaUsers/>Total Customers</h1>
      <p>{TotalAmount.customers}</p>
      </div>
      <div className="dash-widget bg-grey">
      <h1><AiFillPropertySafety/>Assets</h1>
      <p>{TotalAmount.assets}</p>
      </div>
      <div className="dash-widget bg-teal">
      <h1><SiProducthunt/>Total Products</h1>
      <p>{TotalAmount.TotalProducts}</p>
      </div>
    </div>
  </div>


<div className="chart-div">
<div className="chart-row">
  <div></div>
  <div className="chart-col-l6">
        <div id="frrsthistogram" className="chart-set">
        <Chart
  chartType="LineChart"
  options={optionsdeposit}
  data={[['Language', 'Monthly Deposit Income(USD)'], ...linechart]}
  width="100%"
  height="400px"
/>
        </div>


  </div>
  <div className="chart-col-l6">
  <div id="seecondhistogram" className="chart-set">
  <Chart
  chartType="LineChart"
  options={optiongiftcard}
  data={[['Language', 'Monthly Giftcard Income(USD)'], ...graphchart]}
  width="100%"
  height="400px"
/>
  </div>

</div>
<div></div>
</div>
</div>
<div className="table-grid-row">
<div className="admin-withdraw-col-6">
                <div className="head"> 
                    
                        <h4>Withdrawal</h4>
                    <div className="table-responsive" id="printable-area">
                  {WithdrawalTable.length > 0  ? 
                        <table id="tableToConvert" className="admin-out">
                   <thead> 
                        <tr>
                              
                              <th>S/N </th>
                              <th id="payment">Payment Method</th>
                              <th>Amount (USD)</th>
                              <th>Status</th>
                              
                          </tr>
                          </thead>   
                      {WithdrawalTable}
                               
                        </table>
              : 
              <h3>{dataNotAccount}</h3> }
                        </div>
    </div>

               
            </div>

            <div className="admin-deposit-col-6">
                <div className="head"> 
                    
                        <h4>Deposit</h4>
                    <div className="table-responsive" id="printable-area">
                    {DepositTable.length > 0  ? 
                        <table id="tableToConvert" className="admin-out">
                   <thead>   
                      <tr>
                              
                              <th>S/N </th>
                              <th id="payment">Payment Method</th>
                              <th>Amount</th>
                              <th>Status</th>
                              
                          </tr>
                          </thead>   
                      {DepositTable}
                               
                        </table>
              : 
              <h3>{dataNotAccount}</h3> }
              
                        </div>
    </div>

               
            </div>

  </div>
<div className="growth-grid-row">
    <div className="growth-dash">
      <div className="round-chart">
<div id="chartPie" className="h-300">
<Chart
 chartType="PieChart"
 options={options}
  data={[['Language', 'Market Income(USD)'], ...purchasegraphchart]}
  width="100%"
  height="400px"
/>
<p>Market Monthly Income(USD)</p>
</div>

      </div>
      <div className="customer-growth">
        <div className="head">Customer's Growth</div>
        <div className="growth-body">

          {/* country growth */}

         {country_class}
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</div>
</div>


</>

  );
};

export default Dashboard;
