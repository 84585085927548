import React, { useEffect, useState } from "react";
import Dataquery from "./Dataquery";
import { useDispatch, useSelector } from "react-redux";
import { MdEmail,MdLocationCity,MdLocationOn, MdMarkEmailRead, MdMarkEmailUnread, MdOutlineLocationOn, MdOutlineMarkunreadMailbox } from "react-icons/md";
import { FaInstagramSquare, FaPhone, FaFacebook, FaTwitter,FaAddressBook, FaVoicemail } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import Footer from '../Footer';
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'

const AboutUs = () => {
    
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
   const deliverMessage=(e)=>
   {
  e.preventDefault()
   }
  return (
    <div style={(showChat)&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <Dataquery/>
<DesktopHeader/>
        <MobileHeaders/>
<div className="container">
    
    <div className="contactUs">
        <div className="contactusform">
            <form action="" onSubmit={deliverMessage}>
        <h1>CONTACT US</h1>

        <h3>GET IN TOUCH</h3>
        <p>If you would like to find out more, or have some great ideas you want to explore, we'd like to hear from you.</p>
        <div className="contform-group">
            <input type="text" name="" id="" placeholder="Your Name" className="name" required/>
            <input type="email" name="" id="" placeholder="youremail@gmail.com" className="name" required/>
            <input type="text" name="" id=""  placeholder="Your message" className="msg-to-send" required />
            <button className="send-msg">SEND MESSAGE</button>
            </div>
        </form>
        <div className="social-links">
        <iframe title="map" className="iframe" 
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.8210781294874!2d3.3238217000000003!3d6.544264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8e6c9d8fffff%3A0x807a220b269e3b4!2s7b%20Naduka%20Osadebay%20St%2C%20Ajao%20Estate%2C%20Lagos%20100263%2C%20Lagos!5e0!3m2!1sen!2sng!4v1710252735828!5m2!1sen!2sng`} 
            style={{border:0}} 
         loading="lazy" ></iframe> 
           
           
        </div>
        </div>

 </div>
 </div>
<Footer/>
</div>
  )
}

export default AboutUs;
