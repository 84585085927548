import React, { useEffect } from 'react';
import Balance from './Balance';
import { setInboxMSg, setdataNotFoundInbox, setunreadMsg } from '../Redux/Inboxslices';
import { setFetchCount, setHistory, setWallet,  setallgiftcardCount, setbankCount, setcategory, setcategoryCount, setcountryCount, setdataNotFound, setfetchmessageCount, setgiftcardCount, setnotificationCount, setpayoutCount, setprofileCount, settransactionCount, setunreadCount, setupdateDataCount, setusdtCount, setverifyCount, setwalletCount } from '../Redux/Balanceslice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setAttempt, setallBanks, setcountries, setnotificationbox } from '../Redux/Createslice';
import { Setprofile, setpayoutAddress } from '../Redux/ProfilePageslice';
import { setallusdt, setdataNotfoundgift, setgiftcard, setverified } from '../Redux/Tradeslices';
import { setallgiftcard } from '../Redux/SwapCard';
import { setdataNotAccount } from '../Redux/Admin/AdminStore';

export function TimeOutComponent() {
    const {bankCount,updateDataCount,categoryCount,allgiftcardCount,giftcardCount,usdtCount,verifyCount,notificationCount, fetchCount,walletCount,transactionCount,countryCount,profileCount,payoutCount,unreadCount} = useSelector((state) => state.Balanceslice);
    const dispatch = useDispatch();
         /************************ All Bank   **********/
useEffect(() => {
  if(bankCount === 0){
  // Send request to check page load count
  axios.get('/api/all_Banks')
    .then(res => {
      // dispatch(setdataNotAccount('Table is Currently Empty'))
      dispatch(setallBanks(res.data))
       dispatch(setbankCount(bankCount + 1));
    
    })
    .catch(error => {
      dispatch(setAttempt());
         })
  };
}, []); // Empty dependency array to run only once on component mount

useEffect(() => {
  if (bankCount !== 0) {
    
    // Delay subsequent requests by 1 minute
    const delay = setTimeout(() => {
      // Send request after delay
      axios.get('/api/all_Banks')
        .then(res => {
          dispatch(setallBanks(res.data))
          dispatch(setbankCount(bankCount + 1));
        })
        .catch(error => {
          dispatch(setAttempt());
        });
    }, 60000); // 1 minute in milliseconds

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(delay);
  }
}, [bankCount]);
      /************************ UPDATE WALLET   **********/
useEffect(() => {
  if(updateDataCount === 0){
  // Send request to check page load count
  axios.get('/api/updateData')
    .then(response => {
     dispatch(setupdateDataCount(updateDataCount + 1));
           
    
    })
    .catch(error => {
      // dispatch(setAttempt());
         })
  };
}, []); // Empty dependency array to run only once on component mount

useEffect(() => {
  if (updateDataCount !== 0) {
   
    // Delay subsequent requests by 1 minute
    const delay = setTimeout(() => {
      dispatch(setupdateDataCount(updateDataCount + 1));
      // Send request after delay
      axios.get('/api/updateData')
       
    }, 10000); // 10 secs in milliseconds

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(delay);
  }
}, [updateDataCount]);
/************* END ****/
     /************************ CATEGORY PRODUCT   **********/
useEffect(() => {
    if(categoryCount === 0){
    // Send request to check page load count
    axios.get('/api/Category')
      .then(response => {
        dispatch(setdataNotAccount('Table is Currently Empty'))
        dispatch(setcategory(response.data));
         dispatch(setcategoryCount(categoryCount + 1));
      
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (categoryCount !== 0) {
      
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/Category')
          .then(response => {
            dispatch(setdataNotAccount('Table is Currently Empty'))
            dispatch(setcategory(response.data));
            dispatch(setcategoryCount(categoryCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [categoryCount]);
/************* END ****/
     /************************ All Giftcard   **********/
useEffect(() => {
    if(allgiftcardCount === 0){
    // Send request to check page load count
    axios.get('/api/AllGiftCard')
      .then(response => {
        dispatch(setallgiftcard(response.data));
        dispatch(setallgiftcardCount(allgiftcardCount + 1));
      
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (allgiftcardCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/AllGiftCard')
          .then(response => {
            dispatch(setallgiftcard(response.data));
            dispatch(setallgiftcardCount(allgiftcardCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [allgiftcardCount]);
/************* END ****/
    /************************ GITCARD   **********/
useEffect(() => {
    if(giftcardCount === 0){
    // Send request to check page load count
    axios.get('/api/viewGiftcard')
      .then(response => {
        dispatch(setgiftcard(response.data));
                    dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
     dispatch(setgiftcardCount(giftcardCount + 1));
      
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (giftcardCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/viewGiftcard')
          .then(response => {
            dispatch(setgiftcard(response.data));
            dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
            dispatch(setgiftcardCount(giftcardCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [giftcardCount]);
/************* END ****/
     /************************ USDT   **********/
useEffect(() => {
    if(usdtCount === 0){
    // Send request to check page load count
    axios.get('/api/viewUsdt')
      .then(response => {
        dispatch(setallusdt(response.data));
        dispatch(setdataNotFound('0ffer Table is Currently Empty'))
     dispatch(setusdtCount(usdtCount + 1));
      
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (usdtCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/viewUsdt')
          .then(response => {
            dispatch(setallusdt(response.data));
            dispatch(setdataNotFound('0ffer Table is Currently Empty'))
            dispatch(setusdtCount(usdtCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [usdtCount]);
/************* END ****/
     /************************ VERIFY  USER  **********/
useEffect(() => {
    if(verifyCount === 0){
    // Send request to check page load count
    axios.get('/api/verify_user')
      .then(response => {
        dispatch(setverified(response.data))
      dispatch(setverifyCount(verifyCount + 1));
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (verifyCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/verify_user')
          .then(response => {
            dispatch(setverified(response.data))
            dispatch(setverifyCount(verifyCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [verifyCount]);
/************* END ****/
    /************************ Notification Button  **********/
useEffect(() => {
    if(notificationCount === 0){
    // Send request to check page load count
    axios.get('/api/notificationswitch')
      .then(response => {
        dispatch(setnotificationbox(response.data));
        dispatch(setnotificationCount(notificationCount + 1));
      
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (notificationCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/notificationswitch')
          .then(response => {
            dispatch(setnotificationbox(response.data));
            dispatch(setnotificationCount(notificationCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [notificationCount]);
/************* END ****/
  /**************** MESSAGES *********/
    useEffect(() => {
        if(fetchCount === 0){
        // Send request to check page load count
        axios.get('/api/sms_messages')
          .then(response => {
            dispatch(setInboxMSg(response.data));
          dispatch(setfetchmessageCount(fetchCount + 1));
            dispatch(setdataNotFoundInbox('Inbox Empty'));
          
          })
          .catch(error => {
            dispatch(setAttempt());
          })
        };
      }, []); // Empty dependency array to run only once on component mount
  
      useEffect(() => {
        if (fetchCount !== 0) {
          // Delay subsequent requests by 1 minute
          const delay = setTimeout(() => {
            // Send request after delay
            axios.get('/api/sms_messages')
              .then(response => {
                dispatch(setInboxMSg(response.data));
            dispatch(setdataNotFoundInbox('Inbox Empty'));
            dispatch(setfetchmessageCount(fetchCount + 1));
          
              })
              .catch(error => {
                dispatch(setAttempt());
              });
          }, 10000); // 1 minute in milliseconds
    
          // Clean up the timeout to prevent memory leaks
          return () => clearTimeout(delay);
        }
      }, [fetchCount]);
  /*************** END */
     /************************ WALLET  **********/
     useEffect(() => {
         if(walletCount === 0){
         // Send request to check page load count
         axios.get('/api/Wallets_Balance')
           .then(response => {
             dispatch(setWallet(response.data.data));
           dispatch(setwalletCount(walletCount + 1));
           
           })
           .catch(error => {
            dispatch(setAttempt());
                })
         };
       }, []); // Empty dependency array to run only once on component mount
     
       useEffect(() => {
         if (walletCount !== 0) {
           // Delay subsequent requests by 1 minute
           const delay = setTimeout(() => {
             // Send request after delay
             axios.get('/api/Wallets_Balance')
               .then(response => {
                dispatch(setWallet(response.data.data));
                dispatch(setwalletCount(walletCount + 1));
               })
               .catch(error => {
                dispatch(setAttempt());
               });
           }, 10000); // 10 sec in milliseconds
     
           // Clean up the timeout to prevent memory leaks
           return () => clearTimeout(delay);
         }
       }, [walletCount]);
  /************* END ****/
   /************************ PROFILE DETAILS  **********/
   useEffect(() => {
    if(profileCount === 0){
    // Send request to check page load count
    axios.get('/api/profile')
      .then(response => {
        dispatch(Setprofile(response.data.users));
      dispatch(setprofileCount(profileCount + 1));
      
      })
      .catch(error => {
       dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (profileCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/profile')
          .then(response => {
            dispatch(Setprofile(response.data.users));
            dispatch(setprofileCount(profileCount + 1));
          })
          .catch(error => {
           dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [profileCount]);
/************* END ****/
 /************************ PAYOUT SETTING  **********/
 useEffect(() => {
    if(payoutCount === 0){
    // Send request to check page load count
    axios.get('/api/PayoutSetting')
      .then(response => {
        dispatch(setpayoutAddress(response.data));
      dispatch(setpayoutCount(payoutCount + 1));
      })
      .catch(error => {
       dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (payoutCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/PayoutSetting')
          .then(response => {
            dispatch(setpayoutAddress(response.data));
            dispatch(setpayoutCount(payoutCount + 1));
          })
          .catch(error => {
           dispatch(setAttempt());
          });
      }, 360000); // 6 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [payoutCount]);
/************* END ****/
/************************ UNREAD MESSAGE  **********/
useEffect(() => {
    if(unreadCount === 0){
    // Send request to check page load count
    axios.get('/api/Unread_MSG')
      .then(response => {
        dispatch(setunreadMsg(response.data));
      dispatch(setunreadCount(unreadCount + 1));
      })
      .catch(error => {
        dispatch(setAttempt(error.response?.data?.message));
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (unreadCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/Unread_MSG')
          .then(response => {
            dispatch(setunreadMsg(response.data));
            dispatch(setunreadCount(unreadCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 10000); // 10 sec in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [unreadCount]);
/************* END ****/
/************************ Location Country   **********/
useEffect(() => {
    if(countryCount === 0){
    // Send request to check page load count
    axios.get('/api/location_Country')
      .then(response => {
        dispatch(setcountries(response.data));
      dispatch(setcountryCount(countryCount + 1));
      })
      .catch(error => {
        dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (countryCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/location_Country')
          .then(response => {
            dispatch(setcountries(response.data));
            dispatch(setcountryCount(countryCount + 1));
          })
          .catch(error => {
            dispatch(setAttempt());
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [countryCount]);
/************* END ****/
 /************************ Transaction  **********/
 useEffect(() => {
    if(transactionCount === 0){
    // Send request to check page load count
    axios.get('/api/Transaction')
      .then(response => {
        dispatch(setHistory(response.data));
        if (response.data.length === 0) {
            dispatch(setdataNotFound('You are yet to make any transactions'));
        }
      dispatch(settransactionCount(transactionCount + 1));
      })
      .catch(error => {
       dispatch(setAttempt());
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (transactionCount !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/Transaction')
          .then(response => {
            dispatch(setHistory(response.data));
        if (response.data.length === 0) {
            dispatch(setdataNotFound('You are yet to make any transactions'));
        }
        dispatch(settransactionCount(transactionCount + 1))
          })
          .catch(error => {
           dispatch(setAttempt());
          });
      }, 20000); // 20 sec in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [transactionCount]);
/************* END ****/
}