import React, { useEffect, useState } from "react";
import Dataquery from "./Dataquery";
import { useDispatch, useSelector } from "react-redux";
import visionimage from '../../img/vision.jpg'
import missionimage from '../../img/mission2.png'
import cryptoimage from '../../img/crypto.jpg'
import revolutionimage from '../../img/revolution.jpg'
import trust from '../../img/trust.jpg'
import footerimage from '../../img/squreinbox.png'
import revolution2 from '../../img/revolution_2.jpg'
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'
import Footer from '../Footer';

const AboutUs = () => {
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
   
  return (
    <div style={(showChat)&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <Dataquery/>
<DesktopHeader/>
        <MobileHeaders/>
<div className="container">
    
    <div className="aboutUs">
    <div className="aboutheader">
        <h1>About us</h1>
      <center> <hr /></center>
        <p>Welcome to SquareMart, a 
            pioneering e-commerce platform 
            designed for the modern shopper and
             savvy seller. At SquareMart, 
             we're not just another online
              marketplace; we're revolutionaries at the forefront of digital commerce. Our platform is uniquely tailored to embrace the dynamism and security of cryptocurrency, ensuring a seamless, secure, and futuristic shopping experience.</p>
              </div>
    <div className="vision">
        <h1>Our Vision</h1>
        <center> <hr /></center>
        <div className="vison-flex">
        <img src={visionimage} alt="" />
        <p>SquareMart was born from a vision to integrate the flexibility and global reach of cryptocurrencies with the everyday shopping experience. Recognising the potential of blockchain technology in transforming e-commerce, we've developed a platform where convenience meets security.</p>
        </div>
    </div>

    <div className="mission">
        <h1>Our Mission</h1>
        <center> <hr /></center>
        <div className="mission-flex">
        <p>
        Our mission is to provide a robust online marketplace that not only facilitates easy access to a wide range of products but also ensures peace of mind for both buyers and sellers.
        </p>
       
        {/* <img src={missionimage} alt="" /> */}
        </div>
    </div>
    <div className="vision">
        <h4>Cryptocurrency-Powered Commerce</h4>
        <center> <hr /></center>
        <div className="vison-flex">
        <img src={cryptoimage} alt="" />
        <p>
        In a world where digital transactions are rapidly becoming the norm, SquareMart stands out by exclusively using cryptocurrency for all transactions. This approach not only caters to the tech-savvy and crypto enthusiasts but also introduces a new level of efficiency and global accessibility in e-commerce. By leveraging the power of blockchain, we offer a transparent, fast, and secure payment system that transcends traditional barriers.
        </p>
        </div>
    </div>

    <div className="revolution">
        <h4>Revolutionary Escrow System</h4>
        <center> <hr /></center>
        <div className="revolution-flex">
        <p>
        Trust is the cornerstone of any transaction, and at SquareMart, we take this seriously. Our innovative escrow service is a testament to our commitment to secure and fair trading. When a purchase is made, the payment is held in an escrow account and is only released to the seller when the buyer confirms the product's quality and accuracy. This system not only protects buyers from fraudulent transactions but also incentivizes sellers to maintain high standards.
        </p>
       
        {/* <img src={revolutionimage} alt="" className="revimage"/> */}
        </div>
    </div> 
    <div className="vision">
        <h4>A Community of Trust</h4>
        <center> <hr /></center>
        <div className="vision-flex">
        {/* <img src={trust} alt="" /> */}
        <p>
            SquareMart is more than just a platform; it's a community. We believe in fostering a safe and supportive environment for our users. Our customer support team is dedicated to ensuring a smooth and satisfactory experience for both buyers and sellers. We actively listen to our community, continuously evolving our platform to meet and exceed their expectations.
        </p>
        </div>
    </div>

    <div className="mission">
        <h4>Join the Revolution</h4>
        <center> <hr /></center>
        <div className="rev-flex">
        <p>
       At SquareMart, we're not just embracing the future; we're creating it. Our platform is a call to those who envision a world where e-commerce is secure, efficient, and universally accessible. Whether you're a buyer seeking quality products or a seller aiming to reach a global audience, SquareMart is your gateway to a new era of online shopping.
        </p>
      
        <img src={revolution2} alt="" />
        </div>
    </div>
    <h2 className="bottomtext">
        Join us in redefining the e-commerce experience.<br/> Welcome to SquareMart
        
    </h2>
    <h6>
  
        – where the future of shopping begins.
 
    </h6>
    <center>
        <img src={footerimage} alt="" className="centerabt" />
    </center>
    </div>

</div>
<Footer/>
</div>
  )
}

export default AboutUs;
