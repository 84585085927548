import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound=()=>
{
    const Navigate = useNavigate()
    useEffect(()=>{
       
    Navigate(`/` ,{ replace: true });
      
    },[])
return (
    <></>
//  <p style={{ backgroundColor:'red' }}>Not Found</p> 
)
}
export default NotFound