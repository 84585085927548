import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  TotalAmount:'',
  WithdrawalData:[],
  dataNotAccount:'',
  DepositData:[],
  message:'',
  Category:[],
  AvailableGiftCard:[],
  errorCode:'',
  Inventory:[],
  country_chart:[],
  DocumentsData:[],
  ExchangeTrade:[]
}

export const AdminStore = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setdataNotAccount:(state, action) => {
        state.dataNotAccount  = action.payload
      },
      setcountry_chart:(state, action) => {
        state.country_chart  = action.payload
      },
      setExchangeTrade:(state, action) => {
        state.ExchangeTrade  = action.payload
      },
      setInventory:(state, action) => {
        state.Inventory  = action.payload
      },
    setTotalAmount:(state, action) => {
      state.TotalAmount  = action.payload
    },
    setWithdrawalData:(state, action) => {
        state.WithdrawalData  = action.payload
      },
      setDepositData:(state, action) => {
        state.DepositData  = action.payload
      },
      setmessage:(state, action) => {
        state.message  = action.payload
      },
      seterrorCode:(state, action) => {
        state.errorCode  = action.payload
      },
      setDocumentsData:(state, action) => {
        state.DocumentsData  = action.payload
      },
      setCategory:(state, action) => {
        state.Category  = action.payload
      },
      setAvailableGiftCard:(state, action) => {
        state.AvailableGiftCard  = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {setAvailableGiftCard,setCategory,setDocumentsData,setInventory,setcountry_chart,seterrorCode,setmessage,setExchangeTrade, setdataNotAccount,setDepositData,setWithdrawalData,setTotalAmount} = AdminStore.actions

export default AdminStore.reducer