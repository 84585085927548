import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import {FaExchangeAlt} from "react-icons/fa";
import { BiRefresh } from 'react-icons/bi';
import loadimg from '../../img/bx_loader-circle.png'
import { useSelector } from "react-redux";

const Giftsidebar=(props)=>
{
   /************** ALL GIFTCARD ******/
   
const{allgiftcard}=useSelector((state)=>state.SwapCard)


   const cardAvalaible =allgiftcard && allgiftcard.map((element,index) => {
       return (

           <option key={index} value={element.cards}>{element.cards}</option>

       )
   })
   /********** END ************/
const style={
    cursor:'pointer'
}
    return (
        <form action=""  onSubmit={props.HandlerGIFTSubmit}>
        <div className="form-name">
            <div className="buy">
        <label htmlFor="Buy">Buy</label> <br/>
        <select  onChange={props.offeringgiftcard}  name="selling_card" id="buy">
            <option value="">Buying Card</option>
{cardAvalaible}
        </select>
    </div>
    <div className="exchnageicon">
        {/* <p>wow</p> */}
      
        <span><FaExchangeAlt/></span> 

    </div>
    <div className="swap">
        <label htmlFor="swap">swap with</label> <br/>
        <select  onChange={ props.offeringgiftcard} name="accept_payment" id="swap">
        <option>Select Payment</option>
        <option value="USDT">USDT</option>
            <option value="ETH">ETH</option>
            <option value="BTC">BTC</option>
        </select>
    </div>
        </div>

    
    <div className="form-name">
        <label htmlFor="Buy">Amount?</label> <br/>
        <div className="amount">
            <input type="tel"  onChange={props.offeringgiftcard}  name="amount"
             id="" placeholder="Enter amount"/>
            <p className="usd">USD</p>
        </div>
       
    </div>
    <div className="form-name" id="offers">
        {/* <input type="submit" className={props.spinning ? 'spinning' : ''} style={{ cursor:'pointer' }}
         placeholder="Offers" value="Find Offers"/> */}
       <center> <button  style={{ cursor:'pointer' }}>
        {props.spinning ? <img src={loadimg} className="spin-icon" /> :'Find Offers'}
       </button></center>
    </div>
    <div className="adds">
        {/* <div className="add-btn"> */}
                {/* <AiOutlinePlus/> */}
        {/* </div> */}
        {/* <div className="segments">
            <Link to='/CreateGiftcardad'>Create Offer To Sell Gift Card</Link>
            <Link to='/CreateUsdtad'>Create Offer To Sell USDT </Link>
        </div> */}
    </div>
    </form>
    )
}
export default Giftsidebar;