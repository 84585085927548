import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Gifttradevalidate=()=>
{
    const{giftcard_buy_id}=useSelector((state)=>state.SwapCard)
    const Navigate=useNavigate()
    useEffect(()=>{
        if(!(giftcard_buy_id))
        {
             Navigate("/trade" ,{ replace: true });
     
        }
     })
    return (
<></>
    )
}
export default Gifttradevalidate