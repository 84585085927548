import React from 'react'
import dropdown from '../../img/dropdown.png'

const Category = (props) => {
    const btn = {
        fontSize: '18px',
         textTransform:'capitalize',
        pointer:'cursor',
        border: '2px solid white',
        backgroundColor: 'white', 
        borderColor: 'white'
    }
    return (
        <div className="links">
            <div className="icon-before">
                <img src={props.image} alt="" />
            </div>
            <button style={btn} onClick={props.HandlerCategory}> <p>{props.name}</p></button>
            <div className="icon-after">
                <img src={dropdown} alt='' />
            </div>
        </div>
    )
}
export default Category