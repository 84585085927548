import React from "react";
import { FaDollarSign, FaEnvelopeOpenText } from "react-icons/fa";
import { CgDollar } from "react-icons/cg";
const Transaction=(props)=>
{
    return (

        <tr>
      
        <td id="bold"> <b>{props.activity}</b> </td>
        <td>{props.status}</td>
        <td>{props.type} </td>
        <td>{props.time}</td>
        <td id="bold">{props.amount ? <CgDollar/>:''}{props.amount}</td>
    </tr>
    )
}
export default Transaction