import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  theme: "light",
}

export const MoodlightSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme : (state,action) => {
        state.theme = action.payload;

    },
   
  },
})

// Action creators are generated for each case reducer function
export const { changeTheme   } = MoodlightSlice.actions

export default MoodlightSlice.reducer