import React, { useEffect, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import { FaLessThan } from "react-icons/fa";
import { AiOutlineRollback } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setfetchgiftcard } from "../../Redux/SwapCard";
import { CgNametag } from "react-icons/cg";
import Gifttradevalidate from "./Gifttradevalidate";
import { FaLessThan } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";

const BuyGiftcard=()=>
{
      const Navigate = useNavigate()
    const{fetchgiftcard,giftcard_buy_id}=useSelector((state)=>state.SwapCard)
 const dispatch=useDispatch()
    /************* USDT ID *********/
  
 useEffect(() => {
        async function Selectgiftcard() {

            axios
                .get(`api/fetchGiftcardId/${giftcard_buy_id}`)
                .then((response) => {
                    dispatch(setfetchgiftcard( {
                                ...fetchgiftcard,
                                selling_giftcard:response.data.selling_giftcard,
                                giftcard_amount:response.data.amount,
                                 accept_payment:response.data.accept_payment,
                    }));
                    
                      
                })

        }
        Selectgiftcard()
    }, [giftcard_buy_id])
     /******************* AMOUNT OF USDT TO BUY  ******/

function Changeamount(event)
{
    const { name, value, type, checked } = event.target;
   
    if (name == 'giftcard_amount' && (!(/^\d+$/.test(value))  || value <= 0 )) {
        dispatch(setfetchgiftcard( {
            ...fetchgiftcard, 
                giftcard_amount: null
             }));
      }
      else{
        dispatch(setfetchgiftcard( {
            ...fetchgiftcard,
            [name]: type === "checkbox" ? checked : value,
        }
       ))
}   
}
  
  
   /****************** END *********/
  /************** END ***********/
 
 function Systemexchange(e)
    {
        e.preventDefault()
       // localStorage.setItem('Purchase_giftcard', JSON.stringify(fetchgiftcard)) 
         Navigate(`/TradGiftcard` ,{ replace: true });
    }
 return (
    <>
    <Auth/>
    <Gifttradevalidate/>
    <div className="container-fluid">
        <Sidebar />
        <header>
            <Header />
        </header>
        <hr />
        <div className="buy-giftcard">
        <div className="head-back">
        <h1> <Link to='/trade'> <IoIosArrowBack/> </Link> <span>select how you want to buy Giftcard</span></h1>
        

    </div> 
                  <div className="form-class">
                   <div className="head">
                   <h2>You are buying
       {fetchgiftcard.selling_giftcard}</h2>
      
                   </div>
                    <form action="" onSubmit={Systemexchange}>
                        <div className="form-name">
                        <div className="amount">

                            <label htmlFor="">Amount</label><br/>
                            <div className="form-input">
                            <input type="tel" 
                            onChange={Changeamount}
                            name="giftcard_amount"
                        value={fetchgiftcard.giftcard_amount || ''}
                            placeholder="100"/>
                            <select className="" id="">
                                <option value="USD">USD</option>
                                {/* <option value="USD">giftcard</option> */}
                            </select>
                        </div>
                        </div>
                        <div className="form-input swap">
                            <label htmlFor="">Swap with</label>
                            <br/>
                            <input type="text" 
                              value={fetchgiftcard.accept_payment || ''}
                              style={{cursor:'not-allowed'}} readOnly
                            placeholder="GiftCard"/>
                            
                        </div>
                    </div>
                        <div className="btn">
                        <button style={fetchgiftcard.giftcard_amount   ? 
                        {  cursor: 'pointer' } :
                        {  cursor: 'not-allowed' }
                } 
                disabled={fetchgiftcard.giftcard_amount ? 
                   '':
                    'disabled'
            }    type="submit"><span>Continue</span></button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
</>
 )
}
export default BuyGiftcard