import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setpayoutAddress } from "../Redux/ProfilePageslice";
import MovingText from 'react-moving-text'
import { setallBanks, setmessage } from "../Redux/Createslice";
import { useQuery } from "react-query";

const Payout =()=>
{
      
    const profile = useSelector((state) => state.ProfilePageslice.profile);
 const {payoutAddress}=useSelector((state) =>state.ProfilePageslice)
    const userId= `${profile.id}`
    // const[payoutAddress,setpayoutAddress]=useState({})
const[ btcload,setbtcload]=useState(false)
const[ ethload,setethload]=useState(false)
const[ usdtload,setusdtload]=useState(false)
const[ ngnload,setngnload]=useState(false)
const {message,msg}=useSelector((state)=>state.Createslice)

    /******************* SET ADDRESS ***********/
    const[LoadaccountName,setLoadaccountName]=useState(false)
    const dispatch=useDispatch()
/*============= RETRIEVE USER ACCOUNT NAME =====*/
useEffect(()=>{
    async function fetchAccountName()
    {
        if(LoadaccountName){
        axios.get(`api/retrieve_user_account_name/${payoutAddress.account_number}/${payoutAddress.bank_code}`)
        .then((res)=>{
            dispatch(
                setpayoutAddress({
                  ...payoutAddress, 
                  bank_name: res.data.bank_name,
                  account_name: res.data.account_name,
                })
              );
            
            setLoadaccountName(false)
             })   
    }
    }
    fetchAccountName();
},[LoadaccountName, payoutAddress.account_number, payoutAddress.bank_code]);

/*========== END =================*/
function ChangeAddress(event)
{
    const{name,value}=event.target

    //update payout address with user ID 
    dispatch(
      setpayoutAddress({
        ...payoutAddress,
        user_id: userId,
        [name]: value,
      })
    );
    if( name === 'bank_code')
        {
            dispatch(setpayoutAddress({
                ...payoutAddress,
                account_number:'' ,
                account_name:'',
                [name]: value,
              })
            );
        }
    // const isValidAccount = 
    //     name === 'account_number' && value.length === 10 && payoutAddress.bank_code ||
    //     name === 'bank_code' && payoutAddress.account_number && payoutAddress.account_number.length === 10;

    // setLoadaccountName(isValidAccount)
    
      setbtcload(false)
      setusdtload(false)
      setngnload(false)
      setethload(false)
      
    }
    
function HandlerBTC(e){
    e.preventDefault()
    setbtcload(true)
   
  
}
function HandlerETH(e){
    e.preventDefault()
    setethload(true)
   
}
function HandlerNGN(e){
    e.preventDefault()
    setngnload(true)
    if(!payoutAddress.account_name || payoutAddress.account_name == 'No Account Retrieved')
    {
        setngnload(false)
        dispatch(
            setpayoutAddress({
              ...payoutAddress,
              account_name: '',
              account_number: '',
            })
          );
    }
   
}
function HandlerUSDT(e){
    e.preventDefault()
    setusdtload(true)
   
}
const[Errored,setErrored]=useState(false)
useEffect(()=>{
    async function PayoutSetting()
    {
        if(btcload || ethload || usdtload || ngnload) 
        {
            axios.post(`api/payoutAddress`, payoutAddress)
            .then((res)=>{
           dispatch(setmessage(res.data))
            setbtcload(false)
            setErrored(false)
            setusdtload(false)
            setngnload(false)
            setethload(false)
            })
            .catch(error => {
                setErrored(true)
                // dispatch(seterrorCode(error.response.status))
                dispatch(setmessage('Server Error OR Invalid Bank Details'))
                setbtcload(false)
                setusdtload(false)
                setngnload(false)
                setethload(false)
            });
                }
        }

    PayoutSetting()
},[btcload,ethload,usdtload,ngnload])

/************** END ***********/
// Handle blur event for account number field
function handleBlur(event) {
    const { name, value } = event.target;
    
    if (name === 'account_number' && value.length <= 10 && value.length > 0 && (payoutAddress.bank_name || payoutAddress.bank_code)) {
        setLoadaccountName(true);
       
    }
}

const {allBanks}=useSelector((state)=>state.Createslice)
   
    const banks= allBanks && allBanks.map((element,index)=>{
        return (
            <>
                <option key={index} value={element.bank_code}>
                    {element.bank_name}
                </option>

            </>

        );
    })
  const  styled={
        pointerEvents: 'none',
         opacity:'0.5'
    }
  
      return (
        <>
         {/* ERROR MSG */}
         
      {   message ? 
        <div className={Errored ? 'error-field':"sucessmsg"}>
        
        <MovingText
                        type="slideInFromRight"
                        duration='350ms'
                        delay='0s'
                        direction='alternate'
                          iteration='1'
                        fillMode='none'>
                              <div className={Errored ? "error" : 'succMsg'}>
                                    {message}
                                
                        </div> </MovingText>
                        
                        </div>
                        : null
                        }
        <div className="payout">
    {/* <h1>Payout Setting</h1> */}
    <div className="payout-options">
        <div className="option-address">
            <form onSubmit={HandlerBTC} method="post">
                <label htmlFor="btc">BTC</label>
                    <input type="text" name="btc_address" 
                    onChange={ChangeAddress}
                    required
                  value={payoutAddress.btc_address || ''}
                    placeholder="Bitcoin Address" id="btc"/>
                <button 
                style={btcload ? styled : {cursor:'pointer'}} type="submit"> <span>{btcload ? 'Updating..' : 'Update'}</span></button>
            </form>
        </div>
        <div className="option-address">
            <form onSubmit={HandlerUSDT} method="post">
                <label htmlFor="usdt">USDT</label>
                    <input type="text" name="usdt_address"
                     onChange={ChangeAddress} required
                     value={payoutAddress.usdt_address || ''}
                    placeholder="USDT Address" id="usdt"
                    />
                <button   style={ usdtload  ? styled : {cursor:'pointer'}} type="submit"> <span>{usdtload ? 'Updating..' : 'Update'}</span></button>
            </form>
        </div>
        <div className="option-address">
            <form onSubmit={HandlerNGN} method="post">
                <label htmlFor="ngn">NGN</label>
                    <select className="form-control" 
                     defaultValue=''
                    required 
                    // onBlur={handleBlur}
                    onChange={ChangeAddress} 
                    name="bank_code" id="ngn">
                        { payoutAddress.bank_name ? 
                    <option value={payoutAddress.bank_name}>{payoutAddress.bank_name}</option>    
                  :
                  <option value=" " disabled="disabled">&larr; Select Bank &rarr;</option>
                      
                  }
                       
                       {banks.length > 0 ? (
                                                banks
                                            ) : (
                                                <option value=''>Not Available</option>
                                            )}
                    </select>
                    
                    <input type="tel" 
                      maxLength={10}
                      onBlur={handleBlur}
                      value={payoutAddress.account_number || ''}
                        onChange={ChangeAddress} required
                         name="account_number" placeholder="Account number" className="form-control" id="acc-number"/>
             
                <p>{payoutAddress.account_name ? payoutAddress.account_name : '...'}</p>
         {/* {payoutAddress.account_number && payoutAddress.account_number.length > 9  ?   */}
            <button 
            style={(payoutAddress.account_name === '' || payoutAddress.account_name === 'No Account Retrieved') || ngnload ? styled : {cursor:'pointer'}} type="submit"> <span>{ngnload ? 'Updating..' : 'Update'}</span>
                   </button>
{/* :  <button disabled type="submit"> <span>Update</span></button> */} 
                  {/* } */}
            </form>
        </div>
        <div className="option-address">
            <form onSubmit={HandlerETH} method="post">
                <label htmlFor="btc-add">ETH</label>
                    <input type="text" required name="eth_address"
                       value={payoutAddress.eth_address || ''}
                     onChange={ChangeAddress} placeholder="Ethereum Address" id="btc-add"/>
                <button  style={ ethload ? styled : {cursor:'pointer'}} type="submit"> <span>{ethload ? 'Updating..' : 'Update'}</span></button>
            </form>
        </div>
    </div>
</div>

        </>
      )
}
export default Payout
