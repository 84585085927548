import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    linechart:[],
    processTrigger:false,
    processing:false,
    invoiceNumber:'',
    giftcardinvoiceNumber:'SQE23497366MAT',
    giftcardprocessTrigger:false,
    cardprocessing:false,
    cardlockbutton:false,
    tradesucces:false,
    giftcardmsg:'',
    swapDetails:'',
    swapdetailreview:'',
    usdtmsg:'',
    graphchart:'',
    purchasegraphchart:'',
}

export const Chart = createSlice({
  name: 'counter',
  initialState,
  reducers: {
   
    setcardlockbutton:(state, action) => {
      state.cardlockbutton = action.payload
    },
    setprocessTrigger:(state, action) => {
      state.processTrigger = action.payload
    },
    setprocessing:(state, action) => {
      state.processing = action.payload
    },
    setInvoiceNumber:(state, action) => {
      state.invoiceNumber = action.payload
    },
    setgiftcardprocessing:(state, action) => {
      state.cardprocessing = action.payload
    },
    setgiftcardinvoiceNumber:(state, action) => {
      state.giftcardinvoiceNumber = action.payload
    },
    setgiftcardprocessTrigger:(state, action) => {
      state.giftcardprocessTrigger = action.payload
    },
    setSwapdetail:(state, action) => {
      state.swapDetails = action.payload
    },
    settradesucces:(state, action) => {
      state.tradesucces = action.payload
    },
    setswapdetailreview:(state, action) => {
      state.swapdetailreview = action.payload
    },
    setgiftcardmsg:(state, action) => {
      state.giftcardmsg = action.payload
    },
    setusdtmsg:(state, action) => {
      state.usdtmsg = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setusdtmsg,setgiftcardmsg,setswapdetailreview,settradesucces,setSwapdetail,setcardlockbutton,setgiftcardprocessing,setgiftcardprocessTrigger,setgiftcardinvoiceNumber,setInvoiceNumber,setprocessTrigger,setprocessing } = Chart.actions

export default Chart.reducer