import React, { useEffect, useState } from "react";
import Dataquery from "./Dataquery";
import { useDispatch, useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { FaMinus } from "react-icons/fa";
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'
import Footer from '../Footer';

const AboutUs = () => {
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
   const  [faqmodal, Setfaqmodal] = useState(null);
    const [requesty, setRequesty] = useState(false);

      function faqopenmodal(action) {
    if (faqmodal === action) {
      Setfaqmodal(null);
      setRequesty(false);
    } else {
      Setfaqmodal(action);
      setRequesty(true);
    }
  }


//     function faqopenmodal(action){
//     Setfaqmodal(action)
//     // console.log(action)
//    }
  return (
    <div style={(showChat)&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <Dataquery/>
 <DesktopHeader/>
<MobileHeaders/>
<div className="container">
    
    <div className="faq">
            <div className="faqheader">
                <h1>FREQUENTLY ASKED QUESTION</h1>
            </div>
        <div className="faqdrop">
            <div className="question" >
                <h3>How do i fund my account?</h3>
                { faqmodal === 1  && requesty ? <FaMinus onClick={()=>faqopenmodal(1)}/> :
                <BiPlus onClick={()=>faqopenmodal(1)}/> }
            </div>
            { faqmodal === 1  && requesty ?
            <div className="answer">
                <p>To fund your account, simply create an account with us. Once your account is verified, you can seamlessly fund it through your dashboard by selecting the currency you'll like to fund.</p>

            </div> : null}
        </div>
        <div className="faqdrop">
            <div className="question">
                <h3>Do i need to verify my account before using the app ?
</h3>
                  { faqmodal === 2  && requesty ? <FaMinus onClick={()=>faqopenmodal(2)}/> :
                <BiPlus onClick={()=>faqopenmodal(2)} /> }
            </div>
            { faqmodal === 2  && requesty ?
            <div className="answer">
                <p>Yes, account verification is necessary before using the app. Once your account is verified, you can fully access and utilize all the features of the app. </p>

            </div> : null}
        </div>
        <div className="faqdrop">
            <div className="question">
                <h3>How long do i have to wait for trade to complete?</h3>
                  { faqmodal === 3  && requesty ? <FaMinus onClick={()=>faqopenmodal(3)}/> :
                <BiPlus onClick={()=>faqopenmodal(3)} /> }
            </div>
            { faqmodal === 3  && requesty ? 

            <div className="answer">
                <p>The duration for a trade to complete can vary depending on factors such as market conditions, liquidity, and the type of assets being traded. Generally, trades are processed relatively quickly, but it's advisable to check the specific terms and conditions of the trade.</p>

            </div>  :null }
        </div>
        <div className="faqdrop">
            <div className="question">
                <h3>How does escrow protect my fund during trade? </h3>
                  { faqmodal === 4  && requesty ? <FaMinus onClick={()=>faqopenmodal(4)}/> :
                <BiPlus onClick={()=>faqopenmodal(4)}/> }
            </div>
            { faqmodal === 4  && requesty ? 

            <div className="answer">
                <p>Squaremart protects your funds during a trade by acting as a neutral third party that holds the funds until the transaction conditions are met. In a trade, once both parties agree to the terms, the funds are deposited into the escrow account. The escrow service ensures that neither party can access the funds until all the terms of the trade are fulfilled according to the agreed-upon conditions. This helps prevent fraud, disputes, and ensures a secure transaction process for both parties involved in the trade. Once the conditions are met, the funds held in escrow are released accordingly.</p>


            </div> : null }
        </div>


         <div className="faqdrop">
            <div className="question">
                <h3>Can i buy cryptocurrency on this app?</h3>
                  { faqmodal === 5  && requesty ? <FaMinus onClick={()=>faqopenmodal(5)}/> :
                <BiPlus onClick={()=>faqopenmodal(5)} /> }
            </div>
            { faqmodal === 5  && requesty ? 

            <div className="answer">
                <p>Certainly, Squaremart provides trading and swapping features, ensuring seamless and smooth activities for our users.</p>

            </div>  :null }
        </div>

         <div className="faqdrop">
            <div className="question">
                <h3>How do i start selling on squaremart ?</h3>
                  { faqmodal === 6  && requesty ? <FaMinus onClick={()=>faqopenmodal(6)}/> :
                <BiPlus onClick={()=>faqopenmodal(6)} /> }
            </div>
            { faqmodal === 6  && requesty ? 

            <div className="answer">
                <p>To start selling on Squaremart, simply create an account and follow the platform's guidelines for setting up your seller profile. Once your profile is complete and verified, you can list your products for sale and begin engaging with potential buyers through the platform's selling interface.</p>

            </div>  :null }
        </div>

         <div className="faqdrop">
            <div className="question">
                <h3>How do i reach support incase i need help?</h3>
                  { faqmodal === 7  && requesty ? <FaMinus onClick={()=>faqopenmodal(7)}/> :
                <BiPlus onClick={()=>faqopenmodal(7)} /> }
            </div>
            { faqmodal === 7  && requesty ? 

            <div className="answer">
                <p>If you need assistance, you can reach our support team through the app's Help or Support section. We're available to assist you with any inquiries or issues you may encounter.</p>

            </div>  :null }
        </div>

        

    </div>
    
    

</div>
<Footer/>
</div>
  )
}

export default AboutUs;
