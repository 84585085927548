import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/index.css'
import './text.css'
import './css/marketplace.css'
import './css/wallet.css'
import './css/purchase.css'
import './css/add-product.css'
import './css/success.css'
import './css/offer.css'
import './css/notification.css'
import './css/buy-usdt.css'
import './css/buy-giftcard.css'
import './css/exchange.css'
import './css/sign.css'
import './css/address.css'
import './css/create-new-usdt.css'
import './css/inbox.css'
import './css/payout.css'
import './css/settings.css'
import './css/create-new-giftcard.css'
import './css/robot-chat.css';
import './css/verify.css'
import './css/aboutus.css'
import './css/contactus.css'
import './css/faq.css'
import './css/indexweb.css'
/*******************Admin css  **********/

import './css/admin/Css/admin.css'

import App from './App';
import { ThemeProvider } from './component/Dashboard/ThemeContext';
import reportWebVitals from './reportWebVitals';
import { store } from './component/Redux/Store';
import { Provider, useDispatch } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { useEffect } from 'react';
import { isAsyncThunkAction } from '@reduxjs/toolkit';
import { setWallet } from './component/Redux/Balanceslice';
import { QueryClient, QueryClientProvider } from 'react-query';

const token =sessionStorage.getItem("authenticated")
const admin_token =sessionStorage.getItem("admin_authenticated")
function conditions()
{
  if(admin_token)
  {
   return admin_token
  }
  else{
    
    return token
  }
}

const queryClient = new QueryClient();
axios.defaults.headers.common['Authorization'] = `Bearer  ${conditions()}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['allowed_origins'] = '*';
axios.defaults.headers.common['X-X-SQUAREMART'] = process.env.REACT_APP_NAME_KEY
axios.defaults.headers.common['X-desktop-Ipaddress'] = localStorage.getItem('desktop_ip');
axios.defaults.baseURL='https://squaremart.azj.wbp.mybluehost.me/'
// axios.defaults.baseURL='http://localhost:8000/'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
    </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();



