import React from "react";
import { Route, Navigate } from "react-router-dom";

const ProtectTwoFactor = ({ children}) => {
  const isFactor= sessionStorage.getItem("TOTP");
 
  return  isFactor ? children : <Navigate to="/login" />
    
};

export default ProtectTwoFactor;
