import React, { useEffect, useRef, useState } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import withdrawal from '../../img/withdrawal.png'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import MovingText from 'react-moving-text'
import { setdailylimit, setmessage } from "../Redux/Createslice";
import Balance from "./Balance";
import { setpayoutAddress } from "../Redux/ProfilePageslice";
import { FaExchangeAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Withdrawal=()=>
{
    const { pathname } = useLocation();
const[Networkfee,setNetworkfee]=useState(false)
const [networkSelected,setnetworkSelected]=useState('')
const [currencyRate,setcurrencyRate]=useState(0)
  const [loadcurrentRate,setloadcurrentRate]=useState(false)

const[network,setnetwork]=useState()
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    const {dailylimit,allBanks}=useSelector((state)=>state.Createslice)

    const[paymentoptions,setpaymentoptions]=useState({ address: '' })
 
    function Address(event){
      setNetworkfee(true)
      setnetwork()
        setReadyWithdrawal(false)
        setError(false)
 dispatch(setmessage())
        const {name,value,type}=event.target
        setWithdrawdata((previous)=>{
            return {
                ...previous,
                [name]:value
            }
        })
        setpaymentoptions(previous => ({
            ...previous,
            [name]: value
        }));
        dispatch(setpayoutAddress({
            ...payoutAddress,
            [name]:value
          })
        )
        
    }
    /******************* GET NETWORK FEE **************/
  
 useEffect(()=>{
    async function NewtworkFee(){
      if(Networkfee && paymentoptions.address != 'NGN'){
       axios.get(`api/network_fee/${paymentoptions.address}`)
      .then((res)=>{
          setnetwork(res.data)
    })
      setNetworkfee(false)
  } else{
  setNetworkfee(false)
}
    }
  NewtworkFee()
},[Networkfee])

/***************************** BANKS **********/
const banks= allBanks && allBanks.map((element,index)=>{
    return (
        <>
            <option key={index} value={element.bank_code}>
                {element.bank_name}
            </option>

        </>

    );
})
/********************* END *********************/
  const[Withdrawdata,setWithdrawdata]=useState([])
   
  const {payoutAddress}=useSelector((state) =>state.ProfilePageslice)
  const[LoadaccountName,setLoadaccountName]=useState(false)
  function WithdrawalHandler(event)
    {
      
       setError(false)
    //    setReadyWithdrawal(false)
       setloadcurrentRate(true)
        dispatch(setmessage())
        const {name,value,type}=event.target
        if (name == 'amount' && (!(/^\d+$/.test(value))  || value <= 0 )) {
           setWithdrawdata(element => (
                { 
                  ...element, 
                    amount: null 
                  }));
          }
          else{
            setWithdrawdata((previous)=>{
                return {
                    ...previous,
                    [name]:value
                }
            })
            dispatch(setpayoutAddress({
                ...payoutAddress,
                address:Withdrawdata.address ,
                [name]: value,
              })
            );
            
          }
          if( name === 'bank_code')
            {
                dispatch(setpayoutAddress({
                    ...payoutAddress,
                    account_number:'' ,
                    account_name:'',
                    [name]: value,
                  })
                );
            }
    //       const isValidAccount = 
    //       name === 'account_number' && value.length === 10 && ( payoutAddress.bank_name ||
    //       name === 'bank_code') && payoutAddress.account_number && payoutAddress.account_number.length === 10;
  
    //   setLoadaccountName(isValidAccount)

       const isValidate = name === 'sell_network' ? event.target.value :null
       setnetworkSelected(isValidate)
    }
    /*============= RETRIEVE USER ACCOUNT NAME =====*/
useEffect(()=>{
    async function fetchAccountName()
    {
        if(LoadaccountName){
          
        axios.get(`api/retrieve_user_account_name/${payoutAddress.account_number}/${payoutAddress.bank_code}`)
        .then((res)=>{
           
            dispatch(
                setpayoutAddress({
                  ...payoutAddress, 
                  bank_name:res.data.bank_name,
                  account_name: res.data.account_name,
                })
              );
             
            setLoadaccountName(false)
             })   
    }
    }
    fetchAccountName();
},[LoadaccountName, payoutAddress.account_number, payoutAddress.bank_code]);


// Handle blur event for account number field
function handleBlur(event) {
    const { name, value } = event.target;
    
    if (name === 'account_number' && value.length <= 10 && value.length > 0 && (payoutAddress.bank_name || payoutAddress.bank_code)) {
        setLoadaccountName(true);
    }
}
/******************* END */
    const[Error,setError]=useState(false)
const {message}=useSelector((state)=>state.Createslice)
    const{wallets}=useSelector((state)=>state.Balanceslice)
   const [ReadyWithdrawal,setReadyWithdrawal]=useState(false)

/***************** SUBMIT WITHDARAWAL DATA  **********/
const dispatch=useDispatch() 
function Handlersubmit(e){
 e.preventDefault()
 setError(false)
 setlmitload(true)
 dispatch(setmessage())
 setReadyWithdrawal(true)
 }
   const amountFee=(parseInt(Withdrawdata.amount) + (parseInt(Withdrawdata.amount) * wallets.fee))

 /************** Transaction Limit  *****/
 useEffect(()=>{
    async function DailyLimit(){
        axios.get('api/TransactionLimitDaily')
        .then((res)=>{
            dispatch(setdailylimit(res.data))
   
        })

    }
    DailyLimit()
},[])

  const limitDaily=(dailylimit - amountFee)
  const[limitload,setlmitload]=useState(false)
  const naira_amount=(parseFloat(Withdrawdata.amount) * wallets.naira_rate).toFixed(2) 
   const realAmount=parseFloat(Withdrawdata.amount) * wallets.naira_rate

  function Commision()
  {
    if(realAmount <= '5000' ){
        return 12.05;
    }
    else if((realAmount <= '20000') && (realAmount > '5000') ){
        return 25.7;
    }
    else if((realAmount > '20000')){
        return 50;
    }
    else {
        return 0.00;
    }
  }
  /***************** END */
  /*********************** CURRENCY CONVERSION RATE ****************/

  useEffect(()=>{
       async function CURRENCYRATE(){
      if((loadcurrentRate && networkSelected)){
        try{
                axios.get('api/crypto_rate')
        .then((res)=>{
          if(res.data.msg === 'success'){
        const network = networkSelected === 'USDT' ? 'USDTTRC20' : networkSelected
           const rate=res.data.data[network]?.price  
                 setcurrencyRate(rate)
             setloadcurrentRate(false)
           
   }
        })
      }
      catch(error){
              setloadcurrentRate(true)
              dispatch(setmessage('Server Error'))
            }
    // }
    }
  }
  CURRENCYRATE()
},[loadcurrentRate,networkSelected])

    const cryptoRate= (amountFee/currencyRate) + Withdrawdata.sell_network
     const validateAmount = amountFee/currencyRate
  /************************* END ********************/
 useEffect(()=>{
    async function Withdrawl(){
         //Validate if Wallet is sufficient to withdrawal
    if(
       ((Withdrawdata.address === 'BTC' && wallets.btc_amount < amountFee)
      ||    (Withdrawdata.address === 'ETH' && wallets.eth_amount < amountFee)
      ||  (Withdrawdata.address === 'USDT' && wallets.usdt_amount < amountFee)
      // ||  (Withdrawdata.address === 'NGN' && (Withdrawdata.sell_network === 'BTC' && wallets.btc < validateAmount))
      
    ) && ReadyWithdrawal){
        setError(true)
        dispatch(setmessage('Insufficient '+ Withdrawdata.address + ' Amount'))
       setReadyWithdrawal(false)
       setlmitload(false)
    }
    else if ( 
  ((Withdrawdata.address === 'NGN' && (Withdrawdata.sell_network === 'BTC' && wallets.btc < validateAmount))
    ||  (Withdrawdata.address === 'NGN' && (Withdrawdata.sell_network === 'ETH' && wallets.eth < validateAmount))
    ||  (Withdrawdata.address === 'NGN' && (Withdrawdata.sell_network === 'USDT' && wallets.usdt < validateAmount))
  ) && ReadyWithdrawal){
    setError(true)
    dispatch(setmessage('Insufficient '+ Withdrawdata.sell_network + ' Amount to withdraw NGN'))
   setReadyWithdrawal(false)
   setlmitload(false)
      }
          else if(( limitload && (limitDaily < 0)))
    {
        setError(true)
        dispatch(setmessage('Daily Limit Exceeded'))
        setReadyWithdrawal(false)
    }
    else if(ReadyWithdrawal){
        axios
                    .post(`api/withdrawalAmount`,payoutAddress)
                        .then((res)=>{
                            if(res.data.status === 201)
                            {
                                setError(true)
                                dispatch(setmessage(res.data.data))
                                setReadyWithdrawal(false)
                                setlmitload(false)
                            }
                            else{
                                dispatch(setmessage(res.data))
                                setReadyWithdrawal(false)
                                setlmitload(false)
                                setError(false)
                                dispatch(setdailylimit(dailylimit - amountFee))
                        
                            }
                               })
       
    }
    }
    Withdrawl()
 },[ReadyWithdrawal])
/************* END ******/
function buttonCondition()
{
    if((paymentoptions.address === 'BTC'))
    {
        return (
        <button style={(payoutAddress.btc_address) && !ReadyWithdrawal  && Withdrawdata.amount 
            ? {cursor: 'pointer'} : disbalestyle 
        } type="submit">{ReadyWithdrawal ? 'Processing' : 'Withdraw'}</button>
   )   }
  else if((paymentoptions.address === 'NGN'))
    {
        return (
        <button style={ !ReadyWithdrawal  && Withdrawdata.amount && (payoutAddress.bank_name) && payoutAddress.account_number
            ? {cursor: 'pointer'} : disbalestyle 
        } type="submit">{ReadyWithdrawal ? 'Processing' : 'Withdraw'}</button>
   )   }
   else if((paymentoptions.address === 'ETH'))
   {
       return (
       <button style={ !ReadyWithdrawal  && Withdrawdata.amount && (payoutAddress.eth_address)
           ? {cursor: 'pointer'} : disbalestyle 
       } type="submit">{ReadyWithdrawal ? 'Processing' : 'Withdraw'}</button>
  )   }
 else if((paymentoptions.address === 'USDT'))
  {
      return (
      <button style={ !ReadyWithdrawal  && Withdrawdata.amount && (payoutAddress.usdt_address)
          ? {cursor: 'pointer'} : disbalestyle 
      } type="submit">{ReadyWithdrawal ? 'Processing' : 'Withdraw'}</button>
 )   }
 else  {
    return (
    <button style={ !ReadyWithdrawal  && Withdrawdata.amount 
        ? {cursor: 'pointer'} : disbalestyle 
    } type="submit">{ReadyWithdrawal ? 'Processing' : 'Withdraw'}</button>
)   }
}

function Conditions() {
    
    if (paymentoptions.address === 'BTC') {
        return (
            <>
                <label htmlFor="btc">BTC Address</label>
                <input
                    type="text"
                    onChange={WithdrawalHandler}
                    value={payoutAddress.btc_address || ''}
                    placeholder="BTC Address"
                    name="btc_address"
                    id="btc_address"
                />
                <p style={{ textAlign: 'right', color: '#41688a' }}>{network ? network : '...'}</p>
            </>
        );
    } else if (paymentoptions.address === 'ETH') {
        return (
            <>
                <label htmlFor="btc">ETH Address</label>
                <input
                    type="text"
                    name="eth_address"
                    required
                    onChange={WithdrawalHandler}
                    value={payoutAddress.eth_address || ''}
                    placeholder="ETH Address"
                    id="eth"
                />
                <p style={{ textAlign: 'right', color: '#41688a' }}>{network ? network : '...'}</p>
            </>
        );
    } else if (paymentoptions.address === 'NGN') {
        return (
            <>
                <select
                      onChange={WithdrawalHandler}
                    required
                    name="bank_code"
                    className="form-control"
                >
                     { payoutAddress.bank_name ? 
                    <option value={payoutAddress.bank_name}>{payoutAddress.bank_name}</option>    
                  :
                  <option value=" " disabled="disabled">&larr; Select Bank &rarr;</option>
                      
                  }
                       
                       {banks.length > 0 ? (
                                                banks
                                            ) : (
                                                <option value=''>Not Available</option>
                                            )}
                    </select>
                <label htmlFor="btc">Account Number</label>
                <input
                    type="tel"
                    maxLength={10}
                    required
                    // readOnly
                    // className="disabledButton"
                    onChange={WithdrawalHandler}
                    onBlur={handleBlur}
                      value={payoutAddress.account_number || ''}
                       placeholder="Account Number"
                    name="account_number"
                    id="acc-number"
                />
                <p>{payoutAddress.account_name ? payoutAddress.account_name : '...'}</p>
                {/* <input
                    type="text"
                    required
                    readOnly
                    className="disabledButton"
                    // onChange={WithdrawalHandler}
                    defaultValue={payoutAddress.bank_name || '...'}
                    placeholder="Account Name"
                    name="bank_name"
                    id="acc-number"
                /> */}
                <FaExchangeAlt className="icon-exchange" />
                <label htmlFor="btc">Sell</label>
                <select
                    required
                    name="sell_network"
                    onChange={WithdrawalHandler}
                    className="form-control"
                    id="ngn"
                >
                    <option value="">&rarr; Sell</option>
                    <option value="BTC">&rarr; BTC</option>
                    <option value="ETH">&rarr; ETH</option>
                    <option value="USDT">&rarr; USDT</option>
                </select>
                <input
                    type="hidden"
                    name="totalExchange"
                    value={currencyRate && amountFee ? cryptoRate : ''}
                />
                <p style={{ textAlign: 'right', color: '#41688a' }}>
                    {currencyRate && amountFee ? cryptoRate : '...'}
                </p>
                <label htmlFor="btc">Amount in Naira</label>
                <input
                    type="text"
                    required
                    readOnly
                    className="disabledButton"
                    value={Withdrawdata && Withdrawdata.amount ? naira_amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                    placeholder="Naira"
                />
                <p style={{ textAlign: 'right', color: '#41688a' }}>
                    Commission: # {Commision()}
                </p>
            </>
        );
    } else if (paymentoptions.address === 'USDT') {
        return (
            <>
                <label htmlFor="btc">USDT Address</label>
                <input
                    type="text"
                    name="usdt_address"
                    required
                    onChange={WithdrawalHandler}
                    value={payoutAddress.usdt_address || ''}
                    placeholder="USDT Address"
                    id="usdt"
                />
                <p style={{ textAlign: 'right', color: '#41688a' }}>{network ? network : '...'}</p>
            </>
        );
    } else {
        return (
            <>
                <label htmlFor="btc">Wallet Address</label>
                <input
                    type="text"
                    name="address"
                    required
                    readOnly
                    placeholder="Address"
                    id="btc"
                />
            </>
        );
    }
}
const disbalestyle={
    pointerEvents: 'none',
       opacity: '0.5',
}

    return (
        <>
      <Auth />
      <Balance/>
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
        <div className="settings"> 
       
             {/* ERROR MSG */}
         
      {   message ? 
        <div className={Error ? 'error-field' : 'sucessmsg'}>
        
        <MovingText
                        type="slideInFromRight"
                        duration='350ms'
                        delay='0s'
                        direction='alternate'
                          iteration='1'
                        fillMode='none'>
                              <div className= {Error ? 'error' :'succMsg'}>
                                    {message}
                                
                        </div> </MovingText>
                        
                        </div>
                        : null
                        }
        <div className="payout">
    {/* <h1>Payout</h1> */}
    <div className="payout-options">
        <div className="option-address">
            <form onSubmit={Handlersubmit} method="post">
                <label htmlFor="ngn">Withdraw options</label>
                <select
    required
    name="address"
    className="form-control"
    id="ngn"
    value={paymentoptions.address}
    onChange={Address}
>
    {paymentoptions.address ? (
        <>
            <option value={paymentoptions.address}>
                &rarr; {paymentoptions.address} Address
            </option>
            <option value="BTC">&rarr; Bitcoin Address</option>
            <option value="ETH">&rarr; Etherum Address</option>
            <option value="USDT">&rarr; USDT Address</option>
            <option value="NGN">&rarr; Bank Account</option>
        </>
    ) : (
        <>
            <option value="" defaultValue>
                &larr; Select option &rarr;
            </option>
            <option value="BTC">&rarr; Bitcoin Address</option>
            <option value="ETH">&rarr; Etherum Address</option>
            <option value="USDT">&rarr; USDT Address</option>
            <option value="NGN">&rarr; Bank Account</option>
        </>
    )}
</select>
              <FaExchangeAlt className='icon-exchange'/>
              {Conditions()}
             <FaExchangeAlt className='icon-exchange'/>
               <label htmlFor="btc">Amount to Withdraw (USD)</label>
           <input type="tel"
           required
           value={Withdrawdata.amount || ''}
           onChange={WithdrawalHandler}
            placeholder="Amount"
             name="amount" id="btc"/>
              {buttonCondition()} </form>
          
        </div>
        <div className="option-address">
           <img className='withdrawl' src={withdrawal}  width='250' alt=""/>
            </div>
    </div>

</div> </div> </div> 
        </>
    )
}
export default Withdrawal
