import React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlinePlus, AiOutlineArrowsAlt } from "react-icons/ai";
import {FaExchangeAlt} from "react-icons/fa";
import { useEffect } from "react";
import axios from "axios";
import loadimg from '../../img/bx_loader-circle.png'
import { useDispatch, useSelector } from "react-redux";
import { setallgiftcard } from "../Redux/SwapCard";
const Cardssidrbar=(props)=>
{
   /************** ALL GIFTCARD ******/
   
     const dispatch=useDispatch()
     const {allgiftcard}=useSelector((state)=>state.SwapCard)
  
   const cardAvalaible = allgiftcard && allgiftcard.map((element,index) => {
       return (

           <option key={index} value={element.cards}>{element.cards}</option>

       )
   })
   /********** END ************/
const style={
    cursor:'pointer'
}
    return (
        <form action=""  onSubmit={props.HandlerUsdtSubmit}>
        <div className="form-name">
            <div className="buy">
        <label htmlFor="Buy">Buy</label> <br/>
        <select  name="" id="buy">
            <option value="USDT">USDT</option>
         
        </select>
    </div>
    <div className="exchnageicon">
        {/* <p>wow</p> */}
      
        <span><FaExchangeAlt/></span> 

    </div>
    <div className="swap">
        <label htmlFor="swap">swap with</label> <br/>
        <select onChange={props.offeringUsdt} name="accept_giftcard" id="swap">
            <option>Select Swap Card</option>
           {cardAvalaible}
        </select>
    </div>
        </div>

    
    <div className="form-name">
        <label htmlFor="Buy">Amount?</label> <br/>
        <div className="amount">
            <input type="tel" onChange={props.offeringUsdt} name="amount"
             id="" placeholder="Enter amount"/>
            <p className="usd">USD</p>
        </div>
       
    </div>
    <div className="form-name" id="offers">
        {/* <input type="submit"  style={{ cursor:'pointer' }}
         value={props.btn}/> */}
        <center>  <button   value={props.btn} style={{ cursor:'pointer' }}>
        {props.finding ? <img src={loadimg} className="spin-icon" /> :'Find Offers'}
       </button>
       </center>
        
    </div>
    <div className="adds">
        <div className="add-btn">
               {/* <AiOutlinePlus/> */}
        </div>
        {/* <div className="segments">
            <Link to='/CreateGiftcardad'>Create Offer To Sell Gift Card</Link>
            <Link to='/CreateUsdtad'>Create Offer To Sell USDT </Link>
        </div> */}
    </div>
    </form>
    )
}
export default Cardssidrbar;