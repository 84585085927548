import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import ChatInterface from "../pages/Chatbot";
import { setmessage } from "../Redux/Createslice";
import { useDispatch } from "react-redux";
const Internet=()=>
{
  const location = useLocation();
  const currentPath = location.pathname; 
  localStorage.setItem('previousURL',currentPath)
    const [isOnline, setIsOnline] = useState(navigator.onLine);
 const Navigate =useNavigate()
 const dispatch=useDispatch()
  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });

  const handleOnline = () => {
    setIsOnline(true);
  };
// console.log(currentPath)
  const handleOffline = () => {
    setIsOnline(false);
  };
  useEffect(() => {
  if(!(isOnline))
  {
    dispatch(setmessage('Currently Offline'))
 // Navigate('/Net',{replace :true})
  }
})
    return (
       <>
      {/* <ChatInterface/> */}
       </>
    )
}
export default Internet