import React from "react";
import { Route, Navigate } from "react-router-dom";

const AdminPrivateRoute = ({ children}) => {
  const isAuthenticated = sessionStorage.getItem("admin_authenticated");
 
  return  isAuthenticated ? children : <Navigate to="/Admin/login" />
    
};

export default AdminPrivateRoute;
