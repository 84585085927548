import React, { useEffect, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MovingText from 'react-moving-text'
import { FaExclamationCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setmessage, setmsg } from "../../Redux/Createslice";
import Verificationquery from "./VerifyQuery";
import { IoIosArrowBack } from "react-icons/io";

const CreateGiftcardad=()=>
{
     const [errorField,seterrorField]=useState({})
    
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
     const[isLoading,setisLoading]=useState(false)
    const [CardData,setCardData]=useState([])
    const[other,setother]=useState(false)
    const[editor,seteditor] =useState( {
        vendor_id:'',
        // selling_card:''
    });
  /************ type other card to sell *****/
  useEffect(() => {
    if(editor.selling_card === '')
    {
        setother(true)
    }
   
  }, [editor.selling_card]);
//   console.log(editor)
function InputDataValueOther(event)
{
    seterrorField({})
    dispatch(setmsg())
    const { name, value, type, checked } = event.target;
          seteditor((prevData) => {
        const updatedData = {
          ...prevData,
    [name]: type === "checkbox" ? checked : value,
        };
        return updatedData;
    });
}
/**************END */
    const {msg}=useSelector((state)=>state.Createslice)
const dispatch=useDispatch()
      function SubmitHandler(e)
      {
        setverifyload(true)
        e.preventDefault()
        setisLoading(true)
        setmsg()
        setCardData(editor)
      }
     function TextEditor(text) {
       dispatch(setmsg())
        seterrorField({})
        setisLoading(false)
        setverifyload(false)
        seteditor((prevData) => {
            const updatedData = {
                ...prevData,
                terms:  text ,
            };
            return updatedData;
        });
      };
     
      function InputDataValue(event){
        seterrorField({})
        setverifyload(false)
        setisLoading(false)
        dispatch(setmsg())
        const { name, value, type, checked } = event.target;
        seteditor((prevData) => {
            const updatedData = {
                ...prevData,
                vendor_id:userId,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
      }
    
    /*************** CREATE NEW GIFTCARD ********/
    const[Errored,setErrored]=useState(false)
    const [verifyload,setverifyload]=useState(false)
    useEffect(() => {
        async function CreateGiftCard() {
            if(verifyload)
            {
            if((badge == 0))
            {
                dispatch(setmsg('UnAuthorized kindly Verify your account'))
               setisLoading(false)
               setverifyload(false)
               setErrored(true)
            }
            else  if((badge == 1) && (Priceproduct > 500))
            {
              
                dispatch(setmsg('UnAuthorized kindly Verify your account'))
                setisLoading(false)
                setverifyload(false)
                setErrored(true)
            }
            else  if((badge == 2) && (Priceproduct > 2000)){
               dispatch(setmsg('UnAuthorized kindly Verify your account'))
               setisLoading(false)
               setverifyload(false) 
               setErrored(true)
            }
             else if(isLoading) {
                axios.post(`api/NewGiftcard`,
                CardData)
                    .then((response) => {
                        if (response.data.status === 500) {
                            dispatch(setmsg(response.data.data))
                            setisLoading(false)
                            setverifyload(false)
                         }
                         else if(response.data.status === 200)
                         {
                            setErrored(false)
                            seteditor({
                                vendor_id: '',
                              
                            })
                          
                            dispatch(setmsg(response.data.data))
                            setisLoading(false)
                            setverifyload(false)
                         } 
                         else if(response.data.status === 419)
                         {
                            setErrored(true)
                            dispatch(setmsg( <> Field Required
                                <FaExclamationCircle />
                                </>
                              ))
                            // setmsg('Field Required')
                            seterrorField(response.data.errors)
                            setisLoading(false)
                            setverifyload(false)
                        }
                      
                       
                    })
            }
            }
        }
        CreateGiftCard()
    
    }, [CardData,isLoading])
    // console.log(errorField.gift_type)
    /*********************** END ***********/
     /************** ALL GIFTCARD ******/
    //  const [allgiftcard, setallgiftcard] = useState([])
    const {allgiftcard}=useSelector((state)=>state.SwapCard)
    
 
     const cardAvalaible = allgiftcard.map((element) => {
         return (
 
             <option  key={element.id} value={element.cards}>{element.cards}</option>
 
         )
     })
     /********** END ************/
     const modules = {
        // toolbar: [
        //   [{ 'header': [1, 2, false] }],  // Header dropdown
        //   ['link', 'image'],               // Link and image options
        //   ['clean']                        // Remove all formatting
        // ]
      };
      /************ CheckVefication ********/
    
const{verified}=useSelector((state)=>state.Tradeslices)
const badge=verified && verified.verify_badge
const Priceproduct=editor && editor.amount

    return (
        <>
        <Auth/>
        
<Verificationquery/>
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            
<section>
      
    <div className="head">
        <span ><Link to='/trade'><IoIosArrowBack/></Link></span>
        <p>You are making an Ad to Sell Gift card</p>
    </div>
    <div className="content">
        {msg ? 
        
         <MovingText
         type="slideInFromRight"
         duration='350ms'
         delay='0s'
         direction='alternate'
         className={Errored || msg == 'Error Please Try again' ? "errormsg" : "sucessmsg"}
         iteration='1'
         fillMode='none'>
          <p> {msg}</p></MovingText>
     : ''}
        <div className="form-class">
            <form action="" onSubmit={SubmitHandler} method="post">
                <div className="form-input">
                    <label htmlFor="">Choose Payment </label>
                    <select name="accept_payment"  onChange={InputDataValue}>
                    <option value="">Select Payment</option>
                        <option value="USDT">USDT</option>
                        <option value="ETH">ETH</option>
                        <option value="BTC">BTC</option>
                  </select>
                </div>
                <div className="errors">{errorField.accept_payment}</div>
                <br></br>
                
                <div className="title">
                    <p>You are Selling Gift Card</p>
                
                </div>    <br/>
                <div className="form-input">
                                    <label htmlFor="">Choose Option</label>
                                    <select name="selling_card" id="" onChange={InputDataValue}>
                                        <option value=''>Select GiftCard</option>
                                        {cardAvalaible}
                                        <option value=''> Other</option>
                             
                                    </select>
                                     </div>
                                     {( other) ?
                                <div className="input">
                                    <label htmlFor="">Enter Other selling card</label>
                                    <input type="text" name="selling_card"
                                        // defaultValue={editor.accept_giftcard   || ''} 
                                        id="" onChange={InputDataValueOther} placeholder="selling card" />
                                   
                                </div> : null
                                }
                                <div className="errors">{errorField.selling_card}</div>
                            <br></br>
                <div className="input">
                    <label htmlFor="">Amount?</label>
                    <input type="tel" name="amount"
                     defaultValue={editor.amount || ''}
                      id="" onChange={InputDataValue} placeholder="100"/>
                <div className="errors">{errorField.amount}</div>
              
                </div>
                <div className="input">
                    <label htmlFor="">At rate?</label>
                    <input type="text" 
                       defaultValue={editor.rate || ''}
                        name="rate" id="" onChange={InputDataValue} placeholder="0.8"/>
                 <div className="errors">{errorField.rate}</div>
              
                </div>
               
                <div className="input">
                    <label htmlFor="">You're Swap for</label>
                    <input type="tel" style={{ cursor:'not-allowed' }} 
                      defaultValue={editor.accept_payment || ''} readOnly
                 placeholder="Wallet (USDT)"/>
                
                </div>
                <div className="alert">
                    <p>for every ${editor.amount ? editor.amount : '0'} you get this {editor.rate ? editor.rate : '0'}% value of your card</p>
                </div>
                <div className="form-terms">
                    <div className="term-title">
                        <p>Write Terms of transaction</p>
                    </div>
                    <div className="text-field">
                    <ReactQuill
//    modules={modules}
  onChange={TextEditor}
  className="quill"
  style={{ height: "300px", borderRadius: "5px" }}
/>
<div className="errors">{errorField.terms }</div>
                   {/* <textarea name="" id="" placeholder="Enter your terms of offer here |"></textarea> */}
                    </div>
                </div>
                <div className="submit">
                <button type="submit"><span>{isLoading ? "Sending..." : "Proceed"}</span></button>
                </div>
            </form>
        </div>

    </div>
</section>
            </div>
            </>
    )
}
export default CreateGiftcardad