import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  NavMenu:false,
  NavMobileMenu:false
}

export const Toggle = createSlice({
  name: 'counter',
  initialState,
  reducers: {
  
    setNavMenu:(state, action) => {
      state.NavMenu  = action.payload
    },
    setNavMobileMenu:(state, action) => {
      state.NavMobileMenu  = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNavMenu,setNavMobileMenu} = Toggle.actions

export default Toggle.reducer