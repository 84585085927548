import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logos from '../../img/squaremart-logo.png'
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from "react-icons/ai";
import { FaBitcoin, FaQuestion, FaTimes } from "react-icons/fa";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { SiCoinmarketcap } from "react-icons/si";
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'

const TermsCondition=()=>
{
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
    /**************** Menu Mobile */
    const[showmenu,setshowmenu]=useState(false)
    function Menumobile(){
     setshowmenu((previus) => !previus)
     }
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
    <DesktopHeader/> 
 <MobileHeaders/>
            <div className="container">
      
                <div className="privacy">
                <h3 >Squaremart Terms and Conditions</h3>
                <p> Welcome to SquareMart, an online marketplace where you can browse, purchase,
and sell a wide range of products and services. Our platform is dedicated to
creating a secure, efficient, and universally accessible e-commerce experience.
By signing up for or using <p style={{ fontWeight:'600' }}>SquareMart</p> services, you agree to be bound by the following terms and
conditions. These terms govern your use of the website, tools, and services
provided by SquareMart. Please read them carefully.</p>
<br/>
<h3>Disclaimer and Limitation of Liability</h3>
<h4>No Warranty:</h4>
<p>"Provided Without Any Guarantees" <br></br>
Your use of Square Mart is at your sole risk. Square Mart provides its services on
an "as is," "as available," and "with all faults" basis. We make no representation or
warranty that our services will be uninterrupted, timely, or error-free.
Services </p>
<br/> <h4>Payment Services </h4>
<p>Escrow Service: Square Mart may provide escrow services for certain
transactions. When using our escrow service, funds will be held securely until the
completion of the transaction, providing protection to both buyers and sellers.</p>
{/* <ul>1. Your IP address.</ul>
<ul>2. Your contact information and email address.</ul>
<ul>3. Other information such as interests and preferences.</ul>
<ul>4. Data profile regarding your online behavior on our website. </ul> */}
<br/>       
 <h4>Gift Card Exchanges:
 </h4> 
<p>Square Mart facilitates the exchange of gift cards between users. Users agree to
abide by the terms and conditions of the gift card issuers and SquareMart's
policies regarding gift card exchanges.
 </p>
{/* <ul>1. To better understand your needs. </ul>
<ul>2. To improve our services and products.</ul>
<ul>3. To send you promotional emails containing the information we think you
 will find interesting. </ul>
<ul>4. To contact you to fill out surveys and participate in other types of market
 research. </ul>
<ul>5. To customize our website according to your online behavior and personal
 preferences. </ul> */}
<br/> 
<h4>Wallet </h4>
<p>
SquareMart may offer wallet services that allow users to store funds for future
purchases. By using our wallet services, you agree to abide by the terms and
conditions governing the use of the wallet. </p>
<br/> 
<h4>Charges:</h4>
<p>
<h3>Fee Structure</h3>
SquareMart may impose charges for certain services provided on the platform.
These fees will be clearly outlined to users before they utilize the respective
services.
Users agree to pay any applicable fees associated with their use of
SquareMart's services  </p>
<br/>
<h4>Links to Other Websites   </h4>
<br/>
<p>Our website contains links that lead to other websites. If you click on these links,
Squaremart is not held responsible for your data and privacy protection. Visiting those
websites is not governed by this privacy policy agreement. Make sure to read the privacy policy
documentation of the website you go to from our website. </p>
<br/> 
<h4>Restricting the Collection of your Personal Data </h4>
<br/>
<p>At some point, you might wish to restrict the use and collection of your personal data.
You can achieve this by doing the following:</p>
<br/>
<p>When you are filling the forms on the website, make sure to check if there is a box
which you can leave unchecked, if you don't want to disclose your personal information. </p>
<br/>
<p>If you have already agreed to share your information with us, feel free to contact us via
email and we will be more than happy to change this for you. </p>
<br/> <p>Squaremart will not lease, sell or distribute your personal information to any third parties,
unless we have your permission. We might do so if the law forces us. Your personal information
will be used when we need to send you promotional materials if you agree to this privacy policy. </p>
<br/>  <br/> </div>
{showmenu ? 
                     <div id="mySidenav" className= "sidenav" >
                        <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
                          <ul>
                          <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
                          <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>
                          <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li className="login">
                            <Link style={link} to='/login'>LOGIN</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
       </div>
     </div>
    )
}

export default TermsCondition
