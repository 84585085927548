import { createSlice } from '@reduxjs/toolkit'

  
const initialState = {
    fetchgiftcard:[],
    usdt_buy_id:'',
    giftcard_buy_id:'',
    allgiftcard:[],
    fetchUsdt:[],  
    
}

export const SwapCard = createSlice({
  name: 'Card',
  initialState,
  reducers: {
   setusdt_buy_id:(state, action) => {
    state.usdt_buy_id = action.payload
  },
  setgiftcard_buy_id:(state, action) => {
    state.giftcard_buy_id = action.payload
  },
    setfetchgiftcard: (state, action) => {
      state.fetchgiftcard = action.payload
    },
    setallgiftcard: (state, action) => {
        state.allgiftcard = action.payload
      },
      setfetchUsdt: (state, action) => {
        state.fetchUsdt = action.payload
      },
      
    
    
  },
})

// Action creators are generated for each case reducer function
export const {setfetchUsdt,setusdt_buy_id,setgiftcard_buy_id, setfetchgiftcard,setallgiftcard } = SwapCard.actions

export default SwapCard.reducer