import React, { useEffect } from "react";
import { useState } from "react";
import Auth from "./Auth";
import GiftcardTable from "./GiftcardTable";
import Cardssidebar from "./Cardssidebar";
import CryptoUsdt from "./CryptoUsdt";
import Giftcard from "./Giftcard";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Giftsidebar from "./giftsidebar";
import UsdtTable from "./UsdtTable";
import axios from "axios";
import GiftcardSection from "./GiftcardSection";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setallusdt, setdataNotFound, setdataNotfoundgift, setgiftcard } from "../Redux/Tradeslices";
import { setfetchUsdt, setfetchgiftcard, setgiftcard_buy_id, setusdt_buy_id } from "../Redux/SwapCard";
import ScrollToTop from "../pages/ScrollToTop";

const Trade = () => {
    const [swapingShow, setswapingShow] = useState(1)
    const ToggleSwapusdt = () => setswapingShow(1)
    const ToggleSwapgiftcard = () => setswapingShow(2)


    const style2 = {
        cursor: 'pointer',
        border: 'none',
        
        color:'#ffffff',
        backgroundColor: 'rgba(51, 93, 131, 0.60)',
       

    }
    const style1 = {
        border: 'none',
        cursor: 'pointer',
      
        color:'#ffffff',
        backgroundColor: '#335D83',
        color:'#000000',

    }
    const FontColor = {
        color:'#ffffff',
    }
    /****************** USDT SECTION START *******/

    /************ ALL USDT AVAILABLE ***********/
     
    const dispatch=useDispatch()
    const Navigate = useNavigate()
   
    function Buyusdt(id) {
        dispatch(setusdt_buy_id(id))
     dispatch(setfetchUsdt({
        usdt_buy_id: id
     }))
         Navigate("/BuyUsdt" ,{ replace: true });

    }
    
    function CryptoGiftcard(id) {
        dispatch(setgiftcard_buy_id(id))
        dispatch(setfetchgiftcard({
            giftcard_buy_id: id
         }))
        // localStorage.setItem('giftcard_buy_id',id)
         Navigate("/BuyGiftcard" ,{ replace: true });
 
     }
     const profile = useSelector((state) => state.ProfilePageslice.profile);

     const userId= `${profile.id}`
   const{allusdt,dataNotfound}=useSelector((state)=>state.Tradeslices)
   
    /************** TABLE ROW  ************/
   
    // useEffect(() => {
    //     async function SelectUsdt() {
    
    //         axios
    //             .get(`api/viewUsdt`)
    //             .then((response) => {
    //                 dispatch(setallusdt(response.data));
    //                 dispatch(setdataNotFound('0ffer Table is Currently Empty'))
                  
    //             })
    
    //     }
    //     SelectUsdt()
    // }, [userId])
    /**************** END **************/
   
    /*********   Pagination   **************/
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(3);

    // Calculate the index of the first and last product to show on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    // Extract the subset of product data to show on the current page
    const currentProducts = allusdt.slice(indexOfFirstProduct, indexOfLastProduct);

    // Generate the product components for the current page

    const tableRow = currentProducts.map((element) => {
        return (<UsdtTable
            key={element.id}
            CryptoUsdt={() => Buyusdt(element.id)}
            id={element.id}
            selling_usdt={element.selling_usdt}
            offers={element.offers}
            accept={element.accept}
            rate={element.rate}
            amount={element.amount}
        />
        )
    })

    // Calculate the total number of pages based on the total number of products
    const totalPages = Math.ceil(allusdt.length / productsPerPage);

    // Handle the click event for the "next" button
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle the click event for the "previous" button
    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    /******************END  **********/

    /**************** FIND OFFERS ****************/
    const [searchData, setsearchData] = useState({
        userId:userId,
    })
    
    const[offerValue,setofferValue]=useState([])
    function offeringUsdt(event) {
        setfinding(false)
        const { name, value, type, checked } = event.target;
        setsearchData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
    }
    // console.log(searchData)
    const [finding, setfinding] = useState(false)
    function HandlerUsdtSubmit(e) {
        e.preventDefault()
        setfinding(true)
       
        setofferValue(searchData)
            }
    /************ loading sign *********/
    const [dots, setDots] = useState("");

    useEffect(() => {
      let intervalId;
      if (finding) {
        intervalId = setInterval(() => {
          setDots((prevDots) => {
            if (prevDots === "...") {
              return ".";
            } else if (prevDots === "..") {
              return "...";
            } else {
              return "..";
            }
          });
        }, 500);
      } else {
        setDots("");
      }
    
      return () => clearInterval(intervalId);
    }, [finding]);
    /*************** END ***********/
    useEffect(() => {
        async function UsdtSearched() {
            if (finding) {

                axios
                    .post(`api/searchOffers`,offerValue)
                    .then((response) => {
                        dispatch(setallusdt(response.data));
                        dispatch(setdataNotFound('0ffer Table is Currently Empty'))
                        setfinding(false)
                        // Add code to scroll down on the page
    const yOffset = 1000; // You can adjust this value to determine how much to scroll down
    window.scrollBy({ top: yOffset, behavior: 'smooth' });
  
                    })

            }
        }
        UsdtSearched()
      
    }, [offerValue,finding])

    /****************** END ********/

    /******************** USDT SECTION END *******/

    /*********************** GIFTCARD SECTION START **********/
    const{giftcard,dataNotfoundgift}=useSelector((state)=>state.Tradeslices)
    /************** TABLE ROW  ************/
    // useEffect(() => {
    //     async function SelectGiftcard() {

    //         axios
    //             .get(`api/viewGiftcard`)
    //             .then((response) => {
    //                 dispatch(setgiftcard(response.data));
    //                 dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
                  
    //             })

    //     }
    //     SelectGiftcard()
    // }, [userId])

    /************* Search GiftCard ************/
    const [searchDatagift, setsearchDatagift] = useState({
        userId:userId,
    })
    const[offerValuegiftcard,setofferValuegiftcard]=useState([])
    function offeringgiftcard(event) {
        setfinding(false)
        const { name, value, type, checked } = event.target;
        setsearchDatagift((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
    }
    
    const [spinning, setspinning] = useState(false)
    function HandlerGIFTSubmit(e) {
        e.preventDefault()
        setspinning(true)
        setofferValuegiftcard(searchDatagift)

    }
    useEffect(() => {
        async function GiftSearched() {
            if (spinning) {

                axios
                    .post(`api/searchOffersgiftcard`,offerValuegiftcard)
                    .then((response) => {
                        dispatch(setgiftcard(response.data));
                        dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
                      setspinning(false)
                        // Add code to scroll down on the page
    const yOffset = 1000; // You can adjust this value to determine how much to scroll down
    window.scrollBy({ top: yOffset, behavior: 'smooth' });
  
                    })

            }
        }
        GiftSearched()
      
    }, [offerValuegiftcard,spinning])
    /*==================== CARD CLICK ==================*/
    const[offerfindgiftcard,setofferfindgiftcard]=useState([])
    const[loadcard,setloadcard]=useState(false)
    function GiftcardSelect(card)
    {
        
        setswapingShow(2)
        setloadcard(true)
        setofferfindgiftcard((prevData) => {
            const updatedData = {
                ...prevData,
                userId:userId,
                 selling_card:card ,
                accept_giftcard:card 
            };
            return updatedData;
        });
    }
    useEffect(() => {
        async function GiftSearched() {
            if (loadcard) {

                axios
                    .post(`api/offerfindgiftcard`,offerfindgiftcard)
                    .then((response) => {
                    //  console.log(response.data)
                     dispatch(setgiftcard(response.data));
                     dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
                   setloadcard(false)
                     // Add code to scroll down on the page
    const yOffset = 1000; // You can adjust this value to determine how much to scroll down
    window.scrollBy({ top: yOffset, behavior: 'smooth' });
  
                    })

            }
        }
        GiftSearched()
      
    }, [offerfindgiftcard,loadcard])
    // console.log(offerfindgiftcard)

    /*==================== END =============*/
    /*********   Pagination   **************/
     const [currentPagecard, setCurrentPagecard] = useState(1);
     const [productsPerPagecard] = useState(3);
 
     // Calculate the index of the first and last product to show on the current page
     const indexOfLastProductcard = currentPagecard * productsPerPagecard;
     const indexOfFirstProductcard = indexOfLastProductcard - productsPerPagecard;
 
     // Extract the subset of product data to show on the current page
     const currentProductscard = giftcard.slice(indexOfFirstProductcard, indexOfLastProductcard);
 
     // Generate the product components for the current page
 
    const tableRowGifcard = currentProductscard.map((element) => {
        return (<GiftcardSection
            key={element.id}
            CryptoGiftcard={() => CryptoGiftcard(element.id)}
            id={element.id}
            selling_giftcard={element.selling_giftcard}
            offers={element.offers}
            accept_payment={element.accept_payment}
            rate={element.rate}
            amount={element.amount}
        />
        )
    })

    // Calculate the total number of pages based on the total number of products
    const totalPagescard = Math.ceil(giftcard.length / productsPerPagecard);

    // Handle the click event for the "next" button
    const handleNextClickcard = () => {
        if (currentPagecard < totalPagescard) {
            setCurrentPagecard(currentPagecard + 1);
        }
    };

    // Handle the click event for the "previous" button
    const handlePreviousClickcard = () => {
        if (currentPagecard > 1) {
            setCurrentPagecard(currentPagecard - 1);
        }
    };
    /*********************** END Giftcard *************/
    const someUniqueId = Date.now();
  
function Createoffers(event)
{
    const{name,value,type}=event.target
      if(event.target.value == 1)
      {
        Navigate('/CreateGiftcardad', { replace: true })
      }
      else  if(event.target.value == 2)
      {
        Navigate('/CreateUsdtad', { replace: true })
      }
}
    return (
        <>
            <Auth />
            <div className="container-fluid">
                <Sidebar />
                <header>
                    <Header />
                    
                </header>
                <hr />
                <div className="flex-box">
                    {/* CARD SIDE BAR */}
                    <div className="flex left">
                        <div className="btnnav">
                            <div className="btngray">
                            {swapingShow == 1 ?
                                <button style={style1}><a style={FontColor} onClick={ToggleSwapusdt}>
                                    BUY USDT
                                </a></button>
                                : <button style={style2}><a onClick={ToggleSwapusdt}>
                                    BUY USDT
                                </a></button>
                            }
                            {swapingShow == 2 ?
                                <button style={style1}><a style={FontColor} onClick={ToggleSwapgiftcard}>
                                    BUY GIFT CARD</a></button>
                                :
                                <button style={style2}><a onClick={ToggleSwapgiftcard}>
                                    BUY GIFT CARD</a></button>
                            }
                            </div>
                            <select name='offer' defaultValue='' onChange={Createoffers} id="">
                                <option value=""   disabled>create offers</option>
                                <option value="1">Create Offer To Sell Gift Card</option>
                                <option value="2">Create Offer To Sell USDT </option>
                            </select>
                        </div>
                        { swapingShow == 1 ? 

                        <Cardssidebar
                            // someUniqueId={someUniqueId}
                            offeringUsdt={offeringUsdt}
                            finding={finding}
                            HandlerUsdtSubmit={HandlerUsdtSubmit}
                            btn={finding ? `loading${dots}`:  'Find Offers'}
                        /> 
                        : <Giftsidebar 
                        // someUniqueId={someUniqueId}
                        spinning={spinning}
                        offeringgiftcard={offeringgiftcard}
                        HandlerGIFTSubmit={HandlerGIFTSubmit}/>}
                    </div>
                    {swapingShow == 1 ?
                        /******* Buy with USDT ********/
                        <div className="flex right">
                            <Giftcard 
                               GiftcardSelect={GiftcardSelect}
                               />
                            <CryptoUsdt
                                handlePreviousClick={handlePreviousClick}
                                handleNextClick={handleNextClick}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                allusdt={allusdt}
                                dataNotFound={dataNotfound}
                                tableRow={allusdt.length > 0 ? tableRow :
                              null
                            } />
                        </div>
                        /******** END  *******/
                        :
                        /******* Buy with giftCard ********/
                        <div className="flex right">
                            <Giftcard 
                           GiftcardSelect={GiftcardSelect}
                         />
                            <GiftcardTable 
                            handlePreviousClickcard={handlePreviousClickcard}
                            handleNextClickcard={handleNextClickcard}
                            currentPagecard={currentPagecard}
                            totalPagescard={totalPagescard}
                            giftcard={giftcard}
                            dataNotfoundgift={dataNotfoundgift}
                              tableRowGifcard={giftcard.length > 0 ? tableRowGifcard :
                                null }
                                />

                        </div>

                        /********** END **********/
                    }

                </div>
            </div>
        </>
    )
}
export default Trade