import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { PaystackButton } from 'react-paystack';
import { useDispatch, useSelector } from 'react-redux';
import { setmessage, setpaystackModal } from '../Redux/Createslice';
import axios from 'axios';

const Paystack = () => {

  const profile = useSelector((state) => state.ProfilePageslice.profile);

  const email= `${profile.email}`
  const userId= `${profile.id}`
  const[waiting,setWaiting]=useState(false)
  const publicKey =  process.env.REACT_APP_API_KEY // Replace with your public key
const[resValue,setresValue]=useState({})
  const handlePaymentSuccess = (response) => {
    setresValue(response)
    setWaiting(true)
    // dispatch(setpaystackModal(false))
   };
 
  useEffect(()=>{
    async function PaystackRedirect()
    {
      if(resValue.status ===  'success'){
axios.get(`api/handleGatewayCallback/${resValue.reference}`)
.then(res=>{
  //  console.log(res.data)
   dispatch(setmessage(res.data))
   dispatch(setpaystackModal(false))
})
      }
     
    }
    PaystackRedirect()
   
  },[resValue])
  const handlePaymentClose = () => {
   
    dispatch(setmessage( <> Transaction failed
      <FaExclamationCircle />
      </>))
    dispatch(setpaystackModal(false))
  };
const [amount,setamount]=useState({})
function Amount(event)
{
  const{name,value,type}= event.target
  if (name == 'amount' && (!(/^\d+$/.test(value))  || value <= 0 )) {
    setamount(element => (
        { 
          ...element, 
            amount: null 
          }));
  }
  else{
  setamount((element)=>{
    return {
    ...element,
    [name]:type === 'checkbox' ? 'checkbox' : value
    }
    
  })
  }
}
// console.log(email)
const dispatch=useDispatch()
  const config = {
    email:  email,
    amount: amount.amount * 100,
    ref: '' + Math.floor(Math.random() * 1000000000 + 1),
  };
   function Handlerpaystack()
  {
    dispatch(setpaystackModal(false))
  }
  return (
    <div className="container">

        <div className="modal-fund">
        <div className="modal-content">
        <span className="close" onClick={Handlerpaystack}>
            <FaTimes/></span>
         <div className="content">
          <div className='form'>
         <h1>Fund Wallet </h1><br />
            <div className="formclass">
                    <label htmlFor="">Enter Amount</label><br />
                    
         <div class="input-field">
                <div class="icon">#</div>
                 <input type="tel" 
                    onChange={Amount}
                    name="amount" id="" />               
                </div>
            </div>
            <div  className="btn-pay">
            {amount.amount 
    ? 
  
    <PaystackButton
    text={waiting ? 'Pending..' :"Fund" }
    className={waiting ? "paystack-paying" :'paystack-button'}
    publicKey={publicKey}
    email={config.email}
    amount={config.amount}
    reference={config.ref}
    onSuccess={handlePaymentSuccess}
    onClose={handlePaymentClose}
  />

  : <button disabled='disabled'style={{ cursor:'not-allowed' }} type="submit">Fund</button>
}
          </div>  </div>
     
    </div> </div> </div>
    </div>
  );
};

export default Paystack;
