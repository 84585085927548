import React from "react";
import { useSelector } from "react-redux";

export const Footer=()=>
{
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  left: '2em' }
  }
  else if(size < 767)
  {
   return {  left: '0em' }
  }
  else {
    return {  left: '12em' }
  }
   }
    return (
        <footer style={ Contrast()}>
  <p>
  {`Copyright © ${new Date().getFullYear()} Squareswap Inc. All rights reserved.`}
    </p> 
</footer>
    )
}