import react, { useEffect, useState } from 'react'
import Headerbar from './Headerbar'
import Sidebar from './Sidebar/Sidebar'
import { Footer } from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import { seterrorCode, setmessage } from '../Redux/Admin/AdminStore'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../pages/Firebase'
import axios from 'axios'
import loadimg from '../../img/bx_loader-circle.png'

const AddAdmin=()=>
{
    const dispatch=useDispatch()
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu,NavMobileMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
   const errostyle={
    borderColor:'red'
   }
   const buttonstyle={
    opacity:'0.5'
   }
   /*************** ADMN DATA *********/
   const [newAdmin,setnewAdmin]=useState([])
   const [Loading,setLoading]=useState(false)
   function addAdmin(event)
   {
    setLoading(false)
    const {name,value,type}=event.target
    setnewAdmin((previous) => {
      return {
          ...previous,
          [name]:type == 'checkbox' ? 'checkbox' : value
      }
    })
    
   }
//    console.log(newAdmin)
   function Handlersubmit(e)
   {
   e.preventDefault()

     /******** Firebase Auth *****/
     createUserWithEmailAndPassword(
        auth,
        newAdmin.email,
        newAdmin.password
    ).then((auth)=>{
        setLoading(true)
    }).catch((error)=>{
        const errorCode=error.code
        if( errorCode === 'auth/email-already-in-use'){
            dispatch(setmessage(('Email Already Exits !')))
            dispatch(seterrorCode(201))
            setLoading(false)
        }
        else if( errorCode === 'auth/weak-password')
        {
            dispatch(seterrorCode(201))
            dispatch(setmessage(('Password too weak')))
            setLoading(false)
                
        }
        else{
            setLoading(false)
            
        }
        
    })
   } 
   useEffect(()=>{
    async function New_Admin()
    {
        if(Loading){
            axios.post('api/addadmin',newAdmin)
            .then((res)=>{
                setLoading(false)
                dispatch(seterrorCode(res.data.status))      
           dispatch(setmessage(res.data.data))      
              })
        }
    }
    New_Admin()
 },[newAdmin,Loading])
    return (
     <>
     
   <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>

<div className="admin-wrapper-col-8">
        <div className="admin-topic">Add Admin</div>
            
    <div className="admin-form-group-add">
        <h4>Welcome back Admin</h4>
        
        <form onSubmit={Handlersubmit} method='post'>
            <div className="form-group">
            <div className="admin-form-input">
                <label htmlFor="">Admin Role</label>
                <div className="select2">
                 <select id="payment" defaultValue="" required name='role' onChange={addAdmin}>
                        <option value=""  disabled>select option</option>
                        <option value="2">Super Admin</option>
                        <option value="1">Admin</option>
                        <option value="0">Staff</option>
               
                    </select>
                    
            </div>
            </div>
            <div className="admin-form-input">
                <label htmlFor="">First Name</label>
                <input type="text" required name='firstname' onChange={addAdmin}/>
            </div>
            <div className="admin-form-input">
                <label htmlFor="">Last Name</label>
                <input type="text" required onChange={addAdmin} name='lastname'/>
            </div><div className="admin-form-input">
                <label htmlFor="">Email Address</label>
                <input type="email" required name='email' onChange={addAdmin}/>
            </div><div className="admin-form-input">
                <label htmlFor="">Password</label>
                <input type="password" required name='password' onChange={addAdmin}/>
            </div><div className="admin-form-input">
                <label htmlFor="">Confirm Password</label>
                <input type="password"
                style={
                    newAdmin.password !== newAdmin.confirm_password
                      ? { ...errostyle, color: '#f00' } 
                      : { color: 'black' }} required name='confirm_password' onChange={addAdmin}/>
            </div>
</div>
{newAdmin.password !== newAdmin.confirm_password ?
<button className="submit" disabled style={buttonstyle}>Save</button>
    :
    <button className="submit">{Loading ? 
        <img src={loadimg} className="spin-icon" /> :
        'Save'
    
    } </button>

    }    </form>
    </div>

        </div>
        <Footer/>
    </div>

</>
)
    }
    export default AddAdmin
