import React from "react";
import { Route, Navigate } from "react-router-dom";

const PrivateRoute = ({ children}) => {
  const isAuthenticated = sessionStorage.getItem("authenticated");
 
  return  isAuthenticated ? children : <Navigate to="/login" />
    
};

export default PrivateRoute;
