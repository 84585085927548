import React from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTheme } from "../Redux/MoodlightSlice";
import  ModalFund from '../pages/ModalFund'
import MovingText from "react-moving-text";
import axios from "axios";
import { AiFillInfoCircle, AiFillPlusSquare, AiOutlineInfoCircle, AiOutlinePlusSquare } from "react-icons/ai";
import { FaBitcoin, FaDollarSign, FaEthereum, FaFunnelDollar, FaSearch, FaTimes, FaWallet } from "react-icons/fa";
import { RiExchangeCnyLine } from "react-icons/ri";
import { BiDollar, BiMoneyWithdraw }from  "react-icons/bi";
import { Link } from "react-router-dom";
import naira from '../../img/naira.png'
import { useNavigate } from "react-router-dom";
import {  MdAccountBalanceWallet, MdArrowOutward, MdOutlineCurrencyExchange } from "react-icons/md";
import { FcLock} from "react-icons/fc";
import Transaction from "./Transaction";
import { IoMdSwap, IoMdWallet } from "react-icons/io";
import { QRCodeSVG } from "qrcode.react";
import { HiOutlineDotsVertical, HiReceiptRefund } from "react-icons/hi";
import { BsArrowDownLeft, BsExclamationCircle } from "react-icons/bs";
import Balance from "./Balance";
import { setHistory,setWallet,setwalletAddress,setdataNotFound } from "../Redux/Balanceslice";
import Paystack from "../pages/Paystack";
import Verificationquery  from "./Trade/VerifyQuery";
import loadimg from '../../img/bx_loader-circle.png'
import { setmessage, setpaystackModal } from "../Redux/Createslice";
import ScrollToTop from "../pages/ScrollToTop";
import { SiTether } from "react-icons/si";
import walet from '../../img/ant-design_credit-card-filled.svg'
import Address from "./Address";
import copy from 'clipboard-copy';
import Currency from "./Currency";
const Dashboard=()=>
{
  
  const profile = useSelector((state) => state.ProfilePageslice.profile);
 
  const{wallets,walletAddress,History,dataNotFound}=useSelector((state)=>state.Balanceslice)
   const dispatch=useDispatch()
    const userId= `${profile.id}`
    const[copied,setcopied]=useState(false)
  const [currencyValue,setcurrencyValue]=useState('')
    const Navigate = useNavigate()
    const[deposit,setdeposit]=useState([])
    const[showModal,setshowModal]=useState(false)
    const[closecurrency,setclosecurrency]=useState(false)
   const[showloadModal,setshowloadModal]=useState(false)
    function Handlerclose(){
      setisLoading(false)
      setshowModal(false);
      setshowloadModal(false)
       setcopied(false)
      setclosecurrency(false)
    }   
    function Handlercurrencyaction() {
      setclosecurrency(false)
      setisLoading(false)
      setshowModal(false);
      setshowloadModal(false)
       setcopied(false)
    } 
    function HandlerCurrency(action) {
      setclosecurrency(false)
      setcurrencyValue(action)
       setshowModal(true)
    } 
   function Handleropen(action) {
      setcurrencyValue(action)
       setshowModal(true)
    } 
    function DesktopCurrency(event)
    {
      setcurrencyValue(event.target.value)
      setshowModal(true)
    }
    /****************  FETCH USER WALLET ADDRESS OUT *******************/
    useEffect(() => {
      if(currencyValue){
    async function Transaction() {
      axios
         .get(`api/update_wallet_address/${currencyValue}`)
         .then((response) => {
         
         dispatch(setwalletAddress(response.data));
           setshowloadModal(true)
        })
 
}
Transaction()
      }
}, [currencyValue])

/******************** COPY ADDRESS***************/
  const textToCopy = `${walletAddress ? walletAddress.address : null}`
    
     const handleCopy = (addresss) => {
    copy(addresss);
    setcopied(true)
   
  };
    /************************ END ************************/
    function LoadTrade()
    {
         Navigate(`/trade`,{replace :true})
    }
    function Amount(event) {
        const { name, value, type, checked } 
        = event.target;
        setisLoading(false)
        if (name == 'amount' && (!(/^\d+$/.test(value))  || value <= 0 )) {
            setdeposit(prevData => (
                { ...prevData, 
                    amount: null }));
          }
          else{
        setdeposit((catchform) => {
          return {
            ...catchform,
            [name]: type === "checkbox" ? checked : value,
          };
        });
    }
      }
  
     const[isLoading,setisLoading]=useState(false)
    
    /*****************  WALLET *****************/
  
  // const[fetchedWallet,setfetchedWallet]=useState(true)
// useEffect(()=>{
//     async function wallet(){
//         if(fetchedWallet){
//         axios.get(`api/Wallets_Balance`)
//     .then((response) => {
//         dispatch(setWallet(response.data.data));
//         setfetchedWallet(false)
       
//     })
//     }
// }
//     wallet()
// },[userId,fetchedWallet])
// useEffect(() => {
//   const walletData = async () => {
//       try {
//           const response = await axios.get(`api/Wallets_Balance`)
//           dispatch(setWallet(response.data.data));
         
//       } catch (error) {
//           // console.error('Error fetching transaction data:', error);
//       }
//   };

//   // Fetch data initially
//   walletData();

//   // Fetch data every 50 seconds
//   const intervalId = setInterval(walletData, 50000); // 50 seconds in milliseconds

//   // Clean up interval to avoid memory leaks
//   return () => clearInterval(intervalId);
// }, [dispatch,  userId]);
  /******************** END ***************/
  
//  useEffect(() => {
//   const fetchData = async () => {
//       try {
//           const response = await axios.get('api/Transaction');
//           dispatch(setHistory(response.data));
//           if (response.data.length === 0) {
//               dispatch(setdataNotFound('You are yet to make any transactions'));
//           }
//       } catch (error) {
//           // console.error('Error fetching transaction data:', error);
//       }
//   };

//   // Fetch data initially
//   fetchData();

//   // Fetch data every 50 seconds
//   const intervalId = setInterval(fetchData, 50000); // 50 seconds in milliseconds

//   // Clean up interval to avoid memory leaks
//   return () => clearInterval(intervalId);
// }, [dispatch, setHistory, setdataNotFound, userId]);

/******************* SEARCH  Query ************/

const [searchQuery, setSearchQuery] = useState("");
const [filteredHistory, setFilteredHistory] = useState([]);

const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    const filtered = History.filter((transaction) => {
      // Customize the filtering logic based on your requirements
      const lowerCaseQuery = query.toLowerCase();
      return (
        transaction.activities.toLowerCase().includes(lowerCaseQuery) ||
        transaction.time.toLowerCase().includes(lowerCaseQuery) ||
        transaction.status.toLowerCase().includes(lowerCaseQuery) ||
        transaction.realMoney.toLowerCase().includes(lowerCaseQuery) ||
        transaction.type.toLowerCase().includes(lowerCaseQuery) 
      );
     
    });
  
    setFilteredHistory(filtered);
  };
  
/****************** END *****/
  //   Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);

  // Calculate the index of the first and last product to show on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // Extract the subset of product data to show on the current page
  const currentProducts = History.slice(indexOfFirstProduct, indexOfLastProduct);

  // Generate the product components for the current page
  const displayedHistory = searchQuery ? filteredHistory : currentProducts;

  const historyTransaction = displayedHistory.map((sourceElement,index) => {
      return (
         <Transaction 
       key={index}
            activity={sourceElement.activities}
            type={sourceElement.type}
            status={sourceElement.status}
            time={sourceElement.time}
            amount={sourceElement.realMoney}
            />
             
      );
  });

  const historyTransaction_mobile = displayedHistory.map((sourceElement,index) => {
  let  colorcondition=''
  function walletIcon(){
 
  if((sourceElement.activities == 'Wallet top up') 
  || (sourceElement.activities == 'Received')
     || (sourceElement.activities == 'Re-Fund')
  || (sourceElement.activities == 'Exchanged'))
{
 return <MdArrowOutward/>
}
else if(sourceElement.activities === 'Withdraw')
{
  return <BsArrowDownLeft/>
}else{
  return <IoMdSwap/>
}
  }
    if(sourceElement.status == 'Pending'){
      colorcondition=  <td className='pend'><BiDollar/>{sourceElement.realMoney}</td>
   }
   else if(sourceElement.status == 'Success'){
    colorcondition=  <td className='success'><BiDollar/>{sourceElement.realMoney}</td>
   }
   else{
    colorcondition=  <td className='error'><BiDollar/>{sourceElement.realMoney}</td>
   }
    return (
      <tbody key={index}>
      <tr>
      <td>
        {walletIcon()}  
         
     </td>
      <td>
          <p>{sourceElement.activities}</p>
          <h3>{sourceElement.time}</h3>
      </td>
     {colorcondition}
      </tr>
      </tbody>
           
    );
});

  // Calculate the total number of pages based on the total number of products
  const totalPages = Math.ceil(History.length / productsPerPage);

  // Handle the click event for the "next" button
  const handleNextClick = () => {
      if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
      }
  };

  // Handle the click event for the "previous" button
  const handlePreviousClick = () => {
      if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
      }
  };

    /**************** END  *******/
      /************ FUND WALLET CRYPTO *********/
    function handlersubmit(e) {
        e.preventDefault();
        setisLoading(true)
        // const depositParams = new URLSearchParams({
        //     // user_id: userId,
        //     amount: deposit.amount
        //   }).toString();
        //     const redirectUrl = `https://squaremart.azj.wbp.mybluehost.me/api/deposits?${depositParams}&token=${token}`;
        
        // window.location.href = redirectUrl;
    
    
      }
      const amounts={
        amount:deposit.amount
      }
    /************* END *************/
  useEffect(()=>{
    async function Deposits(){
      if(isLoading){
        if(currencyValue === 'BTC'){
      axios.post('api/btcdeposits',amounts)
      .then((res)=>{
        window.location.href = res.data.redirect_url;
        setisLoading(false)
      })
    }  
    else if(currencyValue === 'ETH'){
      axios.post('api/ethdeposits',amounts)
      .then((res)=>{
         window.location.href = res.data.redirect_url;
        setisLoading(false)
      })
    }  
    else if(currencyValue === 'USDT'){
      axios.post('api/ethdeposits',amounts)
      .then((res)=>{
         window.location.href = res.data.redirect_url;
        setisLoading(false)
      })
    }  
    else {
      axios.post('api/btcdeposits',amounts)
      .then((res)=>{
        window.location.href = res.data.redirect_url;
        setisLoading(false)
      })
    }  
  }  
  }
  Deposits()
  },[isLoading])
    
      /***************** COINBASE REDIRECT MESSEAGE *****/
     const urlParams = new URLSearchParams(window.location.search);
     const responseMsg = urlParams.get('responseMsg');
     const {paystackModal,Attempt,message}=useSelector((state)=>state.Createslice)

     useEffect(()=>{
      if(responseMsg){
        dispatch(setmessage(responseMsg))
      }
        
     },[responseMsg])
 
     /***************** END ******/
    
       /******************* WINDOW SIZE *****/
    const {size}=useSelector((state)=>state.WindowSize)
   
    const[readyDot,setreadyDot]=useState()
const[actnO,setacTions]=useState()
function DotDisplay(action)
{
  setreadyDot(true)
setacTions(action)
}
const{theme}=useSelector((state)=>state.Modelight)
/*************** Paystack Payment ******/
  function Handlerpaystack(action)
  {
    setcurrencyValue(action)
    dispatch(setpaystackModal(true))
  }
  function withdrawal()
  {
    Navigate(`/withdrawal`,{replace :true})
  }
 
  /********* curreny mobile */
  function currency(event)
  {
    setcurrencyValue(event.target.value)
    
    }
    
    function walletAmount()
    {
      if(currencyValue == 'ETH'){
        return <p className="result">{wallets && wallets.eth && wallets.eth.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </p>
      }
    else  if(currencyValue == 'BTC'){
        return <p className="result">{wallets && wallets.btc &&  wallets.btc.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </p>
      }
      else if(currencyValue == 'NGN'){
           return <p className="result">{wallets && wallets.naira &&  wallets.naira.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
         </p>
      }
      else if(currencyValue == 'USDT'){
        return <p className="result">{wallets && wallets.usdt && wallets.usdt.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</p>
      }
    }
    function walletAmountUSD()
    {
      if(currencyValue == 'ETH'){
        return <h4>${wallets && wallets.eth_amount && wallets.eth_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </h4>
      }
    else  if(currencyValue == 'BTC'){
        return <h4>${wallets && wallets.btc_amount && wallets.btc_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
      }
      else if(currencyValue == 'NGN'){
           return <h4>{wallets && wallets.naira_usd &&  wallets.naira_usd.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </h4>
      }
      else if(currencyValue == 'USDT'){
        return <h4>{wallets && wallets.usdt_amount && wallets.usdt_amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
        </h4>
      }
      else{
        return <h4>${wallets.balance ?? '0.00' }</h4>
     
      }
    }
    return (
      // <body className={theme == "light" ? "light" : "dark"}>
    <>   
  
    <Auth/>
    <Verificationquery/>
    <div className="container-fluid">
    <Sidebar/>
    <header>
<Header/>
<h1>Dashboard</h1>

<div className="nav">
  
{ message  ?
             
             <MovingText
  type="slideInFromRight"
  duration='350ms'
  className={message == 'Payment Successful' ? "sucessmsg": "errormsg"}
  delay='0s'
  direction='alternate'
  //    timing='ease'
  iteration='1'
  fillMode='none'> 
   <p style={{ fontSize:'15px' }}>{message }</p></MovingText> 


 : null }
                <ul>
                    <li><a >Balances</a></li>
               
                    <li><a href="#transaction">Transactions</a></li>
                
                    </ul>
                    
                <div className="add-new">
                  {/* <Link onClick={()=>Handleropen('BTC')} className="fund-general"> */}
                  <Link className="fund-general">
                    <span>Fund Wallet</span>
                      <span><FaWallet/></span> 
                    <select defaultValue='' onChange={DesktopCurrency} id="">
                  <option disabled='disabled' value="">Select Currency</option>
                  <option value="BTC">BITCON</option>
                       <option value="ETH">ETHEREUM</option>
                    <option value="USDT">TETHER TRC20</option>
                    <option value="USDTERC20">TETHER ERC20</option>
                   
                   </select>
                  
                    </Link>
             
                  
                    <Link to="/Addproduct" className="products">
                    <span>Add product</span> 
                    <span><AiOutlinePlusSquare/></span> 

                    </Link>
                    
                </div>
                
            </div>
           {/* BALANCE */}
           <Balance/>
           <div className="Balance">
        <div className="available">
            <div className="bal">
                 <p>{size >767 ? 'Available Balance' :'Available' }</p>
                 <p className="amount">= {wallets.balance ?? '0.00'} SUSD</p>
            </div>
            <div className="icon">
                <MdAccountBalanceWallet/>
                {/* <AiOutlineInfoCircle/> */}
            </div>
        </div>
        <div className="pending">
            <div className="bal">
                 <p>{size >767 ? 'Pending Balance' : 'Pending'}</p>
                 <p className="amount">= {wallets.pending_balance ?? '0.00'} SUSD</p>
            </div>
            <div className="icon">
                <FcLock/>
            </div>
        </div>
        
    </div>
    </header>
    {/* WALLET */}
    <div className="bal-info">
            <div className="info">
                <p className="current"><SiTether style={{ color:'green' }}/> <span>Tether Wallet</span> </p>
                <div className="amt">
<p> <b>
  <span>  {wallets && wallets.usdt &&  wallets.usdt.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT</span> 
  <span><small>=   {wallets && wallets.usdt_amount && wallets.usdt_amount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</small></span></b>
 
 </p>
 { actnO != 1  ?   
 <BsExclamationCircle onClick={()=>DotDisplay(1)} className="dot-icon"/>
: null }
 </div>
                {size > 767 || actnO == 1  ?  
              
            <ul className="icon">
                    <li onClick={withdrawal}> <span style={{cursor:'pointer'}}  className="withicon"><BiMoneyWithdraw/></span> 
                        <p>Withdraw</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={()=>Handleropen('USDT')}  className="depoicon" ><HiReceiptRefund/></span>
                        <p>Deposit</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={LoadTrade} className="exhicon"><MdOutlineCurrencyExchange/></span>
                        <p> Exchange</p>
                    </li>
                </ul>
       


:  
null
                }
            </div>
           
            <div className="info">
                <p className="current"><FaEthereum style={{ fontSize:'22px', color:'#BDD4F1', }}/> <span>Ethereum Wallet</span></p>
                <div className="amt">

                  <p><b><span> {wallets && wallets.eth && wallets.eth.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} ETH</span> 
                  <span><small>=   {wallets && wallets.eth_amount && wallets.eth_amount.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</small></span></b>
                  </p>
                  { actnO != 2  ?   
 <BsExclamationCircle onClick={()=>DotDisplay(2)} className="dot-icon"/>
: null }
                </div>
                {size > 767 || actnO == 2  ?  
                 <ul className="icon">
                     <li onClick={withdrawal}> <span style={{cursor:'pointer'}} className="withicon"><BiMoneyWithdraw/></span> 
                        <p>Withdraw</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={()=>Handleropen('ETH')}  className="depoicon" ><HiReceiptRefund/></span>
                        <p>Deposit</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={LoadTrade} className="exhicon"><MdOutlineCurrencyExchange/></span>
                        <p> Exchange</p>
                    </li>
                </ul>
                
                :
               null
               }

            </div>
            <div className="info">
                <p className="current"><FaBitcoin style={{ color:'#FB8C00' }}/> <span>Bitcon Wallet</span></p>
               
                <div className="amt">
                  
                  <p><b><span>  {wallets && wallets.btc  && wallets.btc.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} BTC</span> 
                  <span><small>=  {wallets && wallets.btc_amount  && wallets.btc_amount.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</small></span></b>
                </p>
                { actnO != 3  ?   
 <BsExclamationCircle onClick={()=>DotDisplay(3)} className="dot-icon"/>
: null }
</div>
                
                {size > 767 || actnO == 3  ?   <ul className="icon">
                <li onClick={withdrawal}> <span style={{cursor:'pointer'}} className="withicon"><BiMoneyWithdraw/></span> 
                        <p>Withdraw</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={()=>Handleropen('BTC')}  className="depoicon" ><HiReceiptRefund/></span>
                        <p>Deposit</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={LoadTrade} className="exhicon"><MdOutlineCurrencyExchange/></span>
                        <p> Exchange</p>
                    </li>
                </ul>
                :
                null
              }

            </div>

            {/* <div className="info">
                <p className="current"><img src={naira} width='20'/><span> Naira Wallet</span></p>
               
                <div className="amt">
                  
                  <p><b><span>
                  {wallets && wallets.naira  && wallets.naira.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</span>
                   <span><small>=  {wallets && wallets.naira_usd  && wallets.naira_usd.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
        </small></span></b>
                </p>
                { actnO != 4  ?   
 <BsExclamationCircle onClick={()=>DotDisplay(4)} className="dot-icon"/>
: null }
</div>
                
                {size > 767 || actnO == 4  ?   <ul className="icon">
                <li onClick={withdrawal}> <span style={{cursor:'pointer'}} className="withicon"><BiMoneyWithdraw/></span> 
                        <p>Withdraw</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={()=>Handlerpaystack('NGN')}  className="depoicon" ><HiReceiptRefund/></span>
                        <p>Deposit</p>
                    </li>
                    <li> <span style={{cursor:'pointer'}} onClick={LoadTrade} className="exhicon"><MdOutlineCurrencyExchange/></span>
                        <p> Exchange</p>
                    </li>
                </ul>
                :
                null
              }

            </div> */}
        </div>
    {/* WALLET END */}
    {/* latest Transaction */}

    <div className="transaction" id="transaction">
            <h3>Latest Transactions Histroy</h3>
            <div className="transaction-head">
                <div className="btn-export">
                    {/* <button>Excel</button>
                    <button>PDF</button>
                    <button>PRINT</button> */}
                </div>
                <div className="search">
                    <form action="">
                 <div className="input-wrapper">
                    <input
      type="text"
      placeholder="Search.."
      value={searchQuery}
      onChange={handleSearchChange}
    />  
      <FaSearch className="search-icon" />
     </div>
     </form>
</div>
      </div>
            <div className="table">
            {displayedHistory.length > 0 ? (
  <>
    <table>
      <thead>
        <tr>
          <th>Activities</th>
          <th>Status</th>
          <th>Type</th>
          <th>Date</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        { historyTransaction }
      </tbody>
    </table>
    <div className="pagination">
      <div className="paginate">
        <button className={currentPage === 1 ? 'disabledButton' :''} onClick={handlePreviousClick}>
          Previous
        </button>
        <button className={currentPage === totalPages ? 'disabledButton' :''} onClick={handleNextClick}>
          Next
        </button>
      </div>
    </div>
  </>
) : (
  <div className="empty-table">{dataNotFound}</div>
)}
            </div>
        </div>
    {/* END */}

     {/* WALLET MOBILE  */}
     <div className="wallet-mobile">
     { message  ?
             
             <MovingText
  type="slideInFromRight"
  duration='350ms'
  className={message == 'Payment Successful' ? "sucessmsg": "errormsg"}
  delay='0s'
  direction='alternate'
  //    timing='ease'
  iteration='1'
  fillMode='none'> 
   <p style={{ fontSize:'15px' }}>{message }</p></MovingText> 


 : null }
    <div className="wallet-dash">
        <div className="dash-header">
            <div className="dash-box">
                <div className="balance-text">
                <p>Wallet Balance</p>
                {walletAmountUSD()}
            </div>
<div className="pending-text">
                <p>Pending <FcLock/> </p>
                <p>{wallets.pending_balance ?? '0.00'} USD</p>
            </div>
            </div>
            <div className="dash-box">
                <select defaultValue='' onChange={currency} id="">
                <option disabled='disabled' value="">BTC</option>
                  <option value="BTC">BTC</option>
                   <option value="ETH">ETH</option>
                    <option value="USDT">USDT</option>
                    {/* <option value="NGN">NGN</option> */}
                  
                   
                </select>
               {walletAmount()}
            </div>
        </div>
        <div className="dash-footer">
            <button onClick={withdrawal}>Withdraw</button>
            {currencyValue == 'NGN' ?
             <button onClick={()=>Handlerpaystack('NGN')}>Fund Wallet</button>
             : <button onClick={()=>setclosecurrency(true)}>Fund Wallet</button>
            //  :  <button onClick={()=>setclosecurrency(`${currencyValue}`|| 'BTC')}>Fund Wallet</button>
      }
             </div>
       
    </div>
    <div className="trans-table">
        <h1>Latest Transactions</h1>
        {historyTransaction_mobile.length > 0 ? (
        <table>
                {historyTransaction_mobile}
               
        </table>
        ) : (
          <div className="empty-table">{dataNotFound}</div>
        )}
    </div>
 </div>
    </div>

   
    {/* MODAL FUND */}
    {showModal && showloadModal ? <Address
                                    switchButton={copied}
                                    handleCopy={handleCopy}
                               Handlerclose={()=>Handlerclose()}/>
: null} 
{/* wallet address fetch */}
 {  closecurrency ? <Currency
      HandlerCurrency={HandlerCurrency}
      Handlercurrencyaction={()=>Handlercurrencyaction()}/> : null }   
    {/* END */}
    {/* PAYSTACK */}
    {paystackModal ?   <Paystack/> : null }
  
</>
    )
}
export default Dashboard
