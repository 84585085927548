import React, { useEffect } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { BsBellFill } from 'react-icons/bs'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { WindowResize } from '../Redux/WindowSize'
import { setNavMenu } from '../Redux/Admin/Toggle'

const Header=()=>
{
      
    const dispatch=useDispatch()
    const {NavMenu}=useSelector((state)=>state.ToggleNav)
    /*********** Mobile Toggle *********/
    function NavMenuToggle()
    {
    dispatch(setNavMenu(!NavMenu))
    }

    /************* Window size ******/

    const {size}=useSelector((state)=>state.WindowSize)
    useEffect(()=>{
        function windowWidth(){
          dispatch(WindowResize(window.innerWidth))
        }
        window.addEventListener("resize", windowWidth);
        return function () {
          window.removeEventListener("resize", windowWidth);
        };   
      },[])
    return (
        <div className="admin-navbar">
    <div className="left">
      <AiOutlineMenu onClick={NavMenuToggle}  className='mobile-icon'/>
      
       
    </div>
    <div className="middle">
      {/* <input type="text" placeholder="Search..."/> */}
    </div>
    <div className="right">
    <BsBellFill style={{ color:'black' }} className='mobile-icon'/>
     {size > 789 ? 
     <Link to="#">
     <RiLogoutBoxRLine style={{ color:'black' }} className='mobile-icon'/>
    Logout</Link>
    :
    <Link to="#">
     <RiLogoutBoxRLine style={{ color:'black' }} className='mobile-icon'/>
    </Link> 
    }
      
    </div>
</div>
    )
}
export default Header