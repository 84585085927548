import React from 'react'
import logo from '../../img/squaremart-logo.png'
import { BsBellFill } from 'react-icons/bs'
import { AiOutlineMenu } from 'react-icons/ai'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { setNavMenu, setNavMobileMenu } from '../Redux/Admin/Toggle'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { WindowResize } from '../Redux/WindowSize'
import { useState } from 'react'
import avatar from '../../img/avatar.jpg'
import { FaTimes } from 'react-icons/fa'
import { setAvailableGiftCard, setCategory, setDocumentsData, setdataNotAccount, seterrorCode, setmessage } from '../Redux/Admin/AdminStore'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Headerbar=()=>
{
  const Navigate = useNavigate()
   /******* AvailableGiftCard *****/
 useEffect(()=>{
  async function AvailableGiftCard()
  {
    axios.get('api/admin/AllGiftCard')
    .then((res)=>{
      dispatch(setdataNotAccount('Table is Currently Empty'))
  dispatch(setAvailableGiftCard(res.data))
  // console.log(res.data)
    })
    .catch(error => {
    //  console.log(error.response.data.status)
    });
  }
  AvailableGiftCard()
},[])
/********* END *****/
   /******* Category *****/
 useEffect(()=>{
  async function Category()
  {
    axios.get('api/Category')
    .then((res)=>{
      dispatch(setdataNotAccount('Table is Currently Empty'))
  dispatch(setCategory(res.data))
    
    })
    .catch(error => {
    //  console.log(error.response.data.status)
    });
  }
  Category()
},[])
/********* END *****/
   /******* Document *****/
 useEffect(()=>{
  async function DocumentData()
  {
    axios.get('api/allUserDocForVerification')
    .then((res)=>{
      dispatch(setdataNotAccount('Table is Currently Empty'))
  dispatch(setDocumentsData(res.data))
    
    })
    .catch(error => {
    //  console.log(error.response.data.status)
    });
  }
  DocumentData()
},[])
/********* END *****/
    const dispatch=useDispatch()
    const {message,errorCode}=useSelector((state)=>state.AdminStore)
    useEffect(()=>{
      if(message){
        setTimeout(() => {
          dispatch(seterrorCode())
          dispatch(setmessage())
        },6000); 
      }
  })
  function CloseMessage()
  {
    dispatch(seterrorCode())
          dispatch(setmessage())
  }
    const {NavMenu}=useSelector((state)=>state.ToggleNav)
    /*********** Mobile Toggle *********/
    function NavMenuToggle()
    {
    dispatch(setNavMenu(!NavMenu))
    }

    /************* Window size ******/

    const {size}=useSelector((state)=>state.WindowSize)
    useEffect(()=>{
        function windowWidth(){
          dispatch(WindowResize(window.innerWidth))
        }
        window.addEventListener("resize", windowWidth);
        return function () {
          window.removeEventListener("resize", windowWidth);
        };   
      },[])
       
      function NavMenuMobileToggle()
      {
        dispatch(setNavMobileMenu(true))
      }
      /********* NOtification  ****/
      const[openBOx,setopenBOx]=useState(false)
      function Notificationbox()
      {
        setopenBOx(previous => !previous)
      }
    function logout()
    {
    
      sessionStorage.removeItem("admin_authenticated")
      Navigate('/Admin/login',{replace :true})
    }
    return (
      <>
              <header>
        <div className="admin-header left-sec"> 
        <AiOutlineMenu onClick={NavMenuMobileToggle} id="opennav" className='mobile-icon'/>
       
         <AiOutlineMenu onClick={NavMenuToggle}  id="expand" style={NavMenu ? {marginLeft:'4em'} : {marginLeft:'9em'}}  className='mobile-icon'/>
         
         <AiOutlineMenu onClick={NavMenuToggle} style={NavMenu ? {marginLeft:'4em'} : {marginLeft:'9em'}} id="contrast" className='mobile-icon'/>
          
        </div>
        <div className="admin-header mid-sec">
           <img src={logo} alt=""/>
      
        </div>
        <div className="admin-header bell"  onMouseOver={Notificationbox}>
        <BsBellFill className='mobile-icon'/>
            <span>2</span>

        </div>
        <div className="admin-header right-sec" onClick={logout}  style={{ cursor:'pointer' }}>

         <RiLogoutBoxRLine className='mobile-icon'/>
         <span> Logout </span>
        </div>
        {/* <hr/> */}
         </header>
         {/* <div className="loading-wave"></div> */}
  
   {message ? 
   <div className={errorCode == 200 ? "notification-message-success" : "notification-message-error"}>
        <div className="notice-head">
            <li>{message}</li>
            <li><FaTimes onClick={CloseMessage}/></li>
        </div>
        <hr/>
       
    </div>
    : null}
    
    {openBOx ?
    <>
    <div className="notification">
        <div className="notice-head">
            <li>notifications</li>
            <li>clear all</li>
        </div>
        <hr/>
        <div className="notification-messages">
       
            <ul><li><span><img src={avatar}/></span></li><li><p>Larry Added a new task today 28th of August 2023</p></li>  </ul>
            <ul><li><span><img src={avatar}/></span></li><li><p>Larry Added a new task</p></li>  </ul>
            <ul><li><span><img src={avatar}/></span></li><li><p>Larry Added a new task</p></li>  </ul>
            <ul><li><span><img src={avatar}/></span></li><li><p>Larry Added a new task</p></li>  </ul>
            <ul><li><span><img src={avatar}/></span></li><li><p>Larry Added a new task</p></li>  </ul>
               
        </div>
        <div className="viewall">
            <a href="">view all notifications</a>
        </div>
    </div></>
    : null
} 

     
    </>
    )
}
export default Headerbar