import axios from "axios";
import React, { useEffect, useState } from "react";
import { setverified } from "../../Redux/Tradeslices";
import { useDispatch, useSelector } from "react-redux";
import { setnotificationbox } from "../../Redux/Createslice";

    
 
const Verificationquery=()=>
{
   
return null
}
export default Verificationquery
