import React, { useEffect, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MovingText from 'react-moving-text'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setmsg } from "../../Redux/Createslice";
import Verificationquery from "./VerifyQuery";
import { IoIosArrowBack } from "react-icons/io";

const CreateUsdtad = () => {
      const [errorField, seterrorField] = useState({})
    
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
      const [isLoading, setisLoading] = useState(false)
    const [CardData, setCardData] = useState([])
    const[other,setother]=useState(false)
  
    const [editor, seteditor] = useState({
        vendor_id: userId,
        selling_usdt: 'USDT',
    });
      /************ type other card to sell *****/
      useEffect(() => {
        if(editor.accept_giftcard === '')
        {
            setother(true)
        }
       
      }, [editor.accept_giftcard]);
    //   console.log(editor)
    function InputDataValueOther(event)
    {
        seterrorField({})
        dispatch(setmsg())
        const { name, value, type, checked } = event.target;
              seteditor((prevData) => {
            const updatedData = {
              ...prevData,
        [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
    }
  /**************END */
    const {msg}=useSelector((state)=>state.Createslice)
    const [verifyload,setverifyload]=useState(false)
const dispatch=useDispatch()
    function SubmitHandler(e) {
        e.preventDefault()
       setisLoading(true)
       setverifyload(true)
       dispatch(setmsg())
    setCardData(editor)
}
  
    function TextEditor(text) {
        dispatch(setmsg())
        setisLoading(false)
        setverifyload(false)
        seterrorField({})
        seteditor((prevData) => {
            const updatedData = {
                ...prevData,
                terms: text,
            };
            return updatedData;
        });
    };
    
    function InputDataValue(event) {
      
        seterrorField({})
        dispatch(setmsg())
        setisLoading(false)
        setverifyload(false)
        const { name, value, type, checked } = event.target;
        seteditor((prevData) => {
            const updatedData = {
                ...prevData,
                vendor_id: userId,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
    }
   
    /************** ALL GIFTCARD ******/
    const {allgiftcard}=useSelector((state)=>state.SwapCard)
   

    const cardAvalaible = allgiftcard.map((element,index) => {
        return (
<>
            <option key={index} value={element.cards}>{element.cards}</option>
           

</>
        )
    })
    /********** END ************/
    /*************** CREATE NEW USDT TO SELL ********/
    /************ CheckVefication ********/
const{verified}=useSelector((state)=>state.Tradeslices)
const badge=verified && verified.verify_badge
const Priceproduct=editor && editor.amount

    const[Errored,setErrored]=useState(false)
    useEffect(() => {
        async function CreateUsdt() {
            if(verifyload)
            {
            if((badge == 0))
            {
                dispatch(setmsg('UnAuthorized kindly Verify your account'))
               setisLoading(false)
               setverifyload(false)
               setErrored(true)
            }
            else  if((badge == 1) && (Priceproduct > 500))
            {
              
                dispatch(setmsg('UnAuthorized kindly Verify your account'))
                setisLoading(false)
                setverifyload(false)
                setErrored(true)
            }
            else  if((badge == 2) && (Priceproduct > 2000)){
               dispatch(setmsg('UnAuthorized kindly Verify your account'))
               setisLoading(false)
               setverifyload(false)
               setErrored(true)
            }
             else if (isLoading) {
                axios.post(`api/NewUsdtSell`,
                    CardData)
                    .then((response) => {
                        if (response.data.status === 500) {
                            dispatch(setmsg(response.data.data))
                            setisLoading(false)
                            setverifyload(false)
                        }
                        else if (response.data.status === 200) {
                            setErrored(false)
                           seteditor({
                                vendor_id: userId,
                                selling_usdt: 'USDT'
                            })
                            dispatch(setmsg(response.data.data))
                            setisLoading(false)
                            setverifyload(false)
                            
                        }
                        else if (response.data.status === 419) {
                            setErrored(true)
                            dispatch(setmsg( <> Field Required
                                <FaExclamationCircle />
                                </>
                              ))
                            seterrorField (response.data.errors)
                            setisLoading(false)
                            setverifyload(false)
                        }


                    })
            }
        }
        }
        CreateUsdt()

    }, [CardData, isLoading])
    
    return (
        <>
            <Auth />
<Verificationquery/>
            <div className="container-fluid">
                <Sidebar />
                <header>
                    <Header />
                </header>
                <hr />
                <section>
                    <div className="head">
        <span><Link to='/trade'><IoIosArrowBack/></Link></span>

                        <p>Create Offer To Sell Currency </p>
                    </div>
                    <div className="content">
                        {msg ?

                           
                                // <div className={msg == 'Error Please Try again' ? "errormsg" : "sucessmsg"}>
                                   <MovingText
                                type="slideInFromRight"
                                duration='350ms'
                                delay='0s'
                                direction='alternate'
                                className={Errored || msg == 'Error Please Try again' ? "errormsg" : "sucessmsg"}
                                iteration='1'
                                fillMode='none'><p> {msg}</p></MovingText>
                            : ''}
                        <div className="form-class">
                            <form action="" onSubmit={SubmitHandler} method="post">
                                <div className="form-input">
                                    <label htmlFor="">Choose Option</label>
                                    <select name="accept_giftcard" id="" onChange={InputDataValue} >
                                        <option value=''>Select GiftCard</option>
                                        {cardAvalaible}
                                        <option value=''> Other</option>
                                    </select>
                                </div>
                                {( other) ?
                                <div className="input">
                                    <label htmlFor="">Enter Other selling card</label>
                                    <input type="text" name="accept_giftcard"
                                        // defaultValue={editor.accept_giftcard   || ''} 
                                        id="" onChange={InputDataValueOther} placeholder="selling card" />
                                   
                                </div> : null
                                }
                                <div className="errors">{errorField.accept_giftcard}</div>
                                <br></br>
                                <div className="title">
                                    <p>You are Selling USDT</p>
                                </div>
                                <div className="alert">
                                    <p>for every ${editor.amount ? editor.amount : '0'} you get this {editor.rate ? editor.rate : '0'}% amount value</p>
                                </div>
                                <div className="input">
                                    <label htmlFor="">Amount?</label>
                                    <input type="tel" name="amount"
                                        defaultValue={editor.amount || ''}
                                        id="" onChange={InputDataValue} placeholder="100" />
                                    <div className="errors">{errorField.amount}</div>

                                </div>
                                <div className="input">
                                    
                                        <label htmlFor="">Rate on trade?</label>
                                        <input type="text"
                                            defaultValue={editor.rate || ''}
                                            name="rate" id="" onChange={InputDataValue} placeholder="0.8" />
                                        <div className="errors">{errorField.rate}</div>

                              
                                </div>
                                <div className="input">
                                    <label htmlFor="">Swap with</label>
                                    <input type="text" readOnly style={{ cursor: 'not-allowed' }}
                                     value={editor.accept_giftcard || ''}   placeholder="Gift Card" />
  </div>
                                
                                <div className="form-terms">
                                    <div className="term-title">
                                        <p>Terms of transaction</p>
                                    </div>
                                    <div className="text-field">
                                        <ReactQuill
                                            //   value={this.state.text}
                                            onChange={TextEditor}
                                            style={{ height: "300px", borderRadius: "5px" }}
                                        />
                                        <div className="errors">{errorField.terms}</div>

                                        {/* <textarea name="" id="" placeholder="Enter your terms of offer here |"></textarea> */}
                                    </div>
                                </div>
                                {/* <div className="submit">
                                    <input type="submit" value={isLoading ? "Sending..." : "Proceed"} />

                                </div> */}
                                <div className="submit">
                <button type="submit"><span>{isLoading ? "Sending..." : "Proceed"}</span></button>
                </div>
                            </form>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}
export default CreateUsdtad