import axios from "axios";
import React, { useEffect, useState } from "react";
import { FcLock } from "react-icons/fc";
import { MdAccountBalanceWallet } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setHistory, setWallet, setunreadMsg } from "../Redux/Balanceslice";
import { setdataNotFound } from "../Redux/Createslice";
import { setInboxMSg, setdataNotFoundInbox } from "../Redux/Inboxslices";
import { setallusdt, setdataNotfoundgift, setgiftcard } from "../Redux/Tradeslices";

const Database=()=>
{
    const profile = useSelector((state) => state.ProfilePageslice.profile);

    const userId= `${profile.id}`
    
    const token = sessionStorage.getItem("authenticated");
    const dispatch=useDispatch()
    // const[wallets,setWallet]=useState([])
    const { wallets } = useSelector((state) => state.Balanceslice);
    const walletDispatch = useDispatch();
     const[isLoading,setisLoading]=useState(false)
    const headers = {
        'Content-Type': 'application/json',
        'allowed_origins': '*',
          'Authorization': `Bearer  ${token}`, // Replace with your actual token
    };
    const[fetchedWallet,setfetchedWallet]=useState(true)
    /***************** Wallet query *****/
    // useEffect(()=>{
    //     async function wallet(){
    //         // if(fetchedWallet){
    //         axios.get(`api/Wallets_Balance/${userId}`,
    //         {
    //             headers: headers
    //         })
    //     .then((response) => {
    //         dispatch(setWallet(response.data.data));
    //         setfetchedWallet(false)
           
    //     })
    //     // }
    // }
    //     wallet()
    // },[userId])
    /****************** END *******************/
  /********************* Transaction History ******/

//   useEffect(() => {
    
//     async function Transaction() {
//           axios
//              .get(`api/Transaction/${userId}`,
//                  {
//                      headers: headers
//                  })
//              .then((response) => {
             
//              dispatch(setHistory(response.data));
//              dispatch(setdataNotFound('You are yet to make any transactions'))
           
//              })
     
//  }
//  Transaction()
//  }, [userId])
//   /********************* End *************/

//   /******************* Inbox Message ************/
  
//     // useEffect(()=>{
//     //     async function Messages(){
//     //         axios.get(`api/sms_messages`,
//     //         {
//     //             headers: headers
//     //         })
//     //     .then((response) => {
           
//     //         dispatch(setInboxMSg(response.data));
//     //         dispatch(setdataNotFoundInbox('Inbox Empty'))
           
//     //     })
       
//     // }
//     // Messages()
//     // },[])
//   /************************ End *****/
//   /********************** Giftcard Section table ******/
// //   useEffect(() => {
// //     async function SelectGiftcard() {

// //         axios
// //             .get(`api/viewGiftcard/${userId}`,
// //                 {
// //                     headers: headers
// //                 })
// //             .then((response) => {
// //                 dispatch(setgiftcard(response.data));
// //                 dispatch(setdataNotfoundgift('0ffer Table is Currently Empty'))
              
// //             })

// //     }
// //     SelectGiftcard()
// // }, [userId])
//   /***************** END ***/

//   /****************** usdt section table ****/

//   useEffect(() => {
//     async function SelectUsdt() {

//         axios
//             .get(`api/viewUsdt/${userId}`,
//                 {
//                     headers: headers
//                 })
//             .then((response) => {
//                 dispatch(setallusdt(response.data));
//                 dispatch(setdataNotFound('0ffer Table is Currently Empty'))
              
//             })

//     }
//     SelectUsdt()
// }, [userId])
  /**************** End ****/
    const {size}=useSelector((state)=>state.WindowSize)
    return (
      <></>
    )
}
export default Database