import React from "react";
import { Route, Navigate } from "react-router-dom";

const AdminProctedRoute = ({ children}) => {
  const isFactor = sessionStorage.getItem("Admin_TOTP");
 
  return  isFactor ? children : <Navigate to="/Admin/login" />
    
};

export default AdminProctedRoute;
