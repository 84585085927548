import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allusdt:[],
    dataNotfound:'',
    giftcard:[],
    dataNotfoundgift:'',
    verified:0
}

export const Tradeslices = createSlice({
  name: 'Inbox',
  initialState,
  reducers: {
   
   
    setallusdt: (state, action) => {
      state.allusdt = action.payload
    },
    setdataNotFound: (state, action) => {
      state.dataNotfound = action.payload
    },
    setgiftcard: (state, action) => {
        state.giftcard = action.payload
      },
      setdataNotfoundgift: (state, action) => {
        state.dataNotfoundgift = action.payload
      },
      setverified: (state, action) => {
        state.verified = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {setallusdt,setdataNotFound,setverified,setdataNotfoundgift,setgiftcard } = Tradeslices.actions

export default Tradeslices.reducer