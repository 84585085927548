import react, {useRef, useState } from 'react'
import Headerbar from './Headerbar'
import Sidebar from './Sidebar/Sidebar'
import { Footer } from './Footer'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx';

const AdminExchange=()=>
{
    const{ExchangeTrade,dataNotAccount}=useSelector((state)=>state.AdminStore)
   //   Pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [productsPerPage,setproductsPerPage] = useState(10);
   
   // Calculate the index of the first and last product to show on the current page
   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   
   // Extract the subset of product data to show on the current page
   const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return dateObject.toLocaleDateString(undefined, options);
  };
  /******************* SEARCH  Query ************/

 const [searchQuery, setSearchQuery] = useState("");
 const [filteredHistory, setFilteredHistory] = useState([]);
 
 const handleSearchChange = (event) => {
  const query = event.target.value;
  setSearchQuery(query);

  const filtered = ExchangeTrade.filter((transaction) => {
    const lowerCaseQuery = query.toLowerCase();

    const ref = transaction.ref ? transaction.ref.toLowerCase() : "";
    const swapAmount = transaction.swapAmount
      ? transaction.swapAmount.toLowerCase()
      : "";
      const card_name=transaction.card_name ? transaction.card_name.toLowerCase() : "";
    const type = transaction.type ? transaction.type.toLowerCase() : "";
    const status = transaction.status ? transaction.status.toLowerCase() : "";

    // Check if the query matches any part of the date string
    const dateString = formatDate(transaction.created_at).toLowerCase();
    const isDateMatch = dateString.includes(lowerCaseQuery);

    return (
      ref.includes(lowerCaseQuery) ||
      card_name.includes(lowerCaseQuery) ||
      swapAmount.includes(lowerCaseQuery) ||
      type.includes(lowerCaseQuery) ||
      isDateMatch ||
      status.includes(lowerCaseQuery)
    );
  });

  setFilteredHistory(filtered);
};


   
 /****************** END *****/
      let serialNumberdeposit= 1;
   const currentProductExchangeTrade = ExchangeTrade.slice(indexOfFirstProduct, indexOfLastProduct);
   //  Generate the Withdrawal components for the current page
   const displayedHistory = searchQuery ? filteredHistory : currentProductExchangeTrade;

   const ExchangeTradeTable = displayedHistory.map((sourceElement,index) => {
       function Status()
       {
        if(sourceElement.escrow_status  == 2)
        {
            return (
                <button className="admin-error">Rejected</button>
            )
        }
        else  if(sourceElement.escrow_status  == 1)
        {
            return (
                <button className= 'admin-success'>Accepted</button>
            )
        }
        else  {
            return (
                <button className= 'admin-det'>Pending</button>
            )
        }
       }
        return (
          <tbody key={index}> 
          <tr>
       <td>{serialNumberdeposit++}</td>
       <td>{sourceElement.card_name}</td>
        <td>{sourceElement.ref}</td>
       <td>{sourceElement.type ?? 'USDT'}</td>
       <td>{sourceElement.swapAmount}</td>
       <td>{sourceElement.crytocurrencyAmount}</td>
       <td> {formatDate(sourceElement.created_at && sourceElement.created_at)}</td>
       <td>{Status()}</td>
       <td><button className="admin-det">Detail</button></td>
                              
       </tr>
       </tbody> 
       
       );
   });
   /*********** Next Previous button *************/
   // Calculate the total number of pages based on the total number of products
   const totalPages = Math.ceil(ExchangeTrade.length / productsPerPage);
   
   // Handle the click event for the "next" button
   const handleNextClick = () => {
       if (currentPage < totalPages) {
           setCurrentPage(currentPage + 1);
       }
   };
   
   // Handle the click event for the "previous" button
   const handlePreviousClick = () => {
       if (currentPage > 1) {
           setCurrentPage(currentPage - 1);
       }
   };
   const handleRowChange = (event) => {
       const newValue = parseInt(event.target.value);
       setproductsPerPage(newValue);
     };
   
     /************* END **********/
  
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu,NavMobileMenu}=useSelector((state)=>state.ToggleNav)
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
   /*************** END *******/
     // Create a ref for the table component to print
     const tableRef = useRef(null);
     // Function to export the table data to Excel
     const exportToExcel = async () => {
       const table = tableRef.current.querySelector('table');
     
       const ws = XLSX.utils.table_to_sheet(table);
       const wb = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     
       // Use XLSX.write to generate a buffer
       const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
     
       // Convert the buffer to a Blob
       const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     
       // Create a download link
       const url = window.URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = url;
       a.download = 'Trade.xlsx';
     
       // Trigger the download
       a.click();
     
       // Clean up
       window.URL.revokeObjectURL(url);
     };
     
       
         // Function to print the table
         const printTable = () => {
           if (tableRef.current) {
             const table = tableRef.current;
             const newWin = window.open('', '_blank');
             newWin.document.open();
             newWin.document.write(`
               <html>
                 <head>
                   <title>Squaremart Users Trade List</title>
                 </head>
                 <body>
                   ${table.outerHTML}
                 </body>
               </html>
             `);
             newWin.document.close();
             newWin.print();
             newWin.close();
           }
         };
         
    return (
     <>
     
   <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>
<div className="admin-wrapper-col-8">
        <div className="admin-topic">Transactions</div>
        <div className="admin-form-group-add">
            <h4>Exchange Trade</h4>
            <div className="admin-col">
             <div className="actions">
             <ul>
                        {/* <li><button>copy</button></li> */}
                        <li><button onClick={exportToExcel}>Excel</button></li>
                        {/* <li><button>Pdf</button></li> */}
                        <li><button onClick={printTable}>Print</button></li>
                    </ul>
                    </div>
              
                <div className="admin-search">
                    <label>Search:</label>
                    <input type="text"  value={searchQuery}
      onChange={handleSearchChange}
       placeholder="Search for a value..."/>       </div>
            </div>

            <div className="admin-deposit">
                <div className="admin-out"> 
                    
                        {/* <h4>withdrawal</h4> */}
                    <div className="table-responsive" id="printable-area" ref={tableRef}>
                    {ExchangeTradeTable.length > 0  ? 
                             <table id="tableToConvert" className="admin-out">
                          <thead>
                          <tr>
                                <th>S/N</th>
                                <th>Card Name</th>
                                <th>Invoice</th>
                                <th id="payment">Swap For</th>
                                <th>Amount (USD)</th>
                                <th>currencyAmount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead> 
                           {ExchangeTradeTable}
                        </table>
               : 
               <h3>{dataNotAccount}</h3> }
              
                        </div>
    </div>
    </div>     <div className="admin-down">
                    <div className="rowsperpage">
                        <label htmlFor="rowsPerPage">Rows per Page:</label>
                        <select id="rowsPerPage" onChange={handleRowChange} value={productsPerPage}>
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

</div>
                    <div className="admin-btn">
                    <button className={currentPage === 1 ? 'disabledButton' :''} id="prevBtn" onClick={handlePreviousClick}>
                            Previous
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :''} id="nextBtn" onClick={handleNextClick}>
                            Next
                        </button> 
                     </div>
                </div>  </div>    </div>
<Footer/>
</div></>   
    )
}
export default AdminExchange