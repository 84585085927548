import React from "react";
import { useNavigate } from "react-router-dom";
import GiftcardSection from "./GiftcardSection";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";

const GiftcardTable=(props)=>
{
   
    return (
        <div className="table-responsive">
          <h2>Offers</h2>
          <h1></h1>
         <span></span>

        <table>
          <thead>
            <tr>
                {/* <th>Offers</th> */}
                <th>Trade</th>
                <th>Get</th>
                {/* <th>Amount</th>
                <th>Rate at </th> */}
                <th>Action</th>
            
            </tr>
          </thead>
         {props.tableRowGifcard}
       
        </table>
        {props.giftcard.length > 0 ? (
         
         <div className="pagination">
           <div className="paginate">
             <button  className={props.currentPagecard === 1 ? 'disabledButton' : ''} onClick={props.handlePreviousClickcard}>
             <FaAngleDoubleLeft/>
             </button>
             <button className={props.currentPagecard === props.totalPagescard ? 'disabledButton' : ''} onClick={props.handleNextClickcard}>
               <FaAngleDoubleRight/>
             </button>
           </div>
         </div>
         
       ) : <div className="empty-table">{props.dataNotfoundgift}</div>
       }
         
    </div>
    )
}
export default GiftcardTable