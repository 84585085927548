import react, { useRef,useEffect } from 'react'
import Headerbar from './Headerbar'
import Sidebar from './Sidebar/Sidebar'
import { Footer } from './Footer'
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import * as XLSX from 'xlsx';
import Modal from './InfoModal'
import axios from 'axios'
import { setAvailableGiftCard, seterrorCode, setmessage, setdataNotAccount } from '../Redux/Admin/AdminStore'


const AddCard=()=>
{
    const{dataNotAccount,AvailableGiftCard}=useSelector((state)=>state.AdminStore)
    //  console.log(AvailableGiftCard)
    const {size}=useSelector((state)=>state.WindowSize)
  const {NavMenu}=useSelector((state)=>state.ToggleNav)
  /************* Details Info ********/
  const [openmodal,setopenmodal]=useState(false)
  const [detailsData,setdetailsData]=useState([])

  const[verify ,setverify]=useState(false)
 /******************* CARD ADDED  ********/
 function InputDataValue(event) 
 {
   const { name, value, type } = event.target;
   const newFiles = event.target.files ? event.target.files[0] : null;
   setdetailsData((prevData)=>
   {
     return {
       ...prevData,
       [name]: type === "file" ? newFiles : value,
     }
     });
 }

 function Handlersubmit(e)
 {
   e.preventDefault()
   setverify(true)
 }
 const dispatch=useDispatch()
 const [refreshdocument,setrefreshdocument]=useState(false)
  useEffect(() => {

    async function AvailableGiftCard() {
       {
        if(verify){
            axios
                .post(`api/admin/AllGiftCard`,detailsData
                ,{
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
              })
                .then((response) => {
                 
                   dispatch(seterrorCode(response.data.status))      
                   dispatch(setmessage(response.data.data)) 
                   setverify(false) 
                   setopenmodal(false)
                   setrefreshdocument(true)
                   setdetailsData({})
                    
                })
        }
        
      }
    }
    AvailableGiftCard()
}, [verify,detailsData])
  /******* REFRESH CARD LIST *****/
  useEffect(()=>{
    async function CardData()
    {
      if(refreshdocument){
      axios.get('api/admin/AllGiftCard')
      .then((res)=>{
        dispatch(setdataNotAccount('Table is Currently Empty'))
  dispatch(setAvailableGiftCard(res.data))
    setrefreshdocument(false)
    
      })
      .catch(error => {
      //  console.log(error.response.data.status)
      });
    }
  }
    CardData()
  },[refreshdocument])
  /********* END *****/
     
  /************** END *************/
  function Modal_cancel()
  {
    setopenmodal(false)
    setdetailsData({})
    setverify(false) 
  }
   function Contrast()
   {
  if(NavMenu)
  {
    return {  gap: '4em' }
  }
  else if(size < 767)
  {
   return {  gap: '0em' }
  }
  else {
    return {  gap: '12em' }
  }
   }
   /*============= AvailableGiftCard ==========*/
   const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return dateObject.toLocaleDateString(undefined, options);
  };
/******************* SEARCH  Query ************/

const [searchQuery, setSearchQuery] = useState("");
const [filteredHistory, setFilteredHistory] = useState([]);

const handleSearchChange = (event) => {
 const query = event.target.value;
 setSearchQuery(query);

 const filtered = AvailableGiftCard.filter((transaction) => {
   // Customize the filtering logic based on your requirements
   const lowerCaseQuery = query.toLowerCase();

   // Add null checks for properties that might be null or undefined
   const user_name = transaction.user_name ? transaction.user_name.toLowerCase() : "";
   const document = transaction.document
     ? transaction.document.toLowerCase()
     : "";
      // Check if the query matches any part of the date string
    const dateString = formatDate(transaction.created_at).toLowerCase();
    const isDateMatch = dateString.includes(lowerCaseQuery);

   return (
    user_name.includes(lowerCaseQuery) ||
    document.includes(lowerCaseQuery) ||
    isDateMatch 
   
   );
 });

 setFilteredHistory(filtered);
};

  
/****************** END *****/
 
//   Pagination
const [currentPage, setCurrentPage] = useState(1);
const [productsPerPage,setproductsPerPage] = useState(10);

// Calculate the index of the first and last product to show on the current page
const indexOfLastProduct = currentPage * productsPerPage;
const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

// Extract the subset of product data to show on the current page

let serialNumberdeposit= 1;
const currentProductAvailableGiftCard = AvailableGiftCard.slice(indexOfFirstProduct, indexOfLastProduct);
//  Generate the Withdrawal components for the current page
const displayedHistory = searchQuery ? filteredHistory : currentProductAvailableGiftCard;

const AvailableGiftCards = displayedHistory.map((sourceElement,index) => {
    return (
  <tbody  key={index}> 
   <tr>
  <td>{serialNumberdeposit++}</td>
   <td>{sourceElement.cards}</td>
   <td><img src={sourceElement.images} width='50'/></td>
                      
  </tr>
  </tbody> 
  
  );
});
/*********** Next Previous button *************/
// Calculate the total number of pages based on the total number of products
const totalPages = Math.ceil(AvailableGiftCard.length / productsPerPage);

// Handle the click event for the "next" button
const handleNextClick = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

// Handle the click event for the "previous" button
const handlePreviousClick = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};
const handleRowChange = (event) => {
    const newValue = parseInt(event.target.value);
    setproductsPerPage(newValue);
  };
     // Create a ref for the table component to print
     const tableRef = useRef(null);
     // Function to export the table data to Excel
     const exportToExcel = async () => {
       const table = tableRef.current.querySelector('table');
     
       const ws = XLSX.utils.table_to_sheet(table);
       const wb = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     
       // Use XLSX.write to generate a buffer
       const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
     
       // Convert the buffer to a Blob
       const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
     
       // Create a download link
       const url = window.URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = url;
       a.download = 'Deposits.xlsx';
     
       // Trigger the download
       a.click();
     
       // Clean up
       window.URL.revokeObjectURL(url);
     };
     
       
         // Function to print the table
         const printTable = () => {
           if (tableRef.current) {
             const table = tableRef.current;
             const newWin = window.open('', '_blank');
             newWin.document.open();
             newWin.document.write(`
               <html>
                 <head>
                   <title>Squaremart Users Deposit List</title>
                 </head>
                 <body>
                   ${table.outerHTML}
                 </body>
               </html>
             `);
             newWin.document.close();
             newWin.print();
             newWin.close();
           }
         };
         
    return (
     <>
     
   <div className="admin-wrapper-page" style={ Contrast()}>
   <Headerbar/>
        <div className="admin-wrapper-col-4" id="sidenav">
<div className="admin-main">
    <div className="admin-sidebar">
    <Sidebar/>
    </div>
</div>
</div>
<div className="admin-wrapper-col-8">
        <div className="admin-topic">Available GiftCard</div>
        <div className="admin-form-group-add">
            {/* <h4>Deposits</h4> */}
            <div className="admin-col">
             <div className="actions">
             <ul>
                        {/* <li><button>copy</button></li> */}
                        <li><button onClick={exportToExcel}>Excel</button></li>
                        {/* <li><button>Pdf</button></li> */}
                        <li><button onClick={printTable}>Print</button></li>
                    </ul>
      {/* <button className="submit">ADD AvailableGiftCardS</button>
             */}
                    </div>
              
                <div className="admin-search">
                    <label></label>
                    <div className="admin-btn">
                    <button onClick={()=>setopenmodal(true)}>Add GiftCard</button>
                    {/* <input type="submit"  
       placeholder="Add category"/>       */}
          </div>  </div>
            </div>

            <div className="admin-deposit">
                <div className="admin-out"> 
                    
                        {/* <h4>withdrawal</h4> */}
                    <div className="table-responsive" id="printable-area" ref={tableRef}>
                    {AvailableGiftCards.length > 0  ? 
                             <table id="tableToConvert" className="admin-out">
                          <thead>
                          <tr>
                                <th>S/N</th>
                                <th>Card</th>
                                <th>Card Image</th>
                               {/* <th>Action</th> */}
                            </tr>
                            </thead> 
                           {AvailableGiftCards}
                        </table>
               : 
               <h3>{dataNotAccount}</h3> }
              
                        </div>
    </div>
    </div>     <div className="admin-down">
                    <div className="rowsperpage">
                        <label htmlFor="rowsPerPage">Rows per Page:</label>
                        <select id="rowsPerPage" onChange={handleRowChange} value={productsPerPage}>
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

</div>
                    <div className="admin-btn">
                    <button className={currentPage === 1 ? 'disabledButton' :''} id="prevBtn" onClick={handlePreviousClick}>
                            Previous
                        </button>
                        <button className={currentPage === totalPages ? 'disabledButton' :''} id="nextBtn" onClick={handleNextClick}>
                            Next
                        </button> 
              </div>
                </div>  </div>    </div>
<Footer/>
</div>
{openmodal  ?  // <!-- Modal -->
<div id="myModal" className="modal">
<div className="modal-content-purchase">
  <span className="close" onClick={Modal_cancel}>×</span>
  
  <div className="product-purchase-details">
    <div className="purchase-head">
       
    </div>
    <div className="purchase-body">
        <div className="table-purchase">
        <form className='form-class' onSubmit={Handlersubmit}>
                    <label>Gift Card</label><br/>
                    <input type="text" 
                    onChange={InputDataValue}
                    name="cards" placeholder='Add giftcard' required/><br/>
                   <label>GiftCard Image</label>
                    <input type="file" 
                       onChange={InputDataValue}
                       name="icons" required/>
                    <div className="purchase-head-items">
                    <button>{verify ? 'Loading..':'Submit'}</button>
                    </div>
                </form>
                </div></div></div></div>
                </div>
                : null}

</>   
    )
}
export default AddCard