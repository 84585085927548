import React from "react";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { SiTether } from "react-icons/si";
import { FaBitcoin, FaDollarSign, FaEthereum, FaFunnelDollar, FaMarker, FaSearch, FaTimes, FaWallet } from "react-icons/fa";
import { IoIosArrowBack, IoMdCheckmark } from "react-icons/io";
import { AiOutlineCopy } from "react-icons/ai";
    
const Currency=({HandlerCurrency,Handlercurrencyaction})=>
{
    const{wallets}=useSelector((state)=>state.Balanceslice)
   
   
return (
    <div className="container-currency">
    <header>
        <IoIosArrowBack  onClick={Handlercurrencyaction} style={{ height:"30px" , width:"30px"}}/>
        <h1> Select Wallet</h1>
    </header>
 <section className="deposit">
        <div className="minimum-deposit" onClick={()=>HandlerCurrency('BTC')}>
            <FaBitcoin style={{  fontSize:'25px',color:'#FB8C00' }}/>
                  <b>BITCOIN </b>
            <p>  {wallets && wallets.btc &&  wallets.btc.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} btc</p>
        </div>
        <div className="minimum-deposit" onClick={()=>HandlerCurrency('ETH')}>
            <FaEthereum style={{  fontSize:'25px', color:'#BDD4F1', }}/>
              <b>ETHEREUM</b>
            <p>  {wallets && wallets.eth && wallets.eth.toLocaleString('en-US', {  minimumFractionDigits: 2, maximumFractionDigits: 2 })} eth</p>
        </div>
        <div className="minimum-deposit" onClick={()=>HandlerCurrency('USDT')}>
            <SiTether style={{  fontSize:'25px' ,color:'green' }}/>    <b>TETHER (TRC20)</b>
            <p>  {wallets && wallets.usdt && wallets.usdt.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} usdt</p>
        </div>
        <div className="minimum-deposit" onClick={()=>HandlerCurrency('USDTERC20')}>
            <SiTether style={{  fontSize:'25px' ,color:'green' }}/>        <b>TETHER (ERC20)</b>
            <p>  {wallets && wallets.usdt && wallets.usdt.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} usdt</p>
        </div>
    </section>
  
</div>
)
}
export default Currency
