import React from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

const DropReview=(props)=>
{
    return ( 
        <div className="terms">
        <div className="conditions">
        {/* <form> */}
            <div className="final-seller-terms">
                <div className="termsof">
                    <div className="conditions">

                        <div id="imageContainer">
                            <div className="image-preview" id="image-preview">

                            </div>
                        </div>
                        {/* DROP REVIEW */}

                        <div id="review">
                            {/* <h2>Swap Accepted</h2> */}
                            <div className="review"  >
                                <p>Drop a review</p>
                                <FaThumbsUp onClick={props.Handlerthumpsup} style={props.styledlike} />
                                <FaThumbsDown onClick={props.Handlerthumpsdown} style={props.styledislike} />
                            </div>
                        </div>

                    </div>



                </div>

            </div>
            </div>

        {/* </form> */}

    </div>
    )
}
export default DropReview