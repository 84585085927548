import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ClearSessionStorageAfterInactivity = () => {
    const Navigate=useNavigate()
  // Function to clear sessionStorage
  const clearSessionStorage = () => {
    sessionStorage.clear();
    Navigate('/Admin/login',{replace :true})
  };

  useEffect(() => {
    // Set the timer for 1 hr (in milliseconds)
    const onehour = 60 * 60 * 1000;

    // Function to reset the timer whenever there's user activity
    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(clearSessionStorage, onehour);
    };

    let timeoutId = setTimeout(clearSessionStorage, onehour);

    // Event listeners to reset the timer on user activity
    window.addEventListener('click', resetTimer);
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    // Clean up the event listeners on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, []);

  return (
    <div>
    
    </div>
  );
};

export default ClearSessionStorageAfterInactivity;
