import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wallets: [],
  History:[],
  dataNotFound:'',
  walletAddress:[],
  fetchCount:0,
  walletCount:0,
  profileCount:0,
  payoutCount:0,
  unreadCount:0,
  countryCount:0,
  transactionCount:0,
  notificationCount:0,
  verifyCount:0,
  usdtCount:0,
  giftcardCount:0,
  allgiftcardCount:0,
  categoryCount:0,
  category:[],
  updateDataCount:0,
  CategoryLoad:0,
  allproductLoad:0,
  completecounttrade:0,
  bankCount:0
}

export const Balanceslice = createSlice({
  name: 'Balance',
  initialState,
  reducers: {
   
    setWallet: (state, action) => {
      state.wallets = action.payload
    },
    setHistory: (state, action) => {
      state.History = action.payload
    },
    setdataNotFound: (state, action) => {
      state.dataNotFound = action.payload
    },
     setwalletAddress: (state, action) => {
      state.walletAddress = action.payload
    },
    setfetchmessageCount: (state, action) => {
      state.fetchCount = action.payload
    },
    setwalletCount: (state, action) => {
      state.walletCount = action.payload
    },
    setprofileCount: (state, action) => {
      state.profileCount = action.payload
    },
    setpayoutCount: (state, action) => {
      state.payoutCount = action.payload
    },  setunreadCount: (state, action) => {
      state.unreadCount = action.payload
    },setcountryCount: (state, action) => {
      state.countryCount = action.payload
    },
    settransactionCount: (state, action) => {
      state.transactionCount = action.payload
    },
    setnotificationCount: (state, action) => {
      state.notificationCount = action.payload
    }, setverifyCount: (state, action) => {
      state.verifyCount = action.payload
    },
    setusdtCount: (state, action) => {
      state.usdtCount = action.payload
    },setgiftcardCount: (state, action) => {
      state.giftcardCount = action.payload
    },
   
    setallgiftcardCount: (state, action) => {
      state.allgiftcardCount = action.payload
    },setcategoryCount: (state, action) => {
      state.categoryCount = action.payload
    },setcategory: (state, action) => {
      state.category = action.payload
    },setupdateDataCount: (state, action) => {
      state.updateDataCount = action.payload
    },setCategoryLoad: (state, action) => {
      state.CategoryLoad = action.payload
    },setallproductLoad: (state, action) => {
      state.allproductLoad = action.payload
    },setcompletecounttrade: (state, action) => {
      state.completecounttrade = action.payload
    },
    setbankCount: (state, action) => {
      state.bankCount = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setbankCount,setcompletecounttrade,setallproductLoad,setCategoryLoad,setupdateDataCount,setcategory,setcategoryCount,setallgiftcardCount,setgiftcardCount,setusdtCount,setverifyCount,setnotificationCount,settransactionCount,setcountryCount,setunreadCount,setpayoutCount,setprofileCount,setwalletCount,setfetchmessageCount,setwalletAddress, setWallet,setHistory ,setdataNotFound} = Balanceslice.actions

export default Balanceslice.reducer
