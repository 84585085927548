import React, { useEffect, useState } from "react";
import Verificationquery from "./Trade/VerifyQuery";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setnotificationbox } from "../Redux/Createslice";

const Notification=()=>
{
    const[loading,setloading]=useState(false)
  const dispatch=useDispatch()
    const {notificationbox}=useSelector((state)=>state.Createslice)
    
    const[Notifydata,setNotifydata]=useState({
        pushNotification: (notificationbox.pushNotification == 0 ? false : true) || 0,
        emailNotification:(notificationbox.emailNotification  == 0 ? false : true ) || 0,
        smsNotification:(notificationbox.smsNotification  == 0 ? false : true) || 0

    }) 
    const handleCheckboxChange=(event)=>
    {
        setloading(true)
        const { name, value, type, checked } = event.target;
          dispatch(setnotificationbox( 
            {
             ...notificationbox,
                [name]: type === "checkbox" ? checked : value,
           
        }));
        setNotifydata((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
       }
      
     useEffect(()=>{
        async function ChangeNofitication()
        {
         if(loading)
         {
            axios.post('api/changeNotification',Notifydata)
            .then((res)=>{
                setloading(false)
              
            })
         }
        }
        ChangeNofitication()
     },[loading])
    return (
<div className="notification">
    <Verificationquery/>
    <div className="notification-options">
        <div className="push-notification">
            <h1>Push notifications</h1>
            <form action="" method="post">
                <p className="frame-text">Squaremart deals , recommendations and updates</p>
                <label className="switch">
                    <input type="checkbox"
                    name="pushNotification"
                    onChange={handleCheckboxChange}
                    checked={Notifydata.pushNotification } />
                    <span className="slider round"></span>
                  </label>
            </form>
        </div>
        <div className="email-notification">
            <h1>Email notifications</h1>
            <form action="" method="post">
                <p className="frame-text">Get trade, message alerts, feedback</p>
                <label className="email-switch">
                    <input type="checkbox" 
                     name="emailNotification"
                     onChange={handleCheckboxChange}
                     checked={Notifydata.emailNotification }
                    />
                    <span className="email-slider email-round"></span>
                  </label>
            </form>
        </div>
        <div className="sms-notification">
            <h1>SMS notifications</h1>
            <form action="" method="post">
                <p className="frame-text">SMS notifications</p>
                <label className="sms-switch">
                    <input type="checkbox"
                     name="smsNotification"
                     onChange={handleCheckboxChange}
                     checked={Notifydata.smsNotification }/>
                    <span className="sms-slider sms-round"></span>
                </label>
            </form>
        </div>
    </div>
</div>
    )
}
export default Notification