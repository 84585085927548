import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logos from '../../img/squaremart-logo.png'
import { setmessage } from "../Redux/Createslice";
import Footer from "./Footer";
import { FaBitcoin, FaQuestion, FaTimes } from "react-icons/fa";
import { MdOutlineCardGiftcard } from "react-icons/md";
import { SiCoinmarketcap } from "react-icons/si";
import Balance from "../Dashboard/Balance";
import ScrollToTop from "../pages/ScrollToTop";

const TwoFactor=()=>
{
    useEffect(()=>{
        if(sessionStorage.getItem("Admin_TOTP")){
            setTimeout(() => {
            sessionStorage.removeItem("Admin_TOTP")
            Navigate('/Admin/login', { replace: true })
          },180000); 
        }
    },[])
    useEffect(() => {
          if (!(sessionStorage.getItem("Admin_TOTP"))) {

            Navigate('/Admin/login', { replace: true })
        }
    },[])
    const Navigate = useNavigate()
    const [GoogleCode,setGoogleCode]=useState()
    const[Loading,setLoading]=useState(false)
    function HandlerSubmit(e)
    {
        e.preventDefault()
        setLoading(true)
        dispatch(setmessage())
    }
  
function Twoverification(event)
{
    setLoading(false)
    dispatch(setmessage())
    const { name, value, type, checked } = event.target;

    setGoogleCode((prevData) => {
            const updatedData = {
                ...prevData,
                email:sessionStorage.getItem('email_factor'),
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
}
const dispatch=useDispatch()
    useEffect(()=>{
        async function StepVerification()
        {
            if(Loading)
            {
        axios.post(`api/admin/verificationTwoFactor`,GoogleCode)
        .then((res)=>{
          if(res.data.status == 401)
             {
                setLoading(false)
                dispatch(setmessage(res.data.data))
             }
             else if(res.data.success == true){
                sessionStorage.setItem("admin_authenticated", res.data.token);
            Navigate('/Admin/Dashboard', { replace: true })
             }
           
        })
        }
    }
    StepVerification()
    },[Loading])
    const link = {
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '20px'
    }
    const { showChat }=useSelector((state)=>state.Chatslice)
    const {size}=useSelector((state)=>state.WindowSize)
    const {message}=useSelector((state)=>state.Createslice) 
     const[showmenu,setshowmenu]=useState(false)
    function Menumobile(){
     setshowmenu((previus) => !previus)
     }
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
     <div className="container">

       <div className="two-factor" >
        <div className="layout-section">
        <div></div>
            <div className="squaremart">
                <div className="square-log">
                         <div className="sign-in">
                        <h1 style={{ color:'#000000'}}>TWO FACTOR CODE</h1>
                             </div>
                </div>
                <div className="form-control">
                {   message ? 
        <div className= 'error-field'>
       
                              <div className= 'error' >
                                    {message}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                    <form onSubmit={HandlerSubmit} method="post">
                        <label htmlFor="email">OTP CODE</label>
                            <input type="tel" name="google_auth_code"
                            onChange={Twoverification} placeholder="OTP" id="OTP" required/>
                            <br></br>
                          <button className="btn-create" type="submit">{Loading ? 'Loggin...' :'Login'}</button>
                         </form>
                </div>
            </div>
           <div></div>
        </div>
    </div>
  
   
   </div>
   
            
   </div>
    )
}
export default TwoFactor