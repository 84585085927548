import React, { useEffect, useRef } from "react";
import Auth from "./Auth";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MovingText from 'react-moving-text'
import { setverified } from "../Redux/Tradeslices";
import axios from "axios";
import { setmessage } from "../Redux/Createslice";
import ScrollToTop from "../pages/ScrollToTop";

const CompleteRegistration=()=>
{
    
    const{verified}=useSelector((state)=>state.Tradeslices)
    const fileInputRef = useRef(null);
    const dispatch=useDispatch()
     /************** FILEUPLOAD *********/
     const [selectedFiles, setSelectedFiles] = useState(null);
     const handleImageClick = () => {
        fileInputRef.current.click();
    };
    const[verifydata,setverifydata]=useState(verified)
    const handleFileSelect = (event) => {
        const newFiles = event.target.files[0];
        setSelectedFiles(newFiles);
        setverifydata((prevValues) => ({
            ...prevValues,
            document_file:newFiles,
            address_file: newFiles,
        }));
      
        
    };
 
    const Navigate=useNavigate()
    useEffect(()=>{
        //validate register page
        if((verified.verify_badge == 3) )
        {
       Navigate('/verification', { replace: true })
        }
    })
  /************** Data  submit*/
  const{countries}=useSelector((state)=>state.Createslice)

  const country_code= countries.map((sourceElement,index) => {
    return (
      
            <option key={index} value={sourceElement.callingCode}>
                {sourceElement.callingCode}
            </option>


    );
});

  function verifiedData(event)
  {
    setisLoading(false)
    const { name, value, type, checked } = event.target;
    if (name == 'phone' && (!(/^\d+$/.test(value))   )) {
         setverifydata((prevData) => {
        const updatedData = {
            ...prevData,
           phone:''
        };
        return updatedData;
    });
    }else
    {
    setverifydata((prevData) => {
        const updatedData = {
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        };
        return updatedData;
    });
}
  }
  /**************** Upload  **********/
  const[Loading,setisLoading]=useState(false)
  const{message}=useSelector((state)=>state.Createslice)
  function HandlerSubmitData(e)
  {
    e.preventDefault()
    setisLoading(true)
  }
 
  useEffect(()=>{
    async function Verification()
    {
  if(Loading){
    axios.post(`api/completeverification`,verifydata,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }) 
  .then((res)=>{
    setisLoading(false)
  
    if(verified.verify_badge   == 0 ){
    dispatch(setverified({'verify_badge':1}))
   
    }
    else if(verified.verify_badge == 1){
        dispatch(setverified({'verify_badge':2}))
        setSelectedFiles(null)
    }
    else{
        dispatch(setmessage('Document uploaded, Kindly wait for Admin verification'))
    }
  })
  }
    }
    Verification();
  },[Loading])
  /*********** Set Which Document to submit */

  useEffect(()=>{
    if(verified.verify_badge   == 1){
    setverifydata((prevData) => {
        const updatedData = {
            ...prevData,
            action:2
             };
        return updatedData;
    });
}
 else if(verified.verify_badge   == 2){
    setverifydata((prevData) => {
        const updatedData = {
            ...prevData,
            action:3
             };
        return updatedData;
    });
}
},[verified.verify_badge])
/*********** END ****/
  function Conditions()
  {
  
    if(verified.verify_badge   == 0 )
    {
         {/* phone verification */}
  
        return (
<div className="identification-section">
        <div className="identification-number">
            <form action="" onSubmit={HandlerSubmitData} method="post">
                <label htmlFor='phone'>Phone Number</label>
                <span className="span">   
                  <select defaultValue='' onChange={verifiedData}
                         name="code" className='phone' required>
                        <option disabled="disabled" value=''>Select Code</option>
                      {country_code}
                        </select>   <input type="text"
                value={verifydata.phone || ''} onChange={verifiedData} name="phone" required
                placeholder="Phone Number" id="Phone Number"/>   </span> 
                  <button type="submit"><span>{Loading ? 'Saving...' : 'Save and Continue'}</span></button>
            </form>
        </div>
    </div>
        )
    }
    else if( (verified.verify_badge == 1))
    {
      
         {/* VERIFY ADDRESS */}
        return (
             <div className="verify-option">
            <form onSubmit={HandlerSubmitData} method="post">
                <select  className="form-control"  onChange={verifiedData} required name="address_type" id="adds-verfiy" defaultValue=''>
               
                {verifydata.address_type ?
             <>  <option value={verifydata.address_type}>&rarr; {verifydata.address_type}</option>
             <option value="Utility Bill">&rarr; Utility Bill</option>
         </>
                   :<>  <option value="" disabled="disabled" selected="selected">&larr; Select an option to verify address &rarr;</option>
               <option value="Utility Bill">&rarr; Utility Bill</option>
                 </>
              
               }
                    </select>
               
                <input type="file" required 
                                        name="address_file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        />
                <svg onClick={handleImageClick} className="svg" xmlns="http://www.w3.org/2000/svg" width="106" height="140" viewBox="0 0 86 87" fillOpacity="none">
                    <path d="M44.8477 30.0625V11.9219H19.4844V75.0781H66.5156V33.5898H48.375C47.4395 33.5898 46.5423 33.2182 45.8808 32.5567C45.2193 31.8952 44.8477 30.998 44.8477 30.0625ZM55.4297 49.8828V53.9141C55.4297 54.2836 55.1273 54.5859 54.7578 54.5859H45.6875V63.6562C45.6875 64.0258 45.3852 64.3281 45.0156 64.3281H40.9844C40.6148 64.3281 40.3125 64.0258 40.3125 63.6562V54.5859H31.2422C30.8727 54.5859 30.5703 54.2836 30.5703 53.9141V49.8828C30.5703 49.5133 30.8727 49.2109 31.2422 49.2109H40.3125V40.1406C40.3125 39.7711 40.6148 39.4688 40.9844 39.4688H45.0156C45.3852 39.4688 45.6875 39.7711 45.6875 40.1406V49.2109H54.7578C55.1273 49.2109 55.4297 49.5133 55.4297 49.8828Z" fill-Opacity="black" fillOpacity="0.15"/>
                    <path d="M71.773 24.7379L53.6996 6.66445C53.1957 6.16055 52.5154 5.875 51.8016 5.875H16.125C14.6385 5.875 13.4375 7.07598 13.4375 8.5625V78.4375C13.4375 79.924 14.6385 81.125 16.125 81.125H69.875C71.3615 81.125 72.5625 79.924 72.5625 78.4375V26.6443C72.5625 25.9305 72.277 25.2418 71.773 24.7379ZM50.5586 12.073L66.3645 27.8789H50.5586V12.073ZM66.5156 75.0781H19.4844V11.9219H44.8477V30.0625C44.8477 30.998 45.2193 31.8952 45.8808 32.5567C46.5423 33.2182 47.4395 33.5898 48.375 33.5898H66.5156V75.0781Z" fillOpacity="black"/>
                    <path d="M45.6875 40.1406C45.6875 39.7711 45.3852 39.4688 45.0156 39.4688H40.9844C40.6148 39.4688 40.3125 39.7711 40.3125 40.1406V49.2109H31.2422C30.8727 49.2109 30.5703 49.5133 30.5703 49.8828V53.9141C30.5703 54.2836 30.8727 54.5859 31.2422 54.5859H40.3125V63.6562C40.3125 64.0258 40.6148 64.3281 40.9844 64.3281H45.0156C45.3852 64.3281 45.6875 64.0258 45.6875 63.6562V54.5859H54.7578C55.1273 54.5859 55.4297 54.2836 55.4297 53.9141V49.8828C55.4297 49.5133 55.1273 49.2109 54.7578 49.2109H45.6875V40.1406Z" fillOpacity="black"/>
                  </svg>
                <span>Click to upload Address </span>  
                {selectedFiles ? (
                                               
                                                    <div className="image-container">
                                                        <img src={URL.createObjectURL(selectedFiles)} width='100'  alt="" />
                                                        </div>
                                               
                                            ) : (
                                                ''
                                            )}    
                  <button type="submit"><span>{Loading ? 'Saving...' : 'Save and Continue'}</span></button>
            </form>
       
    </div>
        )
    }
    else  if( verified.verify_badge == 2)
    {
         {/* Document */}
return (
    
           
<div className="verify-option">
            <form action="" onSubmit={HandlerSubmitData} method="post">
                <select  className="form-control" defaultValue=''  onChange={verifiedData} required name="document_type" id="adds-verfiy">
                  
                {verifydata.document_type ?
             <>  <option value={verifydata.document_type}>&rarr; {verifydata.document_type}</option>
            <option value="Drivers License">&rarr; Drivers License</option>
                    <option value="Passport">&rarr; Passport</option>
                      <option value="National Identifcation Number">&rarr; National Identifcation Number</option>
         </>          :<>  <option value=" " disabled="disabled" selected="selected">&larr; Select Document to upload &rarr;</option>
                    <option value="Drivers License">&rarr; Drivers License</option>
                    <option value="Passport">&rarr; Passport</option>
                      <option value="National Identifcation Number">&rarr; National Identifcation Number</option>
              </> }  </select>
               
                <input type="file" required
                                        name="document_file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        />
                <svg onClick={handleImageClick} className="svg" xmlns="http://www.w3.org/2000/svg" width="106" height="140" viewBox="0 0 86 87" fillOpacity="none">
                    <path d="M44.8477 30.0625V11.9219H19.4844V75.0781H66.5156V33.5898H48.375C47.4395 33.5898 46.5423 33.2182 45.8808 32.5567C45.2193 31.8952 44.8477 30.998 44.8477 30.0625ZM55.4297 49.8828V53.9141C55.4297 54.2836 55.1273 54.5859 54.7578 54.5859H45.6875V63.6562C45.6875 64.0258 45.3852 64.3281 45.0156 64.3281H40.9844C40.6148 64.3281 40.3125 64.0258 40.3125 63.6562V54.5859H31.2422C30.8727 54.5859 30.5703 54.2836 30.5703 53.9141V49.8828C30.5703 49.5133 30.8727 49.2109 31.2422 49.2109H40.3125V40.1406C40.3125 39.7711 40.6148 39.4688 40.9844 39.4688H45.0156C45.3852 39.4688 45.6875 39.7711 45.6875 40.1406V49.2109H54.7578C55.1273 49.2109 55.4297 49.5133 55.4297 49.8828Z" fill-Opacity="black" fillOpacity="0.15"/>
                    <path d="M71.773 24.7379L53.6996 6.66445C53.1957 6.16055 52.5154 5.875 51.8016 5.875H16.125C14.6385 5.875 13.4375 7.07598 13.4375 8.5625V78.4375C13.4375 79.924 14.6385 81.125 16.125 81.125H69.875C71.3615 81.125 72.5625 79.924 72.5625 78.4375V26.6443C72.5625 25.9305 72.277 25.2418 71.773 24.7379ZM50.5586 12.073L66.3645 27.8789H50.5586V12.073ZM66.5156 75.0781H19.4844V11.9219H44.8477V30.0625C44.8477 30.998 45.2193 31.8952 45.8808 32.5567C46.5423 33.2182 47.4395 33.5898 48.375 33.5898H66.5156V75.0781Z" fillOpacity="black"/>
                    <path d="M45.6875 40.1406C45.6875 39.7711 45.3852 39.4688 45.0156 39.4688H40.9844C40.6148 39.4688 40.3125 39.7711 40.3125 40.1406V49.2109H31.2422C30.8727 49.2109 30.5703 49.5133 30.5703 49.8828V53.9141C30.5703 54.2836 30.8727 54.5859 31.2422 54.5859H40.3125V63.6562C40.3125 64.0258 40.6148 64.3281 40.9844 64.3281H45.0156C45.3852 64.3281 45.6875 64.0258 45.6875 63.6562V54.5859H54.7578C55.1273 54.5859 55.4297 54.2836 55.4297 53.9141V49.8828C55.4297 49.5133 55.1273 49.2109 54.7578 49.2109H45.6875V40.1406Z" fillOpacity="black"/>
                  </svg>
                <span>Click to upload Identification Document</span>   
                {selectedFiles ? (
                                               
                                               <div className="image-container">
                                                   <img src={URL.createObjectURL(selectedFiles)} width='100' alt="" />
                                                   </div>
                                          
                                       ) : (
                                           ''
                                       )}  
                  <button type="submit"><span>{Loading ? 'Saving...' : 'Submit'}</span></button>
            </form>
       
    </div>
)
    }

  }
    return (
<>   
<Auth />
        <div className="container-fluid">
            <Sidebar />
            
            <header>
                <Header />
            </header>
            <hr/>
            { message ?
             
             <MovingText
  type="slideInFromRight"
  duration='350ms'
  className= "sucessmsg"
  delay='0s'
  direction='alternate'
  //    timing='ease'
  iteration='1'
  fillMode='none'> 
   <p>{message}</p></MovingText> 


 : null }
            <section id='verified'>  
         
           {Conditions()}
            </section>
            </div>

                </>
    )
}
export default CompleteRegistration