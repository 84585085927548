
import  React, { useState, useEffect, useRef } from 'react' 
 import frame6 from "../img/Frame 36.png" 
 import { Link } from 'react-router-dom'
import MovingText from 'react-moving-text'
import CookiePopup from './pages/Cookies';
 import ChatInterface from './pages/Chatbot';
 import { useDispatch, useSelector } from 'react-redux';
 // import { FaLinkedin, FaFacebookSquare, FaInstagram } from 'react-icons/fa';
 // import { BsTwitterX } from "react-icons/bs";
 import { CgDollar } from "react-icons/cg";
 import Dataquery from './pages/Dataquery';
 import Header from './Headers'
// import MobileHeaders from './Header'
import Footer from './Footer'
import mockup from '../img/spotlight-mockup 1.png'
import clock from '../img/clkc.png'
import quick from '../img/quick.png'
import diskount from '../img/diskount.png'
import watchme from '../img/watchme.png'
import LOCKer from '../img/LOCKer.png'
import logis from '../img/logis.png'
import amazon22 from '../img/amazon22.png'

const Squareweb=()=>
{
    /************* AL PRODUCT ***********/
    const style={
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '30px',
        color: '#000000',
     
}
    const{allProduct,dataNotFound,Categories,completetrade}=useSelector((state=>state.Createslice))
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(10);

    // Calculate the index of the first and last product to show on the current page
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    // Extract the subset of product data to show on the current page
    const currentProducts =  allProduct.slice(indexOfFirstProduct, indexOfLastProduct);
   
    const products = currentProducts.map((sourceElement,index) => {
        return (
            <div className="market-product" key={index}>
                        <img src={sourceElement.product_image} alt=""/>
                        <div className="product-details">
                            <span>{sourceElement.product_category}</span>
                            <h3>{sourceElement.product_name}</h3>
                            <p>{sourceElement.description}</p>
                            <h4 className="product-price"> ${sourceElement.product_price}</h4>
                        </div>
                    </div>
        )
    })
    
    /********************* Scroll downdrop ******/
  useEffect(() => {
    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 20;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);
  }, []);
    const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
       });

    return (
        <div className="container-web">
            <Dataquery/>
         <section className="squaremart">
          <Header/>
   
    <section className="main-page">
        <div className="bitcoin-icon">
         <MovingText
   type="shakeVertical"
   duration='14950ms'
  delay='5s'
   timing='ease'
   iteration='infinite'
   fillMode='none'>
          
          <img src={mockup} alt=""/>
 </MovingText>
           
        </div>
        <div className="page-content">
            <h2>The Future of Crypto Currency and   <br/><span>Digital Marketing</span>  is Here </h2>
            <p>Welcome to SquareMart, where cutting-edge digital marketing meets the revolutionary world of crypto currencies. Dive into a realm of limitless possibilities as you explore our platform, designed to redefine the way you shop and engage with the online marketplace.</p>
        </div>
        <div className="e-icon">
           <MovingText
   type="shakeVertical"
   duration='15950ms'
  delay='3s'
   timing='ease'
   iteration='infinite'
   fillMode='none'>
           <img src={mockup}/>
             </MovingText>
        </div>
    </section>
</section>
<section className="our-goals">
    <div className="fast-reliable">
        <div className="fast-logo">
            <img src={clock} alt=""/>
        </div>
        <div className="goals-title">
            <h3>Fast and Reliable</h3>
        </div>
        <div className="short-description">
            <p>Enjoy swift and secure transactions on SquareMart, thanks to our cutting-edge technology, ensuring seamless performance and reliability for all your shopping needs.</p>
        </div>
    </div>
    <div className="online-wallet">
        <div className="wallet-logo">
            <img src={quick} alt=""/>
        </div>
        <div className="wallet-title">
            <h3>Instant and Quick Payment</h3>
        </div>
        <div className="wallet-desc">
            <p>Our streamlined checkout process guarantees that transactions are completed swiftly, allowing you to shop with ease and efficiency.</p>
        </div>
    </div>
    <div className="gift-cards">
        <div className="card-logo">
            <img src={diskount} alt=""/>
        </div>
        <div className="card-title">
            <h3>Discounted Gift Cards</h3>
        </div>
        <div className="card-desc">
          Unlock unbeatable savings with discounted gift cards, where you can stretch your budget further by purchasing gift cards for your favourite brands at a fraction of their face value, making every purchase a rewarding opportunity to save.
        </div>
    </div>
</section>
            <section className="our-services reveal">
    <h1>Our Services</h1><br/>
    <div className="product-category">
      
            <div className="item1">
                <div>
                    <h1>SHOPPING</h1>
                    <p>Dive into a world of endless possibilities at SquareMart, where our meticulously curated selection caters to every need and desire. Whether you're in search of the latest tech gadgets, trendy fashion pieces, or unique gifts, our diverse range of options ensures there's something for everyone. Our intuitive interface and advanced search functionalities make finding the perfect item effortless, enjoy secure payment options, fast shipping, and dedicated customer support, ensuring a smooth and hassle-free journey from browsing to checkout.</p>
                </div>
                <div className="img-product">
                    <img src={watchme} alt="logo"/></div>
               <div><Link to='/Marketplace'>Get Started</Link></div>
            </div>
            <div className="item2"> <div>
                <h1>WALLET</h1>
                <p> Safeguard your assets with confidence, knowing that our robust security measures are in place to protect your funds at every step. Plus, with the ability to exchange your deposits for gift cards, you can unlock even more value and explore new shopping possibilities with ease. Seamlessly manage your funds in local currency, USD, or major crypto currencies, allowing you to shop with ease and flexibility.</p>
            </div>
            <div className="img-product"><img src={LOCKer} alt="logo"/></div>
           <div><Link to='/Dashboard'>Get Started</Link></div>
        </div>
            <div className="items">
                <div className="item3">
                    <div>
                    <h1>SHIPPING</h1>
                    <p> Our logistics network is equipped to handle all your shipping needs with care and precision. With real-time tracking and transparent communication, you can stay informed every step of the way.</p>
                </div>
                <div className="img-product"><img src={logis} alt="logo"/></div>
               <div><Link to='/'>Get Started</Link></div>
            </div>
                <div className="item4"> <div>
                    <h1>Gift Card</h1>
                    <p>Enhance your shopping experience with the versatility and convenience of our gift card option at SquareMart. Easily exchange your deposits for gift cards from a variety of brands, providing you with endless options to shop for your favourite products hassle-free.</p>
                </div>
                <div className="img-product"><img src={amazon22} alt="logo"/></div>
               <div><Link to='/Trade'>Get Started</Link></div>
            </div>

            </div>
          
      
    </div>
</section>
<br/>
            <section className="market-place reveal">
                <h1>Our Featured Products</h1> <br/>
                <div className="all-product">
                  
                {allProduct.length > 0 ? products : 
                       
                       <div style={style}>{dataNotFound}
                </div>
                      
                    }

                  
                </div>
            </section>
          <section className="trusted-customers reveal">
    <div className="customer-testify">
        <h2>Trusted by over 2000+ customers</h2>
        <p>Score based on thousands of reviews </p>
    </div>
    <div className="memo">
        <div className="customer-memo">
            <div className="customer-comment">
                <p>Shopping at SquareMart has been an absolute pleasure! The wide selection of products caters to every need, and I love how easy it is to find exactly what I'm looking for. The secure payment options and fast shipping ensure that I can shop with confidence. SquareMart truly sets the standard for a seamless and enjoyable shopping experience.</p>
            </div>
            <div className="customer-details">
                <img src={frame6} alt=""/>
                <h4>- Kieran Thorne</h4>
            </div>
        </div>
        <div className="customer-memo">
            <div className="customer-comment">
                <p>I recently discovered the gift card trading feature on SquareMart, and I have to say, it's been a game-changer for me. I can easily exchange my unused gift cards within a few clicks. The variety of brands available for exchange is impressive.  I highly recommend giving it a try</p>
            </div>
            <div className="customer-details">
                <img src={frame6} alt=""/>
                <h4>- Olamide Adegoke</h4>
            </div>
        </div>
        <div className="customer-memo">
            <div className="customer-comment">
                <p>SquareMart has become my go-to destination for all my shopping needs, and for good reason. Their platform is a treasure trove of amazing finds, and I love the personalized touch with their recommendations. And the best part? The fast shipping means I never have to wait long to get my hands on my purchases. I highly recommend SquareMart to anyone looking for a top-notch shopping experience.</p>
            </div>
            <div className="customer-details">
                <img src={frame6} alt=""/>
                <h4>- Esme Alvarado</h4>
            </div>
        </div>
    </div>
</section>

            <CookiePopup/>
    {/* CHAT live support */}
          <ChatInterface/>
        </div>
    )
}
export default Squareweb
