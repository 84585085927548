import { configureStore } from '@reduxjs/toolkit'
import Createslice from './Createslice'
import ModelightSlice from './MoodlightSlice'
import WindowSize from './WindowSize'
import Chatslice from './Chatslice'
import ProfilePageslice from './ProfilePageslice'
import ChatText from './ChatText'
import AuthSlice from './AuthSlice'
import Balanceslice from './Balanceslice'
import Inboxslices from './Inboxslices'
import Tradeslices from './Tradeslices'
import ToggleNav from './Admin/Toggle'
import SwapCard from './SwapCard'

/***************** ADMIN  *****/
import Chart from './Chart'
import AdminChart from './Admin/Chart'
import AdminStore from './Admin/AdminStore'
import RenderFirst from './RenderFirst'
export const store = configureStore({
  reducer: {
    Inboxslices:Inboxslices,
    ToggleNav:ToggleNav,
    SwapCard:SwapCard,
    RenderFirst:RenderFirst,
    Tradeslices:Tradeslices,
    Createslice:Createslice,
    Modelight:ModelightSlice,
    WindowSize:WindowSize,
    Chatslice:Chatslice,
    Balanceslice:Balanceslice,
    ChatText:ChatText,
    AuthSlice:AuthSlice,
    ProfilePageslice:ProfilePageslice,

    /************ ADMIN *****/

    Chart:Chart,
    AdminStore:AdminStore,
    AdminChart:AdminChart,
  },
})