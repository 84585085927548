import React, { useEffect, useMemo, useState } from "react";
import Auth from "./Auth";
import Header from "./Header";
import Sidebar from "./Sidebar";
import verify from '../../img/verwhite.png'
import axios from "axios";
import doc from '../../img/doc.png'
import address from '../../img/address.png'
import phone from '../../img/phone.png'
import { FaArrowAltCircleRight, FaArrowRight, FaPhone, FaPhoneSquare } from "react-icons/fa";
import { BsPhone, BsTelephone, BsTelephoneFill } from "react-icons/bs";
import { FcDocument } from "react-icons/fc";
import { HiDocumentDuplicate } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setverified } from "../Redux/Tradeslices";
import { BiRightArrow } from "react-icons/bi";
import arrowed from '../../img/arrowverify.png'
import { useNavigate } from "react-router-dom";
import Balance from "./Balance";
import   Verificationquery  from "./Trade/VerifyQuery";
import ScrollToTop from "../pages/ScrollToTop";
import approved from '../../img/approved-stamp-png-6-removebg-preview.png'

const Verification=()=>
{
     const profile = useSelector((state) => state.ProfilePageslice.profile);
   const user_id= `${profile.id}`
    
    const{verified}=useSelector((state)=>state.Tradeslices)
    const dispatch=useDispatch()

    const bgstyled = {
      backgroundColor: '#56B547',
      color:'#ffffff',
  }
    const styled = {
        color: '#56B547',
    }
    const imgStyle= {
            borderRadius: '50%',
          border: '1px solid #56B547',
          padding: '6px 10px',
          backgroundColor: '#56B547'
    
    }
    const items={
       
        borderRadius: '8px',
        border: '2px solid #118AB2',

    }
      const defaultImgstyle={
        borderRadius: '50%',
        border: '1px solid #118AB2',
        padding: '6px 10px',
        backgroundColor: '#26AD2380'
       
      }
     /************** Complement  verification link */
     const Navigate=useNavigate()
      function Getverify()
      {
        
        Navigate('/CompleteRegistration', { replace: true })
      }
    //   console.log(verified)
      function Conditionquery()
      {
        if(verified){
            if(verified.verify_badge < 3)
            {
return ( 
<div className="complete" onClick={Getverify}>

<p>Complete Verification </p>

<img src={arrowed} alt="arrow"/>
</div>)
            }
        //     else if(verified.verify_badge == 3){
        //       return (
        //       <div className="completes-img" style={{ position:'relative',marginTop:'-6em',zIndex:'9999' }}>
        // <img src={approved} width='150'/>
        //       </div>
              
        //       )
        //     }     

        }
        
        else{
            return ( 
                <div className="complete" onClick={Getverify}>
                
                <p>Complete Verification </p>
                
                <img src={arrowed} alt="arrow"/>
                </div>
                )
        }
      }
    return (
        <>
        <Auth />
        
        <Verificationquery/>
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr/>
{/* Verification */}
<section id='verify'>  
{Conditionquery()}
            <div className="verify-board">
                    <div className="verification-items" style={verified.verify_badge > 0 &&  verified.verify_badge >= 1 ? items : null}>
                   
                    <p className="verified" style={verified.verify_badge > 0 &&  verified.verify_badge >= 1 ? bgstyled : null}>
                    <span>
                    <img src={verify} style={verified.verify_badge> 0 &&  verified.verify_badge >= 1  ?
                             imgStyle : defaultImgstyle} /> 
                        </span>   Level 1 </p>
                        <ul>
                            <li style={verified.verify_badge > 0 &&  verified.verify_badge >= 1  ? styled : null}>
                          <BsTelephoneFill/><br/>
                          Phone Verified</li>
                            <li>Transaction limit  upto 500USD</li>
                            {/* <li>Swap product up to 1,000 USD</li> */}
                        </ul>
                    </div>
                    <div className="verification-items" style={verified.verify_badge > 1 &&  verified.verify_badge >= 2 ? items : null}>
                        <p className="verified" style={verified.verify_badge> 1 &&  verified.verify_badge >= 2 ? bgstyled : null}>
                        <img src={verify} style={verified.verify_badge > 1 &&  verified.verify_badge >= 2  ?
                             imgStyle : defaultImgstyle} />  Level 2 </p>
                        <ul>
                            <li style={verified.verify_badge > 1 &&  verified.verify_badge >= 2  ? styled : null}>
                            <MdLocationOn/><br/>Address</li>
                            <li>Increase transaction limit  to 2,000USD</li>
                           
                            {/* <li>Swap product up to 1,000 USD</li> */}
                        </ul>
                    </div><div className="verification-items" style={verified.verify_badge > 2 &&  verified.verify_badge >= 3 ? items : null}>
                        <p  className="verified" style={verified.verify_badge > 2 &&  verified.verify_badge >= 3 ? bgstyled : null}>
                        <img src={verify} style={verified.verify_badge> 2 &&  verified.verify_badge >= 3  ?
                             imgStyle : defaultImgstyle} /> Level 3 </p>
                        <ul>
                            <li  style={verified.verify_badge > 2 &&  verified.verify_badge >= 3  ? styled : null}>
                          <HiDocumentDuplicate/><br/>Document</li>
                            <li>Unlimited Transactions</li>
                            {/* <li>Swap product up to 1,000 USD</li> */}
                        </ul>
                    </div>
                </div>
            </section>
{/* END */}

            </div>
            </>
    )
}
export default Verification