import React, { useEffect, useRef } from "react"; 
import { auth } from "./Firebase";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { setloginbutton, setmessage } from "../Redux/Createslice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ScrollToTop from "./ScrollToTop";

const Google=()=>
{
  
const dispatch=useDispatch()
const Navigate=useNavigate()
const [GoogleData,setGoogleData]=useState({})
const [Ready,setReady]=useState(false)
const urlParams = new URLSearchParams(window.location.search);
function handleGoogleSuccess(credentialResponse)
{
  dispatch(setloginbutton(true))
  setReady(true)
    var GoogleData_decoded = jwt_decode(credentialResponse.credential);
    
    setGoogleData({
          'email':GoogleData_decoded.email,
          'name':GoogleData_decoded.name,
          'id':GoogleData_decoded.sub
        
      })
        /******** Firebase Auth *****/
        const emailRef=GoogleData_decoded.email
        const passwordRef='dummy1234567'
    createUserWithEmailAndPassword(
      auth,
      emailRef,
      passwordRef
  ).then((auth)=>{
   
  }).catch((error)=>{
    // console.log(('Email Already Exits !'))
        })
    }
      useEffect(()=>{
        const cartId = urlParams.get('purchase');
        async function GoogleAuth(){
          if(Ready)
          {
          axios.post(`api/authorized/google/callback`,GoogleData)
       .then((res)=>{
        if(res.data){
          sessionStorage.setItem("authenticated", res.data);
             
          if (cartId) {
            localStorage.setItem('cartID', cartId);
            Navigate('/Purchase', { replace: true })
        }else
        {
          Navigate('/Dashboard', { replace: true })
        }
        dispatch(setloginbutton(false))
        }
       })
         
        }
      }
      GoogleAuth()
      },[Ready])
      // console.log(GoogleData);
     
  return (
  
  <div>
  {/* <button>Google</button>
  <button onClick={signInWithGoogle}>Signing with Google</button> */}
 
 <GoogleLogin 
   title="Login"
   onSuccess={handleGoogleSuccess}
  onError={() => {
    dispatch(setmessage('Login Failed'))
    console.log('Login Failed');
  }}
/>
</div>

 )
}
export default Google
