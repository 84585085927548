import React, { useEffect, useRef, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { FaClock, FaExclamation, FaExclamationCircle, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import sender from '../../../img/send.png'
import MovingText from 'react-moving-text'
import axios from "axios";

const UploadSellCard = (props) => {
  
    const disstyled={
        cursor:'not-allowed',
      
      }  
               
    return (
      <> 
             
                       
            <div className="terms">
                    
                    <>
                       <h1>Read terms before proceeding Uploading {props.selling} to buyer</h1>
                
                    <div className="conditions">
                        <div className="head">
                            
                            <h2> Transaction ID: {props.invoiceNumber}</h2>
                        </div>
                        <p>
                        {props.accept} for this trade has been locked in escrow. Please make sure you meet buyer’s term before uploading {props.selling} worth {props.amount}USD for this transaction
                        </p>
                        <div className="time">

                
<FaClock />
<p>Time left to pay : <span id="counter"> 
{props.minute}:{props.seconds}

    
</span></p>



</div>
           <form onSubmit={props.HandlerTradeData}>
                            <div className="final-seller-terms">
                                <div className="termsof">
                                    <div className="conditions">
                                        <div id="imageContainer">
                                            <div className="image-preview" id="image-preview">

                                                {props.selectedFiles ? (
                                                    <>
                                                        <img
                                                            src={URL.createObjectURL(props.selectedFiles)}
                                                            alt=""
                                                            style={{ width: "100%" }}
                                                        />
                                                     
                                                    </>
                                                ) : (
                                                    ""
                                                )}

                                            </div>
                                        </div>
                                       
                                    </div>
                                      <input type="file"
                                        name="giftcard_image"
                                        ref={props.fileInputRef}

                                        accept="image/*"
                                        onChange={props.handleFileSelect}
                                       style={{ display: "none" }}
                                    />
                                    <div className="actions">
                                        <button type="button" className="cancel" id="cancel" onClick={() => props.handleRemoveImage()} style={{ cursor: "pointer" }}>Cancel</button>
                                        <div className="btn-action">
                                            <button type="button" className="upload" id="upload" onClick={props.handleImageClick} style={{ cursor: "pointer" }}>Upload Gift Card to Seller</button>
                                          
                                        </div>
                                    </div>
                                    <input type="file"
                                        name="" id="image-input"
                                        style={{ display: 'none' }} />

                                </div>

                            </div>
                            <div className="cont">
                            <div className="actions">
                                   {/* <button style={{cursor:'pointer'}} type="button" onClick={props.RequestReject} className="reject" id="reject">{props.action == 2 && props.uploading ? "Please wait..." :'Reject'}</button> */}
                                 
                                   <button disabled={props.uploading  ? 'disabled': null} style={props.uploading ? disstyled : {cursor:'pointer'}} type="button" onClick={props.RequestAccept} className="approved" id="approved"><span>
                                    {props.action == 1 && props.uploading ? "Please wait..." :'Approved'}</span></button>
                                 
                               
                               </div>
                                {/* <button style={{ cursor: "pointer" }}>{props.uploading ? "Please wait..." : 'Continue'}</button> */}
                            </div>

                        </form>
                        </div>
          </>
             
        
          
          
      </div>
</>
          
    )
}
export default UploadSellCard