import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import axios from "axios";
import Fund from './ModalFund';
import Footer from './Footer';
import logos from '../../img/squaremart-logo.png'
import googlelogo from '../../img/Google - png 0.png'
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Authenticate } from '../Redux/AuthSlice';
import logo from '../../img/squaremart-logo.png'
import connect from '../../img/sign-coin-logo.png'
import { setmessage } from '../Redux/Createslice';
import { GoogleLogin } from '@react-oauth/google';
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from 'react-icons/ai';
import { FaBitcoin, FaClock, FaQuestion, FaRegEye, FaRegEyeSlash, FaTimes } from 'react-icons/fa';
import { MdOutlineCardGiftcard } from 'react-icons/md';
import { SiCoinmarketcap } from 'react-icons/si';
import Google from './Google';
import loadimg from '../../img/bx_loader-circle.png'
import DesktopHeader from '../Headers';
import MobileHeaders from '../Header'
const Login = () => {
    /***************** Google Auth *******/
    const{loginbutton}=useSelector((state)=>state.Createslice)
    function Googled() {

        window.location.href = 'api/authorized/google'
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    const Navigate = useNavigate()
  
    const accesstoken = sessionStorage.getItem("authenticated");
    /************ Direct login from purchase product ***/
    useEffect(() => {
        const cartId = urlParams.get('purchase');
        if (cartId) {
            localStorage.setItem('cartID', cartId);
        }
// console.log(cartId)
        async function auth() {
        
            if (token) {
                sessionStorage.setItem("authenticated", token);
                if(localStorage.getItem('cartID')){
                 
                   Navigate('/Purchase', { replace: true })
                }
                else{
                 Navigate('/Dashboard', { replace: true })
                }
            }
            else if (accesstoken) {
                if(localStorage.getItem('cartID')){
                    Navigate('/Purchase', { replace: true })
                }
                else
                {
                     Navigate('/Dashboard', { replace: true })
                }
               

            }

        }
        auth()
       
    })
    /******************* End ********/
    /**** Field Login *******************/
    const [datafield,setdatafield]=useState()
    const[Loading,setLoading]=useState(false)
    function HandlerSubmit(e)
    {
        setCounter()
        seterrorfield()
        dispatch(setmessage())
        e.preventDefault()
        setLoading(true)
    }
function DataLogin(event)
{
    setLoading(false)
    setCounter()
    dispatch(setmessage())
    seterrorfield()
    const { name, value, type, checked } = event.target;

        setdatafield((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
}
const {message}=useSelector((state)=>state.Createslice)
const [errorfield,seterrorfield]=useState()
const dispatch=useDispatch()
useEffect(()=>{
    async function Login()
    {
        if(Loading)
        {
    axios.post(`api/loginUser`,datafield)
    .then((res)=>{
        sessionStorage.setItem('emailfactor',`${datafield.email}`)
        if(localStorage.getItem('cartID')){
            if(res.data.success == false)
         {
            setLoading(false)
            setCounter(res.data.count)
            seterrorfield(res.data.errorfield)
            dispatch(setmessage(res.data.message))
          //  console.log(res.data.message)
                   }
            if(res.data.status == 201)
            {
               sessionStorage.setItem("TOTP", true);
                
               Navigate('/TwoFactor', { replace: true })
            }
            else if(res.data.success == true){
            sessionStorage.setItem("authenticated", res.data.token);
            Navigate('/Purchase', { replace: true })
            }
         }
         else if(res.data.success == false)
         {
           setLoading(false)
            setCounter(res.data.count)
            seterrorfield(res.data.errorfield)
            dispatch(setmessage(res.data.message))
           // console.log(res.data.message)
                   }
         else if(res.data.status == 201)
         {
            sessionStorage.setItem("TOTP", true);
             
            Navigate('/TwoFactor', { replace: true })
         }
         else if(res.data.success == true){
            sessionStorage.setItem("authenticated", res.data.token);
        Navigate('/Dashboard', { replace: true })
         }
       
    })
    }
}
    Login();
   
})
const link = {
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: '20px'
}
const { showChat }=useSelector((state)=>state.Chatslice)
const {size}=useSelector((state)=>state.WindowSize)
  
 const[showmenu,setshowmenu]=useState(false)
function Menumobile(){
 setshowmenu((previus) => !previus)
 }
    /******************** END ***************/
    /******************** Time left to sign **************/
    const [counter, setCounter] = useState(); // set counter to 30 minutes (in seconds)

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
  
      return () => {
        clearInterval(intervalId);
      };
    }, []);
    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;
  const disabledbutton={
    pointerEvents: 'none',
  opacity: '0.5'
  }
    /********************** End *********/
       /********************** PASSWORD SHOW ***/
       const [showPassword, setShowPassword] = useState(false);

       const togglePasswordVisibility = () => {
         setShowPassword(!showPassword);
       };
  
       const inputType = showPassword ? 'text' : 'password';
     
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
        <DesktopHeader/>
<MobileHeaders/>
         <div className="container">

          <div className="signin-layout">
        <div className="layout-section">
            <div className="squaremart">
                <div className="square-log">
                        {/* <img src={logo}
                         alt="Squaremart logo"/> */}
                    <div className="sign-in"  style={{ width:'100%' }}>
                        <h1 style={{ color:'#000000'}}>Log in</h1>
                        <Google/>
                        {/* <button onClick={Googled}><img src={googlelogo} 
                        alt="Continue with google"/></button> */}
                    </div>
                </div>
                <div className="form-control">
              {counter > 0 && counter ? <div className="time">
                       
                       <FaClock />  <p>Remaining time: <span>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
       </span></p>
       </div> : null}  
    { (counter > 0 && counter ) || errorfield ? 
        <div className= 'error-field'>
       
                              <div className= 'error' >
                                    {message}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                    <form onSubmit={HandlerSubmit} method="post">
                        <label htmlFor="email">Email Address</label>
                            <input type="email" name='email' onChange={DataLogin} placeholder="Enter your Email" id="email" required/>
                        <label htmlFor="password">Password</label>
                        <span className="spann">          
                          <input type={inputType} name='password' onChange={DataLogin} placeholder="Enter your Password" id="password" required/>
                          
                            {showPassword ? (
        <FaRegEyeSlash
        className="eye-icon"
          onClick={togglePasswordVisibility}
        />
      ) : (
        <FaRegEye
        className="eye-icon"
          onClick={togglePasswordVisibility}
        />
      )} </span> 
                            <span className="first-time"><Link to="/ForgetPassword" style={{ color:'#000000'}}>Forget Password ? <span style={{ color:'#41688A'}}>Reset Now</span></Link></span>
                        <button className="btn-create" style={(counter > 0 && counter) ? disabledbutton : {cursor : 'pointer'}}
                         type="submit">{Loading || loginbutton ? <img src={loadimg} className="spin-icon" /> :'Login'}</button>
                        <span className="first-time">Don't have an account? <Link to="/register" style={{ color:'#41688A'}}>Sign up</Link></span>
                    </form>
                </div>
            </div>
            <div className="squaremart-illustrate">
                <img className="coin-illustrate" 
                src={connect} alt="Connect with us"/>
            </div>
        </div>
    </div>
    {showmenu ? 
                     <div id="mySidenav" className= "sidenav" >
                        <span className="closebtn" onClick={Menumobile} >{showmenu ? <FaTimes/> :null}</span>  
 
                       
                          <ul>
                          <li> <Link to="/"><AiOutlineHome className="icon" /><span>Home</span></Link> </li>
                          <li> <Link to="/trade"><FaBitcoin className="icon" />Buy with Crypto</Link> </li>
                          <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li>

                                    <li> <Link to="/trade"><MdOutlineCardGiftcard className="icon" /><span>Gift cards</span></Link> </li>

                                    {/* <li> <Link to="/Marketplace"><SiCoinmarketcap className="icon" />Marketplace</Link> </li> */}
                                    <li> <Link to="/Dashboard"><AiOutlineWallet className="icon" /> Wallet</Link> </li>
                                    <li> <Link to="/"><FaQuestion className="icon" />Help</Link> </li>

                            <li className="login">
                            <Link style={link} to='/register'>Register</Link></li>
                        
                          </ul>
                        
                 
              </div>
              : null }
    {/* <Footer/> */}
    </div>
            {/* <button onClick={Googled}><img src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png" alt="" /></button>
        
       <form onSubmit={HandlerSubmit}> 
          <input type='email' name='email' onChange={DataLogin}/>
            <input type='type' name='password' onChange={DataLogin}/>
        <button>Login</button>
          </form>     */}
        </div>
    )
}
export default Login
