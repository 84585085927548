import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 
    IsFirstRender:false,

}

export const RenderFirst = createSlice ({
  name: 'counter',
  initialState,
  reducers: {
  
    setIsFirstRender: (state,action) => {
      state.IsFirstRender = action.payload
    },
   
  
  },
})

// Action creators are generated for each case reducer function
export const { setIsFirstRender } = RenderFirst.actions

export default RenderFirst.reducer