import React, { useState } from 'react'
import {FaAngleDown, FaTimes} from 'react-icons/fa'
import { FaCartArrowDown } from 'react-icons/fa'
import tag from '../../img/tags.png'
import { CgDollar } from 'react-icons/cg'
const Modal=(props)=>
{
    
    return (
        <div className="modals">
        <div className="modal-content">
          <span className="close"><FaTimes  onClick={props.ModalClose}/></span>
         <div className="content">
            <h1>{props.title}</h1>
            <div className="img">
            <img src={props.product_img} alt=""/>
        </div>
            <div className="flexbox">
                <div className="price">
                    <label htmlFor="">Amount in USD</label>
                    <p><CgDollar/>{props.price}</p>
                </div>
                <div className="location">
                    <label htmlFor="location">location</label>
                    <p>{props.location}</p>
                </div>
            </div>
            <div className="tags">
                <img src={tag} alt="tag"/>
                <div className="tag-items">
                    <p>{props.tag}</p>
                    {/* <p>xbox</p>
                    <p>console</p> */}
                </div>
            </div>
            <div className="description">
                <h1>Ad Description</h1>
                <textarea name="" id="" disabled placeholder={props.description}></textarea>
                <div className="btn">
               <button id="purchase" onClick={props.CartItem}>Purchase Item</button>
                                        </div>

            </div>

         </div>
        </div>
      </div>
    )
}
export default Modal