import React, { useEffect, useState } from "react";
import Auth from "../Auth";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { SiVerizon } from "react-icons/si";
import { FaBackward, FaCloudversify, FaMarker, FaRegThumbsDown, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import axios from "axios";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setfetchUsdt } from "../../Redux/SwapCard";
import { AiOutlineRollback } from "react-icons/ai";
import Tradepagevalidat from "./Tradepagevalidate";

const Systemexchange=()=>
{
     const Navigate = useNavigate()
     const{usdt_buy_id,fetchUsdt}=useSelector((state)=>state.SwapCard)
   
    const dispatch=useDispatch()
   
   /************* USDT ID *********/
 
//    const[fetchUsdt,setfetchUsdt]=useState({})
  
 useEffect(() => {
        async function SelectUsdt() {

            axios
                .get(`api/fetchUsdtId/${usdt_buy_id}`)
                .then((response) => {
                    dispatch(setfetchUsdt({
                        ...fetchUsdt,
                        ...response.data
                    }));
                      
                })

        }
        SelectUsdt()
    }, [usdt_buy_id])
    /****************** FINAL TRADE ******/
     
    function Finaltrade(e)
    {
       e.preventDefault()
         Navigate("/Finaltrade" ,{ replace: true });
    }
    /**************** END **********/
    return (
        <>
            <Tradepagevalidat/>
        <Auth/>
        <div className="container-fluid">
            <Sidebar />
            <header>
                <Header />
            </header>
            <hr />
            <div className="terms">
                <div className="head-back">
                <h1> 
    <Link to='/BuyUsdt'> <IoIosArrowBack /></Link><span>
                    Read terms before proceeding to send giftcard</span></h1>
              
                </div>
               
                <div className="conditions">
                    <div className="head">
                        <h2> USDT/GIFT CARD SYSTEM EXCHANGE</h2>
                    </div>
                    <p>
                        
                        Once you agree to the seller’s offer terms for this transaction you can proceed with the 
                        trade any breach in offer the terms would a penalty to you and the trade would be cancelled. 
                    </p>
               
                    {/* <div className="time">
                        <p>
                           <span><img src="img/clock.png" alt=""/></span>
                            <span>Time left to pay: </span>
                            <span> 00:00</span>  
                            continue to start trade
                        </p>
                    </div> */}
                    <div className="about-seller">
                
                        <div className="seller">
                            <h2>About Seller</h2>
                            <div className="seller-items">
                                <div className="items-grid">
                                    <div className="grid-box green">
                                        <ul>
                                            <li>+{fetchUsdt.likes}</li>
                                            <li><FaThumbsUp/></li>
                                        </ul>
                                          <p>Positive Feedback</p>

                                    </div>
                                     <div className="grid-box red">
                                        <ul>
                                            <li>-{fetchUsdt.dislike}</li>
                                            <li><FaThumbsDown/></li>
                                        </ul>
                                          <p>Negative Feedback</p>

                                    </div>
                                       
                                </div>
                                <div className="items">
                                    <h3>Verification</h3>
                                    <div className="btn">verified</div>
                                    

                                </div>
                                <div className="items">
                                    <h3>Number of successful trade</h3>
                                    <small>{fetchUsdt.successful_trade}</small>
                                </div>
                                <div className="items">
                                       <h3>Average trade per day</h3>
                                    <small>3</small>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="seller-terms">
                            <div className="termsof">
                                {/* <div className="conditions">
                                    <h4>seller terms</h4>
                                    <ul>
                                 <div dangerouslySetInnerHTML={{ __html: fetchUsdt.terms }}
                                           />
                                      
                                    </ul>
                                </div> */}
                                <form onSubmit={Finaltrade}>
                                <div className="cont">
                                    <button  style={{ cursor:'pointer' }}><span>
                                    Continue</span></button>
                                </div>
                                </form>
                              
                            </div>
                       
                    </div>
                </div>
               
            </div>
            </div>
            </>
    )
}
export default Systemexchange