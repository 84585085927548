import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  count: 0,
  allProduct:[],
  dataNotFound:'',
  completetrade:[],
  paystackModal:false,
  message:'',
  msg:'',
  countries:[],
  Attempt:'',
  dailylimit:'',
  Categories:[],
  lockbutton:false,
  loginbutton:false,
  category_name:false,
  notificationbox:{},
  SettingNav:false,
  allBanks:[]
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
           state.count += 1
    },
    decrement: (state) => {
      state.count -= 1
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload
    },
    setloginbutton:(state, action) => {
      state.loginbutton = action.payload
    },
    setallProduct: (state, action) => {
      state.allProduct = action.payload
    },
    setlockbutton: (state, action) => {
      state.lockbutton = action.payload
    },
    setdataNotFound: (state, action) => {
      state.dataNotFound = action.payload
    },
    setcomplete: (state, action) => {
      state.completetrade = action.payload
    },
    setcategory_name: (state, action) => {
      state.category_name = action.payload
    },
    setCategory: (state, action) => {
      state.Categories = action.payload
    },
    setpaystackModal: (state,action) => {
      state.paystackModal = action.payload
    },
    setSettingNav: (state,action) => {
      state.SettingNav = action.payload
    },
    setmessage: (state,action) => {
      state.message = action.payload
    },
    setmsg: (state,action) => {
      state.msg = action.payload
    },
    setnotificationbox:(state, action) => {
      state.notificationbox = action.payload
    },
    setdailylimit: (state, action) => {
      state.dailylimit = action.payload
    },
    setAttempt: (state, action) => {
      state.Attempt = action.payload
    },
    setcountries: (state, action) => {
      state.countries = action.payload
    },
    setallBanks: (state, action) => {
      state.allBanks = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setallBanks,setSettingNav, setcategory_name,setcountries,setCategory,setAttempt,increment,setdailylimit,setmsg,setnotificationbox,setloginbutton,setlockbutton,setmessage,setpaystackModal,setcomplete,setdataNotFound, decrement,setallProduct, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer