import axios from "axios";
import React, { useEffect, useState }  from "react";
import { setAttempt, setallProduct, setdataNotFound } from "../Redux/Createslice";
import { useDispatch, useSelector } from "react-redux";
import { setallproductLoad } from "../Redux/Balanceslice";
const Dataquery=()=>
{
     /***************** Ip address *********/
     const{allproductLoad}=useSelector((state=>state.Balanceslice))

    useEffect(() => {
if(!localStorage.getItem('desktop_ip')){
    const prefix = "DESK";
    const suffix = "TOP";
    const date = new Date().toISOString().slice(2, 7).replace("-", "");
    const randomNumber = Math.floor(Math.random() * 10000);
    const paddedNumber = randomNumber.toString().padStart(4, "0");
    localStorage.setItem('desktop_ip',(`${prefix}${date}${paddedNumber}${suffix}`));
                    
    }
    }, [localStorage.getItem('desktop_ip')])
    /***************** END  ***********/

    const dispatch=useDispatch()
    const headers = {
        'Content-Type': 'application/json',
        'allowed_origins': '*',
    };
    /************************ Location Country   **********/
useEffect(() => {
    if(allproductLoad === 0){
    // Send request to check page load count
    axios.get('/api/allProduct')
      .then(response => {
        dispatch(setallProduct(response.data));
        localStorage.setItem('cached', JSON.stringify(response.data))
        dispatch(setdataNotFound('Product Table is Currently Empty'))
         dispatch(setallproductLoad(allproductLoad + 1));
      })
      .catch(error => {
        dispatch(setAttempt('Error Reload the page'));
           })
    };
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    if (allproductLoad !== 0) {
      // Delay subsequent requests by 1 minute
      const delay = setTimeout(() => {
        // Send request after delay
        axios.get('/api/allProduct')
          .then(response => {
            dispatch(setallProduct(response.data));
            localStorage.setItem('cached', JSON.stringify(response.data))
            dispatch(setdataNotFound('Product Table is Currently Empty'))
           dispatch(setallproductLoad(allproductLoad + 1));
    
          })
          .catch(error => {
            dispatch(setAttempt('Error Reload the page'));
          });
      }, 60000); // 1 minute in milliseconds

      // Clean up the timeout to prevent memory leaks
      return () => clearTimeout(delay);
    }
  }, [allproductLoad]);
    // useEffect(() => {

    //     async function AllProduct() {
    //        {
    //             axios
    //                 .get(`api/allProduct`,
    //                     {
    //                         headers: headers
    //                     })
    //                 .then((response) => {
    //                        dispatch(setallProduct(response.data));
    //                     localStorage.setItem('cached', JSON.stringify(response.data))
    //                     dispatch(setdataNotFound('Product Table is Currently Empty'))

    //                 })
    //         }
    //     }
    //     AllProduct()
    // }, [])
return (
    <>
    </>
)
}
export default Dataquery