import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import axios from "axios";
import Fund from '../pages/ModalFund';
import Footer from './Footer';
import logos from '../../img/squaremart-logo.png'
import googlelogo from '../../img/Google - png 0.png'
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Authenticate } from '../Redux/AuthSlice';
import logo from '../../img/squaremart-logo.png'
import connect from '../../img/sign-coin-logo.png'
import { setmessage } from '../Redux/Createslice';
import { GoogleLogin } from '@react-oauth/google';
import { AiOutlineHome, AiOutlineMenu, AiOutlineWallet } from 'react-icons/ai';
import { FaBitcoin, FaClock, FaQuestion, FaTimes } from 'react-icons/fa';
import { MdOutlineCardGiftcard } from 'react-icons/md';
import { SiCoinmarketcap } from 'react-icons/si';
import Google from '../pages/Google';
import loadimg from '../../img/bx_loader-circle.png'

const Adminlogin = () => {
    /***************** Google Auth *******/
    const{loginbutton}=useSelector((state)=>state.Createslice)
   

    const Navigate = useNavigate()
  
    const accesstoken = sessionStorage.getItem("admin_authenticated");
   
    useEffect(() => {
        async function auth() {
          if (accesstoken){
                     Navigate('/Admin/Dashboard', { replace: true })
            
            }

        }
        auth()
       
    })
    /******************* End ********/
    /**** Field Login *******************/
    const [datafield,setdatafield]=useState()
    const[Loading,setLoading]=useState(false)
    function HandlerSubmit(e)
    {
        setCounter()
        seterrorfield()
        dispatch(setmessage())
        e.preventDefault()
        setLoading(true)
    }
function DataLogin(event)
{
    setLoading(false)
    setCounter()
    dispatch(setmessage())
    seterrorfield()
    const { name, value, type, checked } = event.target;

        setdatafield((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: type === "checkbox" ? checked : value,
            };
            return updatedData;
        });
}
const {message}=useSelector((state)=>state.Createslice)
const [errorfield,seterrorfield]=useState()
const dispatch=useDispatch()
useEffect(()=>{
    async function Login()
    {
        if(Loading)
        {
    axios.post(`api/admin/login`,datafield)
    .then((res)=>{
        sessionStorage.setItem('email_factor',`${datafield.email}`)
        if(res.data.success == false)
         {
           setLoading(false)
            setCounter(res.data.count)
            seterrorfield(res.data.errorfield)
            dispatch(setmessage(res.data.message))
       }
         else if(res.data.status == 201)
         {
            sessionStorage.setItem("Admin_TOTP", true);
             
            Navigate('/Admin/TwoFactor', { replace: true })
         }
         else if(res.data.success == true){
            sessionStorage.setItem("admin_authenticated", res.data.token);
        Navigate('/Admin/Dashboard', { replace: true })
         }
       
    })
    }
}
    Login();
   
})
const link = {
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: '20px'
}
const { showChat }=useSelector((state)=>state.Chatslice)
const {size}=useSelector((state)=>state.WindowSize)
  
 const[showmenu,setshowmenu]=useState(false)
function Menumobile(){
 setshowmenu((previus) => !previus)
 }
    /******************** END ***************/
    /******************** Time left to sign **************/
    const [counter, setCounter] = useState(); // set counter to 30 minutes (in seconds)

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
  
      return () => {
        clearInterval(intervalId);
      };
    }, []);
    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;
  const disabledbutton={
    pointerEvents: 'none',
  opacity: '0.5'
  }
    /********************** End *********/
    return (
        <div style={(showChat || showmenu )&& size < 676 ? { position: 'fixed', } :{ position: 'relative', }}>
       
         <div className="container">

        
          <div className="signin-layout">
        <div className="layout-section">
            <div className="squaremart">
            <div className="sign-in"  style={{ width:'100%' }}>
                        <h1 style={{ color:'#000000'}}>Admin Log in</h1>
                       
                    </div>
                <div className="form-control">
              {counter > 0 && counter ? <div className="time">
                       
                       <FaClock />  <p>Remaining time: <span>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
       </span></p>
       </div> : null}  
    { (counter > 0 && counter ) || errorfield ? 
        <div className= 'error-field'>
       
                              <div className= 'error' >
                                    {message}
                                
                        </div>
                        
                        </div>
                        : null
                        }
                    <form onSubmit={HandlerSubmit} method="post">
                        <label htmlFor="email">Email Address</label>
                            <input type="email" name='email' onChange={DataLogin} placeholder="Email" id="email" required/>
                        <label htmlFor="password">Password</label>
                            <input type="password" name='password' onChange={DataLogin} placeholder="Password" id="password" required/>
                             <button className="btn-create" style={(counter > 0 && counter) ? disabledbutton : {cursor : 'pointer'}}
                         type="submit">{Loading || loginbutton ? <img src={loadimg} className="spin-icon" /> :'Login'}</button>
                                         </form>
                </div>
            </div>
            <div className="squaremart-illustrate">
                <img className="coin-illustrate" 
                src={connect} alt="Connect with us"/>
            </div>
        </div>
    </div>
  
    </div>
           
        </div>
    )
}
export default Adminlogin